import { LoadingButton } from "@mui/lab"
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material"
import { useFormik } from "formik"
import { StatusCodes } from "http-status-codes"
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { object, string } from "yup"
import { useApplicationProperties } from "../../../../../../hooks/useApplicationProperties"
import { digitalManagerApi } from "../../../../../../services/api"
import { BackToURL } from "../../../../../components/BackToURL"
import { ApplicationPermissionUserGet } from "../../../../../models/ApplicationPermissionUser"
import {
  ApplicationPropertyDetail,
  ApplicationPropertyGet,
  ApplicationPropertyPossibleValue,
} from "../../../../../models/ApplicationProperty"

interface PropertyPossibleValues {
  [key: string]: ApplicationPropertyPossibleValue[]
}

const validationSchema = object({
  propertyName: string().required("Property name field is required"),
  propertyPossibleValue: object({ value: string().required() }),
})

const handleAutocompleteGetOptionLabel = (
  propertyValue: ApplicationPropertyPossibleValue
) => propertyValue.label

const handleAutocompleteRenderInput = (params: AutocompleteRenderInputParams) => (
  <TextField {...params} />
)

export function UserApplicationPermissionPropertyCreate() {
  const { userId, applicationId, permissionId } = useParams()
  const { applicationProperties, isLoadingApplicationProperties } =
    useApplicationProperties(applicationId, false, true)

  const [
    userApplicationPermissionPropertiesIds,
    setUserApplicationPermissionPropertiesIds,
  ] = useState<ApplicationPropertyDetail[]>([])
  const navigate = useNavigate()
  const returnURL = `/users/${userId}/applications/${applicationId}/permissions/${permissionId}#properties`

  const propertyValues: PropertyPossibleValues = useMemo(() => {
    const propertyValuesDict: PropertyPossibleValues = {}
    if (applicationProperties?.length) {
      applicationProperties.forEach((property: ApplicationPropertyGet) => {
        propertyValuesDict[property.name] = property.possibleValues
      })
    }
    return propertyValuesDict
  }, [applicationProperties])

  useEffect(() => {
    if (userId && applicationId && permissionId) {
      digitalManagerApi
        .get<ApplicationPermissionUserGet>(
          `/api/v1/users/${userId}/applications/${applicationId}/permissions/${permissionId}`
        )
        .then((res) => {
          if (res.status === StatusCodes.OK && res.data && res.data.properties.length) {
            const rows: ApplicationPropertyDetail[] = []
            res.data.properties.forEach((property: ApplicationPropertyDetail) => {
              rows.push({
                name: property.name,
                value: property.value,
              })
            })
            setUserApplicationPermissionPropertiesIds(rows)
          }
        })
        .catch((err) => {
          if (err.response.status) {
            navigate(returnURL)
          }
        })
    }
  }, [applicationId, navigate, returnURL, permissionId, userId])

  const formik = useFormik({
    validationSchema,
    initialValues: {
      propertyName: "",
      propertyPossibleValue: { value: "", label: "" },
    },
    onSubmit: (values, { setSubmitting }) => {
      digitalManagerApi
        .post(
          `/api/v1/applications/${applicationId}/properties/${values.propertyName}/values`,
          {
            propertyValue: values.propertyPossibleValue.value,
            userId,
            permissionId,
          }
        )
        .then((res) => {
          if (res.status === StatusCodes.CREATED) {
            toast.success("Property added to user's permission")
            navigate(returnURL)
          }
        })
        .catch(() => {
          toast.error("Cannot add property to user's permission")
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
  })

  const handleChangeSelectPropertyName = useCallback(
    (event: SelectChangeEvent) => {
      formik.setValues({
        ...formik.values,
        propertyName: event.target.value,
        propertyPossibleValue: { value: "", label: "" },
      })
    },
    [formik]
  )

  const handleChangeFormikValues = useCallback(
    (
      _e: SyntheticEvent<Element, Event>,
      appPropertyValue: ApplicationPropertyPossibleValue | null
    ) => {
      if (appPropertyValue) {
        formik.setValues({
          ...formik.values,
          propertyPossibleValue: {
            value: appPropertyValue.value,
            label: appPropertyValue.label,
          },
        })
      }
    },
    [formik]
  )

  const handleAutocompleteGetOptionDisabled = useCallback(
    (option: ApplicationPropertyPossibleValue) => {
      const optionAlreadySelected = userApplicationPermissionPropertiesIds.find(
        (applicationRolePermissionId: ApplicationPropertyDetail) =>
          formik.values.propertyName === applicationRolePermissionId.name &&
          option.value === applicationRolePermissionId.value
      )
      return !!optionAlreadySelected
    },
    [formik.values.propertyName, userApplicationPermissionPropertiesIds]
  )

  const submitForm = useCallback(() => {
    formik.submitForm()
  }, [formik])

  const isOptionEqualValue = useCallback(
    (option, label) => option.value === label.value,
    []
  )

  return (
    <Box>
      <BackToURL url={returnURL} label="Back to user's permission" />
      <Paper>
        <Card>
          <CardHeader
            title="Add property to user's permission"
            action={
              <LoadingButton
                variant="contained"
                type="submit"
                loading={formik.isSubmitting}
                onClick={submitForm}
                disabled={!formik.dirty || !formik.isValid}
              >
                Save
              </LoadingButton>
            }
          />
          <CardContent
            sx={{
              padding: 0,
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
          >
            <Divider />
            <List
              sx={{
                width: "100%",
                bgcolor: "Background.paper",
              }}
            >
              <ListItem key={0}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Property Name</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Select
                        id="propertyName"
                        name="propertyName"
                        value={formik.values.propertyName}
                        onChange={handleChangeSelectPropertyName}
                        disabled={isLoadingApplicationProperties}
                        size="small"
                        displayEmpty
                        fullWidth
                      >
                        {!isLoadingApplicationProperties &&
                          applicationProperties.map(
                            (property: ApplicationPropertyGet) => (
                              <MenuItem value={property.name} key={property.name}>
                                {property.name}
                              </MenuItem>
                            )
                          )}
                      </Select>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={1}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Property Value</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        id="propertyValues"
                        options={propertyValues[formik.values.propertyName] ?? []}
                        getOptionLabel={handleAutocompleteGetOptionLabel}
                        getOptionDisabled={handleAutocompleteGetOptionDisabled}
                        isOptionEqualToValue={isOptionEqualValue}
                        fullWidth
                        disableClearable
                        size="small"
                        renderInput={handleAutocompleteRenderInput}
                        disabled={!formik.values.propertyName}
                        onChange={handleChangeFormikValues}
                        value={formik.values.propertyPossibleValue}
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Paper>
    </Box>
  )
}
