import { ReactJSXElement } from "@emotion/react/types/jsx-namespace"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { LoadingButton } from "@mui/lab"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  Switch,
  Typography,
} from "@mui/material"
import { useFormik } from "formik"
import { StatusCodes } from "http-status-codes"
import { useCallback, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { digitalManagerApi } from "../../../../services/api"
import { DiMaProgress } from "../../../components/DiMaProgress"
import { ApproverSendEmail, UserApproverGet } from "../../../models/User"

const appApproverRolesListHeader: ReactJSXElement = (
  <ListItem key="list-header" divider>
    <ListItemText>
      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid item xs={3}>
          <Typography variant="h6">Role</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h6">Approver type</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6">Properties</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6">Receive emails</Typography>
        </Grid>
      </Grid>
    </ListItemText>
  </ListItem>
)

export function EmailSettings() {
  const [isLoadingRoles, setIsLoadingRoles] = useState<boolean>(true)
  const [approverRoles, setApproverRoles] = useState<UserApproverGet[]>([])

  const formikSendEmail = useFormik<ApproverSendEmail[]>({
    initialValues: [],
    onSubmit: (formikValues, { setSubmitting }) => {
      handleSaveSettings(formikValues, setSubmitting)
    },
  })

  useEffect(() => {
    digitalManagerApi
      .get<UserApproverGet[]>(`/api/v1/users/currentUser/approvers`)
      .then((res) => {
        if (res.status === StatusCodes.OK) {
          setApproverRoles(res.data)
          setIsLoadingRoles(false)

          const tempApproverSendEmail: Array<ApproverSendEmail> = []
          res.data.forEach((approver) => {
            const tempApprover = {
              approverId: approver.id,
              sendEmail: approver.sendEmail,
            }
            tempApproverSendEmail.push(tempApprover)
          })

          formikSendEmail.resetForm({ values: tempApproverSendEmail })
        }
      })
      .catch(() => {
        toast.error("Cannot retrieve user's approver roles")
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSwitchSendEmail = useCallback(
    (formikApproverIndex: number, newValue: boolean) => {
      formikSendEmail.setFieldValue(`${formikApproverIndex}.sendEmail`, newValue)
    },
    [formikSendEmail]
  )

  const handleSaveSettings = useCallback(
    (
      formikValues: ApproverSendEmail[],
      setSubmitting: (isSubmitting: boolean) => void
    ) => {
      digitalManagerApi
        .put(`/api/v1/users/currentUser/settings/approvers`, {
          approverSendEmails: formikValues,
        })
        .then(() => {
          toast.success("User's email settings updated")
          formikSendEmail.resetForm({ values: formikValues })
        })
        .catch(() => {
          toast.error("Cannot update user's email settings")
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
    [formikSendEmail]
  )

  const handleDisableAllSettings = useCallback(() => {
    formikSendEmail.values.forEach((value, index) => {
      if (value.sendEmail) {
        formikSendEmail.setFieldValue(`${index}.sendEmail`, false)
      }
    })
  }, [formikSendEmail])

  // Group approver roles by application and order by role
  const approverRolesByApplication = new Map<string, UserApproverGet[]>()
  approverRoles.forEach((approverRole) => {
    if (approverRolesByApplication.has(approverRole.application.name)) {
      approverRolesByApplication.get(approverRole.application.name)?.push(approverRole)
    } else {
      approverRolesByApplication.set(approverRole.application.name, [approverRole])
    }
  })

  const appsAccordions: Array<ReactJSXElement> = []
  approverRolesByApplication.forEach((mapValue, mapKey) => {
    const appApproverRolesListItems: Array<ReactJSXElement> = []

    // Order approverRolesByApplication's array by role.name (alphanumeric ascending)
    mapValue.sort((a, b) => {
      if (a.role.name > b.role.name) {
        return 1
      } else {
        return b.role.name > a.role.name ? -1 : 0
      }
    })

    appApproverRolesListItems.push(appApproverRolesListHeader)
    mapValue.forEach((appApproverRole) => {
      // The same 'appApproverRole' object has a different index in the Map ('appsAccordions') and in Formik ('formikSendEmail')
      // We can identify the index of the current Map's object in Formik using the object ID
      const formikApproverIndex = formikSendEmail.values.findIndex(
        (formikValue) => formikValue.approverId === appApproverRole.id
      )

      appApproverRolesListItems.push(
        <ListItem key={appApproverRole.id} divider>
          <ListItemText>
            <Grid
              container
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid item xs={3}>
                {appApproverRole.role.name}
              </Grid>
              <Grid item xs={3}>
                {appApproverRole.approverType}
              </Grid>
              <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
                {appApproverRole.property.value
                  ? `${appApproverRole.property.name}: ${appApproverRole.property.label}`
                  : "Generic"}
              </Grid>
              <Grid
                item
                xs={2}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Switch
                  checked={
                    formikApproverIndex !== -1 &&
                    formikSendEmail.values[formikApproverIndex].sendEmail
                  }
                  onChange={(event) => {
                    handleSwitchSendEmail(formikApproverIndex, event.target.checked)
                  }}
                />
              </Grid>
            </Grid>
          </ListItemText>
        </ListItem>
      )
    })

    appsAccordions.push(
      <Accordion key={mapKey} defaultExpanded={true} sx={{ mb: "16px" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ width: "33%", flexShrink: 0 }}>{mapKey}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />
          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
            }}
          >
            {appApproverRolesListItems}
          </List>
        </AccordionDetails>
      </Accordion>
    )
  })

  return (
    <>
      {isLoadingRoles && <DiMaProgress />}
      {!isLoadingRoles && (
        <>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              alignItems: "center",
              justifyContent: "end",
              column: "10px",
              marginBottom: "16px",
            }}
          >
            <Button variant="contained" onClick={handleDisableAllSettings}>
              Disable all
            </Button>
            <LoadingButton
              variant="contained"
              onClick={formikSendEmail.submitForm}
              loading={formikSendEmail.isSubmitting}
              disabled={!formikSendEmail.dirty}
            >
              Save
            </LoadingButton>
          </Stack>
          <Box>{appsAccordions}</Box>
        </>
      )}
    </>
  )
}
