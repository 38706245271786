export const downloadBlob = (file: Blob, filename: string) => {
  const url = window.URL.createObjectURL(file)
  const link = document.createElement("a")
  link.href = url
  link.setAttribute("download", filename)
  document.body.appendChild(link)
  link.click()
  if (link.parentNode) {
    link.parentNode.removeChild(link)
  }
}
