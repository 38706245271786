import { useEffect } from "react"
import { msalInstance } from "../../../App"

export function Logout() {
  useEffect(() => {
    msalInstance.logoutRedirect()
  }, [])

  return <>Loggin out...</>
}
