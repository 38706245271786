import { TabContext, TabList } from "@mui/lab"
import { Box, Grid } from "@mui/material"
import { useCallback, useContext, useEffect, useState } from "react"
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom"
import { digitalManagerApi } from "../../../../../services/api"
import { BackToURL } from "../../../../components/BackToURL"
import {
  DiMaDetailsHeader,
  DiMaDetailsHeaderProps,
} from "../../../../components/DiMaDetailsHeader"
import { DiMaProgress } from "../../../../components/DiMaProgress"
import { DiMaTabPanel } from "../../../../components/DiMaTabPanel"
import { LinkTab } from "../../../../components/LinkTab"
import { UserContext } from "../../../../context/UserContext"
import { ApplicationRoleGet } from "../../../../models/ApplicationRole"
import {
  AccessLevel,
  DimaFeatureShortNameEnum,
  getAppFeatureAccessLevelByAppId,
} from "../../../../models/Authorization"
import { ApplicationRoleApprovers } from "./approvers_tab/ApplicationRoleApprovers"
import { ApplicationRoleDetails } from "./details_tab/ApplicationRoleDetails"
import { ApplicationRolePermissions } from "./permissions_tab/ApplicationRolePermissions"
import { ApplicationRoleUsers } from "./users_tab/ApplicationRoleUsers"

export function ApplicationRoleContainer() {
  const { user, isLoadingUser } = useContext(UserContext)

  const { applicationId, roleId } = useParams()
  const [activeTab, setActiveTab] = useState<string>("#details")
  const [role, setRole] = useState<ApplicationRoleGet>()
  const navigate = useNavigate()
  const { hash } = useLocation()

  const returnURL = `/applications/${applicationId}#roles`
  const AVATAR_LENGTH = 2

  const handleChange = useCallback((_: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue)
  }, [])

  useEffect(() => {
    if (applicationId && roleId) {
      digitalManagerApi
        .get<ApplicationRoleGet>(`/api/v1/applications/${applicationId}/roles/${roleId}`)
        .then((res) => {
          setRole(res.data)
        })
        .catch((err) => {
          if (err.response.status) {
            navigate(returnURL)
          }
        })
    }
  }, [applicationId, navigate, returnURL, roleId])

  useEffect(() => {
    if (hash) {
      setActiveTab(hash)
    }
  }, [hash])

  if (!applicationId || !roleId) {
    return <Navigate to="/applications" />
  }

  if (!role || isLoadingUser || !user) {
    return <DiMaProgress />
  }

  const detailsHeaderProps: DiMaDetailsHeaderProps = {
    avatar: role.name.substring(0, AVATAR_LENGTH),
    title: role.name,
    label: "Role ID",
    subtitle: role.id,
  }

  const canUserAccessRoleUsers =
    user.authorizations &&
    getAppFeatureAccessLevelByAppId(
      applicationId,
      DimaFeatureShortNameEnum.RM,
      user.authorizations.applications
    ) >= AccessLevel.Read

  const canUserAccessRoleApprovers =
    user.authorizations &&
    getAppFeatureAccessLevelByAppId(
      applicationId,
      DimaFeatureShortNameEnum.AM,
      user.authorizations.applications
    ) >= AccessLevel.Read

  return (
    <>
      <BackToURL url={returnURL} label="Back to roles" />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <Grid container>
          <Grid item xs={10}>
            <DiMaDetailsHeader {...detailsHeaderProps} />
          </Grid>
        </Grid>
        <TabContext value={activeTab}>
          <Box className="DiMaBox-tabs">
            <TabList onChange={handleChange}>
              <LinkTab label="Details" value="#details" />
              <LinkTab label="Permissions" value="#permissions" />
              <LinkTab label="Users" value="#users" disabled={!canUserAccessRoleUsers} />
              <LinkTab
                label="Approvers"
                value="#approvers"
                disabled={!canUserAccessRoleApprovers}
              />
            </TabList>
          </Box>
          <DiMaTabPanel label="#details" activeTab={activeTab}>
            <ApplicationRoleDetails
              role={role}
              applicationId={applicationId}
              user={user}
              setRole={setRole}
            />
          </DiMaTabPanel>
          <DiMaTabPanel label="#permissions" activeTab={activeTab}>
            <ApplicationRolePermissions />
          </DiMaTabPanel>
          {canUserAccessRoleUsers && (
            <DiMaTabPanel label="#users" activeTab={activeTab}>
              <ApplicationRoleUsers />
            </DiMaTabPanel>
          )}
          {canUserAccessRoleApprovers && (
            <DiMaTabPanel label="#approvers" activeTab={activeTab}>
              <ApplicationRoleApprovers />
            </DiMaTabPanel>
          )}
        </TabContext>
      </Box>
    </>
  )
}
