import { ApplicationPropertyDetail } from "./ApplicationProperty"
import { ApplicationRoleApproverTypeEnum } from "./ApplicationRoleApprover"
import { Guid, UtcDateTime } from "./CustomType"
import { UserDetail } from "./User"

export enum RoleRequestApprovalStatusEnum {
  Approved = "Approved",
  Forwarded = "Forwarded",
  Open = "Open",
  Rejected = "Rejected",
}

export enum RoleRequestApprovalActionEnum {
  Approve = "Approve",
  Forward = "Forward",
  Reject = "Reject",
}

export interface RoleRequestApproval {
  id: Guid
  property: ApplicationPropertyDetail
  status: RoleRequestApprovalStatusEnum
  userApproverType: ApplicationRoleApproverTypeEnum
  completedBy: UserDetail | null
  completionDate: UtcDateTime | null
  completionComment: string
  forwardedBy: UserDetail | null
  forwardingDate: UtcDateTime | null
  forwardingComment: string | null
}
