import { Box } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { Link, useParams } from "react-router-dom"
import {
  ApplicationRoleApproverGet,
  ApplicationRoleApproverProperty,
} from "../../../../../../models/ApplicationRoleApprover"
import { DimaFeatureShortNameEnum } from "../../../../../../models/Authorization"
import { getBooleanFlagIcon } from "../../../../../../utils/getBooleanFlagIcon"
import { DiMaAddButton } from "../../../../../partials/DiMaAddButton"
import { EntityTab } from "../../../../../partials/EntityTab"

export function ApplicationRoleApproverProperties(props: {
  readonly approver: ApplicationRoleApproverGet
}) {
  const { approver } = props
  const { applicationId, roleId, userId } = useParams()

  const columns: GridColDef[] = [
    {
      field: "propertyName",
      headerName: "Property name",
      renderCell: (params) => (
        <Link
          to={`/applications/${applicationId}/roles/${roleId}/approvers/${userId}/properties/${params.row.propertyName}/values/${params.row.propertyValue}`}
        >
          {params.row.propertyName}
        </Link>
      ),
      flex: 2,
    },
    {
      field: "propertyLabel",
      headerName: "Property label",
      flex: 2,
    },
    {
      field: "approverType",
      headerName: "Approver Type",
      flex: 2,
    },
    {
      field: "isAutomatic",
      headerName: "Is Automatic",
      type: "boolean",
      renderCell: (params) => getBooleanFlagIcon(params.row.isAutomatic),
      flex: 1,
    },
  ]

  const rows = approver.properties.map(
    (property: ApplicationRoleApproverProperty, index: number) => ({
      id: index,
      propertyName: property.name,
      propertyValue: property.value,
      propertyLabel: property.label,
      approverType: property.approverType,
      isAutomatic: property.isAutomatic,
    })
  )

  return (
    <>
      <Box className="DiMaBox-one-element-right">
        <DiMaAddButton
          url={`/applications/${applicationId}/roles/${roleId}/approvers/${userId}/create`}
          feature={DimaFeatureShortNameEnum.AM}
        />
      </Box>
      <EntityTab
        rows={rows}
        cols={columns}
        isLoading={false}
        initialState={{
          sorting: { sortModel: [{ field: "name", sort: "asc" }] },
        }}
      />
    </>
  )
}
