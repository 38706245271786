import { TabContext, TabList } from "@mui/lab"
import { Box } from "@mui/material"
import { useCallback, useContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { DiMaProgress } from "../../components/DiMaProgress"
import { DiMaTabPanel } from "../../components/DiMaTabPanel"
import { LinkTab } from "../../components/LinkTab"
import { UserContext } from "../../context/UserContext"
import {
  AccessLevel,
  DimaFeatureShortNameEnum,
  hasUserAccessToFeature,
} from "../../models/Authorization"
import { AccessRequests } from "./users_tab/AccessRequests"
import { Users } from "./users_tab/Users"

export function UsersContainer() {
  const { user } = useContext(UserContext)

  const [activeTab, setActiveTab] = useState<string>("#users")
  const { hash } = useLocation()

  useEffect(() => {
    if (hash) {
      setActiveTab(hash)
    }
  }, [hash])

  const handleChange = useCallback((_: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue)
  }, [])

  if (!user) {
    return <DiMaProgress />
  }

  const userHasBackofficeReadAccess = hasUserAccessToFeature(
    user.authorizations,
    DimaFeatureShortNameEnum.BA,
    AccessLevel.Read
  )

  return (
    <TabContext value={activeTab}>
      <Box className="DiMaBox-tabs">
        <TabList onChange={handleChange}>
          <LinkTab label="Users" value="#users" />
          <LinkTab
            label="Access Requests"
            value="#accessRequest"
            disabled={!userHasBackofficeReadAccess}
          />
        </TabList>
      </Box>
      <DiMaTabPanel label="#users" activeTab={activeTab}>
        <Users />
      </DiMaTabPanel>
      {userHasBackofficeReadAccess && (
        <DiMaTabPanel label="#accessRequest" activeTab={activeTab}>
          <AccessRequests />
        </DiMaTabPanel>
      )}
    </TabContext>
  )
}
