import { ChipProps } from "@mui/material"
import { RoleRequestApprovalStatusEnum } from "../models/RoleRequestApproval"

export function getRoleRequestApprovalStatusProps(
  status: RoleRequestApprovalStatusEnum
): ChipProps {
  let props: ChipProps = {
    label: `${RoleRequestApprovalStatusEnum[status]}`,
    variant: "outlined",
  }

  switch (status) {
    case RoleRequestApprovalStatusEnum.Approved:
      props = { ...props, color: "success" }
      break
    case RoleRequestApprovalStatusEnum.Rejected:
      props = { ...props, color: "error" }
      break
    case RoleRequestApprovalStatusEnum.Forwarded:
      props = { ...props, color: "info" }
      break
    case RoleRequestApprovalStatusEnum.Open:
      props = { ...props, color: "warning", label: "Pending" }
      break
    default: // deadcode
      props = { ...props, color: undefined }
  }

  return props
}
