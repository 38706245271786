import { Box, Chip } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { StatusCodes } from "http-status-codes"
import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { digitalManagerApi } from "../../../../../services/api"
import { ApplicationPermissionUserGet } from "../../../../models/ApplicationPermissionUser"
import { DimaFeatureShortNameEnum } from "../../../../models/Authorization"
import { getBooleanFlagIcon } from "../../../../utils/getBooleanFlagIcon"
import {
  dateStringComparator,
  getNiceFormattedDate,
} from "../../../../utils/getFormattedDate"
import { getUserApplicationStatusProps } from "../../../../utils/getUserApplicationStatusProps"
import { DiMaAddButton } from "../../../partials/DiMaAddButton"
import { EntityTab } from "../../../partials/EntityTab"

export function UserApplicationsPermissions() {
  const { userId } = useParams()
  const [userPermissions, setUserPermissions] = useState<ApplicationPermissionUserGet[]>(
    []
  )
  const [isLoadingUserPermissions, setIsLoadingUserPermissions] = useState<boolean>(true)

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      renderCell: (params) => (
        <Link
          to={`/users/${userId}/applications/${params.row.applicationId}/permissions/${params.row.permissionId}`}
        >
          {params.row.name}
        </Link>
      ),
      flex: 2,
    },
    {
      field: "applicationShortName",
      headerName: "App",
      flex: 0.5,
    },
    {
      field: "validityStartDate",
      headerName: "Validity Start Date",
      valueGetter: (params) => getNiceFormattedDate(params.row.validityStartDate),
      sortComparator: dateStringComparator,
      flex: 1.5,
    },
    {
      field: "validityEndDate",
      headerName: "Validity End Date",
      valueGetter: (params) => getNiceFormattedDate(params.row.validityEndDate),
      sortComparator: dateStringComparator,
      flex: 1.5,
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => (
        <Chip {...getUserApplicationStatusProps(params.row.status)} />
      ),
      flex: 1,
    },
    {
      field: "hasProperties",
      headerName: "Has Properties",
      type: "boolean",
      renderCell: (params) => getBooleanFlagIcon(params.row.hasProperties),
      flex: 1,
    },
  ]

  useEffect(() => {
    if (userId) {
      setIsLoadingUserPermissions(true)
      setUserPermissions([])
      digitalManagerApi
        .get<ApplicationPermissionUserGet[]>(`/api/v1/users/${userId}/permissions`)
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            setUserPermissions(res.data)
          }
        })
        .finally(() => {
          setIsLoadingUserPermissions(false)
        })
    } else {
      setIsLoadingUserPermissions(false)
    }
  }, [userId])

  const rows = userPermissions.map(
    (userPermission: ApplicationPermissionUserGet, index) => ({
      id: index,
      applicationId: userPermission.application.id,
      applicationShortName: userPermission.application.shortName,
      permissionId: userPermission.permission.id,
      name: userPermission.permission.name,
      validityStartDate: userPermission.validityStartDate,
      validityEndDate: userPermission.validityEndDate,
      status: userPermission.status,
      hasProperties: userPermission.properties.length !== 0,
    })
  )

  return (
    <>
      <Box className="DiMaBox-one-element-right">
        <DiMaAddButton
          url={`/users/${userId}/apps-permissions/create`}
          feature={DimaFeatureShortNameEnum.RM}
        />
      </Box>
      <EntityTab
        rows={rows}
        cols={columns}
        isLoading={isLoadingUserPermissions}
        initialState={{
          sorting: { sortModel: [{ field: "validityStartDate", sort: "asc" }] },
        }}
      />
    </>
  )
}
