import { Box } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { Link, useParams } from "react-router-dom"
import { useApplicationRoles } from "../../../../../hooks/useApplicationRoles"
import { ApplicationRoleGet } from "../../../../models/ApplicationRole"
import { getBooleanFlagIcon } from "../../../../utils/getBooleanFlagIcon"
import { DiMaAddButton } from "../../../partials/DiMaAddButton"
import { EntityTab } from "../../../partials/EntityTab"

export function ApplicationRoles() {
  const { applicationId } = useParams()
  const { applicationRoles, isLoadingApplicationRoles } =
    useApplicationRoles(applicationId)

  const rolesColumns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      renderCell: (params) => (
        <Link to={`/applications/${applicationId}/roles/${params.row.id}`}>
          {params.row.name}
        </Link>
      ),
      flex: 1,
    },
    { field: "description", headerName: "Description", flex: 2 },
    {
      field: "isDefault",
      headerName: "Is Default",
      type: "boolean",
      renderCell: (params) => getBooleanFlagIcon(params.row.isDefault),
      flex: 1,
    },
    {
      field: "isRequestable",
      headerName: "Is Requestable",
      type: "boolean",
      renderCell: (params) => getBooleanFlagIcon(params.row.isRequestable),
      flex: 1,
    },
  ]

  const rolesRows = applicationRoles.map((appRole: ApplicationRoleGet) => ({
    id: appRole.id,
    name: appRole.name,
    description: appRole.description,
    isDefault: appRole.isDefault,
    isRequestable: appRole.isRequestable,
  }))

  return (
    <>
      <Box className="DiMaBox-one-element-right">
        <DiMaAddButton url={`/applications/${applicationId}/roles/create`} feature="AM" />
      </Box>
      <EntityTab
        rows={rolesRows}
        cols={rolesColumns}
        isLoading={isLoadingApplicationRoles}
        initialState={{
          sorting: { sortModel: [{ field: "name", sort: "asc" }] },
        }}
      />
    </>
  )
}
