import { LoadingButton } from "@mui/lab"
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material"
import { useFormik } from "formik"
import { StatusCodes } from "http-status-codes"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { boolean, mixed, object, string } from "yup"
import { useFileTypes } from "../../../../hooks/useFileTypes"
import { digitalManagerApi } from "../../../../services/api"
import { BackToURL } from "../../../components/BackToURL"
import { FilePost } from "../../../models/File"
import { FileTypeGet } from "../../../models/FileType"

const validationSchema = object({
  name: string().required("Name is required"),
  extension: string().required("Extension is required"),
  isPublic: boolean().required("Is Public is required"),
  quickDownload: boolean().required("Quick download is required"),
  file: mixed().required("File is required"),
})

export function FileCreate() {
  const navigate = useNavigate()
  const returnURL = `/files`
  const { fileTypes, isLoadingFileTypes } = useFileTypes()
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [isUploadClicked, setIsUploadClicked] = useState(false)

  const formik = useFormik<FilePost>({
    validationSchema,
    initialValues: {
      name: "",
      suffix: "",
      extension: "",
      description: "",
      typeId: null,
      isPublic: false,
      quickDownload: false,
      file: null,
    },
    onSubmit: (values, { setSubmitting }) => {
      const formData = new FormData()
      formData.append("name", values.name)
      formData.append("suffix", values.suffix)
      formData.append("extension", values.extension)
      formData.append("description", values.description)
      formData.append("typeId", values.typeId ? values.typeId : "")
      formData.append("isPublic", String(values.isPublic))
      formData.append("quickDownload", String(values.quickDownload))
      if (values.file !== null) {
        formData.append("formFile", values.file)
      } else {
        toast.error("File is required")
        setSubmitting(false)
        return
      }

      digitalManagerApi
        .post(`/api/v1/files`, formData, {
          headers: { "Content-type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            )
            setUploadProgress(progress)
          },
        })
        .then((res) => {
          if (res.status === StatusCodes.CREATED) {
            toast.success("File created")
            navigate(returnURL)
          }
        })
        .catch(() => {
          toast.error("Cannot create file")
        })
        .finally(() => {
          setSubmitting(false)
          setUploadProgress(0)
        })
    },
  })

  const handleChange = (event: SelectChangeEvent) => {
    formik.setFieldValue(event.target.name, event.target.value)
  }

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue("file", event.currentTarget.files?.[0])
  }

  const submitForm = () => {
    setIsUploadClicked(true)
    setUploadProgress(0)
    formik.submitForm()
  }

  return (
    <Box>
      <BackToURL url={returnURL} label="Back to files" />
      <Paper>
        <Card>
          <CardHeader
            title="Create new file"
            action={
              <LoadingButton
                variant="contained"
                type="submit"
                loading={formik.isSubmitting}
                onClick={submitForm}
                disabled={!formik.dirty || !formik.isValid}
              >
                Save
              </LoadingButton>
            }
          />
          <CardContent
            sx={{
              padding: 0,
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
          >
            <Divider />
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
              }}
            >
              <ListItem key={0}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Name</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="name"
                        name="name"
                        type="text"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={1}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Suffix</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="suffix"
                        name="suffix"
                        type="text"
                        value={formik.values.suffix}
                        onChange={formik.handleChange}
                        error={formik.touched.suffix && Boolean(formik.errors.suffix)}
                        helperText={formik.touched.suffix && formik.errors.suffix}
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={2}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Extension</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="extension"
                        name="extension"
                        type="text"
                        value={formik.values.extension}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.extension && Boolean(formik.errors.extension)
                        }
                        helperText={formik.touched.extension && formik.errors.extension}
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={3}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Description</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="description"
                        name="description"
                        type="text"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.description && Boolean(formik.errors.description)
                        }
                        helperText={
                          formik.touched.description && formik.errors.description
                        }
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={4}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Type</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Select
                        id="typeId"
                        name="typeId"
                        value={formik.values.typeId || ""}
                        onChange={handleChange}
                        disabled={isLoadingFileTypes}
                        size="small"
                        displayEmpty
                        fullWidth
                      >
                        <MenuItem value="">&nbsp;</MenuItem>
                        {!isLoadingFileTypes &&
                          fileTypes.map((fileType: FileTypeGet) => (
                            <MenuItem value={fileType.id} key={fileType.id}>
                              {fileType.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={5}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Is Public</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Checkbox
                        id="isPublic"
                        name="isPublic"
                        checked={formik.values.isPublic}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={6}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Quick Download</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Checkbox
                        id="quickDownload"
                        name="quickDownload"
                        checked={formik.values.quickDownload}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={7}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">File</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <input
                        type="file"
                        id="file"
                        name="file"
                        style={{ display: "none" }}
                        onChange={handleFileInputChange}
                      />
                      <label htmlFor="file">
                        <Button component="span" variant="outlined" color="primary">
                          {formik.values.file ? formik.values.file.name : "Choose File"}
                        </Button>
                      </label>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
            </List>
            {isUploadClicked && (
              <LinearProgress variant="determinate" value={uploadProgress} />
            )}
          </CardContent>
        </Card>
      </Paper>
    </Box>
  )
}
