import { Box, Chip, Typography } from "@mui/material"
import * as forge from "node-forge"

import {
  DiMaDetailsContent,
  DiMaDetailsContentProps,
} from "../../../../components/DiMaDetailsContent"
import {
  AccessLevel,
  DimaFeatureShortNameEnum,
  hasUserAccessToApplicationFeature,
} from "../../../../models/Authorization"
import {
  LitePanelContainer,
  ManufacturedDeviceInfo,
} from "../../../../models/SupplierPortal"
import { UserInfo } from "../../../../models/User"

export function ManufacturedDeviceDetails(props: {
  readonly device: ManufacturedDeviceInfo
  readonly user: UserInfo
}) {
  const { device, user } = props

  const containers = [
    ...JSON.parse(device.containers).map((item: LitePanelContainer) => (
      <Chip
        sx={{ margin: "0.5%" }}
        key={item.Name}
        label={`${item.Name}:${item.Version}`}
      />
    )),
  ]

  const certificate = forge.pki.certificateFromPem(
    JSON.parse(device.abbAbility).AbbAbilityCertificate
  )

  const listItems = [
    {
      label: "Serialnumber ABB",
      displayItem: <Typography>{device.serialNumberAbb}</Typography>,
    },
    {
      label: "Serialnumber ASEM",
      displayItem: <Typography>{device.serialNumberAsem}</Typography>,
    },
    {
      label: "Bootloader version",
      displayItem: <Typography>{device.bootloaderVersion}</Typography>,
    },
    {
      label: "RecoveryOS version",
      displayItem: <Typography>{device.recoveryOsVersion}</Typography>,
    },
    {
      label: "OS version",
      displayItem: <Typography>{device.osVersion}</Typography>,
    },
    {
      label: "Bios version",
      displayItem: <Typography>{device.biosVersion}</Typography>,
    },
    {
      label: "Bios date",
      displayItem: <Typography>{device.biosDate}</Typography>,
    },
    {
      label: "Firmware version",
      displayItem: <Typography>{device.firmwareVersion}</Typography>,
    },
    {
      label: "WAN Mac",
      displayItem: <Typography>{device.wanMac}</Typography>,
    },
    {
      label: "LAN Mac",
      displayItem: <Typography>{device.lanMac}</Typography>,
    },
    {
      label: "Kernel version",
      displayItem: <Typography>{device.kernelVersion}</Typography>,
    },
    {
      label: "Board model",
      displayItem: <Typography>{device.boardModel}</Typography>,
    },
    {
      label: "Secure Boot",
      displayItem: <Typography>{device.secureBoot}</Typography>,
    },
    {
      label: "Device manager version",
      displayItem: <Typography>{device.deviceManagerVersion}</Typography>,
    },
    {
      label: "Containers",
      displayItem: (
        <Box>
          <Box sx={{ maxWidth: "100%" }}>{containers}</Box>
        </Box>
      ),
    },
    {
      label: "Product name",
      displayItem: <Typography>{device.productName}</Typography>,
    },
    {
      label: "ObjectId",
      displayItem: <Typography>{JSON.parse(device.abbAbility).ObjectID}</Typography>,
    },
    {
      label: "Otp",
      displayItem: <Typography>{JSON.parse(device.abbAbility).Otp}</Typography>,
    },
    {
      label: "PKI Url",
      displayItem: <Typography>{JSON.parse(device.abbAbility).PkiUrl}</Typography>,
    },
    {
      label: "Certificate valid from",
      displayItem: (
        <Typography>{certificate.validity.notBefore.toDateString()}</Typography>
      ),
    },
    {
      label: "Certificate valid to",
      displayItem: (
        <Typography>{certificate.validity.notAfter.toDateString()}</Typography>
      ),
    },
    {
      label: "Issuer",
      displayItem: (
        <Typography>
          {
            certificate.issuer.attributes.filter(
              (value) => value.name === "commonName"
            )[0].value
          }
        </Typography>
      ),
    },
  ]

  const detailsContentProps: DiMaDetailsContentProps<ManufacturedDeviceInfo> = {
    label: "Manufactured device",
    canWrite: hasUserAccessToApplicationFeature(
      user.authorizations.applications,
      device.id,
      DimaFeatureShortNameEnum.SP,
      AccessLevel.Write
    ),
    isEditable: false,
    isDeletable: false,
    listItems,
  }

  return <DiMaDetailsContent {...detailsContentProps} />
}
