import { LoadingButton } from "@mui/lab"
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Modal,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material"
import { useFormik } from "formik"
import { StatusCodes } from "http-status-codes"
import { useCallback, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { mixed, object, string } from "yup"
import { useApplicationRoles } from "../../../../hooks/useApplicationRoles"
import { useApplications } from "../../../../hooks/useApplications"
import { useNotificationTopics } from "../../../../hooks/useNotificationTopics"
import { digitalManagerApi } from "../../../../services/api"
import { BackToURL } from "../../../components/BackToURL"
import DiMaCheckboxList from "../../../components/DiMaCheckboxList"
import { DiMaProgress } from "../../../components/DiMaProgress"
import {
  DiMaRichTextEditor,
  DiMaRichTextEditorValue,
} from "../../../components/DiMaRichTextEditor"
import { ApplicationGet } from "../../../models/Application"
import { ApplicationRoleGet } from "../../../models/ApplicationRole"
import { AccessLevel, DimaFeatureShortNameEnum } from "../../../models/Authorization"
import { Guid } from "../../../models/CustomType"
import { NotificationPost } from "../../../models/Notification"
import { NotificationTargetType } from "../../../models/NotificationTarget"
import { NotificationTopicGet } from "../../../models/NotificationTopic"
import { getNowDateTime, getUtcDateTime } from "../../../utils/getFormattedDate"

const validationSchema = object({
  applicationId: string().required("Application is required"),
  title: string().required("Title is required"),
  body: string().required("Body is required"),
  url: string().url(),
  applicationVersion: string(),
  operatingSystem: string(),
  validityStartDate: string().required("Validity Start Date is required"),
  validityEndDate: string().required("Validity End Date is required"),
  topicId: string().required("Topic is required"),
  targetType: mixed<NotificationTargetType>()
    .oneOf(Object.values(NotificationTargetType))
    .required("Notification Target is required"),
})

const returnURL = `/notifications`

export function NotificationCreate() {
  const { applicationsList, isLoadingApplications } = useApplications(
    DimaFeatureShortNameEnum.NM,
    AccessLevel.Write
  )
  const navigate = useNavigate()
  const [editBodyOpen, setEditBodyOpen] = useState<boolean>(false)
  const [editorValue, setEditorValue] = useState<string>("")
  const [checkedApplicationRolesIds, setCheckedApplicationRolesIds] = useState<Guid[]>([])

  const formik = useFormik({
    validationSchema,
    initialValues: {
      applicationId: "",
      title: "",
      body: "",
      bodyHtml: "",
      bodyPlate: "",
      url: "",
      applicationVersion: "",
      operatingSystem: "",
      validityStartDate: getNowDateTime(),
      validityEndDate: "",
      topicId: "",
      targetType: NotificationTargetType.All,
      isAnonymous: false,
    },
    onSubmit: (values, { setSubmitting }) => {
      let errorInTargets = false

      if (
        values.targetType === NotificationTargetType.Role &&
        checkedApplicationRolesIds.length === 0
      ) {
        toast.warning("Select at least one role")
        setSubmitting(false)
        errorInTargets = true
      }

      if (!errorInTargets) {
        const utcValidityStartDate = getUtcDateTime(values.validityStartDate)
        const utcValidityEndDate = getUtcDateTime(values.validityEndDate)

        if (values.targetType === NotificationTargetType.Anonymous) {
          values.targetType = NotificationTargetType.All
          values.isAnonymous = true
        } else {
          values.isAnonymous = false
        }

        const notification: NotificationPost = {
          applicationId: values.applicationId,
          topicId: values.topicId,
          title: values.title,
          body: values.body,
          bodyHtml: values.bodyHtml,
          bodyPlate: values.bodyPlate,
          url: values.url ? values.url : null,
          applicationVersion: values.applicationVersion
            ? values.applicationVersion
            : null,
          operatingSystem: values.operatingSystem ? values.operatingSystem : null,
          validityStartDate: utcValidityStartDate,
          validityEndDate: utcValidityEndDate,
          isAnonymous: values.isAnonymous,
        }

        digitalManagerApi
          .post(`/api/v1/notifications/applications`, notification)
          .then((res) => {
            if (res.status === StatusCodes.CREATED && res.data && res.data.id) {
              const promises = []
              // create targets
              const notificationId = res.data.id

              if (values.targetType === NotificationTargetType.All) {
                promises.push(
                  digitalManagerApi.post(
                    `/api/v1/notifications/${notificationId}/targets`,
                    {
                      type: NotificationTargetType.All,
                    }
                  )
                )
              } else {
                checkedApplicationRolesIds.forEach((role) => {
                  promises.push(
                    digitalManagerApi.post(
                      `/api/v1/notifications/${notificationId}/targets`,
                      {
                        type: NotificationTargetType.Role,
                        value: role,
                      }
                    )
                  )
                })
              }

              Promise.all(promises)
                .then(() => {
                  toast.success("Notification created")
                  navigate(returnURL)
                })
                .catch(() => {
                  toast.error("Cannot create notification")
                  digitalManagerApi.delete(`/api/v1/notifications/${notificationId}`)
                })
                .finally(() => {
                  setSubmitting(false)
                })
            }
          })
          .catch(() => {
            toast.error("Cannot create notification")
            setSubmitting(false)
          })
      }
    },
  })

  const { notificationTopicsList, isLoadingNotificationTopics } = useNotificationTopics(
    false,
    formik.values.applicationId
  )
  const { applicationRoles, isLoadingApplicationRoles } = useApplicationRoles(
    formik.values.applicationId
  )

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      formik.setFieldValue(event.target.name, event.target.value)
    },
    [formik]
  )

  const handleChangeTargetType = useCallback(
    (event: SelectChangeEvent) => {
      const tt = (event.target as HTMLInputElement).value

      if (
        tt === NotificationTargetType.All ||
        tt === NotificationTargetType.Role ||
        tt === NotificationTargetType.Anonymous
      ) {
        formik.setFieldValue("targetType", tt)
      }
    },
    [formik]
  )

  const submitForm = useCallback(() => {
    formik.submitForm()
  }, [formik])

  const setEditBodyOpenTrue = useCallback(() => {
    setEditBodyOpen(true)
  }, [])

  const setEditBodyOpenFalse = useCallback(() => {
    setEditBodyOpen(false)
  }, [])

  const onGetValue = useCallback(
    (val: DiMaRichTextEditorValue) => {
      setEditBodyOpen(false)
      const vals = {
        ...formik.values,
        body: val.stringValue,
        bodyHtml: val.htmlValue,
        bodyPlate: val.plateValue,
      }
      formik.setValues(vals)
      formik.validateForm(vals)
      setEditorValue(val.plateValue)
    },
    [formik]
  )

  return (
    <Box>
      <BackToURL url={returnURL} label="Back to notifications" />
      <Paper>
        <Card>
          <CardHeader
            title="Create new notification"
            action={
              <LoadingButton
                variant="contained"
                type="submit"
                loading={formik.isSubmitting}
                onClick={submitForm}
                disabled={!formik.dirty || !formik.isValid}
              >
                Save
              </LoadingButton>
            }
          />
          <CardContent
            sx={{
              padding: 0,
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
          >
            <Divider />
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
              }}
            >
              <ListItem key={0}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Application</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Select
                        id="applicationId"
                        name="applicationId"
                        value={formik.values.applicationId}
                        onChange={handleChange}
                        disabled={isLoadingApplications}
                        size="small"
                        displayEmpty
                        fullWidth
                      >
                        {!isLoadingApplications &&
                          applicationsList.map((application: ApplicationGet) => (
                            <MenuItem value={application.id} key={application.id}>
                              {application.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={1}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Title</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="title"
                        name="title"
                        type="text"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        error={formik.touched.title && Boolean(formik.errors.title)}
                        helperText={formik.touched.title && formik.errors.title}
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={2}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Body</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Button onClick={setEditBodyOpenTrue}>
                        Edit notification content
                      </Button>
                      <Modal
                        open={editBodyOpen}
                        onClose={setEditBodyOpenFalse}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            width: "80%",
                            height: "80%",
                            bgcolor: "background.paper",
                            border: "2px solid #000",
                            boxShadow: 24,
                            p: 4,
                          }}
                        >
                          <DiMaRichTextEditor
                            initialValue={editorValue}
                            onGetValue={onGetValue}
                          />
                        </Box>
                      </Modal>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={3}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Url</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="url"
                        name="url"
                        type="text"
                        value={formik.values.url}
                        onChange={formik.handleChange}
                        error={formik.touched.url && Boolean(formik.errors.url)}
                        helperText={formik.touched.url && formik.errors.url}
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={4}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Application Versions</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="applicationVersion"
                        name="applicationVersion"
                        type="text"
                        value={formik.values.applicationVersion}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.applicationVersion &&
                          Boolean(formik.errors.applicationVersion)
                        }
                        helperText={
                          formik.touched.applicationVersion &&
                          formik.errors.applicationVersion
                        }
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={5}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Operating Systems</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="operatingSystem"
                        name="operatingSystem"
                        type="text"
                        value={formik.values.operatingSystem}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.operatingSystem &&
                          Boolean(formik.errors.operatingSystem)
                        }
                        helperText={
                          formik.touched.operatingSystem && formik.errors.operatingSystem
                        }
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={6}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Validity Start Date</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="validityStartDate"
                        name="validityStartDate"
                        type="datetime-local"
                        inputProps={{
                          min: getNowDateTime(),
                          max: "9999-12-31T23:59:59",
                        }}
                        value={formik.values.validityStartDate}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.validityStartDate &&
                          Boolean(formik.errors.validityStartDate)
                        }
                        helperText={
                          formik.touched.validityStartDate &&
                          formik.errors.validityStartDate
                        }
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={7}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Validity End Date</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="validityEndDate"
                        name="validityEndDate"
                        type="datetime-local"
                        inputProps={{
                          min: formik.values.validityStartDate,
                          max: "9999-12-31T23:59:59",
                        }}
                        value={formik.values.validityEndDate}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.validityEndDate &&
                          Boolean(formik.errors.validityEndDate)
                        }
                        helperText={
                          formik.touched.validityEndDate && formik.errors.validityEndDate
                        }
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={8}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Topic</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Select
                        id="topicId"
                        name="topicId"
                        value={formik.values.topicId}
                        onChange={handleChange}
                        disabled={isLoadingNotificationTopics}
                        size="small"
                        displayEmpty
                        fullWidth
                      >
                        {!isLoadingNotificationTopics &&
                          notificationTopicsList.map(
                            (notificationTopic: NotificationTopicGet) => (
                              <MenuItem
                                value={notificationTopic.id}
                                key={notificationTopic.id}
                              >
                                {notificationTopic.name}
                              </MenuItem>
                            )
                          )}
                      </Select>
                      {!isLoadingNotificationTopics &&
                        notificationTopicsList.length === 0 && (
                          <Link
                            to="/notifications#topics"
                            style={{ color: "#3366ff", textTransform: "none" }}
                          >
                            Please, create a notification topic
                          </Link>
                        )}
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <Divider />
              <ListItem key={9}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Who will receive</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Select
                        value={formik.values.targetType}
                        onChange={handleChangeTargetType}
                        disabled={isLoadingApplications}
                        size="small"
                        displayEmpty
                        fullWidth
                      >
                        <MenuItem value={NotificationTargetType.All}>
                          All logged users
                        </MenuItem>
                        <MenuItem
                          value={NotificationTargetType.Role}
                          disabled={
                            isLoadingApplicationRoles || applicationRoles.length === 0
                          }
                        >
                          Users with specific roles
                        </MenuItem>
                        <MenuItem value={NotificationTargetType.Anonymous}>
                          Only NOT logged users
                        </MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>

              {formik.values.targetType === NotificationTargetType.Role && (
                <>
                  {!applicationRoles && <DiMaProgress />}
                  {applicationRoles && (
                    <DiMaCheckboxList
                      items={applicationRoles.map((role: ApplicationRoleGet) => ({
                        id: role.id,
                        title: role.name,
                        description: role.description,
                      }))}
                      checkedItemsIds={checkedApplicationRolesIds}
                      setCheckedItemsIds={setCheckedApplicationRolesIds}
                    />
                  )}
                </>
              )}
            </List>
          </CardContent>
        </Card>
      </Paper>
    </Box>
  )
}
