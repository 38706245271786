import { LoadingButton } from "@mui/lab"
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material"
import { useFormik } from "formik"
import { StatusCodes } from "http-status-codes"
import { useCallback } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { boolean, mixed, object, string } from "yup"
import { digitalManagerApi } from "../../../../../services/api"
import { BackToURL } from "../../../../components/BackToURL"
import { ApplicationPropertyType } from "../../../../models/ApplicationProperty"

const validationSchema = object({
  name: string().required("Name field is required"),
  type: mixed<ApplicationPropertyType>()
    .oneOf(Object.values(ApplicationPropertyType))
    .required("Type field is required"),
  isRequestable: boolean().required("Is Requestable field is required"),
})

export function ApplicationPropertyCreate() {
  const { applicationId } = useParams()
  const navigate = useNavigate()
  const returnURL = `/applications/${applicationId}#properties`

  const formik = useFormik({
    validationSchema,
    initialValues: {
      name: "",
      type: "",
      isRequestable: false,
    },
    onSubmit: (values, { setSubmitting }) => {
      digitalManagerApi
        .post(`/api/v1/applications/${applicationId}/properties`, {
          name: values.name,
          type: values.type,
          isRequestable: values.isRequestable,
        })
        .then((res) => {
          if (res.status === StatusCodes.CREATED) {
            toast.success("Property created")
            navigate(returnURL)
          }
        })
        .catch(() => {
          toast.error("Cannot create property")
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
  })

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      formik.setFieldValue("type", event.target.value)
    },
    [formik]
  )

  const submitForm = useCallback(() => {
    formik.submitForm()
  }, [formik])

  const setFieldValue = useCallback(
    (event) => formik.setFieldValue("name", event.target.value.replaceAll(" ", "")),
    [formik]
  )

  return (
    <Box>
      <BackToURL url={returnURL} label="Back to properties" />
      <Paper>
        <Card>
          <CardHeader
            title="Create new property"
            action={
              <LoadingButton
                variant="contained"
                type="submit"
                loading={formik.isSubmitting}
                onClick={submitForm}
                disabled={!formik.dirty || !formik.isValid}
              >
                Save
              </LoadingButton>
            }
          />
          <CardContent
            sx={{
              padding: 0,
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
          >
            <Divider />
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
              }}
            >
              <ListItem key={0}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Name</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="name"
                        name="name"
                        type="text"
                        value={formik.values.name}
                        onChange={setFieldValue}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={1}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Type</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Select
                        id="type"
                        name="type"
                        value={formik.values.type}
                        onChange={handleChange}
                        size="small"
                        displayEmpty
                        fullWidth
                      >
                        {Object.values(ApplicationPropertyType).map(
                          (type: ApplicationPropertyType) => (
                            <MenuItem value={type} key={type}>
                              {type}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={2}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Is Requestable</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Checkbox
                        id="isRequestable"
                        name="isRequestable"
                        checked={formik.values.isRequestable}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Paper>
    </Box>
  )
}
