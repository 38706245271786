import DeleteIcon from "@mui/icons-material/Delete"
import { Box } from "@mui/material"
import { GridActionsCellItem, GridColumns, GridRowParams } from "@mui/x-data-grid"
import { StatusCodes } from "http-status-codes"
import { useCallback } from "react"
import { toast } from "react-toastify"
import { digitalManagerApi } from "../../../../../../services/api"
import { ApplicationPropertyDetail } from "../../../../../models/ApplicationProperty"
import { ApplicationRoleUserGet } from "../../../../../models/ApplicationRoleUser"
import {
  AccessLevel,
  DimaFeatureShortNameEnum,
  hasUserAccessToApplicationFeature,
} from "../../../../../models/Authorization"
import { UserInfo } from "../../../../../models/User"
import { DiMaAddButton } from "../../../../partials/DiMaAddButton"
import { EntityTab } from "../../../../partials/EntityTab"

export function UserApplicationRoleProperties(props: {
  readonly userApplicationRole: ApplicationRoleUserGet
  readonly reloadUserApplicationRole: () => void
  readonly user: UserInfo
}) {
  const { userApplicationRole, reloadUserApplicationRole, user } = props

  const onClick = useCallback(
    (params: GridRowParams) => () => {
      digitalManagerApi
        .delete(
          `/api/v1/applications/${userApplicationRole.application.id}/properties/${params.row.name}/values/${params.row.value}`,
          {
            params: {
              userId: userApplicationRole.user.id,
              roleId: userApplicationRole.role.id,
            },
          }
        )
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            toast.success("Property deleted")
          }
        })
        .catch(() => {
          toast.error("Cannot delete the property")
        })
        .finally(() => {
          reloadUserApplicationRole()
        })
    },
    [
      reloadUserApplicationRole,
      userApplicationRole.application.id,
      userApplicationRole.role.id,
      userApplicationRole.user.id,
    ]
  )

  const columns: GridColumns<ApplicationPropertyDetail> = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    { field: "label", headerName: "Value", flex: 2 },
    {
      field: "actions",
      type: "actions",
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          key="delete"
          icon={<DeleteIcon color="error" />}
          onClick={onClick(params)}
          label="Delete"
          showInMenu
          disabled={
            !hasUserAccessToApplicationFeature(
              user.authorizations.applications,
              userApplicationRole.application.id,
              DimaFeatureShortNameEnum.RM,
              AccessLevel.Write
            )
          }
        />,
      ],
    },
  ]

  const userApplicationRolesRows = userApplicationRole.properties.map(
    (property: ApplicationPropertyDetail, index: number) => ({
      id: index,
      name: property.name,
      value: property.value,
      label: property.label,
    })
  )

  return (
    <>
      <Box className="DiMaBox-one-element-right">
        <DiMaAddButton
          url={`/users/${userApplicationRole.user.id}/applications/${userApplicationRole.application.id}/roles/${userApplicationRole.role.id}/properties/create`}
          feature={DimaFeatureShortNameEnum.RM}
          applicationId={userApplicationRole.application.id}
        />
      </Box>
      <EntityTab
        rows={userApplicationRolesRows}
        cols={columns}
        isLoading={false}
        initialState={{
          sorting: { sortModel: [{ field: "name", sort: "asc" }] },
        }}
      />
    </>
  )
}
