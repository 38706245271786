import { StatusCodes } from "http-status-codes"
import { useEffect, useState } from "react"
import { ApplicationPermissionGet } from "../app/models/ApplicationPermission"
import { digitalManagerApi } from "../services/api"

export function useApplicationPermissions(applicationId?: string) {
  const [applicationPermissions, setApplicationPermissions] = useState<
    ApplicationPermissionGet[]
  >([])
  const [isLoadingApplicationPermissions, setIsLoadingApplicationPermissions] =
    useState<boolean>(true)

  useEffect(() => {
    setIsLoadingApplicationPermissions(true)
    setApplicationPermissions([])
    if (applicationId) {
      digitalManagerApi
        .get<ApplicationPermissionGet[]>(
          `/api/v1/applications/${applicationId}/permissions`
        )
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            setApplicationPermissions(res.data)
          }
        })
        .finally(() => {
          setIsLoadingApplicationPermissions(false)
        })
    } else {
      setIsLoadingApplicationPermissions(false)
    }
  }, [applicationId])

  return {
    applicationPermissions,
    isLoadingApplicationPermissions,
  }
}
