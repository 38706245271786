import { ApplicationDetail } from "./Application"
import { ApplicationRoleDetail } from "./ApplicationRole"
import { UserDetail } from "./User"

export enum ApplicationRoleApproverTypeEnum {
  Approver = "Approver",
  BackupApprover = "BackupApprover",
  Consulted = "Consulted",
  Informed = "Informed",
}

export interface ApplicationRoleApproverProperty {
  name: string | null
  value: string | null
  label: string | null
  approverType: ApplicationRoleApproverTypeEnum
  isAutomatic: boolean
  sendEmail: boolean
}

export interface ApplicationRoleApproverGet {
  application: ApplicationDetail
  role: ApplicationRoleDetail
  user: UserDetail
  properties: ApplicationRoleApproverProperty[]
}

export interface ApplicationRoleApproverPost {
  propertyName: string | null
  propertyValue: string | null
  type: ApplicationRoleApproverTypeEnum
  isAutomatic: boolean
  sendEmail: boolean
}

export interface ApplicationRoleApproverPut {
  type: ApplicationRoleApproverTypeEnum
  isAutomatic: boolean
}