import { useContext, useEffect, useState } from "react"
import { Navigate } from "react-router-dom"
import { DiMaProgress } from "../components/DiMaProgress"
import { UserContext } from "../context/UserContext"
import { AccessLevel, hasUserAccessToFeature } from "../models/Authorization"

export function RequireFeatureAccessLevel(props: {
  readonly feature: string
  readonly accessLevel: AccessLevel
  readonly children: JSX.Element | JSX.Element[]
}) {
  const { user } = useContext(UserContext)

  const { feature, accessLevel, children } = props

  const [hasAccess, setHasAccess] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    if (user && feature && accessLevel) {
      setHasAccess(false)
      if (hasUserAccessToFeature(user.authorizations, feature, accessLevel)) {
        setHasAccess(true)
      }

      setIsLoading(false)
    }
  }, [user, feature, accessLevel])

  if (isLoading) {
    return <DiMaProgress />
  }

  if (!hasAccess) {
    return <Navigate to="/" />
  }

  return <>{children}</>
}
