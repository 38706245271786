import { useEffect, useState } from "react"
import { ApplicationGet } from "../app/models/Application"
import { AccessLevel } from "../app/models/Authorization"
import { digitalManagerApi } from "../services/api"

export function useApplications(
  featureShortName?: string,
  minAccessLevel = AccessLevel.Read
) {
  const [applicationsList, setApplicationsList] = useState<ApplicationGet[]>([])
  const [loadingEntities, setLoadingEntities] = useState<boolean>(true)

  useEffect(() => {
    const url = featureShortName
      ? `/api/v1/applications?minAccessLevel=${minAccessLevel}&feature=${featureShortName}`
      : `/api/v1/applications?minAccessLevel=${minAccessLevel}`
    digitalManagerApi
      .get<ApplicationGet[]>(url)
      .then((res) => {
        setApplicationsList(res.data)
      })
      .finally(() => {
        setLoadingEntities(false)
      })
  }, [featureShortName, minAccessLevel])

  return {
    applicationsList,
    isLoadingApplications: loadingEntities,
  }
}
