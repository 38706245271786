import { createContext, ReactChild, ReactFragment, useState } from "react"

export interface AppContextType {
  badges: AppContextBadges
  setBadges: (badges: AppContextBadges) => void
}

interface AppContextBadges {
  roleRequests: number
  accessRequests: number
}

export const AppContext = createContext<AppContextType>({
  badges: {
    roleRequests: 0,
    accessRequests: 0,
  },
  setBadges: () => null,
})

export const AppContextProvider = (props: { children: ReactChild | ReactFragment }) => {
  const [appContextState, setAppContextState] = useState({
    badges: {
      roleRequests: 0,
      accessRequests: 0,
    },
    setBadges: (badges: AppContextBadges) => {
      setAppContextState((currentState) => ({ ...currentState, badges }))
    },
  })

  return (
    <AppContext.Provider value={appContextState}>{props.children}</AppContext.Provider>
  )
}
