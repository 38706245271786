import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { IconButton, Stack, TextField, Typography } from "@mui/material"
import { useFormik } from "formik"
import { useCallback } from "react"
import { toast } from "react-toastify"
import { object, string } from "yup"
import { digitalManagerApi } from "../../../../../services/api"
import {
  DiMaDetailsContent,
  DiMaDetailsContentProps,
} from "../../../../components/DiMaDetailsContent"
import {
  AccessLevel,
  DimaFeatureShortNameEnum,
  hasUserAccessToFeature,
} from "../../../../models/Authorization"
import { UserGet, UserInfo, UserPut } from "../../../../models/User"
import { copyToClipboard } from "../../../../utils/copyToClipboard"

const validationSchema = object({
  first: string().required("First name is required"),
  last: string().required("Last name is required"),
})

const returnURL = `/users`

export function UserDetails(props: {
  readonly user: UserGet
  readonly loggedUser: UserInfo
  readonly setSelectedUser: (updatedUser: UserGet) => void
}) {
  const { user, loggedUser, setSelectedUser } = props

  const deleteUser = useCallback(
    () => digitalManagerApi.delete(`/api/v1/users/${user.id}`),
    [user.id]
  )

  const formik = useFormik<UserPut>({
    validationSchema,
    initialValues: {
      first: user.first,
      last: user.last,
    },
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) =>
      digitalManagerApi
        .put<UserGet>(`/api/v1/users/${user.id}`, {
          first: values.first,
          last: values.last,
        })
        .then((res) => {
          toast.success("User updated")
          setSelectedUser(res.data)
        })
        .catch(() => {
          toast.error("Cannot update user")
          formik.resetForm()
          return Promise.reject(new Error("Cannot update user"))
        })
        .finally(() => {
          setSubmitting(false)
        }),
  })

  const copyToClipboardUsername = useCallback(() => {
    copyToClipboard(user.username)
  }, [user.username])

  const detailsContentProps: DiMaDetailsContentProps<UserPut> = {
    formik,
    label: "User",
    canWrite: hasUserAccessToFeature(
      loggedUser.authorizations,
      DimaFeatureShortNameEnum.RM,
      AccessLevel.Write
    ),
    deleteAction: deleteUser,
    isDeletable: loggedUser.username !== user.username,
    onDeleteReturnUrl: returnURL,
    listItems: [
      {
        label: "First name",
        displayItem: <Typography>{user.first}</Typography>,
        editItem: (
          <TextField
            fullWidth
            id="first"
            name="first"
            type="text"
            value={formik.values.first}
            onChange={formik.handleChange}
            error={formik.touched.first && Boolean(formik.errors.first)}
            helperText={formik.touched.first && formik.errors.first}
            size="small"
          />
        ),
      },
      {
        label: "Last name",
        displayItem: <Typography>{user.last}</Typography>,
        editItem: (
          <TextField
            fullWidth
            id="last"
            name="last"
            type="text"
            value={formik.values.last}
            onChange={formik.handleChange}
            error={formik.touched.last && Boolean(formik.errors.last)}
            helperText={formik.touched.last && formik.errors.last}
            size="small"
          />
        ),
      },
      {
        label: "Email address",
        displayItem: (
          <Stack direction="row" sx={{ alignItems: "center" }}>
            <Typography sx={{ textTransform: "none" }}>{user.username}</Typography>
            <IconButton onClick={copyToClipboardUsername}>
              <ContentCopyIcon />
            </IconButton>
          </Stack>
        ),
      },
    ],
  }

  return <DiMaDetailsContent {...detailsContentProps} />
}
