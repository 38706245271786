import { Box, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import {
  DiMaDetailsContent,
  DiMaDetailsContentProps,
} from "../../../../components/DiMaDetailsContent"
import {
  AccessLevel,
  DimaFeatureShortNameEnum,
  hasUserAccessToApplicationFeature,
} from "../../../../models/Authorization"
import { RoleRequestGet } from "../../../../models/RoleRequest"
import { UserInfo } from "../../../../models/User"

export function RoleRequestRequestor(props: {
  readonly roleRequest: RoleRequestGet
  readonly user: UserInfo
}) {
  const { roleRequest, user } = props

  const detailsContentProps: DiMaDetailsContentProps = {
    label: "Requestor",
    isEditable: false,
    isDeletable: false,
    listItems: [
      {
        label: "First name",
        displayItem: <Typography>{roleRequest.user.first}</Typography>,
      },
      {
        label: "Last name",
        displayItem: <Typography>{roleRequest.user.last}</Typography>,
      },
      {
        label: "Email address",
        displayItem: (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexGrow: 0.1,
            }}
          >
            <Typography sx={{ textTransform: "none" }}>
              {roleRequest.user.username}
            </Typography>
            {hasUserAccessToApplicationFeature(
              user.authorizations.applications,
              roleRequest.application.id,
              DimaFeatureShortNameEnum.RM,
              AccessLevel.Read
            ) && (
              <Link
                style={{ color: "#3366FF" }}
                to={`/users/${roleRequest.user.id}#apps-roles`}
              >
                See current roles
              </Link>
            )}
          </Box>
        ),
      },
      {
        label: "Country",
        displayItem: <Typography>{roleRequest.country}</Typography>,
      },
      {
        label: "Address",
        displayItem: <Typography>{roleRequest.address}</Typography>,
      },
      {
        label: "Company",
        displayItem: <Typography>{roleRequest.company}</Typography>,
      },
      {
        label: "Department",
        displayItem: <Typography>{roleRequest.department}</Typography>,
      },
      {
        label: "Phone",
        displayItem: <Typography>{roleRequest.phone}</Typography>,
      },
    ],
  }

  return <DiMaDetailsContent {...detailsContentProps} />
}
