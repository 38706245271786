import { TextField, Typography } from "@mui/material"
import { useFormik } from "formik"
import { useCallback, useContext } from "react"
import { toast } from "react-toastify"
import { object, string } from "yup"
import { digitalManagerApi } from "../../../../../services/api"
import {
  DiMaDetailsContent,
  DiMaDetailsContentProps,
} from "../../../../components/DiMaDetailsContent"
import { DiMaProgress } from "../../../../components/DiMaProgress"
import { UserContext } from "../../../../context/UserContext"
import {
  AccessLevel,
  DimaFeatureShortNameEnum,
  hasUserAccessToFeature,
} from "../../../../models/Authorization"
import {
  NotificationTopicGet,
  NotificationTopicPut,
} from "../../../../models/NotificationTopic"

const validationSchema = object({
  name: string().required("Name is required"),
})

const returnURL = `/notifications#topics`

export function TopicDetails(props: {
  readonly notificationTopic: NotificationTopicGet
  readonly setNotificationTopic: (updatedTopic: NotificationTopicGet) => void
}) {
  const { user, isLoadingUser } = useContext(UserContext)

  const { notificationTopic, setNotificationTopic } = props

  const deleteNotificationTopic = useCallback(
    () => digitalManagerApi.delete(`/api/v1/notificationTopics/${notificationTopic.id}`),
    [notificationTopic.id]
  )

  const formik = useFormik<NotificationTopicPut>({
    validationSchema,
    initialValues: {
      name: notificationTopic.name,
    },
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) =>
      digitalManagerApi
        .put<NotificationTopicGet>(`/api/v1/notificationTopics/${notificationTopic.id}`, {
          name: values.name,
        })
        .then((res) => {
          toast.success("Notification topic updated")
          setNotificationTopic(res.data)
        })
        .catch(() => {
          toast.error("Cannot update notification topic")
          formik.resetForm()
          return Promise.reject(new Error("Cannot update notification topic"))
        })
        .finally(() => {
          setSubmitting(false)
        }),
  })

  if (isLoadingUser || !user) {
    return <DiMaProgress />
  }

  const detailsContentProps: DiMaDetailsContentProps<NotificationTopicPut> = {
    formik,
    canWrite: hasUserAccessToFeature(
      user.authorizations,
      DimaFeatureShortNameEnum.NM,
      AccessLevel.Write
    ),

    label: "Notification topic",
    deleteAction: deleteNotificationTopic,
    onDeleteReturnUrl: returnURL,
    listItems: [
      {
        label: "Name",
        displayItem: <Typography>{notificationTopic.name}</Typography>,
        editItem: (
          <TextField
            fullWidth
            id="name"
            name="name"
            type="text"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            size="small"
          />
        ),
      },
    ],
  }

  return <DiMaDetailsContent {...detailsContentProps} />
}
