import { MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material"
import { useFormik } from "formik"
import { StatusCodes } from "http-status-codes"
import { useCallback, useContext } from "react"
import { toast } from "react-toastify"
import { number, object, string } from "yup"
import { useDeviceFamilies } from "../../../../../hooks/useDeviceFamilies"
import { digitalManagerApi } from "../../../../../services/api"
import {
  DiMaDetailsContent,
  DiMaDetailsContentProps,
} from "../../../../components/DiMaDetailsContent"
import { DiMaProgress } from "../../../../components/DiMaProgress"
import { UserContext } from "../../../../context/UserContext"
import {
  AccessLevel,
  DimaFeatureShortNameEnum,
  hasUserAccessToFeature,
} from "../../../../models/Authorization"
import { DeviceGet, DevicePut } from "../../../../models/Device"
import { v4 as uuidv4 } from "uuid"

const validationSchema = object({
  name: string().required("Name is required"),
  slaveId: number().required("Slave ID is required"),
  familyId: string().required("Device Family is required"),
})

const returnURL = `/devices`

export function DeviceDetails(props: {
  readonly device: DeviceGet
  readonly setDevice: (updatedDevice: DeviceGet) => void
}) {
  const { user, isLoadingUser } = useContext(UserContext)
  const { device, setDevice } = props
  const { deviceFamiliesList, isLoadingDeviceFamilies } = useDeviceFamilies()

  const deleteDevice = useCallback(
    () => digitalManagerApi.delete(`/api/v1/devices/${device.id}`),
    [device.id]
  )

  const formik = useFormik<DevicePut>({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      name: device.name,
      slaveId: device.slaveId,
      familyId: device.family.id,
    },
    onSubmit: (values, { setSubmitting }) =>
      digitalManagerApi
        .put<DeviceGet>(`/api/v1/devices/${device.id}`, {
          name: values.name,
          slaveId: values.slaveId,
          familyId: values.familyId,
        })
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            toast.success("Device updated")
            setDevice(res.data)
          }
        })
        .catch(() => {
          toast.error("Cannot update device")
          formik.resetForm()
          return Promise.reject(new Error("Cannot update device"))
        })
        .finally(() => {
          setSubmitting(false)
        }),
  })

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      formik.setFieldValue(event.target.name, event.target.value)
    },
    [formik]
  )

  if (isLoadingUser || !user) {
    return <DiMaProgress />
  }

  const detailsContentProps: DiMaDetailsContentProps<DevicePut> = {
    formik,
    canWrite: hasUserAccessToFeature(
      user.authorizations,
      DimaFeatureShortNameEnum.DM,
      AccessLevel.Write
    ),
    label: "Device",
    deleteAction: deleteDevice,
    onDeleteReturnUrl: returnURL,
    listItems: [
      {
        label: "Name",
        displayItem: <Typography>{device.name}</Typography>,
        editItem: (
          <TextField
            fullWidth
            id="name"
            name="name"
            type="text"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            size="small"
          />
        ),
      },
      {
        label: "Slave ID",
        displayItem: <Typography>{device.slaveId}</Typography>,
        editItem: (
          <TextField
            fullWidth
            id="slaveId"
            name="slaveId"
            type="text"
            value={formik.values.slaveId}
            onChange={formik.handleChange}
            error={formik.touched.slaveId && Boolean(formik.errors.slaveId)}
            helperText={formik.touched.slaveId && formik.errors.slaveId}
            size="small"
          />
        ),
      },
      {
        label: "Family",
        displayItem: <Typography>{device.family.name}</Typography>,
        editItem: (
          <Select
            id="familyId"
            name="familyId"
            value={formik.values.familyId}
            onChange={handleChange}
            disabled={isLoadingDeviceFamilies}
            size="small"
            displayEmpty
            fullWidth
          >
            {!isLoadingDeviceFamilies &&
              deviceFamiliesList.map((n) => (
                <MenuItem value={n.id} key={uuidv4()}>
                  {n.name}
                </MenuItem>
              ))}
          </Select>
        ),
      },
    ],
  }

  return <DiMaDetailsContent {...detailsContentProps} />
}
