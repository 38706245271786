import { StatusCodes } from "http-status-codes"
import { useCallback, useEffect, useState } from "react"
import { ManufacturedDeviceGeneralInfo } from "../app/models/SupplierPortal"
import { digitalManagerApi } from "../services/api"

export function useManufacturedDevices() {
  const [manufacturedDevicesList, setManufacturedDevicesList] = useState<
    ManufacturedDeviceGeneralInfo[]
  >([])
  const [isLoadingManufacturedDevice, setIsLoadingManufacturedDevice] =
    useState<boolean>(true)
  const [reloadEntities, setReloadEntities] = useState<boolean>(true)

  useEffect(() => {
    if (reloadEntities) {
      digitalManagerApi
        .get<ManufacturedDeviceGeneralInfo[]>(`/api/v1/litePanelPro/manufacturedDevices`)
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            setManufacturedDevicesList(res.data)
          }
        })
        .finally(() => {
          setIsLoadingManufacturedDevice(false)
          setReloadEntities(false)
        })
    }
  }, [reloadEntities])

  const reloadManufacturedDevices = useCallback(() => setReloadEntities(true), [])

  return {
    manufacturedDevicesList,
    isLoadingManufacturedDevice,
    reloadManufacturedDevices,
  }
}
