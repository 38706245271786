import { StatusCodes } from "http-status-codes"
import { useEffect, useState } from "react"
import { Guid } from "../app/models/CustomType"
import { UserApplicationGet } from "../app/models/UserApplication"
import { digitalManagerApi } from "../services/api"

export function useUserApplications(userId?: Guid) {
  const [userApplications, setUserApplications] = useState<UserApplicationGet[]>([])
  const [isLoadingUserApplications, setIsLoadingUserApplications] =
    useState<boolean>(true)

  useEffect(() => {
    setIsLoadingUserApplications(true)
    setUserApplications([])
    if (userId) {
      digitalManagerApi
        .get<UserApplicationGet[]>(`/api/v1/users/${userId}/applications`)
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            setUserApplications(res.data)
          }
        })
        .finally(() => {
          setIsLoadingUserApplications(false)
        })
    } else {
      setIsLoadingUserApplications(false)
    }
  }, [userId])

  return {
    userApplications,
    isLoadingUserApplications,
  }
}
