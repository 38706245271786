import { StatusCodes } from "http-status-codes"
import { useEffect, useState } from "react"
import { Guid } from "../app/models/CustomType"
import { DeviceRoleGet } from "../app/models/DeviceRole"
import { digitalManagerApi } from "../services/api"

export function useDeviceRoles(deviceId?: Guid) {
  const [deviceRoles, setDeviceRoles] = useState<DeviceRoleGet[]>([])
  const [isLoadingDeviceRoles, setIsLoadingDeviceRoles] = useState<boolean>(true)

  useEffect(() => {
    setIsLoadingDeviceRoles(true)
    setDeviceRoles([])
    if (deviceId) {
      digitalManagerApi
        .get<DeviceRoleGet[]>(`/api/v1/devices/${deviceId}/roles`)
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            setDeviceRoles(res.data)
          }
        })
        .finally(() => {
          setIsLoadingDeviceRoles(false)
        })
    } else {
      setIsLoadingDeviceRoles(false)
    }
  }, [deviceId])

  return {
    deviceRoles,
    isLoadingDeviceRoles,
  }
}
