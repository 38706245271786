import { Box } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { Link, useParams } from "react-router-dom"
import { useApplicationPermissions } from "../../../../../hooks/useApplicationPermissions"
import { ApplicationPermissionGet } from "../../../../models/ApplicationPermission"
import { DimaFeatureShortNameEnum } from "../../../../models/Authorization"
import { DiMaAddButton } from "../../../partials/DiMaAddButton"
import { EntityTab } from "../../../partials/EntityTab"
import { getBooleanFlagIcon } from "../../../../utils/getBooleanFlagIcon"

export function ApplicationPermissions() {
  const { applicationId } = useParams()
  const { applicationPermissions, isLoadingApplicationPermissions } =
    useApplicationPermissions(applicationId)

  const permissionsColumns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      renderCell: (params) => (
        <Link to={`/applications/${applicationId}/permissions/${params.row.id}`}>
          {params.row.name}
        </Link>
      ),
      flex: 1,
    },
    {
      field: "noDevice",
      headerName: "No Device",
      type: "boolean",
      renderCell: (params) => getBooleanFlagIcon(params.row.noDevice),
      flex: 0.5,
    },
    { field: "description", headerName: "Description", flex: 2 },
  ]

  const permissionsRows = applicationPermissions.map(
    (appPermission: ApplicationPermissionGet) => ({
      id: appPermission.id,
      name: appPermission.name,
      description: appPermission.description,
      noDevice: appPermission.noDevice,
    })
  )

  return (
    <>
      <Box className="DiMaBox-one-element-right">
        <DiMaAddButton
          url={`/applications/${applicationId}/permissions/create`}
          feature={DimaFeatureShortNameEnum.AM}
        />
      </Box>
      <EntityTab
        rows={permissionsRows}
        cols={permissionsColumns}
        isLoading={isLoadingApplicationPermissions}
        initialState={{
          sorting: { sortModel: [{ field: "name", sort: "asc" }] },
        }}
      />
    </>
  )
}
