import { Box } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { Link } from "react-router-dom"
import { useNotificationTopics } from "../../../../hooks/useNotificationTopics"
import { DimaFeatureShortNameEnum } from "../../../models/Authorization"
import { NotificationTopicGet } from "../../../models/NotificationTopic"
import { DiMaAddButton } from "../../partials/DiMaAddButton"
import { EntityTab } from "../../partials/EntityTab"

export function Topics() {
  const { notificationTopicsList, isLoadingNotificationTopics } =
    useNotificationTopics(true)

  const notificationTopicsColumns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      renderCell: (params) => (
        <Link to={`/notifications/topics/${params.row.id}`}>{params.row.name}</Link>
      ),
      flex: 1,
    },
    {
      field: "application",
      headerName: "App",
      valueGetter: (params) => params.row.application.name,
      flex: 1,
    },
  ]

  const notificationTopicsRows = notificationTopicsList.map(
    (notificationTopic: NotificationTopicGet) => ({
      id: notificationTopic.id,
      name: notificationTopic.name,
      application: notificationTopic.application,
    })
  )

  return (
    <>
      <Box className="DiMaBox-one-element-right">
        <DiMaAddButton
          url="/notifications/topics/create"
          feature={DimaFeatureShortNameEnum.NM}
        />
      </Box>
      <EntityTab
        rows={notificationTopicsRows}
        cols={notificationTopicsColumns}
        isLoading={isLoadingNotificationTopics}
        initialState={{
          sorting: { sortModel: [{ field: "name", sort: "asc" }] },
        }}
      />
    </>
  )
}
