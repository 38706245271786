import { ChipProps } from "@mui/material"
import { RoleRequestStatusEnum } from "../models/RoleRequest"

export function getRoleRequestStatusProps(status: RoleRequestStatusEnum): ChipProps {
  let props: ChipProps = {
    label: `${RoleRequestStatusEnum[status]}`,
    variant: "outlined",
  }

  switch (status) {
    case RoleRequestStatusEnum.Open:
      props = {
        ...props,
        label: "Pending",
        color: "warning",
      }
      break
    case RoleRequestStatusEnum.Closed:
      props = { ...props, color: "success" }
      break
    default:
      props = { ...props, color: undefined }
  }

  return props
}
