import { Badge, Tab } from "@mui/material"
import { useCallback } from "react"
import { useLocation, useNavigate } from "react-router-dom"

interface LinkTabProps {
  readonly label?: string
  readonly value?: string
  readonly disabled?: boolean
  readonly badge?: number
}

export function LinkTab(props: LinkTabProps) {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { value, disabled, badge } = props

  const submitForm = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault()
      navigate(`${pathname}${value}`)
    },
    [navigate, pathname, value]
  )

  return (
    <Tab
      component="a"
      {...props}
      onClick={submitForm}
      disabled={disabled}
      icon={
        badge ? (
          <Badge badgeContent={badge} color="error" sx={{ pl: "10px" }}></Badge>
        ) : undefined
      }
      iconPosition="end"
      sx={{
        "& .MuiTab-iconWrapper": {
          mb: 0,
          mr: 0.5,
          pb: -10,
        },
      }}
    />
  )
}
