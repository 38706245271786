import { Chip } from "@mui/material"
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid"
import { Link, useParams } from "react-router-dom"
import { useRoleUsers } from "../../../../../../hooks/useRoleUsers"
import { ApplicationRoleUser } from "../../../../../models/ApplicationRole"
import {
  dateStringComparator,
  getNiceFormattedDate,
} from "../../../../../utils/getFormattedDate"
import { getUserApplicationStatusProps } from "../../../../../utils/getUserApplicationStatusProps"
import { EntityTab } from "../../../../partials/EntityTab"

function getFullName(params: GridValueGetterParams) {
  return `${params.row.first || ""} ${params.row.last || ""}`
}

export function ApplicationRoleUsers() {
  const { applicationId, roleId } = useParams()
  const { roleUsersList, isLoadingRoleUsers } = useRoleUsers(applicationId, roleId)

  const columns: GridColDef[] = [
    {
      field: "username",
      headerName: "Email address",
      renderCell: (params) => (
        <Link to={`/users/${params.row.id}`}>{params.row.username}</Link>
      ),
      flex: 2,
    },
    {
      field: "fullName",
      headerName: "Full name",
      valueGetter: getFullName,
      flex: 2,
    },
    {
      field: "validityStartDate",
      headerName: "Validity Start Date",
      valueGetter: (params) => getNiceFormattedDate(params.row.validityStartDate),
      sortComparator: dateStringComparator,
      flex: 2,
    },
    {
      field: "validityEndDate",
      headerName: "Validity End Date",
      valueGetter: (params) => getNiceFormattedDate(params.row.validityEndDate),
      sortComparator: dateStringComparator,
      flex: 2,
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => (
        <Chip
          sx={{
            width: "100px",
          }}
          {...getUserApplicationStatusProps(params.row.status)}
        />
      ),
      flex: 1,
    },
  ]

  const rows = roleUsersList.map((user: ApplicationRoleUser) => ({
    id: user.id,
    username: user.username,
    first: user.first,
    last: user.last,
    validityStartDate: user.validityStartDate,
    validityEndDate: user.validityEndDate,
    status: user.status,
  }))

  return (
    <EntityTab
      rows={rows}
      cols={columns}
      isLoading={isLoadingRoleUsers}
      initialState={{
        sorting: {
          sortModel: [{ field: "username", sort: "asc" }],
        },
      }}
    />
  )
}
