import { Checkbox, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material"
import { useFormik } from "formik"
import { useCallback, useEffect } from "react"
import { Navigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { mixed, object } from "yup"
import { digitalManagerApi } from "../../../../../../../../services/api"
import {
  DiMaDetailsContent,
  DiMaDetailsContentProps,
} from "../../../../../../../components/DiMaDetailsContent"
import {
  ApplicationRoleApproverGet,
  ApplicationRoleApproverPut,
  ApplicationRoleApproverTypeEnum,
} from "../../../../../../../models/ApplicationRoleApprover"
import {
  AccessLevel,
  DimaFeatureShortNameEnum,
  hasUserAccessToApplicationFeature,
} from "../../../../../../../models/Authorization"
import { UserInfo } from "../../../../../../../models/User"

const validationSchema = object({
  type: mixed<ApplicationRoleApproverTypeEnum>()
    .oneOf(Object.values(ApplicationRoleApproverTypeEnum))
    .required("Type field is required"),
})

export function ApplicationRoleApproverPropertyDetails(props: {
  readonly approver: ApplicationRoleApproverGet
  readonly user: UserInfo
  readonly setApprover: (updateApprover: ApplicationRoleApproverGet) => void
}) {
  const { approver, user, setApprover } = props
  const { applicationId, roleId, userId, propertyName, propertyValue } = useParams()
  const returnURL = `/applications/${applicationId}/roles/${roleId}/approvers/${userId}`

  const deleteRoleApproverRule = useCallback(
    () =>
      digitalManagerApi.delete(
        `/api/v1/applications/${applicationId}/roles/${roleId}/approvers/${userId}/properties/${propertyName}/values/${propertyValue}`
      ),
    [applicationId, propertyName, propertyValue, roleId, userId]
  )

  const formik = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      type: approver.properties[0].approverType,
      isAutomatic: approver.properties[0].isAutomatic,
    },
    onSubmit: (values, { setSubmitting }) =>
      digitalManagerApi
        .put<ApplicationRoleApproverGet>(
          `/api/v1/applications/${applicationId}/roles/${roleId}/approvers/${userId}/properties/${propertyName}/values/${propertyValue}`,
          {
            type: values.type,
            isAutomatic: values.isAutomatic,
          }
        )
        .then((res) => {
          toast.success("Role approver rule updated")
          setApprover(res.data)
        })
        .catch(() => {
          toast.error("Cannot update role approver rule")
          return Promise.reject(new Error("Cannot update role approver rule"))
        })
        .finally(() => {
          setSubmitting(false)
        }),
  })

  useEffect(() => {
    if (
      formik.values.type !== ApplicationRoleApproverTypeEnum.Approver &&
      formik.values.type !== ApplicationRoleApproverTypeEnum.BackupApprover
    ) {
      formik.setFieldValue("isAutomatic", false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.type])

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      formik.setFieldValue(event.target.name, event.target.value)
    },
    [formik]
  )

  if (!approver?.properties[0].approverType || !applicationId) {
    return <Navigate to={returnURL} />
  }

  const detailsContentProps: DiMaDetailsContentProps<ApplicationRoleApproverPut> = {
    formik,
    label: "Role approver rule",
    canWrite: hasUserAccessToApplicationFeature(
      user.authorizations.applications,
      applicationId,
      DimaFeatureShortNameEnum.AM,
      AccessLevel.Write
    ),
    deleteAction: deleteRoleApproverRule,
    onDeleteReturnUrl: returnURL,
    listItems: [
      {
        label: "Property Name",
        displayItem: <Typography>{propertyName}</Typography>,
      },
      {
        label: "Property Value",
        displayItem: <Typography>{approver.properties[0].label}</Typography>,
      },
      {
        label: "Approver Type",
        displayItem: <Typography>{approver.properties[0].approverType}</Typography>,
        editItem: (
          <Select
            id="type"
            name="type"
            value={formik.values.type}
            onChange={handleChange}
            size="small"
            displayEmpty
            fullWidth
          >
            {Object.values(ApplicationRoleApproverTypeEnum).map(
              (type: ApplicationRoleApproverTypeEnum) => (
                <MenuItem value={type} key={type}>
                  {type}
                </MenuItem>
              )
            )}
          </Select>
        ),
      },
      {
        label: "Is Automatic",
        displayItem: (
          <Typography>
            {approver.properties[0].isAutomatic?.toString() || "false"}
          </Typography>
        ),
        editItem: (
          <Checkbox
            id="isAutomatic"
            name="isAutomatic"
            checked={formik.values.isAutomatic}
            onChange={formik.handleChange}
            disabled={
              formik.values.type !== ApplicationRoleApproverTypeEnum.Approver &&
              formik.values.type !== ApplicationRoleApproverTypeEnum.BackupApprover
            }
          />
        ),
      },
    ],
  }

  return <DiMaDetailsContent {...detailsContentProps} />
}
