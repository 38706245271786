import { StatusCodes } from "http-status-codes"
import { useEffect, useState } from "react"
import { DeviceGet } from "../app/models/Device"
import { digitalManagerApi } from "../services/api"

export function useDevices() {
  const [devicesList, setDevicesList] = useState<DeviceGet[]>([])
  const [isLoadingDevices, setIsLoadingDevices] = useState<boolean>(true)

  useEffect(() => {
    setIsLoadingDevices(true)
    setDevicesList([])
    digitalManagerApi
      .get<DeviceGet[]>(`/api/v1/devices`)
      .then((res) => {
        if (res.status === StatusCodes.OK) {
          setDevicesList(res.data)
        }
      })
      .finally(() => {
        setIsLoadingDevices(false)
      })
  }, [])

  return {
    devicesList,
    isLoadingDevices,
  }
}
