import { LoadingButton } from "@mui/lab"
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material"
import { useFormik } from "formik"
import { StatusCodes } from "http-status-codes"
import { useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { number, object, string } from "yup"
import { useDeviceFamilies } from "../../../../hooks/useDeviceFamilies"
import { digitalManagerApi } from "../../../../services/api"
import { BackToURL } from "../../../components/BackToURL"
import { DeviceFamilyGet } from "../../../models/DeviceFamily"

const validationSchema = object({
  name: string().required("Name is required"),
  slaveId: number().required("Slave ID is required"),
  familyId: string().required("Device Family is required"),
})

const returnURL = `/devices`

export function DeviceCreate() {
  const { deviceFamiliesList, isLoadingDeviceFamilies } = useDeviceFamilies()
  const navigate = useNavigate()

  const formik = useFormik({
    validationSchema,
    initialValues: {
      name: "",
      slaveId: "",
      familyId: "",
    },
    onSubmit: (values, { setSubmitting }) => {
      digitalManagerApi
        .post(`/api/v1/devices`, {
          name: values.name,
          slaveId: values.slaveId,
          familyId: values.familyId,
        })
        .then((res) => {
          if (res.status === StatusCodes.CREATED) {
            toast.success("Device created")
            navigate(returnURL)
          }
        })
        .catch(() => {
          toast.error("Cannot create device")
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
  })

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      formik.setFieldValue(event.target.name, event.target.value)
    },
    [formik]
  )

  const submitForm = useCallback(() => {
    formik.submitForm()
  }, [formik])

  return (
    <Box>
      <BackToURL url={returnURL} label="Back to devices" />
      <Paper>
        <Card>
          <CardHeader
            title="Create new device"
            action={
              <LoadingButton
                variant="contained"
                type="submit"
                loading={formik.isSubmitting}
                onClick={submitForm}
                disabled={!formik.dirty || !formik.isValid}
              >
                Save
              </LoadingButton>
            }
          />
          <CardContent
            sx={{
              padding: 0,
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
          >
            <Divider />
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
              }}
            >
              <ListItem key={0}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Name</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="name"
                        name="name"
                        type="text"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={1}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Slave ID</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="slaveId"
                        name="slaveId"
                        type="text"
                        value={formik.values.slaveId}
                        onChange={formik.handleChange}
                        error={formik.touched.slaveId && Boolean(formik.errors.slaveId)}
                        helperText={formik.touched.slaveId && formik.errors.slaveId}
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={2}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Family</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Select
                        id="familyId"
                        name="familyId"
                        value={formik.values.familyId}
                        onChange={handleChange}
                        disabled={isLoadingDeviceFamilies}
                        size="small"
                        displayEmpty
                        fullWidth
                      >
                        {!isLoadingDeviceFamilies &&
                          deviceFamiliesList.map((deviceFamily: DeviceFamilyGet) => (
                            <MenuItem value={deviceFamily.id} key={deviceFamily.id}>
                              {deviceFamily.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Paper>
    </Box>
  )
}
