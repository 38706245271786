import { Box, Chip } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { StatusCodes } from "http-status-codes"
import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { digitalManagerApi } from "../../../../../services/api"
import { ApplicationRoleUserGet } from "../../../../models/ApplicationRoleUser"
import { DimaFeatureShortNameEnum } from "../../../../models/Authorization"
import { getBooleanFlagIcon } from "../../../../utils/getBooleanFlagIcon"
import {
  dateStringComparator,
  getNiceFormattedDate,
} from "../../../../utils/getFormattedDate"
import { getUserApplicationStatusProps } from "../../../../utils/getUserApplicationStatusProps"
import { DiMaAddButton } from "../../../partials/DiMaAddButton"
import { EntityTab } from "../../../partials/EntityTab"

export function UserApplicationsRoles() {
  const { userId } = useParams()
  const [userRoles, setUserRoles] = useState<ApplicationRoleUserGet[]>([])
  const [isLoadingUserRoles, setIsLoadingUserRoles] = useState<boolean>(true)

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      renderCell: (params) => (
        <Link
          to={`/users/${userId}/applications/${params.row.applicationId}/roles/${params.row.roleId}`}
        >
          {params.row.name}
        </Link>
      ),
      flex: 2,
    },
    {
      field: "applicationShortName",
      headerName: "App",
      flex: 0.5,
    },
    {
      field: "validityStartDate",
      headerName: "Validity Start Date",
      valueGetter: (params) => getNiceFormattedDate(params.row.validityStartDate),
      sortComparator: dateStringComparator,
      flex: 1.5,
    },
    {
      field: "validityEndDate",
      headerName: "Validity End Date",
      valueGetter: (params) => getNiceFormattedDate(params.row.validityEndDate),
      sortComparator: dateStringComparator,
      flex: 1.5,
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => (
        <Chip {...getUserApplicationStatusProps(params.row.status)} />
      ),
      flex: 1,
    },
    {
      field: "hasProperties",
      headerName: "Has Properties",
      type: "boolean",
      renderCell: (params) => getBooleanFlagIcon(params.row.hasProperties),
      flex: 1,
    },
  ]

  useEffect(() => {
    if (userId) {
      setIsLoadingUserRoles(true)
      setUserRoles([])
      digitalManagerApi
        .get<ApplicationRoleUserGet[]>(`/api/v1/users/${userId}/roles`)
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            setUserRoles(res.data)
          }
        })
        .finally(() => {
          setIsLoadingUserRoles(false)
        })
    } else {
      setIsLoadingUserRoles(false)
    }
  }, [userId])

  const rows = userRoles.map((userRole: ApplicationRoleUserGet, index) => ({
    id: index,
    applicationId: userRole.application.id,
    applicationShortName: userRole.application.shortName,
    roleId: userRole.role.id,
    name: userRole.role.name,
    validityStartDate: userRole.validityStartDate,
    validityEndDate: userRole.validityEndDate,
    status: userRole.status,
    hasProperties: userRole.properties.length !== 0,
  }))

  return (
    <>
      <Box className="DiMaBox-one-element-right">
        <DiMaAddButton
          url={`/users/${userId}/apps-roles/create`}
          feature={DimaFeatureShortNameEnum.RM}
        />
      </Box>
      <EntityTab
        rows={rows}
        cols={columns}
        isLoading={isLoadingUserRoles}
        initialState={{
          sorting: { sortModel: [{ field: "validityStartDate", sort: "asc" }] },
        }}
      />
    </>
  )
}
