import { TextField, Typography } from "@mui/material"
import { useFormik } from "formik"
import { StatusCodes } from "http-status-codes"
import { useCallback } from "react"
import { toast } from "react-toastify"
import { object, string } from "yup"
import { digitalManagerApi } from "../../../../../services/api"
import {
  DiMaDetailsContent,
  DiMaDetailsContentProps,
} from "../../../../components/DiMaDetailsContent"
import {
  AccessLevel,
  DimaFeatureShortNameEnum,
  hasUserAccessToFeature,
} from "../../../../models/Authorization"
import { DeviceCategoryGet, DeviceCategoryPut } from "../../../../models/DeviceCategory"
import { UserInfo } from "../../../../models/User"

const validationSchema = object({
  name: string().required("Name is required"),
})

const returnURL = `/devices#categories`

export function CategoryDetails(props: {
  readonly deviceCategory: DeviceCategoryGet
  readonly setDeviceCategory: (updatedDeviceCategory: DeviceCategoryGet) => void
  readonly user: UserInfo
}) {
  const { deviceCategory, setDeviceCategory, user } = props

  const deleteDeviceCategory = useCallback(
    () => digitalManagerApi.delete(`/api/v1/deviceCategories/${deviceCategory.id}`),
    [deviceCategory.id]
  )

  const formik = useFormik<DeviceCategoryPut>({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      name: deviceCategory.name,
    },
    onSubmit: (values, { setSubmitting }) =>
      digitalManagerApi
        .put<DeviceCategoryGet>(`/api/v1/deviceCategories/${deviceCategory.id}`, {
          name: values.name,
        })
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            toast.success("Device Category updated")
            setDeviceCategory(res.data)
          }
        })
        .catch(() => {
          toast.error("Cannot update device category")
          formik.resetForm()
          return Promise.reject(new Error("Cannot update device category"))
        })
        .finally(() => {
          setSubmitting(false)
        }),
  })

  const detailsContentProps: DiMaDetailsContentProps<DeviceCategoryPut> = {
    formik,
    canWrite: hasUserAccessToFeature(
      user.authorizations,
      DimaFeatureShortNameEnum.DM,
      AccessLevel.Write
    ),
    label: "Device Category",
    deleteAction: deleteDeviceCategory,
    onDeleteReturnUrl: returnURL,
    listItems: [
      {
        label: "Name",
        displayItem: <Typography>{deviceCategory.name}</Typography>,
        editItem: (
          <TextField
            fullWidth
            id="name"
            name="name"
            type="text"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            size="small"
          />
        ),
      },
    ],
  }

  return <DiMaDetailsContent {...detailsContentProps} />
}
