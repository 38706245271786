import { TabContext, TabList } from "@mui/lab"
import { Box } from "@mui/material"
import { StatusCodes } from "http-status-codes"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom"
import { digitalManagerApi } from "../../../../services/api"
import { BackToURL } from "../../../components/BackToURL"
import {
  DiMaDetailsHeader,
  DiMaDetailsHeaderProps,
} from "../../../components/DiMaDetailsHeader"
import { DiMaProgress } from "../../../components/DiMaProgress"
import { DiMaTabPanel } from "../../../components/DiMaTabPanel"
import { LinkTab } from "../../../components/LinkTab"
import { UserContext } from "../../../context/UserContext"
import { ApplicationRoleApproverTypeEnum } from "../../../models/ApplicationRoleApprover"
import { RoleRequestGet } from "../../../models/RoleRequest"
import { RoleRequestApprovalStatusEnum } from "../../../models/RoleRequestApproval"
import { RoleRequestApprovals } from "./approvals_tab/RoleRequestApprovals"
import { RoleRequestDetails } from "./details_tab/RoleRequestDetails"
import { RoleRequestHistory } from "./history_tab/RoleRequestHistory"
import { RoleRequestRequestor } from "./requestor_tab/RoleRequestRequestor"

const returnURL = "/role-requests"

export function RoleRequestContainer() {
  const { roleRequestId } = useParams()
  const { user, isLoadingUser } = useContext(UserContext)
  const [activeTab, setActiveTab] = useState<string>("#requestor")
  const [roleRequest, setRoleRequest] = useState<RoleRequestGet>()
  const [reloadEntity, setReloadEntity] = useState<boolean>(true)
  const [isLoadingRoleRequest, setIsLoadingRoleRequest] = useState<boolean>(true)
  const { hash } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (roleRequestId && reloadEntity === true) {
      setIsLoadingRoleRequest(true)
      digitalManagerApi
        .get<RoleRequestGet>(`/api/v1/roleRequests/${roleRequestId}`)
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            setRoleRequest(res.data)
            setReloadEntity(false)
          } else {
            navigate(returnURL)
          }
        })
        .catch(() => {
          navigate(returnURL)
        })
        .finally(() => {
          setIsLoadingRoleRequest(false)
        })
    }
  }, [navigate, reloadEntity, roleRequestId])

  const reloadRoleRequest = useCallback(() => setReloadEntity(true), [])

  useEffect(() => {
    if (hash) {
      setActiveTab(hash)
    }
  }, [hash])

  const handleChange = useCallback((_: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue)
  }, [])

  if (!roleRequestId) {
    return <Navigate to={returnURL} />
  }

  if (!roleRequest || isLoadingRoleRequest) {
    return <DiMaProgress />
  }

  if (isLoadingUser || !user) {
    return <DiMaProgress />
  }

  const detailsHeaderProps: DiMaDetailsHeaderProps = {
    avatar: roleRequest.application.shortName,
    title: roleRequest.application.name,
    label: "Status",
    subtitle: roleRequest.status,
    hideCopyToClipboard: true,
  }

  return (
    <>
      <BackToURL url={returnURL} label="Back to role requests" />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <DiMaDetailsHeader {...detailsHeaderProps} />
        <TabContext value={activeTab}>
          <Box className="DiMaBox-tabs">
            <TabList onChange={handleChange}>
              <LinkTab label="Details" value="#details" />
              <LinkTab label="Requestor" value="#requestor" />
              <LinkTab
                label="Approvals"
                value="#approvals"
                badge={
                  roleRequest.approvals.filter(
                    (a) =>
                      (a.status === RoleRequestApprovalStatusEnum.Open &&
                        (a.userApproverType ===
                          ApplicationRoleApproverTypeEnum.Approver ||
                          a.userApproverType ===
                            ApplicationRoleApproverTypeEnum.BackupApprover)) ||
                      (a.status === RoleRequestApprovalStatusEnum.Forwarded &&
                        a.userApproverType === ApplicationRoleApproverTypeEnum.Consulted)
                  ).length
                }
              />
              <LinkTab label="History" value="#history" />
            </TabList>
          </Box>
          <DiMaTabPanel label="#details" activeTab={activeTab}>
            <RoleRequestDetails
              roleRequest={roleRequest}
              setRoleRequest={setRoleRequest}
            />
          </DiMaTabPanel>
          {
            <DiMaTabPanel label="#requestor" activeTab={activeTab}>
              <RoleRequestRequestor roleRequest={roleRequest} user={user} />
            </DiMaTabPanel>
          }
          <DiMaTabPanel label="#approvals" activeTab={activeTab}>
            <RoleRequestApprovals
              roleRequest={roleRequest}
              reloadRoleRequest={reloadRoleRequest}
              isLoadingApprovals={reloadEntity}
            />
          </DiMaTabPanel>
          <DiMaTabPanel label="#history" activeTab={activeTab}>
            <RoleRequestHistory roleRequest={roleRequest} />
          </DiMaTabPanel>
        </TabContext>
      </Box>
    </>
  )
}
