import { Checkbox, TextField, Typography } from "@mui/material"
import { useFormik } from "formik"
import { useCallback } from "react"
import { Navigate } from "react-router-dom"
import { toast } from "react-toastify"
import { boolean, object, string } from "yup"
import { digitalManagerApi } from "../../../../../../services/api"
import {
  DiMaDetailsContent,
  DiMaDetailsContentProps,
} from "../../../../../components/DiMaDetailsContent"
import {
  ApplicationPermissionGet,
  ApplicationPermissionPut,
} from "../../../../../models/ApplicationPermission"
import {
  AccessLevel,
  DimaFeatureShortNameEnum,
  hasUserAccessToApplicationFeature,
} from "../../../../../models/Authorization"
import { UserInfo } from "../../../../../models/User"

const validationSchema = object({
  name: string().required("Name is required"),
  description: string().required("Description is required"),
  noDevice: boolean().required("No Device is required"),
})

export function ApplicationPermissionDetails(props: {
  readonly permission: ApplicationPermissionGet
  readonly applicationId: string
  readonly user: UserInfo
  readonly setPermission: (updatedPermission: ApplicationPermissionGet) => void
}) {
  const { permission, applicationId, user, setPermission } = props

  const returnURL = `/applications/${applicationId}#permissions`

  const deletePermission = useCallback(
    () =>
      digitalManagerApi.delete(
        `/api/v1/applications/${applicationId}/permissions/${permission.id}`
      ),
    [applicationId, permission.id]
  )

  const formik = useFormik<ApplicationPermissionPut>({
    validationSchema,
    initialValues: {
      name: permission.name,
      description: permission.description,
      noDevice: permission.noDevice || false,
    },
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) =>
      digitalManagerApi
        .put<ApplicationPermissionGet>(
          `/api/v1/applications/${applicationId}/permissions/${permission.id}`,
          {
            name: values.name,
            description: values.description,
            noDevice: values.noDevice,
          }
        )
        .then((res) => {
          toast.success("Permission updated")
          setPermission(res.data)
        })
        .catch(() => {
          toast.error("Cannot update permission")
          formik.resetForm()
          return Promise.reject(new Error("Cannot update permission"))
        })
        .finally(() => {
          setSubmitting(false)
        }),
  })

  if (!permission || !applicationId) {
    return <Navigate to={returnURL} />
  }

  const detailsContentProps: DiMaDetailsContentProps<ApplicationPermissionPut> = {
    formik,
    label: "Permission",
    canWrite: hasUserAccessToApplicationFeature(
      user.authorizations.applications,
      applicationId,
      DimaFeatureShortNameEnum.AM,
      AccessLevel.Write
    ),
    deleteAction: deletePermission,
    onDeleteReturnUrl: returnURL,
    listItems: [
      {
        label: "Name",
        displayItem: <Typography>{permission.name}</Typography>,
        editItem: (
          <TextField
            fullWidth
            id="name"
            name="name"
            type="text"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            size="small"
          />
        ),
      },
      {
        label: "Description",
        displayItem: <Typography>{permission.description}</Typography>,
        editItem: (
          <TextField
            fullWidth
            id="description"
            name="description"
            type="text"
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={formik.touched.description && formik.errors.description}
            size="small"
          />
        ),
      },
      {
        label: "No Device",
        displayItem: <Typography>{permission.noDevice?.toString() || "false"}</Typography>,
        editItem: (
          <Checkbox
            id="noDevice"
            name="noDevice"
            checked={formik.values.noDevice}
            onChange={formik.handleChange}
          />
        ),
      },
    ],
  }

  return <DiMaDetailsContent {...detailsContentProps} />
}
