import { StatusCodes } from "http-status-codes"
import { useEffect, useState } from "react"
import { DeviceFamilyGet } from "../app/models/DeviceFamily"
import { digitalManagerApi } from "../services/api"

export function useDeviceFamilies() {
  const [deviceFamiliesList, setDeviceFamiliesList] = useState<DeviceFamilyGet[]>([])
  const [isLoadingDeviceFamilies, setIsLoadingDeviceFamilies] = useState<boolean>(true)

  useEffect(() => {
    setIsLoadingDeviceFamilies(true)
    setDeviceFamiliesList([])
    digitalManagerApi
      .get<DeviceFamilyGet[]>(`/api/v1/deviceFamilies`)
      .then((res) => {
        if (res.status === StatusCodes.OK) {
          setDeviceFamiliesList(res.data)
        }
      })
      .finally(() => {
        setIsLoadingDeviceFamilies(false)
      })
  }, [])

  return {
    deviceFamiliesList,
    isLoadingDeviceFamilies,
  }
}
