import FileDownloadIcon from "@mui/icons-material/FileDownload"
import { Button, Typography } from "@mui/material"
import { useCallback } from "react"
import { Navigate } from "react-router-dom"
import { useFileDownload } from "../../../../../../hooks/useDownloadFile"
import { digitalManagerApi } from "../../../../../../services/api"
import {
  DiMaDetailsContent,
  DiMaDetailsContentProps,
} from "../../../../../components/DiMaDetailsContent"
import { Guid } from "../../../../../models/CustomType"
import { FilePut } from "../../../../../models/File"
import { FileVersionGet } from "../../../../../models/FileVersion"
import { getNiceFormattedDate } from "../../../../../utils/getFormattedDate"

function formatFileSize(bytes: number) {
  if (bytes === 0) {
    return "0 Bytes"
  }
  const k = 1024
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"]

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`
}

export function FileVersionDetails(props: {
  readonly version: FileVersionGet
  readonly fileId: Guid
}) {
  const { version, fileId } = props

  const returnURL = `/files/${fileId}#versions`
  const { handleDownload, downloading } = useFileDownload()

  const deleteVersion = useCallback(
    () => digitalManagerApi.delete(`/api/v1/files/${fileId}/versions/${version.id}`),
    [fileId, version.id]
  )

  const getFileName = useCallback(
    () =>
      `${version.file.name}${version.suffix ? version.suffix : ""}.${
        version.file.extension
      }`,
    [version.file.name, version.suffix, version.file.extension]
  )

  const handleDownloadClick = useCallback(() => {
    handleDownload(version.downloadLink, getFileName())
  }, [handleDownload, version.downloadLink, getFileName])

  if (!version) {
    return <Navigate to={returnURL} />
  }

  const detailsContentProps: DiMaDetailsContentProps<FilePut> = {
    label: "Version",
    isEditable: false,
    deleteAction: deleteVersion,
    onDeleteReturnUrl: returnURL,
    listItems: [
      {
        label: "Suffix",
        displayItem: <Typography>{version.suffix}</Typography>,
      },
      {
        label: "Size",
        displayItem: <Typography>{formatFileSize(version.size)}</Typography>,
      },
      {
        label: "Upload Date",
        displayItem: <Typography>{getNiceFormattedDate(version.uploadDate)}</Typography>,
      },
      {
        label: "Uploaded By",
        displayItem: <Typography>{version.uploadedBy.username}</Typography>,
      },
      {
        displayItem: (
          <Button
            variant="contained"
            startIcon={<FileDownloadIcon />}
            onClick={handleDownloadClick}
            disabled={downloading}
          >
            {downloading ? "Downloading..." : "Download"}
          </Button>
        ),
      },
    ],
  }

  return <DiMaDetailsContent {...detailsContentProps} />
}
