import { StatusCodes } from "http-status-codes"
import { useCallback, useEffect, useState } from "react"
import { digitalManagerApi } from "../services/api"

export function useAccessRequestCheck() {
  const [existAccessRequest, setExistAccessRequest] = useState<boolean>(false)
  const [isLoadingResponse, setIsLoadingResponse] = useState<boolean>(true)

  useEffect(() => {
    digitalManagerApi
      .get<boolean>("/api/v1/backOfficeAccess/requests/check")
      .then((res) => {
        if (res.status === StatusCodes.OK) {
          setExistAccessRequest(res.data)
        }
      })
      .finally(() => setIsLoadingResponse(false))
  }, [])

  const setAccessRequest = useCallback(() => setExistAccessRequest(true), [])

  return {
    existAccessRequest,
    setAccessRequest,
    isLoadingResponse,
  }
}
