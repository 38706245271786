import { TabContext, TabList } from "@mui/lab"
import Box from "@mui/material/Box"
import React, { useCallback, useContext, useState } from "react"
import { BackToURL } from "../../components/BackToURL"
import {
  DiMaDetailsHeader,
  DiMaDetailsHeaderProps,
} from "../../components/DiMaDetailsHeader"
import { DiMaProgress } from "../../components/DiMaProgress"
import { DiMaTabPanel } from "../../components/DiMaTabPanel"
import { LinkTab } from "../../components/LinkTab"
import { UserContext } from "../../context/UserContext"
import {
  AccessLevel,
  DimaFeatureShortNameEnum,
  hasUserAccessToFeature,
} from "../../models/Authorization"
import { EmailSettings } from "./email_settings_tab/EmailSettings"

export function SettingsContainer() {
  const { user } = useContext(UserContext)
  const returnURL = `/`

  const [activeTab, setActiveTab] = useState<string>("#email-settings")
  const handleChange = useCallback((_: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue)
  }, [])

  if (!user) {
    return <DiMaProgress />
  }

  const userHasEmailSettingsAccess = hasUserAccessToFeature(
    user.authorizations,
    DimaFeatureShortNameEnum.RR,
    AccessLevel.Read
  )

  const detailsHeaderProps: DiMaDetailsHeaderProps = {
    avatar:
      user.first && user.last
        ? user.first.substring(0, 1) + user.last.substring(0, 1)
        : "US",
    title: user.first && user.last ? `${user.first} ${user.last}` : "No name available",
    label: "User ID",
    subtitle: user.id,
  }

  return (
    <>
      <BackToURL url={returnURL} label="Back to home" />
      <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <DiMaDetailsHeader {...detailsHeaderProps} />
        <TabContext value={activeTab}>
          <Box className="DiMaBox-tabs">
            <TabList onChange={handleChange}>
              {userHasEmailSettingsAccess && (
                <LinkTab label="Email settings" value="#email-settings" />
              )}
            </TabList>
          </Box>
          {userHasEmailSettingsAccess && (
            <DiMaTabPanel label="#email-settings" activeTab={activeTab}>
              <EmailSettings />
            </DiMaTabPanel>
          )}
        </TabContext>
      </Box>
    </>
  )
}
