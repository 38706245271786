import { TabContext, TabList } from "@mui/lab"
import { Box, Grid } from "@mui/material"
import { useCallback, useContext, useEffect, useState } from "react"
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom"
import { digitalManagerApi } from "../../../../../services/api"
import { BackToURL } from "../../../../components/BackToURL"
import {
  DiMaDetailsHeader,
  DiMaDetailsHeaderProps,
} from "../../../../components/DiMaDetailsHeader"
import { DiMaProgress } from "../../../../components/DiMaProgress"
import { DiMaTabPanel } from "../../../../components/DiMaTabPanel"
import { LinkTab } from "../../../../components/LinkTab"
import { UserContext } from "../../../../context/UserContext"
import { DeviceRoleGet } from "../../../../models/DeviceRole"
import { DeviceRoleDetails } from "./details_tab/DeviceRoleDetails"
import { DeviceRolePermissions } from "./permissions_tab/DeviceRolePermissions"

export function DeviceRoleContainer() {
  const { user, isLoadingUser } = useContext(UserContext)

  const { deviceId, roleId } = useParams()
  const [activeTab, setActiveTab] = useState<string>("#details")
  const [role, setRole] = useState<DeviceRoleGet>()
  const navigate = useNavigate()
  const { hash } = useLocation()

  const returnURL = `/devices/${deviceId}#roles`
  const AVATAR_LENGTH = 2

  const handleChange = useCallback((_: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue)
  }, [])

  useEffect(() => {
    if (deviceId && roleId) {
      digitalManagerApi
        .get<DeviceRoleGet>(`/api/v1/devices/${deviceId}/roles/${roleId}`)
        .then((res) => {
          setRole(res.data)
        })
        .catch((err) => {
          if (err.response.status) {
            navigate(returnURL)
          }
        })
    }
  }, [deviceId, navigate, returnURL, roleId])

  useEffect(() => {
    if (hash) {
      setActiveTab(hash)
    }
  }, [hash])

  if (!deviceId || !roleId) {
    return <Navigate to="/devices" />
  }

  if (!role || isLoadingUser || !user) {
    return <DiMaProgress />
  }

  const detailsHeaderProps: DiMaDetailsHeaderProps = {
    avatar: role.name.substring(0, AVATAR_LENGTH),
    title: role.name,
    label: "Role ID",
    subtitle: role.id,
  }

  return (
    <>
      <BackToURL url={returnURL} label="Back to roles" />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <Grid container>
          <Grid item xs={10}>
            <DiMaDetailsHeader {...detailsHeaderProps} />
          </Grid>
        </Grid>
        <TabContext value={activeTab}>
          <Box className="DiMaBox-tabs">
            <TabList onChange={handleChange}>
              <LinkTab label="Details" value="#details" />
              <LinkTab label="Permissions" value="#permissions" />
            </TabList>
          </Box>
          <DiMaTabPanel label="#details" activeTab={activeTab}>
            <DeviceRoleDetails
              role={role}
              deviceId={deviceId}
              user={user}
              setRole={setRole}
            />
          </DiMaTabPanel>
          <DiMaTabPanel label="#permissions" activeTab={activeTab}>
            <DeviceRolePermissions />
          </DiMaTabPanel>
        </TabContext>
      </Box>
    </>
  )
}
