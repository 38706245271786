import { TabContext, TabList } from "@mui/lab"
import { Box } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { DiMaTabPanel } from "../../components/DiMaTabPanel"
import { LinkTab } from "../../components/LinkTab"
import { Files } from "./files_tab/Files"

export function FilesContainer() {
  const [activeTab, setActiveTab] = useState<string>("#files")
  const { hash } = useLocation()

  useEffect(() => {
    if (hash) {
      setActiveTab(hash)
    }
  }, [hash])

  const handleChange = useCallback((_: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue)
  }, [])

  return (
    <TabContext value={activeTab}>
      <Box className="DiMaBox-tabs">
        <TabList onChange={handleChange}>
          <LinkTab label="Files" value="#files" />
        </TabList>
      </Box>
      <DiMaTabPanel label="#files" activeTab={activeTab}>
        <Files />
      </DiMaTabPanel>
    </TabContext>
  )
}
