import { Box } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { Link, useParams } from "react-router-dom"
import { useFileVersions } from "../../../../../hooks/useFileVersions"
import { DimaFeatureShortNameEnum } from "../../../../models/Authorization"
import { FileVersionGet } from "../../../../models/FileVersion"
import { getNiceFormattedDate } from "../../../../utils/getFormattedDate"
import { DiMaAddButton } from "../../../partials/DiMaAddButton"
import { EntityTab } from "../../../partials/EntityTab"

export function FileVersions() {
  const { fileId } = useParams()
  const { fileVersions, isLoadingFileVersions } = useFileVersions(fileId)

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      renderCell: (params) => (
        <Link to={`/files/${fileId}/versions/${params.row.id}`}>
          {`${params.row.name}${params.row.suffix ? params.row.suffix : ""}.${
            params.row.extension
          }`}
        </Link>
      ),
      flex: 2,
    },
    {
      field: "uploadDate",
      headerName: "Upload Date",
      renderCell: (params) => getNiceFormattedDate(params.row.uploadDate),
      flex: 2,
    },
    {
      field: "uploadedBy",
      headerName: "Uploaded By",
      flex: 2,
    },
  ]

  const rows = fileVersions.map((fileVersion: FileVersionGet) => ({
    id: fileVersion.id,
    name: fileVersion.file.name,
    extension: fileVersion.file.extension,
    suffix: fileVersion.suffix,
    uploadDate: fileVersion.uploadDate,
    uploadedBy: fileVersion.uploadedBy.username,
  }))

  return (
    <>
      <Box className="DiMaBox-one-element-right">
        <DiMaAddButton
          url={`/files/${fileId}/versions/create`}
          feature={DimaFeatureShortNameEnum.FM}
        />
      </Box>
      <EntityTab
        rows={rows}
        cols={columns}
        isLoading={isLoadingFileVersions}
        initialState={{
          sorting: { sortModel: [{ field: "uploadDate", sort: "desc" }] },
        }}
      />
    </>
  )
}
