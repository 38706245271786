import PersonSearchIcon from "@mui/icons-material/PersonSearch"
import { LoadingButton } from "@mui/lab"
import { Autocomplete, Grid, TextField, Typography } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { StatusCodes } from "http-status-codes"
import { useCallback, useState } from "react"
import { toast } from "react-toastify"
import { digitalManagerApi } from "../../../../services/api"
import { ActiveDirectoryExplorerUserGet } from "../../../models/User"
import { EntityTab } from "../../partials/EntityTab"

const isValidGUID = function (value: string): boolean {
  if (
    /^(\{)?[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(})?$/.test(
      value
    )
  ) {
    return true
  } else {
    return false
  }
}

export function ActiveDirectoryExplorer() {
  const [acValue, setACValue] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [rows, setRows] = useState<ActiveDirectoryExplorerUserGet[]>([])
  const [isAutocompleteModified, setIsAutocompleteModified] = useState<boolean>(false)

  const fillACValueFromInputs = useCallback(
    (
      e:
        | React.KeyboardEvent<HTMLDivElement>
        | React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
    ) => {
      const inputText = (e.target as HTMLInputElement).value
      const idSet = new Set<string>()
      inputText.split(" ").forEach((id) => {
        if (isValidGUID(id)) {
          idSet.add(id)
          if (!isAutocompleteModified) {
            setIsAutocompleteModified(true)
          }
        }
      })
      acValue.forEach((id) => idSet.add(id))
      setACValue(Array.from(idSet))
    },
    [acValue, isAutocompleteModified]
  )

  const retrieveIds = useCallback(() => {
    setIsLoading(true)
    setIsAutocompleteModified(false)
    digitalManagerApi
      .post<ActiveDirectoryExplorerUserGet[]>(`/api/v1/activeDirectory/users`, {
        ids: acValue,
      })
      .then((res) => {
        if (res.status === StatusCodes.OK) {
          setRows(res.data)
        }
      })
      .catch(() => {
        toast.error(`Something went wrong.`)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [acValue])

  const onKeyDown = useCallback(
    (e) => {
      if (e.key === "Enter" && (e.target as HTMLInputElement).value) {
        fillACValueFromInputs(e)
      }
    },
    [fillACValueFromInputs]
  )

  const onBlur = useCallback(
    (e) => {
      fillACValueFromInputs(e)
    },
    [fillACValueFromInputs]
  )

  const renderInput = useCallback(
    (params) => (
      <TextField
        {...params}
        placeholder={
          acValue.length === 0 ? "Paste here the list of Active Directory IDs" : ""
        }
        onKeyDown={onKeyDown}
        onBlur={onBlur}
      />
    ),
    [acValue.length, onBlur, onKeyDown]
  )

  const onChange = useCallback(
    function (_event, newValues, reason) {
      if (reason === "removeOption" || reason === "clear") {
        if (!isAutocompleteModified) {
          setIsAutocompleteModified(true)
        }
        setACValue(newValues as string[])
      }
    },
    [isAutocompleteModified]
  )

  const onClick = useCallback(() => {
    retrieveIds()
  }, [retrieveIds])

  const columns: GridColDef[] = [
    {
      field: "activeDirectoryId",
      headerName: "Active Directory ID",
      renderCell: (params) => <Typography>{params.row.activeDirectoryId}</Typography>,
      flex: 1,
    },
    {
      field: "username",
      headerName: "Email address",
      renderCell: (params) => <Typography>{params.row.username}</Typography>,
      flex: 1,
    },
  ]

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={10}>
          <Autocomplete
            fullWidth
            limitTags={2}
            id="tags-filled"
            options={[]}
            freeSolo
            multiple
            value={acValue}
            clearOnBlur
            onChange={onChange}
            renderInput={renderInput}
          />
        </Grid>
        <Grid item xs={2}>
          <LoadingButton
            fullWidth
            loading={isLoading}
            sx={{ height: "55px" }}
            onClick={onClick}
            startIcon={<PersonSearchIcon />}
            variant="contained"
            disabled={acValue.length <= 0 || !isAutocompleteModified}
          >
            Search
          </LoadingButton>
        </Grid>
      </Grid>
      <EntityTab rows={rows} cols={columns} isLoading={isLoading} />
    </>
  )
}
