import { Typography } from "@mui/material"
import { useCallback } from "react"
import { Navigate } from "react-router-dom"
import { digitalManagerApi } from "../../../../../../../services/api"
import {
  DiMaDetailsContent,
  DiMaDetailsContentProps,
} from "../../../../../../components/DiMaDetailsContent"
import { ApplicationPermissionPut } from "../../../../../../models/ApplicationPermission"
import { ApplicationRolePermissionGet } from "../../../../../../models/ApplicationRolePermission"
import {
  AccessLevel,
  DimaFeatureShortNameEnum,
  hasUserAccessToApplicationFeature,
} from "../../../../../../models/Authorization"
import { UserInfo } from "../../../../../../models/User"

export function ApplicationRolePermissionDetails(props: {
  readonly applicationRolePermission: ApplicationRolePermissionGet
  readonly user: UserInfo
}) {
  const { applicationRolePermission, user } = props
  const returnURL = `/applications/${applicationRolePermission.application.id}/roles/${applicationRolePermission.role.id}?noDevice=${applicationRolePermission.noDevice}#permissions`

  const deleteRolePermission = useCallback(
    () =>
      digitalManagerApi.delete(
        `/api/v1/applications/${applicationRolePermission.application.id}/roles/${applicationRolePermission.role.id}/permissions/${applicationRolePermission.permission.id}?noDevice=${applicationRolePermission.noDevice}`
      ),
    [
      applicationRolePermission.application.id,
      applicationRolePermission.noDevice,
      applicationRolePermission.permission.id,
      applicationRolePermission.role.id,
    ]
  )

  if (!applicationRolePermission) {
    return <Navigate to={returnURL} />
  }

  const detailsContentProps: DiMaDetailsContentProps<ApplicationPermissionPut> = {
    label: "Permission",
    canWrite: hasUserAccessToApplicationFeature(
      user.authorizations.applications,
      applicationRolePermission.application.id,
      DimaFeatureShortNameEnum.AM,
      AccessLevel.Write
    ),
    isEditable: false,
    deleteAction: deleteRolePermission,
    onDeleteReturnUrl: returnURL,
    listItems: [
      {
        label: "Application",
        displayItem: (
          <Typography>{applicationRolePermission.application.name}</Typography>
        ),
      },
      {
        label: "Role",
        displayItem: <Typography>{applicationRolePermission.role.name}</Typography>,
      },
      {
        label: "Name",
        displayItem: <Typography>{applicationRolePermission.permission.name}</Typography>,
      },
      {
        label: "Description",
        displayItem: (
          <Typography>{applicationRolePermission.permission.description}</Typography>
        ),
      },
      {
        label: "No Device",
        displayItem: (
          <Typography>{applicationRolePermission.noDevice.toString()}</Typography>
        ),
      },
    ],
  }

  return <DiMaDetailsContent {...detailsContentProps} />
}
