import FileDownloadOffOutlinedIcon from "@mui/icons-material/FileDownloadOffOutlined"
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined"
import PersonSearchIcon from "@mui/icons-material/PersonSearch"
import { LoadingButton } from "@mui/lab"
import {
  Autocomplete,
  CircularProgress,
  Grid,
  MenuItem,
  Options,
  TextField,
  Typography,
} from "@mui/material"
import {
  GridColumns,
  GridExportMenuItemProps,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid"
import { StatusCodes } from "http-status-codes"
import { useCallback, useState } from "react"
import { toast } from "react-toastify"
import { digitalManagerApi } from "../../../../services/api"
import { TestReportDownloadGet, TestReportGet } from "../../../models/TestReport"
import { downloadBlob } from "../../../utils/downloadBlob"
import { getBooleanFlagIcon } from "../../../utils/getBooleanFlagIcon"
import { getNiceFormattedDate } from "../../../utils/getFormattedDate"
import { EntityTab } from "../../partials/EntityTab"

interface TestReportTabProps {
  id: number
  sn: string
  found: boolean
  paid?: boolean
  factory?: string
  dateModified?: Date
}

const checkSerialNumber = (serialNumber: string): boolean => {
  const globalSerialNumberRegex = /^([A-HL-M]{2})([\dA-C])([\dA-C])(\d{6})$/
  const chineseSerialNumberRegex =
    /^2T1([01])0((([1-5]|D)001|A1[1-9A-R][1-9A-S]))(\d{2})(([0-4]\d|5[0-2]))([\dA-HJ-NPR-Y]){4}$/
  const chineseOldSerialNumberRegex = /^X([A-HL-M]{2})([EXT])(.{6})$/
  const generalElectricSerialNumberRegex = /^([A-Za-z]{1,3})(\d{1})(\d{2})([=&!@#$]{1})$/

  const checkGlobalSerialNumber = (sn: string): boolean =>
    globalSerialNumberRegex.test(sn)

  const checkGESerialNumber = (sn: string): boolean =>
    generalElectricSerialNumberRegex.test(sn)

  const checkChineseSerialNumber = (sn: string): boolean =>
    chineseSerialNumberRegex.test(sn) || chineseOldSerialNumberRegex.test(sn)

  return (
    checkGlobalSerialNumber(serialNumber) ||
    checkChineseSerialNumber(serialNumber) ||
    checkGESerialNumber(serialNumber)
  )
}

export function TestReportSearch() {
  const [snArray, setSnArray] = useState<string[]>([])
  // snTableArray is used to download .zip files only for the (found) test reports that are currently visible in the DiMaDataGrid
  const [snTableArray, setSnTableArray] = useState<string[]>([])
  const [isRetrievingTestReports, setIsRetrievingTestReports] = useState<boolean>(false)
  const [isAutocompleteModified, setIsAutocompleteModified] = useState<boolean>(false)
  const [downloadingTestReportsArray, setDownloadingTestReportsArray] = useState<
    string[]
  >([])
  const [isZipDownloading, setIsZipDownloading] = useState<boolean>(false)
  const [rows, setRows] = useState<TestReportTabProps[]>([])

  const retrieveTestReports = useCallback(() => {
    setIsRetrievingTestReports(true)
    setIsAutocompleteModified(false)
    const snRows: string[] = []

    digitalManagerApi
      .post<TestReportGet[]>(`/api/v1/testReports`, {
        sns: snArray,
      })
      .then((res) => {
        if (res.status === StatusCodes.OK) {
          const testReportTabs: Array<TestReportTabProps> = []
          const notFoundSnArray = [...snArray]

          res.data.forEach((retrievedTestReport) => {
            notFoundSnArray.splice(notFoundSnArray.indexOf(retrievedTestReport.sn), 1)
            testReportTabs.push({
              id: testReportTabs.length,
              sn: retrievedTestReport.sn,
              found: true,
              paid: retrievedTestReport.paid,
              factory: retrievedTestReport.factory,
              dateModified: retrievedTestReport.dateModified,
            })
            snRows.push(retrievedTestReport.sn)
          })

          notFoundSnArray.forEach((sn) => {
            testReportTabs.push({
              sn,
              id: testReportTabs.length,
              found: false,
            })
          })

          setRows(testReportTabs)
        }
      })
      .catch(() => {
        toast.error("Cannot retrieve test reports")
      })
      .finally(() => {
        setSnTableArray(snRows)
        setIsRetrievingTestReports(false)
      })
  }, [snArray])

  const downloadTestReport = useCallback(
    (testReportSn: string) => {
      setDownloadingTestReportsArray([...downloadingTestReportsArray, testReportSn])

      digitalManagerApi
        .get<TestReportDownloadGet>(`/api/v1/testReports/${testReportSn}/download`)
        .then((res) => {
          if (res.status === StatusCodes.OK && res.data.reportUrl) {
            window.location.assign(res.data.reportUrl)
          } else {
            toast.error(`Cannot download the test report`)
          }
        })
        .catch(() => {
          toast.error(`Cannot download the test report`)
        })
        .finally(() => {
          setDownloadingTestReportsArray(
            downloadingTestReportsArray.filter((sn) => sn !== testReportSn)
          )
        })
    },
    [downloadingTestReportsArray]
  )

  const downloadTestReportsZip = useCallback(() => {
    setIsZipDownloading(true)

    digitalManagerApi
      .post<Blob>(
        `/api/v1/testReports/download`,
        {
          sns: snTableArray,
        },
        { responseType: "blob" }
      )
      .then((res) => {
        const tempDate = new Date()
        const [day, month, year] = [
          tempDate.getDate(),
          tempDate.getMonth() + 1,
          tempDate.getFullYear(),
        ]
        const fileName = `TestReports-${year}_${month}_${day}.zip`

        downloadBlob(res.data, fileName)
      })
      .catch(() => {
        toast.error(`Cannot download the ZIP file`)
      })
      .finally(() => setIsZipDownloading(false))
  }, [snTableArray])

  const onClickMenu = useCallback(
    (coverUpMenu: (() => void) | undefined) => () => {
      downloadTestReportsZip()

      // Hide the export menu after the export
      coverUpMenu?.()
    },
    [downloadTestReportsZip]
  )

  const ZipExportMenuItem = useCallback(
    (props: GridExportMenuItemProps<Options>) => {
      const { hideMenu } = props

      return <MenuItem onClick={onClickMenu(hideMenu)}>Download All (.zip)</MenuItem>
    },
    [onClickMenu]
  )

  const ZipToolbar = useCallback(
    () => (
      <GridToolbarContainer>
        <GridToolbarExportContainer>
          <ZipExportMenuItem />
        </GridToolbarExportContainer>
        <GridToolbarQuickFilter style={{ marginRight: "0px", marginLeft: "auto" }} />
      </GridToolbarContainer>
    ),
    [ZipExportMenuItem]
  )

  const fillSnArrayFromInputs = useCallback(
    (
      e:
        | React.KeyboardEvent<HTMLDivElement>
        | React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
    ) => {
      const inputText = (e.target as HTMLInputElement).value
      const snSet = new Set<string>()

      inputText.split(" ").forEach((sn) => {
        if (checkSerialNumber(sn)) {
          snSet.add(sn)
          if (!isAutocompleteModified) {
            setIsAutocompleteModified(true)
          }
        }
      })

      snArray.forEach((sn) => snSet.add(sn))
      setSnArray(Array.from(snSet))
    },
    [isAutocompleteModified, snArray]
  )

  const onClickDownload = useCallback(
    (params) => () => {
      downloadTestReport(params.row.sn)
    },
    [downloadTestReport]
  )

  const cols: GridColumns<TestReportTabProps> = [
    {
      field: "sn",
      headerName: "Serial number",
      renderCell: (params) => <Typography>{params.row.sn}</Typography>,
      flex: 1,
    },
    {
      field: "found",
      headerName: "Found",
      renderCell: (params) => getBooleanFlagIcon(params.row.found),
      flex: 1,
    },
    {
      field: "paid",
      headerName: "Paid",
      renderCell: (params) => (
        <>{params.row.paid ? getBooleanFlagIcon(params.row.paid) : ""}</>
      ),
      flex: 1,
    },
    {
      field: "factory",
      headerName: "Factory",
      renderCell: (params) => (
        <Typography sx={{ textTransform: "capitalize" }}>
          {params.row.factory ?? ""}
        </Typography>
      ),
      flex: 1,
    },
    {
      field: "dateModified",
      headerName: "Last update",
      renderCell: (params) => (
        <Typography>
          {params.row.dateModified
            ? getNiceFormattedDate(params.row.dateModified.toString())
            : ""}
        </Typography>
      ),
      flex: 1,
    },
    {
      field: "download",
      headerName: "Download",
      renderCell: (params) => (
        <>
          {
            <LoadingButton
              onClick={onClickDownload(params)}
              startIcon={
                <>
                  {params.row.found && (
                    <FileDownloadOutlinedIcon
                      color={isZipDownloading ? "disabled" : "info"}
                    />
                  )}
                  {!params.row.found && <FileDownloadOffOutlinedIcon color="disabled" />}
                </>
              }
              loading={downloadingTestReportsArray.includes(params.row.sn)}
              disabled={isZipDownloading || !params.row.found}
              loadingIndicator={<CircularProgress color="info" size={16} />}
              loadingPosition={"start"}
              style={{ minWidth: 30 }}
            />
          }
        </>
      ),
      flex: 1,
    },
  ]

  const onClick = useCallback(() => {
    retrieveTestReports()
  }, [retrieveTestReports])

  const onChange = useCallback(
    function (_event, newValues, reason) {
      if (reason === "removeOption" || reason === "clear") {
        if (!isAutocompleteModified) {
          setIsAutocompleteModified(true)
        }
        setSnArray(newValues as string[])
      }
    },
    [isAutocompleteModified]
  )

  const onKeyDown = useCallback(
    (e) => {
      if (e.key === "Enter" && (e.target as HTMLInputElement).value) {
        fillSnArrayFromInputs(e)
      }
    },
    [fillSnArrayFromInputs]
  )

  const onBlur = useCallback(
    (e) => {
      fillSnArrayFromInputs(e)
    },
    [fillSnArrayFromInputs]
  )

  const renderInput = useCallback(
    (params) => (
      <TextField
        {...params}
        placeholder={snArray.length === 0 ? "Paste here the list of Test Report SNs" : ""}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
      />
    ),
    [onBlur, onKeyDown, snArray.length]
  )

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={10}>
          <Autocomplete
            fullWidth
            freeSolo
            clearOnBlur
            multiple
            limitTags={6}
            id="test-report-sns"
            value={snArray}
            options={[]}
            onChange={onChange}
            renderInput={renderInput}
          />
        </Grid>
        <Grid item xs={2}>
          <LoadingButton
            fullWidth
            loading={isRetrievingTestReports}
            disabled={isZipDownloading || !isAutocompleteModified || snArray.length === 0}
            sx={{ height: "55px" }}
            onClick={onClick}
            startIcon={<PersonSearchIcon />}
            variant="contained"
          >
            Search
          </LoadingButton>
        </Grid>
      </Grid>
      <EntityTab
        rows={rows}
        cols={cols}
        isLoading={isRetrievingTestReports || isZipDownloading}
        CustomToolbar={ZipToolbar}
        initialState={{
          sorting: { sortModel: [{ field: "found", sort: "desc" }] },
        }}
      />
    </>
  )
}
