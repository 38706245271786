import { Box } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { Link, useParams } from "react-router-dom"
import { useDeviceRoles } from "../../../../../hooks/useDeviceRoles"
import { DeviceRoleGet } from "../../../../models/DeviceRole"
import { getBooleanFlagIcon } from "../../../../utils/getBooleanFlagIcon"
import { DiMaAddButton } from "../../../partials/DiMaAddButton"
import { EntityTab } from "../../../partials/EntityTab"

export function DeviceRoles() {
  const { deviceId } = useParams()
  const { deviceRoles, isLoadingDeviceRoles } = useDeviceRoles(deviceId)

  const rolesColumns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      renderCell: (params) => (
        <Link to={`/devices/${deviceId}/roles/${params.row.id}`}>{params.row.name}</Link>
      ),
      flex: 1,
    },
    {
      field: "isDefault",
      headerName: "Is Default",
      type: "boolean",
      renderCell: (params) => getBooleanFlagIcon(params.row.isDefault),
      flex: 1,
    },
  ]

  const rolesRows = deviceRoles.map((deviceRole: DeviceRoleGet) => ({
    id: deviceRole.id,
    name: deviceRole.name,
    isDefault: deviceRole.isDefault,
  }))

  return (
    <>
      <Box className="DiMaBox-one-element-right">
        <DiMaAddButton url={`/devices/${deviceId}/roles/create`} feature="DM" />
      </Box>
      <EntityTab
        rows={rolesRows}
        cols={rolesColumns}
        isLoading={isLoadingDeviceRoles}
        initialState={{
          sorting: { sortModel: [{ field: "name", sort: "asc" }] },
        }}
      />
    </>
  )
}
