import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges"
import LoadingButton from "@mui/lab/LoadingButton"
import { Box, Typography } from "@mui/material"
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid"
import { useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { useManufacturedDevices } from "../../../../hooks/useManufacturedDevices"
import { digitalManagerApi } from "../../../../services/api"
import { ManufacturedDeviceGeneralInfo } from "../../../models/SupplierPortal"
import { EntityTab } from "../../partials/EntityTab"

export function ManufacturedDevices() {
  const {
    manufacturedDevicesList,
    isLoadingManufacturedDevice,
    reloadManufacturedDevices,
  } = useManufacturedDevices()
  const navigate = useNavigate()

  const onValidate = useCallback(
    (deviceId: string) => () => {
      digitalManagerApi
        .put(`/api/v1/litePanelPro/resetPassword?manufacturedDeviceId=${deviceId}`)
        .then(() => {
          toast.success("Reset password request validated successfully")
          reloadManufacturedDevices()
        })
        .catch(() => {
          toast.error("Failde to validate the request")
        })
    },
    [reloadManufacturedDevices]
  )

  const navigateToPage = useCallback(
    (params: GridRenderCellParams) => () => {
      navigate(`/supplierPortal/manufacturedDevices/${params.row.id}`)
    },
    [navigate]
  )
  const columns: GridColDef[] = [
    {
      field: "serialNumberAbb",
      headerName: "SN ABB",
      renderCell: (params) => (
        <Typography variant="body1">{params.row.serialNumberAbb}</Typography>
      ),
      flex: 1,
    },
    {
      field: "serialNumberAsem",
      headerName: "SN ASEM",
      renderCell: (params) => (
        <Typography variant="body1">{params.row.serialNumberAsem}</Typography>
      ),
      flex: 1,
    },

    {
      field: "wanMac",
      headerName: "Wan Mac",
      renderCell: (params) => (
        <Typography variant="body1">{params.row.wanMac}</Typography>
      ),
      flex: 1,
    },
    {
      field: "lanMac",
      headerName: "Lan Mac",
      renderCell: (params) => (
        <Typography variant="body1">{params.row.lanMac}</Typography>
      ),
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      renderCell: (params: GridRenderCellParams) => (
        <Box>
          <LoadingButton startIcon={<MoreHorizIcon />} onClick={navigateToPage(params)}>
            Details
          </LoadingButton>
          <LoadingButton
            disabled={!params.row.isResetPasswordRequestCompleted}
            onClick={onValidate(params.row.id)}
          >
            <PublishedWithChangesIcon />
          </LoadingButton>
        </Box>
      ),
      flex: 1,
    },
  ]

  const rows = manufacturedDevicesList.map(
    (ManufacturedDeviceEntity: ManufacturedDeviceGeneralInfo) => ({
      id: ManufacturedDeviceEntity.id,
      serialNumberAbb: ManufacturedDeviceEntity.serialNumberAbb,
      serialNumberAsem: ManufacturedDeviceEntity.serialNumberAsem,
      wanMac: ManufacturedDeviceEntity.wanMac,
      lanMac: ManufacturedDeviceEntity.lanMac,
      isResetPasswordRequestCompleted:
        ManufacturedDeviceEntity.litePanelProResetPasswordRequests.some(
          (rpr) => rpr.completionDate == null
        ),
    })
  )

  return (
    <EntityTab
      rows={rows}
      cols={columns}
      isLoading={isLoadingManufacturedDevice}
      initialState={{
        sorting: { sortModel: [{ field: "serialNumberAbb", sort: "asc" }] },
      }}
    />
  )
}
