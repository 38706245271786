import { Guid } from "./CustomType"

export enum AccessLevel {
  NoAccess = 0,
  Read = 1,
  Write = 2,
  Create = 3,
}

export type ApplicationFeaturesAccessLevel = {
  [key: string]: AccessLevel
}

export interface ApplicationAccessLevel {
  id: string
  name: string
  shortName: string
  featuresAccesses: ApplicationFeaturesAccessLevel
}

export interface FeatureAccessLevel {
  id: string
  name: string
  shortName: string
  accessLevel: AccessLevel
}

export interface UserAuthorizations {
  applications: ApplicationAccessLevel[]
  features: FeatureAccessLevel[]
}

export interface UserAccesses {
  hasAccessToDima: boolean
  authorizations: UserAuthorizations
}

export enum DimaFeatureEnum {
  AM = "Application Management",
  FM = "File Manager",
  NM = "Notifications",
  RM = "Role Manager",
  RR = "Role Requests",
  TR = "Test Reports",
  BA = "Backoffice Access",
  AD = "AD Explorer",
  TA = "Tallarna",
  SP = "Supplier Portal",
  DM = "Device manager",
}

export enum DimaFeatureShortNameEnum {
  AM = "AM",
  FM = "FM",
  NM = "NM",
  RM = "RM",
  RR = "RR",
  TR = "TR",
  BA = "BA",
  AD = "AD",
  TA = "TA",
  SP = "SP",
  DM = "DM",
}

export const DimaFeature: { [key: string]: string } = {
  AM: "Application Management",
  FM: "File Manager",
  NM: "Notifications",
  RM: "Role Manager",
  RR: "Role Requests",
  TR: "Test Reports",
  BA: "Backoffice Access",
  AD: "AD Explorer",
  TA: "Tallarna",
  SP: "Supplier Portal",
  DM: "Device manager",
}

export interface ApplicationAccessLevelEdit {
  id: string
  featuresAccesses: ApplicationFeaturesAccessLevel
}

export interface FeatureAccessLevelEdit {
  id: string
  accessLevel: AccessLevel
}

export const getAppFeatureAccessLevelByAppShortName = (
  appShortName: string,
  feature: string,
  applicationsAccessLevel?: ApplicationAccessLevel[]
): AccessLevel => {
  if (applicationsAccessLevel) {
    const appAccessLevel = applicationsAccessLevel.find(
      (app) => app.shortName === appShortName && app.featuresAccesses[feature]
    )

    if (appAccessLevel) {
      return appAccessLevel.featuresAccesses[feature]
    }
  }

  return AccessLevel.NoAccess
}

export const getAppFeatureAccessLevelByAppId = (
  appId: string,
  feature: string,
  applicationsAccessLevel?: ApplicationAccessLevel[]
): AccessLevel => {
  if (applicationsAccessLevel) {
    const appAccessLevel = applicationsAccessLevel.find(
      (app) => app.id === appId && app.featuresAccesses[feature]
    )

    if (appAccessLevel) {
      return appAccessLevel.featuresAccesses[feature]
    }
  }

  return AccessLevel.NoAccess
}

export const getFeatureAccessLevel = (
  featureShortName: string,
  featuresAccessLevel?: FeatureAccessLevel[]
): AccessLevel => {
  if (featuresAccessLevel) {
    const featureAccessLevel = featuresAccessLevel.find(
      (f) => f.shortName === featureShortName
    )

    if (featureAccessLevel) {
      return featureAccessLevel.accessLevel
    }
  }

  return AccessLevel.NoAccess
}

export const hasUserAccessToDima = (user: UserAuthorizations): boolean => {
  const hasAccessToApplicationFeature = user.applications.find((app) =>
    Object.keys(app.featuresAccesses).find((feature) => {
      if (app.featuresAccesses && app.featuresAccesses[feature] >= AccessLevel.Read) {
        return true
      }
      return false
    })
  )

  const hasAccessToFeature = user.features.find((feature) => {
    if (feature.accessLevel >= AccessLevel.Read) {
      return true
    }
    return false
  })

  return !! (hasAccessToApplicationFeature || hasAccessToFeature)
}

export const canUserGrantAccessToDima = (user: UserAuthorizations): boolean => {
  const canGrantAccessToAppFeature = user.applications.find((app) =>
    Object.keys(app.featuresAccesses).find((feature) => {
      if (app.featuresAccesses && app.featuresAccesses[feature] >= AccessLevel.Write) {
        return true
      }
      return false
    })
  )

  const canGrantAccessToFeature = user.features.find((feature) => {
    if (feature.accessLevel >= AccessLevel.Write) {
      return true
    }
    return false
  })

  return !! (canGrantAccessToAppFeature || canGrantAccessToFeature)
}

export const hasUserAccessToFeature = (
  user: UserAuthorizations,
  feature: string,
  level: AccessLevel
): boolean => {
  const hasAccessToApplicationFeature = user.applications.find(
    (app: ApplicationAccessLevel) =>
      Object.keys(app.featuresAccesses)
        .filter((f) => f === feature)
        .find((f) => {
          if (app.featuresAccesses && app.featuresAccesses[f] >= level) {
            return true
          }
          return false
        })
  )

  const hasAccessToFeature = user.features.find((f: FeatureAccessLevel) => {
    if (f.shortName === feature && f.accessLevel >= level) {
      return true
    }
    return false
  })

  return !! (hasAccessToApplicationFeature || hasAccessToFeature)
}

export const hasUserAccessToApplicationFeature = (
  appAuthorizations: ApplicationAccessLevel[],
  applicationId: Guid,
  feature: string,
  minAccessLevel: AccessLevel
) =>
  getAppFeatureAccessLevelByAppId(applicationId, feature, appAuthorizations) >=
  minAccessLevel
