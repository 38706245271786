import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace"
import { Box, Stack, Typography } from "@mui/material"
import { Link } from "react-router-dom"

export function BackToURL(props: { readonly url: string; readonly label: string }) {
  const { url, label } = props
  return (
    <Stack
      direction="row"
      sx={{
        margin: "0 0 20px 0",
      }}
    >
      <Link to={url}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <KeyboardBackspaceIcon />
          <Typography component="span" sx={{ marginLeft: "10px" }}>
            {label}
          </Typography>
        </Box>
      </Link>
    </Stack>
  )
}
