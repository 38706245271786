import { LoadingButton } from "@mui/lab"
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material"
import { useFormik } from "formik"
import { StatusCodes } from "http-status-codes"
import { useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { object, string } from "yup"
import { digitalManagerApi } from "../../../../../../../services/api"
import { BackToURL } from "../../../../../../components/BackToURL"
import { ApplicationRoleDetail, ApplicationRoleGet } from "../../../../../../models/ApplicationRole"
import { Guid } from "../../../../../../models/CustomType"
import {
  DeviceRolePermissionGet,
} from "../../../../../../models/DeviceRolePermission"

const validationSchema = object({
  applicationRoleId: string().required("Application role is required"),
})

export function DeviceRolePermissionApplicationRoleCreate() {
  const { deviceId, roleId, permissionId } = useParams()
  const [applicationId, setApplicationId] = useState<Guid>("")
  const [applicationRoles, setApplicationRoles] = useState<ApplicationRoleGet[]>([])
  const [isLoadingApplicationRoles, setIsLoadingApplicationRoles] =
    useState<boolean>(true)
  const [
    deviceRolePermissionApplicationRolesIds,
    setDeviceRolePermissionApplicationRolesIds,
  ] = useState<ApplicationRoleDetail[]>([])
  const [
    isLoadingDeviceRolePermissionApplicationRolesIds,
    setIsLoadingDeviceRolePermissionApplicationRolesIds,
  ] = useState<boolean>(true)

  const navigate = useNavigate()
  const returnURL = `/devices/${deviceId}/roles/${roleId}/permissions/${permissionId}#app-roles`

  useEffect(() => {
    if (applicationId) {
      digitalManagerApi
        .get<ApplicationRoleGet[]>(`/api/v1/applications/${applicationId}/roles`)
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            setApplicationRoles(res.data)
          }
        })
        .catch(() => {
          toast.error("Cannot retrieve application roles")
        })
        .finally(() => {
          setIsLoadingApplicationRoles(false)
        })
    } else {
      setIsLoadingApplicationRoles(false)
    }
  }, [applicationId])

  useEffect(() => {
    if (deviceId && roleId && permissionId) {
      digitalManagerApi
        .get<DeviceRolePermissionGet>(
          `/api/v1/devices/${deviceId}/roles/${roleId}/permissions/${permissionId}`
        )
        .then((res) => {
          if (res.status === StatusCodes.OK && res.data) {
            setApplicationId(res.data.application.id)
          }
          if (res.data.applicationRoles.length) {
            setDeviceRolePermissionApplicationRolesIds(res.data.applicationRoles)
          }
        })
        .catch((err) => {
          if (err.response.status) {
            navigate(returnURL)
          }
        })
        .finally(() => {
          setIsLoadingDeviceRolePermissionApplicationRolesIds(false)
        })
    }
  }, [deviceId, navigate, permissionId, returnURL, roleId])

  const formik = useFormik({
    validationSchema,
    initialValues: {
      applicationRoleId: "",
    },
    onSubmit: (values, { setSubmitting }) => {
      digitalManagerApi
        .post(
          `/api/v1/devices/${deviceId}/roles/${roleId}/permissions/${permissionId}/applicationRoles/${values.applicationRoleId}`
        )
        .then((res) => {
          if (res.status === StatusCodes.CREATED) {
            toast.success("Application's role added to device role's permission")
            navigate(returnURL)
          }
        })
        .catch(() => {
          toast.error("Cannot add application's role to device role's permission")
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
  })

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      formik.setFieldValue(event.target.name, event.target.value)
    },
    [formik]
  )

  const submitForm = useCallback(() => {
    formik.submitForm()
  }, [formik])

  return (
    <Box>
      <BackToURL url={returnURL} label="Back to role's permission" />
      <Paper>
        <Card>
          <CardHeader
            title="Add application's role to role's permission"
            action={
              <LoadingButton
                variant="contained"
                type="submit"
                loading={formik.isSubmitting}
                onClick={submitForm}
                disabled={!formik.dirty || !formik.isValid}
              >
                Save
              </LoadingButton>
            }
          />
          <CardContent
            sx={{
              padding: 0,
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
          >
            <Divider />
            <List
              sx={{
                width: "100%",
                bgcolor: "Background.paper",
              }}
            >
              <ListItem key={0}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Application role</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Select
                        id="applicationRoleId"
                        name="applicationRoleId"
                        value={formik.values.applicationRoleId}
                        onChange={handleChange}
                        disabled={
                          isLoadingApplicationRoles ||
                          isLoadingDeviceRolePermissionApplicationRolesIds
                        }
                        size="small"
                        displayEmpty
                        fullWidth
                      >
                        {!isLoadingApplicationRoles &&
                          !isLoadingDeviceRolePermissionApplicationRolesIds &&
                          applicationRoles.map((applicationRole: ApplicationRoleGet) => (
                            <MenuItem
                              value={applicationRole.id}
                              key={applicationRole.id}
                              disabled={deviceRolePermissionApplicationRolesIds.some(
                                (item) => item.id === applicationRole.id
                              )}
                            >
                              {applicationRole.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Paper>
    </Box>
  )
}
