import FileDownloadIcon from "@mui/icons-material/FileDownload"
import {
  Button,
  Checkbox,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material"
import { useFormik } from "formik"
import { useCallback } from "react"
import { Navigate } from "react-router-dom"
import { toast } from "react-toastify"
import { boolean, object, string } from "yup"
import { useFileDownload } from "../../../../../hooks/useDownloadFile"
import { useFileTypes } from "../../../../../hooks/useFileTypes"
import { digitalManagerApi } from "../../../../../services/api"
import {
  DiMaDetailsContent,
  DiMaDetailsContentProps,
} from "../../../../components/DiMaDetailsContent"
import {
  AccessLevel,
  DimaFeatureShortNameEnum,
  hasUserAccessToFeature,
} from "../../../../models/Authorization"
import { FileGet, FilePut } from "../../../../models/File"
import { FileTypeGet } from "../../../../models/FileType"
import { UserInfo } from "../../../../models/User"

const validationSchema = object({
  name: string().required("Name is required"),
  extension: string().required("Extension is required"),
  isPublic: boolean().required("Is public is required"),
  quickDownload: boolean().required("Quick download is required"),
})

export function FileDetails(props: {
  readonly file: FileGet
  readonly user: UserInfo
  readonly setFile: (updatedFile: FileGet) => void
}) {
  const { file, user, setFile } = props

  const returnURL = `/files`

  const { fileTypes, isLoadingFileTypes } = useFileTypes()

  const deleteFile = useCallback(
    () => digitalManagerApi.delete(`/api/v1/files/${file.id}`),
    [file.id]
  )

  const { handleDownload, downloading } = useFileDownload()

  const formik = useFormik<FilePut>({
    validationSchema,
    initialValues: {
      name: file.name,
      extension: file.extension,
      description: file.description,
      typeId: file.type ? file.type.id : null,
      isPublic: file.isPublic,
      quickDownload: file.quickDownload,
    },
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) =>
      digitalManagerApi
        .put<FileGet>(`/api/v1/files/${file.id}`, {
          name: values.name,
          extension: values.extension,
          description: values.description,
          typeId: values.typeId ? values.typeId : null,
          isPublic: values.isPublic,
          quickDownload: values.quickDownload,
        })
        .then((res) => {
          toast.success("File updated")
          setFile(res.data)
        })
        .catch(() => {
          toast.error("Cannot update file")
          formik.resetForm()
          return Promise.reject(new Error("Cannot update file"))
        })
        .finally(() => {
          setSubmitting(false)
        }),
  })

  const getFileName = useCallback(
    () =>
      `${file.name}${file.lastVersion.suffix ? file.lastVersion.suffix : ""}.${
        file.extension
      }`,
    [file.name, file.lastVersion.suffix, file.extension]
  )

  const handleDownloadClick = useCallback(() => {
    handleDownload(file.downloadLink, getFileName())
  }, [handleDownload, file.downloadLink, getFileName])

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      formik.setFieldValue(event.target.name, event.target.value)
    },
    [formik]
  )

  if (!file) {
    return <Navigate to={returnURL} />
  }

  const detailsContentProps: DiMaDetailsContentProps<FilePut> = {
    formik,
    label: "File",
    canWrite: hasUserAccessToFeature(
      user.authorizations,
      DimaFeatureShortNameEnum.FM,
      AccessLevel.Write
    ),
    deleteAction: deleteFile,
    onDeleteReturnUrl: returnURL,
    listItems: [
      {
        label: "Name",
        displayItem: <Typography>{file.name}</Typography>,
        editItem: (
          <TextField
            fullWidth
            id="name"
            name="name"
            type="text"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            size="small"
          />
        ),
      },
      {
        label: "Extension",
        displayItem: <Typography>{file.extension}</Typography>,
        editItem: (
          <TextField
            fullWidth
            id="extension"
            name="extension"
            type="text"
            value={formik.values.extension}
            onChange={formik.handleChange}
            error={formik.touched.extension && Boolean(formik.errors.extension)}
            helperText={formik.touched.extension && formik.errors.extension}
            size="small"
          />
        ),
      },
      {
        label: "Description",
        displayItem: <Typography>{file.description}</Typography>,
        editItem: (
          <TextField
            fullWidth
            id="description"
            name="description"
            type="text"
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={formik.touched.description && formik.errors.description}
            size="small"
          />
        ),
      },
      {
        label: "Type",
        displayItem: <Typography>{file.type ? file.type.name : ""}</Typography>,
        editItem: (
          <Select
            id="typeId"
            name="typeId"
            value={formik.values.typeId || ""}
            onChange={handleChange}
            disabled={isLoadingFileTypes}
            size="small"
            displayEmpty
            fullWidth
          >
            <MenuItem value="">&nbsp;</MenuItem>
            {!isLoadingFileTypes &&
              fileTypes.map((fileType: FileTypeGet) => (
                <MenuItem value={fileType.id} key={fileType.id}>
                  {fileType.name}
                </MenuItem>
              ))}
          </Select>
        ),
      },
      {
        label: "Is Public",
        displayItem: <Typography>{file.isPublic?.toString() || "false"}</Typography>,
        editItem: (
          <Checkbox
            id="isPublic"
            name="isPublic"
            checked={formik.values.isPublic}
            onChange={formik.handleChange}
          />
        ),
      },
      {
        label: "Quick Download",
        displayItem: <Typography>{file.quickDownload?.toString() || "false"}</Typography>,
        editItem: (
          <Checkbox
            id="quickDownload"
            name="quickDownload"
            checked={formik.values.quickDownload}
            onChange={formik.handleChange}
          />
        ),
      },
      {
        label: "Owner",
        displayItem: <Typography>{file.owner.username}</Typography>,
      },
      {
        displayItem: (
          <Button
            variant="contained"
            startIcon={<FileDownloadIcon />}
            onClick={handleDownloadClick}
            disabled={downloading}
          >
            {downloading ? "Downloading..." : "Download last version"}
          </Button>
        ),
      },
    ],
  }

  return <DiMaDetailsContent {...detailsContentProps} />
}
