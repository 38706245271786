import { LoadingButton } from "@mui/lab"
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from "@mui/material"
import { useFormik } from "formik"
import { StatusCodes } from "http-status-codes"
import { useCallback, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { mixed, object } from "yup"
import { digitalManagerApi } from "../../../../../services/api"
import { BackToURL } from "../../../../components/BackToURL"
import { FileVersionPost } from "../../../../models/FileVersion"

const validationSchema = object({
  file: mixed().required("File is required"),
})

export function FileVersionCreate() {
  const { fileId } = useParams()
  const navigate = useNavigate()
  const returnURL = `/files/${fileId}#versions`
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [isUploadClicked, setIsUploadClicked] = useState(false)

  const formik = useFormik<FileVersionPost>({
    validationSchema,
    initialValues: {
      suffix: "",
      file: null,
    },
    onSubmit: (values, { setSubmitting }) => {
      const formData = new FormData()
      formData.append("suffix", values.suffix)
      if (values.file !== null) {
        formData.append("formFile", values.file)
      } else {
        toast.error("File is required")
        setSubmitting(false)
        return
      }

      digitalManagerApi
        .post(`/api/v1/files/${fileId}/versions`, formData, {
          headers: { "Content-type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            )
            setUploadProgress(progress)
          },
        })
        .then((res) => {
          if (res.status === StatusCodes.CREATED) {
            toast.success("File created")
            navigate(returnURL)
          }
        })
        .catch(() => {
          toast.error("Cannot create file")
        })
        .finally(() => {
          setSubmitting(false)
          setUploadProgress(0)
        })
    },
  })

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue("file", event.currentTarget.files?.[0])
  }

  const submitForm = useCallback(() => {
    setIsUploadClicked(true);
    setUploadProgress(0);
    formik.submitForm();
  }, [formik]);

  return (
    <Box>
      <BackToURL url={returnURL} label="Back to versions" />
      <Paper>
        <Card>
          <CardHeader
            title="Create new version"
            action={
              <LoadingButton
                variant="contained"
                type="submit"
                loading={formik.isSubmitting}
                onClick={submitForm}
                disabled={!formik.dirty || !formik.isValid}
              >
                Save
              </LoadingButton>
            }
          />
          <CardContent
            sx={{
              padding: 0,
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
          >
            <Divider />
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
              }}
            >
              <ListItem key={0}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Suffix</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="suffix"
                        name="suffix"
                        type="text"
                        value={formik.values.suffix}
                        onChange={formik.handleChange}
                        error={formik.touched.suffix && Boolean(formik.errors.suffix)}
                        helperText={formik.touched.suffix && formik.errors.suffix}
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={1}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">File</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <input
                        type="file"
                        id="file"
                        name="file"
                        style={{ display: "none" }}
                        onChange={handleFileInputChange}
                      />
                      <label htmlFor="file">
                        <Button component="span" variant="outlined" color="primary">
                          {formik.values.file ? formik.values.file.name : "Choose File"}
                        </Button>
                      </label>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
            </List>
            {isUploadClicked && (
              <LinearProgress variant="determinate" value={uploadProgress} />
            )}
          </CardContent>
        </Card>
      </Paper>
    </Box>
  )
}
