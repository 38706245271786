import { styled } from "@mui/material/styles"
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip"

const DEFAULT_MAX_WIDTH = 200
const FONT_SIZE = 12

type InputProps = {
  custommaxwidth?: number
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))<InputProps>(({ theme, custommaxwidth }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: custommaxwidth ?? DEFAULT_MAX_WIDTH,
    fontSize: theme.typography.pxToRem(FONT_SIZE),
    border: "1px solid #dadde9",
  },
}))

export default HtmlTooltip
