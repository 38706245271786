import { LoadingButton } from "@mui/lab"
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from "@mui/material"
import { useFormik } from "formik"
import { StatusCodes } from "http-status-codes"
import { useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { boolean, object, string } from "yup"
import { digitalManagerApi } from "../../../../services/api"
import { BackToURL } from "../../../components/BackToURL"
import { FactoryPost } from "../../../models/Factory"

const validationSchema = object({
  name: string().required("Name is required"),
  isFree: boolean().required("IsFree is required"),
  isEnabled: boolean().required("IsEnabled is required"),
})

const returnURL = `/test-reports#factories`

export function FactoryCreate() {
  const navigate = useNavigate()

  const formik = useFormik<FactoryPost>({
    validationSchema,
    initialValues: {
      name: "",
      isFree: false,
      fileShareName: "",
      isEnabled: false,
    },
    onSubmit: (values, { setSubmitting }) => {
      digitalManagerApi
        .post(`/api/v1/factories`, {
          name: values.name,
          isFree: values.isFree,
          fileShareName: values.fileShareName,
          isEnabled: values.isEnabled,
        })
        .then((res) => {
          if (res.status === StatusCodes.CREATED) {
            toast.success("Factory created")
            navigate(returnURL)
          }
        })
        .catch(() => {
          toast.error("Cannot create factory")
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
  })

  const submitForm = useCallback(() => {
    formik.submitForm()
  }, [formik])

  return (
    <Box>
      <BackToURL url={returnURL} label="Back to factories" />
      <Paper>
        <Card>
          <CardHeader
            title="Create new factory"
            action={
              <LoadingButton
                variant="contained"
                type="submit"
                loading={formik.isSubmitting}
                onClick={submitForm}
                disabled={!formik.dirty || !formik.isValid}
              >
                Save
              </LoadingButton>
            }
          />
          <CardContent
            sx={{
              padding: 0,
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
          >
            <Divider />
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
              }}
            >
              <ListItem key={0}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Name</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="name"
                        name="name"
                        type="text"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={1}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Is Free</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Checkbox
                        id="isFree"
                        name="isFree"
                        checked={formik.values.isFree}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={2}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">File Share Name</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="fileShareName"
                        name="fileShareName"
                        type="text"
                        value={formik.values.fileShareName}
                        onChange={formik.handleChange}
                        error={formik.touched.fileShareName && Boolean(formik.errors.fileShareName)}
                        helperText={formik.touched.fileShareName && formik.errors.fileShareName}
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={3}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Is Enabled</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Checkbox
                        id="isEnabled"
                        name="isEnabled"
                        checked={formik.values.isEnabled}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Paper>
    </Box>
  )
}
