import { MenuItem, Options } from "@mui/material"
import {
  GridColDef,
  GridExportMenuItemProps,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridToolbarQuickFilter,
  GridValueGetterParams,
} from "@mui/x-data-grid"
import { useCallback, useState } from "react"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { useApplicationUsers } from "../../../../../hooks/useApplicationUsers"
import { digitalManagerApi } from "../../../../../services/api"
import { ApplicationUserGet } from "../../../../models/ApplicationUser"
import { downloadBlob } from "../../../../utils/downloadBlob"
import {
  dateStringComparator,
  getNiceFormattedDate,
} from "../../../../utils/getFormattedDate"
import { EntityTab } from "../../../partials/EntityTab"

function getFullName(params: GridValueGetterParams) {
  return `${params.row.first || ""} ${params.row.last || ""}`
}

export function ApplicationUsers() {
  const { applicationId } = useParams()
  const { applicationUsers, isLoadingApplicationUsers } =
    useApplicationUsers(applicationId)
  const [isNpsDownloading, setIsNpsDownloading] = useState<boolean>(false)

  const downloadNps = useCallback(() => {
    setIsNpsDownloading(true)

    digitalManagerApi
      .get<Blob>(`/api/v1/applications/${applicationId}/users/export`, {
        responseType: "blob",
      })
      .then((res) => {
        const tempDate = new Date()
        const [day, month, year] = [
          tempDate.getDate(),
          tempDate.getMonth() + 1,
          tempDate.getFullYear(),
        ]
        const fileName = `NPS-${year}_${month}_${day}.csv`

        downloadBlob(res.data, fileName)
      })
      .catch(() => {
        toast.error(`Cannot download the NPS file`)
      })
      .finally(() => setIsNpsDownloading(false))
  }, [applicationId])

  const onClickMenu = useCallback(
    (coverUpMenu: (() => void) | undefined) => () => {
      downloadNps()

      // Hide the export menu after the export
      coverUpMenu?.()
    },
    [downloadNps]
  )

  const NpsExportMenuItem = useCallback(
    (props: GridExportMenuItemProps<Options>) => {
      const { hideMenu } = props

      return <MenuItem onClick={onClickMenu(hideMenu)}>Download NPS</MenuItem>
    },
    [onClickMenu]
  )

  const NpsToolbar = useCallback(
    () => (
      <GridToolbarContainer>
        <GridToolbarExportContainer>
          <NpsExportMenuItem />
        </GridToolbarExportContainer>
        <GridToolbarQuickFilter style={{ marginRight: "0px", marginLeft: "auto" }} />
      </GridToolbarContainer>
    ),
    [NpsExportMenuItem]
  )

  const usersColumns: GridColDef[] = [
    {
      field: "username",
      headerName: "Email address",
      flex: 1,
    },
    {
      field: "fullName",
      headerName: "Full name",
      valueGetter: getFullName,
      flex: 1,
    },
    {
      field: "lastAccess",
      headerName: "Last access",
      renderCell: (params) => getNiceFormattedDate(params.row.lastAccess),
      sortComparator: dateStringComparator,
      flex: 1,
    },
    {
      field: "version",
      headerName: "Version",
      flex: 1,
    },
  ]

  const usersRows = applicationUsers.map((applicationUser: ApplicationUserGet) => ({
    id: applicationUser.id,
    username: applicationUser.user.username,
    first: applicationUser.user.first,
    last: applicationUser.user.last,
    lastAccess: applicationUser.lastAccess,
    version: applicationUser.version,
  }))

  return (
    <EntityTab
      rows={usersRows}
      cols={usersColumns}
      isLoading={isLoadingApplicationUsers || isNpsDownloading}
      CustomToolbar={NpsToolbar}
      initialState={{
        sorting: { sortModel: [{ field: "name", sort: "asc" }] },
      }}
    />
  )
}
