import { StatusCodes } from "http-status-codes"
import { useEffect, useState } from "react"
import { Guid } from "../app/models/CustomType"
import { FileVersionGet } from "../app/models/FileVersion"
import { digitalManagerApi } from "../services/api"

export function useFileVersions(fileId?: Guid) {
  const [fileVersions, setFileVersions] = useState<FileVersionGet[]>([])
  const [isLoadingFileVersions, setIsLoadingFileVersions] = useState<boolean>(true)

  useEffect(() => {
    setIsLoadingFileVersions(true)
    setFileVersions([])
    digitalManagerApi
      .get<FileVersionGet[]>(`/api/v1/files/${fileId}/versions`)
      .then((res) => {
        if (res.status === StatusCodes.OK) {
          setFileVersions(res.data)
        }
      })
      .finally(() => {
        setIsLoadingFileVersions(false)
      })
  }, [fileId])

  return {
    fileVersions,
    isLoadingFileVersions,
  }
}
