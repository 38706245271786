import { StatusCodes } from "http-status-codes"
import { useEffect, useState } from "react"
import { ApplicationRoleGet } from "../app/models/ApplicationRole"
import { digitalManagerApi } from "../services/api"

export function useApplicationRoles(applicationId?: string) {
  const [applicationRoles, setApplicationRoles] = useState<ApplicationRoleGet[]>([])
  const [isLoadingApplicationRoles, setIsLoadingApplicationRoles] =
    useState<boolean>(true)

  useEffect(() => {
    setIsLoadingApplicationRoles(true)
    setApplicationRoles([])
    if (applicationId) {
      digitalManagerApi
        .get<ApplicationRoleGet[]>(`/api/v1/applications/${applicationId}/roles`)
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            setApplicationRoles(res.data)
          }
        })
        .finally(() => {
          setIsLoadingApplicationRoles(false)
        })
    } else {
      setIsLoadingApplicationRoles(false)
    }
  }, [applicationId])

  return {
    applicationRoles,
    isLoadingApplicationRoles,
  }
}
