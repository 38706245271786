import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios"
import { StatusCodes } from "http-status-codes"
import { toast } from "react-toastify"
import { msalInstance } from "../App"

const apiBaseURL = process.env.REACT_APP_API_URL
const apiDigitalManagerBaseURL = process.env.REACT_APP_API_DIGITALMANAGER_URL
const contentType = "application/json"

const baseAxiosConfig = {
  baseURL: apiBaseURL,
  headers: {
    "Content-Type": contentType,
  },
}

const baseDigitalManagerConfig = {
  baseURL: apiDigitalManagerBaseURL,
  headers: {
    "Content-Type": contentType,
  },
}

const onResponse = (response: AxiosResponse): AxiosResponse => response

const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
  if (
    error &&
    error.response &&
    error.response.status > StatusCodes.INTERNAL_SERVER_ERROR
  ) {
    toast.error("Ops! An error occurred while calling the server", {
      toastId: "axiosOnResponseError500",
    })
  }

  return Promise.reject(error)
}

const onRequest = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  // inject accessToken header to all api call
  const accounts = msalInstance.getAllAccounts()
  if (!accounts || !accounts.length) {
    return Promise.reject(new Error("User not authenticated"))
  }

  const user = accounts[0]
  if (user) {
    try {
      const res = await msalInstance.acquireTokenSilent({
        scopes: ["api://4909f345-1cc0-4e61-8a40-62273ae597d5/access_as_user"],
        account: user,
      })
      if (res?.accessToken && config.headers) {
        config.headers["Authorization"] = `Bearer ${res.accessToken}`
        window.localStorage.setItem("lastAccessToken", res.accessToken)
      }
    } catch (err) {
      // a new login is required
      msalInstance.logoutRedirect()
    }
  }

  return config
}

const onRequestError = (error: AxiosError): Promise<AxiosError> => Promise.reject(error)

export const axiosUnauth = axios.create(baseAxiosConfig)
axiosUnauth.interceptors.request.use(undefined, onRequestError)
axiosUnauth.interceptors.response.use(onResponse, onResponseError)

export const digitalManagerNotAuthenticatedApi = axios.create(baseDigitalManagerConfig)
digitalManagerNotAuthenticatedApi.interceptors.response.use(onResponse, onResponseError)

export const digitalManagerApi = axios.create(baseDigitalManagerConfig)
digitalManagerApi.interceptors.request.use(onRequest, onRequestError)
digitalManagerApi.interceptors.response.use(onResponse, onResponseError)
