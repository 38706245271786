import CloseIcon from "@mui/icons-material/Close"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined"
import ManageSearchIcon from "@mui/icons-material/ManageSearch"
import TaskAltIcon from "@mui/icons-material/TaskAlt"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material"
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid"
import { useFormik } from "formik"
import { StatusCodes } from "http-status-codes"
import { Dispatch, SetStateAction, useCallback, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { number, object } from "yup"
import { useOidsRequests } from "../../../../hooks/useOidsRequests"
import { digitalManagerApi } from "../../../../services/api"
import { DiMaProgress } from "../../../components/DiMaProgress"
import { OidsRequestGet } from "../../../models/SupplierPortal"
import { downloadBlob } from "../../../utils/downloadBlob"
import {
  getNiceFormattedDate,
  getNowDateTime,
  getUtcDateTime,
} from "../../../utils/getFormattedDate"
import { EntityTab } from "../../partials/EntityTab"

interface BoxProps {
  readonly params: GridRenderCellParams
  readonly setDialogParams: (
    value: SetStateAction<GridRenderCellParams | undefined>
  ) => void
  readonly setValidateOidsRequest: (value: SetStateAction<boolean>) => void
  readonly setDownloading: (value: SetStateAction<boolean>) => void
  readonly reloadOidRequests: () => void
}

const MAX_OIDS = 1000
const SHADE = 500

function ValidateObjectidDialog(props: {
  readonly validateObjectid: boolean
  readonly setValidateOidsRequest: Dispatch<SetStateAction<boolean>>
  readonly dialogParams?: GridRenderCellParams
  readonly reloadOidRequests: () => void
}) {
  const { dialogParams, validateObjectid, setValidateOidsRequest, reloadOidRequests } =
    props

  const formik = useFormik({
    initialValues: {
      validationDate: getNowDateTime(),
    },
    onSubmit: (values, { setSubmitting }) => {
      digitalManagerApi
        .put(
          `/api/v1/litePanelPro/objectIdsValidation?validationDate=${getUtcDateTime(
            values.validationDate
          )}&oidsRequestId=${dialogParams?.id}`
        )
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            setValidateOidsRequest(false)
            toast.success("Oids validated")
          }
        })
        .catch(() => {
          toast.error("Cannot validate the oids")
        })
        .finally(() => {
          setSubmitting(false)
          reloadOidRequests()
        })
    },
  })

  const setValidateOidsRequestFalse = useCallback(() => {
    setValidateOidsRequest(false)
    formik.resetForm()
  }, [formik, setValidateOidsRequest])

  return (
    <Dialog open={validateObjectid}>
      <DialogTitle>
        <Typography sx={{ marginRight: "10%" }}>
          {"Indicates the date from which the oids are valid"}
        </Typography>

        <IconButton
          aria-label="close"
          onClick={setValidateOidsRequestFalse}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[SHADE],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          id="validationDate"
          name="validationDate"
          type="datetime-local"
          value={formik.values.validationDate}
          onChange={formik.handleChange}
          error={formik.touched.validationDate && Boolean(formik.errors.validationDate)}
          helperText={formik.touched.validationDate && formik.errors.validationDate}
          size="small"
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          onClick={setValidateOidsRequestFalse}
          autoFocus
          variant="text"
          color="error"
        >
          Cancel
        </LoadingButton>
        <LoadingButton
          onClick={formik.submitForm}
          loading={formik.isSubmitting}
          autoFocus
          variant="text"
          color="success"
        >
          Validate
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

function OpenObjectIdRequestDialog(props: {
  readonly openOidRequest: boolean
  readonly reloadOidRequests: () => void
  readonly setOpenOidRequest: Dispatch<SetStateAction<boolean>>
}) {
  const { openOidRequest, reloadOidRequests, setOpenOidRequest } = props

  const validationSchema = object({
    numberOfOids: number()
      .max(MAX_OIDS)
      .min(0)
      .required("Please provide a number of oids"),
  })

  const formik = useFormik({
    validationSchema,
    initialValues: {
      numberOfOids: 0,
    },
    validateOnMount: true,
    onSubmit: (values, { setSubmitting }) => {
      digitalManagerApi
        .post(
          `/api/v1/litePanelPro/objectIdsRequests?numObjectIds=${values.numberOfOids}`
        )
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            setOpenOidRequest(false)
            toast.success("Oids request successfully created")
          }
        })
        .catch(() => {
          toast.error("Cannot create the Oids request")
        })
        .finally(() => {
          setSubmitting(false)
          reloadOidRequests()
        })
    },
  })

  const setOpenOidRequestFalse = useCallback(() => {
    setOpenOidRequest(false)
  }, [setOpenOidRequest])

  return (
    <Dialog open={openOidRequest}>
      <DialogTitle>
        <Typography sx={{ marginRight: "10%" }}>
          {"Indicates the number of oids you want to create"}
        </Typography>

        <IconButton
          aria-label="close"
          onClick={setOpenOidRequestFalse}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[SHADE],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          id="numberOfOids"
          name="numberOfOids"
          type="number"
          inputProps={{
            max: MAX_OIDS,
            min: 0,
          }}
          value={formik.values.numberOfOids}
          onChange={formik.handleChange}
          error={formik.touched.numberOfOids && Boolean(formik.errors.numberOfOids)}
          helperText={formik.touched.numberOfOids && formik.errors.numberOfOids}
          size="small"
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          onClick={formik.submitForm}
          disabled={!formik.isValid}
          autoFocus
          variant="text"
          color="success"
          loading={formik.isSubmitting}
        >
          Create
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

function DownloadingDialog(props: {
  readonly openOidRequest: boolean
  readonly handleDownloadingDialog: () => void
}) {
  const { openOidRequest, handleDownloadingDialog } = props

  return (
    <Dialog open={openOidRequest}>
      <DialogTitle>
        <Typography sx={{ marginRight: "5%" }}>{"Downloading the oids..."}</Typography>

        <IconButton
          aria-label="close"
          onClick={handleDownloadingDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[SHADE],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DiMaProgress />
      </DialogContent>
    </Dialog>
  )
}

function CreateBox(props: BoxProps) {
  const {
    params,
    setDialogParams,
    setValidateOidsRequest,
    setDownloading,
    reloadOidRequests,
  } = props
  const navigate = useNavigate()

  const onClickLoadingButton = useCallback(() => {
    setDialogParams(params)
    setValidateOidsRequest(true)
  }, [params, setDialogParams, setValidateOidsRequest])

  const onClickButton = useCallback(() => {
    setDownloading(true)
    digitalManagerApi
      .get(`/api/v1/litePanelPro/objectIdsCsv?oidsRequestId=${params.row.id}`)
      .then(async (res) => {
        if (res.status === StatusCodes.OK) {
          const fileName = `OidsRequest-${params.row.id}.csv`
          const csvContent = res.data
          const blob = new Blob([csvContent], { type: "text/csv" })
          downloadBlob(blob, fileName)
        }
      })
      .catch(() => {
        toast.error("Cannot download the file")
      })
      .finally(() => {
        setDownloading(false)
      })
  }, [params.row.id, setDownloading])

  const onDelete = useCallback(() => {
    digitalManagerApi
      .delete(`/api/v1/litePanelPro/objectIdsRequests?requestId=${params.row.id}`)
      .then(async (res) => {
        if (res.status === StatusCodes.OK) {
          reloadOidRequests()
          toast.success("Oids request successfully deleted")
        }
      })
      .catch(() => {
        toast.error("Cannot delete the Oids request")
      })
      .finally(() => {
        setDownloading(false)
      })
  }, [params.row.id, reloadOidRequests, setDownloading])

  const navigateToPage = useCallback(() => {
    navigate(`/supplierPortal/objectIdsRequests/${params.row.id}/objectIds`)
  }, [navigate, params.row.id])

  return (
    <Box>
      {params.row.expireDate === undefined ? (
        <LoadingButton
          startIcon={<TaskAltIcon></TaskAltIcon>}
          onClick={onClickLoadingButton}
        >
          Validate
        </LoadingButton>
      ) : (
        <LoadingButton
          size="small"
          startIcon={<EditIcon></EditIcon>}
          onClick={onClickLoadingButton}
        ></LoadingButton>
      )}
      <Button onClick={onClickButton}>
        <FileDownloadOutlinedIcon></FileDownloadOutlinedIcon>
      </Button>

      <Button onClick={navigateToPage}>
        <ManageSearchIcon />
      </Button>
      <Button onClick={onDelete}>
        <DeleteIcon />
      </Button>
    </Box>
  )
}

export function ObjectIdRequests() {
  const { oidRequestList, isLoadingOidRequests, reloadOidRequests } = useOidsRequests()
  const [validateOidsRequest, setValidateOidsRequest] = useState<boolean>(false)
  const [openOidRequest, setOpenOidRequest] = useState<boolean>(false)
  const [dialogParams, setDialogParams] = useState<GridRenderCellParams>()
  const [downloading, setDownloading] = useState<boolean>(false)

  const handleDialog = useCallback(() => {
    setOpenOidRequest(!openOidRequest)
  }, [openOidRequest])

  const handleDownloadingDialog = useCallback(() => {
    setDownloading(!downloading)
  }, [downloading])

  const columns: GridColDef[] = [
    {
      field: "requestDate",
      headerName: "Request Date",
      renderCell: (params) => (
        <Typography variant="body1">
          {getNiceFormattedDate(params.row.requestDate)}
        </Typography>
      ),
      flex: 1,
    },
    {
      field: "oidCount",
      headerName: "Number of OIDs",
      renderCell: (params) => (
        <Typography variant="body1">{params.row.oidsCount}</Typography>
      ),
      flex: 1,
    },
    {
      field: "expireDate",
      headerName: "Expire Date",
      renderCell: (params) => (
        <Typography variant="body1">
          {params.row.expireDate
            ? getNiceFormattedDate(params.row.expireDate)
            : "Not validated"}
        </Typography>
      ),
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      renderCell: (params: GridRenderCellParams) => (
        <CreateBox
          params={params}
          setDialogParams={setDialogParams}
          setValidateOidsRequest={setValidateOidsRequest}
          setDownloading={setDownloading}
          reloadOidRequests={reloadOidRequests}
        />
      ),
      flex: 1,
    },
  ]

  const rows = oidRequestList.map((ObjectIdRequest: OidsRequestGet) => ({
    id: ObjectIdRequest.id,
    oidsCount: ObjectIdRequest.oidsCount,
    requestDate: ObjectIdRequest.requestDate,
    expireDate: ObjectIdRequest.expireDate,
    requestedBy: ObjectIdRequest.requestedBy,
  }))
  return (
    <>
      <ValidateObjectidDialog
        validateObjectid={validateOidsRequest}
        setValidateOidsRequest={setValidateOidsRequest}
        reloadOidRequests={reloadOidRequests}
        dialogParams={dialogParams}
      />
      <OpenObjectIdRequestDialog
        openOidRequest={openOidRequest}
        reloadOidRequests={reloadOidRequests}
        setOpenOidRequest={setOpenOidRequest}
      ></OpenObjectIdRequestDialog>
      <DownloadingDialog
        openOidRequest={downloading}
        handleDownloadingDialog={handleDownloadingDialog}
      ></DownloadingDialog>
      <Box className={"DiMaBox-one-element-right"}>
        <Button color="primary" variant="contained" onClick={handleDialog}>
          Open ObjectId Request
        </Button>
      </Box>
      <EntityTab
        rows={rows}
        cols={columns}
        isLoading={isLoadingOidRequests}
        initialState={{
          sorting: { sortModel: [{ field: "expireDate", sort: "asc" }] },
        }}
      />
    </>
  )
}
