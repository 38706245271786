import CloseOutlinedIcon from "@mui/icons-material/Close"
import PendingIcon from "@mui/icons-material/Pending"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material"
import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid"
import { useFormik } from "formik"
import { StatusCodes } from "http-status-codes"
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { useAccessRequests } from "../../../../hooks/useAccessRequests"
import { digitalManagerApi } from "../../../../services/api"
import { DiMaProgress } from "../../../components/DiMaProgress"
import { AppContext } from "../../../context/AppContext"
import { UserContext } from "../../../context/UserContext"
import {
  AccessLevel,
  DimaFeatureShortNameEnum,
  hasUserAccessToFeature,
} from "../../../models/Authorization"
import { UserAccessRequest } from "../../../models/User"
import { getNiceFormattedDate } from "../../../utils/getFormattedDate"
import { EntityTab } from "../../partials/EntityTab"

interface BoxProps {
  readonly params: GridRenderCellParams
  readonly setDialogParams: (
    value: SetStateAction<GridRenderCellParams | undefined>
  ) => void
  readonly setElaborateAccessRequest: (value: SetStateAction<boolean>) => void
  readonly userHasBackofficeWriteAccess: boolean
}

function Dialogs(props: {
  readonly elaborateAccessRequest: boolean
  readonly setElaborateAccessRequest: Dispatch<SetStateAction<boolean>>
  readonly reloadAccessRequests: () => void
  readonly dialogParams?: GridRenderCellParams
}) {
  const navigate = useNavigate()
  const {
    dialogParams,
    elaborateAccessRequest,
    reloadAccessRequests,
    setElaborateAccessRequest,
  } = props

  const SHADE = 500

  const [rejectAccessRequest, setRejectAccessRequest] = useState<boolean>(false)

  const formik = useFormik({
    initialValues: {
      additionalInformation: "",
    },
    onSubmit: (values, { setSubmitting }) => {
      digitalManagerApi
        .put(
          `/api/v1/backOfficeAccess/requests/${dialogParams?.row.id}/reject/?additionalInformation=${values.additionalInformation}`
        )
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            setElaborateAccessRequest(false)
            setRejectAccessRequest(false)
            toast.success("Access request rejected")
          }
        })
        .catch(() => {
          toast.error("Cannot reject access request")
        })
        .finally(() => {
          reloadAccessRequests()
          setSubmitting(false)
        })
    },
  })

  const setElaborateAccessRequestFalse = useCallback(() => {
    setElaborateAccessRequest(false)
  }, [setElaborateAccessRequest])

  const setRejectAccessRequestTrue = useCallback(() => {
    setRejectAccessRequest(true)
    formik.resetForm()
  }, [formik])

  const setRejectAccessRequestFalse = useCallback(() => {
    setRejectAccessRequest(false)
  }, [])

  const submitForm = useCallback(() => {
    formik.submitForm()
  }, [formik])

  const navigateToPage = useCallback(() => {
    navigate(`/users/${dialogParams?.row.userId}#backoffice-access`)
    toast.success(
      "Welcome to the permissions management page. Please click on the toggle, fill out the form and click save to complete the operation."
    )
  }, [dialogParams?.row.userId, navigate])

  return (
    <>
      <Dialog open={elaborateAccessRequest}>
        <DialogTitle>
          <Typography sx={{ marginRight: "10%" }}>
            {"Please read the request reason carefully before accepting"}
          </Typography>

          <IconButton
            aria-label="close"
            onClick={setElaborateAccessRequestFalse}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[SHADE],
            }}
          >
            <CloseOutlinedIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Reason: {dialogParams?.row.reason}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            onClick={setRejectAccessRequestTrue}
            autoFocus
            variant="text"
            color="error"
          >
            Reject
          </LoadingButton>
          <LoadingButton
            onClick={navigateToPage}
            autoFocus
            variant="text"
            color="success"
          >
            Accept
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog fullWidth open={rejectAccessRequest}>
        <DialogTitle>
          <Typography sx={{ marginRight: "10%" }}>
            {"Confirmation of rejection"}
          </Typography>

          <IconButton
            aria-label="close"
            onClick={setRejectAccessRequestFalse}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[SHADE],
            }}
          >
            <CloseOutlinedIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            placeholder="Please indicate the reason for the refusal of the access request. (Optional)"
            multiline
            rows={4}
            fullWidth
            id="additionalInformation"
            name="additionalInformation"
            type="text"
            value={formik.values.additionalInformation}
            onChange={formik.handleChange}
            size="medium"
          ></TextField>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={formik.isSubmitting}
            onClick={submitForm}
            autoFocus
            variant="text"
            color="error"
          >
            Reject
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

function CreateBox(props: BoxProps) {
  const {
    params,
    setDialogParams,
    setElaborateAccessRequest,
    userHasBackofficeWriteAccess,
  } = props

  const onClick = useCallback(() => {
    setDialogParams(params)
    setElaborateAccessRequest(true)
  }, [params, setDialogParams, setElaborateAccessRequest])

  return (
    <Box>
      <Button
        startIcon={<PendingIcon></PendingIcon>}
        onClick={onClick}
        disabled={!userHasBackofficeWriteAccess}
      >
        View request
      </Button>
    </Box>
  )
}

export function AccessRequests() {
  const { badges, setBadges } = useContext(AppContext)
  const { user } = useContext(UserContext)

  const [elaborateAccessRequest, setElaborateAccessRequest] = useState<boolean>(false)

  const [dialogParams, setDialogParams] = useState<GridRenderCellParams>()

  const {
    accessRequestList,
    isLoadingAccessRequest,
    reloadAccessRequests,
    accessRequestsBadge,
  } = useAccessRequests()

  useEffect(() => {
    if (
      accessRequestsBadge !== undefined &&
      accessRequestsBadge !== badges.accessRequests
    ) {
      setBadges({
        ...badges,
        accessRequests: accessRequestsBadge,
      })
    }
  }, [badges, setBadges, accessRequestsBadge])

  if (!user) {
    return <DiMaProgress />
  }

  const userHasBackofficeWriteAccess = hasUserAccessToFeature(
    user.authorizations,
    DimaFeatureShortNameEnum.BA,
    AccessLevel.Write
  )

  const columns: GridColumns<UserAccessRequest> = [
    {
      field: "username",
      headerName: "Email address",
      renderCell: (params) => <Typography>{params.row.username}</Typography>,
      flex: 1,
    },

    {
      field: "creationDate",
      headerName: "Creation Date",
      renderCell: (params) => (
        <Typography>{getNiceFormattedDate(params.row.creationDate)}</Typography>
      ),
      flex: 1,
    },

    {
      field: "actions",
      type: "actions",
      renderCell: (params: GridRenderCellParams) => (
        <CreateBox
          params={params}
          setDialogParams={setDialogParams}
          setElaborateAccessRequest={setElaborateAccessRequest}
          userHasBackofficeWriteAccess={userHasBackofficeWriteAccess}
        />
      ),
      flex: 1,
    },
  ]

  const rows = accessRequestList.map((AccessRequest: UserAccessRequest) => ({
    id: AccessRequest.id,
    userId: AccessRequest.userId,
    username: AccessRequest.username,
    creationDate: AccessRequest.creationDate,
    completionDate: AccessRequest.completionDate,
    rejectionDate: AccessRequest.rejectionDate,
    reason: AccessRequest.reason,
  }))

  return (
    <>
      <Dialogs
        elaborateAccessRequest={elaborateAccessRequest}
        setElaborateAccessRequest={setElaborateAccessRequest}
        reloadAccessRequests={reloadAccessRequests}
        dialogParams={dialogParams}
      />
      <EntityTab
        rows={rows}
        cols={columns}
        isLoading={isLoadingAccessRequest}
        initialState={{
          sorting: { sortModel: [{ field: "creationDate", sort: "desc" }] },
        }}
      />
    </>
  )
}
