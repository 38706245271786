import { StatusCodes } from "http-status-codes"
import { useEffect, useState } from "react"
import { ApplicationRoleUser } from "../app/models/ApplicationRole"
import { digitalManagerApi } from "../services/api"

export function useRoleUsers(applicationId?: string, roleId?: string) {
  const [roleUsersList, setRoleUsersList] = useState<ApplicationRoleUser[]>([])
  const [isLoadingRoleUsers, setIsLoadingRoleUsers] = useState<boolean>(true)

  useEffect(() => {
    if (applicationId && roleId) {
      digitalManagerApi
        .get<ApplicationRoleUser[]>(
          `/api/v1/applications/${applicationId}/roles/${roleId}/users`
        )
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            setRoleUsersList(res.data)
          }
        })
        .finally(() => {
          setIsLoadingRoleUsers(false)
        })
    }
  }, [applicationId, roleId])

  return {
    roleUsersList,
    isLoadingRoleUsers,
  }
}
