import { LoadingButton } from "@mui/lab"
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from "@mui/material"
import { useFormik } from "formik"
import { StatusCodes } from "http-status-codes"
import { useCallback } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { boolean, object, string } from "yup"
import { digitalManagerApi } from "../../../../../services/api"
import { BackToURL } from "../../../../components/BackToURL"
import { ApplicationRolePost } from "../../../../models/ApplicationRole"

const validationSchema = object({
  name: string().required("Name is required"),
  description: string().required("Description is required"),
  isInternal: boolean().required("Is Internal is required"),
  isDefault: boolean().required("Is Default is required"),
  isRequestable: boolean().required("Is Requestable is required"),
})

export function ApplicationRoleCreate() {
  const { applicationId } = useParams()
  const navigate = useNavigate()
  const returnURL = `/applications/${applicationId}#roles`

  const formik = useFormik<ApplicationRolePost>({
    validationSchema,
    initialValues: {
      name: "",
      description: "",
      fullDescription: "",
      isInternal: false,
      isDefault: false,
      isRequestable: true,
    },
    onSubmit: (values, { setSubmitting }) => {
      digitalManagerApi
        .post(`/api/v1/applications/${applicationId}/roles`, {
          ...values,
        })
        .then((res) => {
          if (res.status === StatusCodes.CREATED) {
            toast.success("Role created")
            navigate(returnURL)
          }
        })
        .catch(() => {
          toast.error("Cannot create role")
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
  })

  const submitForm = useCallback(() => {
    formik.submitForm()
  }, [formik])

  return (
    <Box>
      <BackToURL url={returnURL} label="Back to roles" />
      <Paper>
        <Card>
          <CardHeader
            title="Create new role"
            action={
              <LoadingButton
                variant="contained"
                type="submit"
                loading={formik.isSubmitting}
                onClick={submitForm}
                disabled={!formik.dirty || !formik.isValid}
              >
                Save
              </LoadingButton>
            }
          />
          <CardContent
            sx={{
              padding: 0,
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
          >
            <Divider />
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
              }}
            >
              <ListItem key={0}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Name</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="name"
                        name="name"
                        type="text"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={1}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Description</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="description"
                        name="description"
                        type="text"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.description && Boolean(formik.errors.description)
                        }
                        helperText={
                          formik.touched.description && formik.errors.description
                        }
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={2}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Full Description</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="fullDescription"
                        name="fullDescription"
                        type="text"
                        value={formik.values.fullDescription}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.fullDescription &&
                          Boolean(formik.errors.fullDescription)
                        }
                        helperText={
                          formik.touched.fullDescription && formik.errors.fullDescription
                        }
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={3}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Is Internal</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Checkbox
                        id="isInternal"
                        name="isInternal"
                        checked={formik.values.isInternal}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={4}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Is Default</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Checkbox
                        id="isDefault"
                        name="isDefault"
                        checked={formik.values.isDefault}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={5}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Is Requestable</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Checkbox
                        id="isRequestable"
                        name="isRequestable"
                        checked={formik.values.isRequestable}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Paper>
    </Box>
  )
}
