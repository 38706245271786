import { Typography } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { StatusCodes } from "http-status-codes"
import { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { digitalManagerApi } from "../../../../../services/api"
import { DiMaProgress } from "../../../../components/DiMaProgress"
import { UserApproverGet } from "../../../../models/User"
import { EntityTab } from "../../../partials/EntityTab"

export function UserApproverRoles() {
  const [approverRoles, setApproverRoles] = useState<UserApproverGet[]>()
  const [isLoadingApproverRoles, setIsLoadingApproverRoles] = useState<boolean>(true)
  const navigate = useNavigate()
  const { userId } = useParams()
  const returnURL = `/users/${userId}#details`

  useEffect(() => {
    digitalManagerApi
      .get<UserApproverGet[]>(`/api/v1/users/${userId}/approvers`)
      .then((res) => {
        if (res.status === StatusCodes.OK) {
          setApproverRoles(res.data)
        }
      })
      .catch(() => {
        navigate(returnURL)
      })
      .finally(() => {
        setIsLoadingApproverRoles(false)
      })
  }, [userId, returnURL, navigate])

  if (isLoadingApproverRoles || !approverRoles) {
    return <DiMaProgress />
  }

  const columns: GridColDef[] = [
    {
      field: "approverType",
      headerName: "Approver Type",
      renderCell: (params) => (
        <Link
          to={`/applications/${params.row.application.id}/roles/${params.row.role.id}/approvers/${userId}`}
        >
          {params.row.approverType}
        </Link>
      ),
      flex: 1,
    },
    {
      field: "application",
      headerName: "Application",
      renderCell: (params) => <Typography>{params.row.application.shortName}</Typography>,
      flex: 1,
    },
    {
      field: "role",
      headerName: "Role",
      renderCell: (params) => <Typography>{params.row.role.name}</Typography>,
      flex: 1,
    },
    {
      field: "property",
      headerName: "Property",
      renderCell: (params) => (
        <Typography>
          {params.row.property.name != null
            ? `${params.row.property.name}: ${params.row.property.label}`
            : `Generic`}
        </Typography>
      ),
      flex: 2,
    },
  ]

  const rows = approverRoles.map((role: UserApproverGet) => ({
    id: role.id,
    application: role.application,
    role: role.role,
    property: role.property,
    approverType: role.approverType,
  }))

  return <EntityTab rows={rows} cols={columns} isLoading={isLoadingApproverRoles} />
}
