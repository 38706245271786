import { Checkbox, TextField, Typography } from "@mui/material"
import { useFormik } from "formik"
import { useCallback } from "react"
import { Navigate } from "react-router-dom"
import { toast } from "react-toastify"
import { boolean, object, string } from "yup"
import { digitalManagerApi } from "../../../../../services/api"
import { FactoryGet, FactoryPut } from "../../../../models/Factory"
import { UserInfo } from "../../../../models/User"
import { DiMaDetailsContent, DiMaDetailsContentProps } from "../../../../components/DiMaDetailsContent"
import { AccessLevel, DimaFeatureShortNameEnum, hasUserAccessToFeature } from "../../../../models/Authorization"

const validationSchema = object({
  name: string().required("Name is required"),
  isFree: boolean().required("IsFree is required"),
  isEnabled: boolean().required("IsEnabled is required"),
})

export function FactoryDetails(props: {
  readonly factory: FactoryGet
  readonly user: UserInfo
  readonly setFactory: (updatedFactory: FactoryGet) => void
}) {
  const { factory, user, setFactory } = props

  const returnURL = `/test-reports#factories`

  const deleteFactory = useCallback(
    () => digitalManagerApi.delete(`/api/v1/factories/${factory.id}`),
    [factory.id]
  )

  const formik = useFormik<FactoryPut>({
    validationSchema,
    initialValues: {
      name: factory.name,
      isFree: factory.isFree || false,
      fileShareName: factory.fileShareName,
      isEnabled: factory.isEnabled,
    },
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) =>
      digitalManagerApi
        .put<FactoryGet>(`/api/v1/factories/${factory.id}`, {
          name: values.name,
          isFree: values.isFree,
          fileShareName: values.fileShareName,
          isEnabled: values.isEnabled,
        })
        .then((res) => {
          toast.success("Factory updated")
          setFactory(res.data)
        })
        .catch(() => {
          toast.error("Cannot update factory")
          formik.resetForm()
          return Promise.reject(new Error("Cannot update factory"))
        })
        .finally(() => {
          setSubmitting(false)
        }),
  })

  if (!factory) {
    return <Navigate to={returnURL} />
  }

  const detailsContentProps: DiMaDetailsContentProps<FactoryPut> = {
    formik,
    label: "Factory",
    canWrite: hasUserAccessToFeature(
      user.authorizations,
      DimaFeatureShortNameEnum.TR,
      AccessLevel.Write
    ),
    deleteAction: deleteFactory,
    onDeleteReturnUrl: returnURL,
    listItems: [
      {
        label: "Name",
        displayItem: <Typography>{factory.name}</Typography>,
        editItem: (
          <TextField
            fullWidth
            id="name"
            name="name"
            type="text"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            size="small"
          />
        ),
      },
      {
        label: "Is Free",
        displayItem: <Typography>{factory.isFree?.toString() || "false"}</Typography>,
        editItem: (
          <Checkbox
            id="isFree"
            name="isFree"
            checked={formik.values.isFree}
            onChange={formik.handleChange}
          />
        ),
      },
      {
        label: "File Share Name",
        displayItem: <Typography>{factory.fileShareName}</Typography>,
        editItem: (
          <TextField
            fullWidth
            id="fileShareName"
            name="fileShareName"
            type="text"
            value={formik.values.fileShareName}
            onChange={formik.handleChange}
            error={formik.touched.fileShareName && Boolean(formik.errors.fileShareName)}
            helperText={formik.touched.fileShareName && formik.errors.fileShareName}
            size="small"
          />
        ),
      },
      {
        label: "Is Enabled",
        displayItem: <Typography>{factory.isEnabled?.toString() || "false"}</Typography>,
        editItem: (
          <Checkbox
            id="isEnabled"
            name="isEnabled"
            checked={formik.values.isEnabled}
            onChange={formik.handleChange}
          />
        ),
      },
    ],
  }

  return <DiMaDetailsContent {...detailsContentProps} />
}
