import { GridColDef } from "@mui/x-data-grid"
import { useParams } from "react-router-dom"
import { useUserApplications } from "../../../../../hooks/useUserApplications"
import { UserApplicationGet } from "../../../../models/UserApplication"
import {
  dateStringComparator,
  getNiceFormattedDate,
} from "../../../../utils/getFormattedDate"
import { EntityTab } from "../../../partials/EntityTab"

export function UserApplications() {
  const { userId } = useParams()
  const { userApplications, isLoadingUserApplications } = useUserApplications(userId)

  const applicationsColumns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "shortName",
      headerName: "Short name",
      flex: 0.5,
    },
    {
      field: "lastAccess",
      headerName: "Last access",
      renderCell: (params) => getNiceFormattedDate(params.row.lastAccess),
      sortComparator: dateStringComparator,
      flex: 1,
    },
    {
      field: "version",
      headerName: "Version",
      flex: 1,
    },
  ]

  const applicationsRow = userApplications.map((userApplication: UserApplicationGet) => ({
    id: userApplication.id,
    name: userApplication.application.name,
    shortName: userApplication.application.shortName,
    lastAccess: userApplication.lastAccess,
    version: userApplication.version,
  }))

  return (
    <EntityTab
      rows={applicationsRow}
      cols={applicationsColumns}
      isLoading={isLoadingUserApplications}
      initialState={{
        sorting: { sortModel: [{ field: "name", sort: "asc" }] },
      }}
    />
  )
}
