import { TabContext, TabList } from "@mui/lab"
import { Box } from "@mui/material"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom"
import { digitalManagerApi } from "../../../../services/api"
import { BackToURL } from "../../../components/BackToURL"
import {
  DiMaDetailsHeader,
  DiMaDetailsHeaderProps,
} from "../../../components/DiMaDetailsHeader"
import { DiMaProgress } from "../../../components/DiMaProgress"
import { DiMaTabPanel } from "../../../components/DiMaTabPanel"
import { LinkTab } from "../../../components/LinkTab"
import { UserContext } from "../../../context/UserContext"
import { FileGet } from "../../../models/File"
import { FileDetails } from "./details_tab/FileDetails"
import { FileSharing } from "./sharing_tab/FileSharing"
import { FileVersions } from "./versions_tab/FileVersions"

const returnURL = "/files"

export function FileContainer() {
  const { user, isLoadingUser } = useContext(UserContext)
  const { fileId } = useParams()
  const [activeTab, setActiveTab] = useState<string>("#details")
  const [file, setFile] = useState<FileGet>()
  const { hash } = useLocation()
  const navigate = useNavigate()

  const AVATAR_LENGTH = 2

  useEffect(() => {
    if (fileId) {
      digitalManagerApi
        .get<FileGet>(`/api/v1/files/${fileId}`)
        .then((res) => {
          setFile(res.data)
        })
        .catch(() => {
          navigate(returnURL)
        })
    }
  }, [fileId, navigate])

  useEffect(() => {
    if (hash) {
      setActiveTab(hash)
    }
  }, [hash])

  const handleChange = useCallback((_: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue)
  }, [])

  if (!fileId) {
    return <Navigate to={returnURL} />
  }

  if (!file || isLoadingUser || !user) {
    return <DiMaProgress />
  }

  const detailsHeaderProps: DiMaDetailsHeaderProps = {
    avatar: file.name.substring(0, AVATAR_LENGTH),
    title: `${file.name}.${file.extension}`,
    label: "ID",
    subtitle: file.id,
  }

  return (
    <>
      <BackToURL url={returnURL} label="Back to files" />
      <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <DiMaDetailsHeader {...detailsHeaderProps} />
        <TabContext value={activeTab}>
          <Box className="DiMaBox-tabs">
            <TabList onChange={handleChange}>
              <LinkTab label="Details" value="#details" />
              <LinkTab label="Versions" value="#versions" />
              <LinkTab label="Sharing" value="#sharing" />
            </TabList>
          </Box>
          <DiMaTabPanel label="#details" activeTab={activeTab}>
            <FileDetails file={file} user={user} setFile={setFile} />
          </DiMaTabPanel>
          <DiMaTabPanel label="#versions" activeTab={activeTab}>
            <FileVersions />
          </DiMaTabPanel>
          <DiMaTabPanel label="#sharing" activeTab={activeTab}>
            <FileSharing />
          </DiMaTabPanel>
        </TabContext>
      </Box>
    </>
  )
}
