import { ApplicationDetail } from "./Application"
import { ApplicationRoleDetail } from "./ApplicationRole"
import { Guid, UtcDateTime } from "./CustomType"
import { RoleRequestApproval } from "./RoleRequestApproval"
import { UserDetail } from "./User"

export enum RoleRequestStatusEnum {
  Open = "Open",
  Closed = "Closed",
}

export interface RoleRequestGet {
  id: Guid
  application: ApplicationDetail
  role: ApplicationRoleDetail
  user: UserDetail
  company: string
  department: string
  address: string
  country: string
  phone: string
  referentEmail: string
  period: number
  originalPeriod: number
  reason: string
  approvals: RoleRequestApproval[]
  status: RoleRequestStatusEnum
  creationDate: UtcDateTime
  completedBy?: UserDetail
  completionDate?: UtcDateTime
}

export interface RoleRequestPut {
  period: number
}

export interface ApproverRole {
  applicationId: Guid
  roleId: Guid
  applicationShortName: string
  roleName: string
}
