import { Checkbox, List, ListItem, ListItemButton, ListItemText } from "@mui/material"
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from "react"
import { Guid } from "../models/CustomType"

interface DiMaCheckboxListProps {
  readonly items: DiMaListItem[]
  readonly checkedItemsIds: Guid[]
  readonly setCheckedItemsIds: Dispatch<SetStateAction<Guid[]>>
  readonly options?: {
    readonly sortItemsByTitle?: boolean
    readonly sortCheckedItemsIds?: boolean
  }
}

export interface DiMaListItem {
  id: Guid
  title: string
  description?: string
}

export default function DiMaCheckboxList(props: DiMaCheckboxListProps) {
  const { items, checkedItemsIds, setCheckedItemsIds, options } = props

  useEffect(
    () => {
      if (options?.sortCheckedItemsIds) {
        checkedItemsIds.sort((a, b) => a.localeCompare(b))
        setCheckedItemsIds(checkedItemsIds)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options?.sortCheckedItemsIds]
  )

  const localItems = useMemo(() => {
    if (options?.sortItemsByTitle) {
      items.sort((a, b) => a.title.localeCompare(b.title, undefined, { numeric: true }))
    }
    return items
  }, [items, options?.sortItemsByTitle])

  const handleToggle = useCallback(
    (item: DiMaListItem) => () => {
      const currentIndex = checkedItemsIds
        .map((checkedItemId) => checkedItemId)
        .indexOf(item.id)
      const newCheckedItemIds = [...checkedItemsIds]

      if (currentIndex === -1) {
        newCheckedItemIds.push(item.id)
      } else {
        newCheckedItemIds.splice(currentIndex, 1)
      }

      if (options?.sortCheckedItemsIds) {
        newCheckedItemIds.sort((a, b) => a.localeCompare(b))
      }
      setCheckedItemsIds(newCheckedItemIds)
    },
    [checkedItemsIds, options, setCheckedItemsIds]
  )

  return (
    <List
      sx={{
        width: "100%",
        bgcolor: "background.paper",
        paddingTop: 0,
        paddingBottom: 0,
      }}
    >
      {localItems.map((item) => (
        <ListItem key={item.id} sx={{ paddingTop: 0, paddingBottom: 0 }}>
          <ListItemButton onClick={handleToggle(item)}>
            <Checkbox
              sx={{ paddingLeft: 0 }}
              checked={checkedItemsIds.indexOf(item.id) !== -1}
              disableRipple
              tabIndex={-1}
            />
            <ListItemText primary={item.title} secondary={item.description ?? ""} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  )
}
