import { LoadingButton } from "@mui/lab"
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Paper,
} from "@mui/material"
import { useFormik } from "formik"
import { StatusCodes } from "http-status-codes"
import { useCallback, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { digitalManagerApi } from "../../../../../services/api"
import DiMaCheckboxList from "../../../../components/DiMaCheckboxList"
import { DiMaProgress } from "../../../../components/DiMaProgress"
import { ApplicationDeviceGet } from "../../../../models/ApplicationDevice"
import { Guid } from "../../../../models/CustomType"
import { DeviceGet } from "../../../../models/Device"

interface ApplicationDevicesProps {
  applicationDevices: ApplicationDeviceGet
  reloadDevices: () => void
}

const handleSave = (applicationId: Guid, checkedDevices: Guid[]) =>
  digitalManagerApi
    .post(`api/v1/applications/${applicationId}/devices`, {
      deviceIds: checkedDevices,
    })
    .then((res) => {
      if (res.status === StatusCodes.CREATED) {
        toast.success("Application's devices updated")
      }
    })
    .catch(() => {
      toast.error("Cannot update application's devices")
    })

export function ApplicationDevices(props: Readonly<ApplicationDevicesProps>) {
  const { applicationDevices, reloadDevices } = props
  const [devices, setDevices] = useState<DeviceGet[] | null>(null)
  const [formikInitialValues, setFormikInitialValues] = useState<Guid[]>(
    applicationDevices.devices.map((device) => device.id)
  )

  const formik = useFormik<Guid[]>({
    initialValues: formikInitialValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      await handleSave(applicationDevices.id, values)
      setFormikInitialValues(values)
      reloadDevices()
    },
  })

  useEffect(() => {
    digitalManagerApi
      .get<DeviceGet[]>("api/v1/devices")
      .then((res) => {
        setDevices(res.data)
      })
      .catch(() => {
        setDevices([])
        toast.error("Error retrieving the list of devices")
      })
  }, [])

  const onClickSelectAll = useCallback(() => {
    if (devices) {
      formik.setValues(devices.map((device) => device.id))
    }
  }, [devices, formik])

  const onClickUnselectAll = useCallback(() => {
    formik.setValues([])
  }, [formik])

  return (
    <Paper>
      <Card>
        <CardHeader
          title="Devices Within the Application"
          action={
            <ButtonGroup disabled={!devices}>
              <Button onClick={onClickSelectAll}>Select All</Button>
              <Button onClick={onClickUnselectAll}>Unselect All</Button>
              <LoadingButton
                variant="contained"
                type="submit"
                onClick={formik.submitForm}
                disabled={!formik.dirty}
                loading={formik.isSubmitting}
              >
                Save
              </LoadingButton>
            </ButtonGroup>
          }
        />
        <Divider />
        <CardContent
          sx={{
            padding: 0,
            paddingRight: "16px",
          }}
        >
          <Box sx={{ paddingTop: 1 }}>
            {!devices && (
              <Box sx={{ paddingTop: 1 }}>
                <DiMaProgress />
              </Box>
            )}
            {devices && (
              <Box sx={{ maxHeight: 500, overflow: "auto" }}>
                <DiMaCheckboxList
                  items={devices.map((device) => ({
                    id: device.id,
                    title: `${device.slaveId} - ${device.name}`,
                  }))}
                  checkedItemsIds={formik.values}
                  setCheckedItemsIds={formik.setValues}
                  options={{ sortItemsByTitle: true, sortCheckedItemsIds: true }}
                />
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
    </Paper>
  )
}
