import { InteractionType } from "@azure/msal-browser"
import { MsalAuthenticationTemplate } from "@azure/msal-react"

export function RequireAuth({ children }: { readonly children: JSX.Element }) {
  const authRequest = {
    scopes: ["openid", "profile"],
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
    >
      <>{children}</>
    </MsalAuthenticationTemplate>
  )
}
