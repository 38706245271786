import { StatusCodes } from "http-status-codes"
import { useCallback, useEffect, useState } from "react"
import { MacAddressGet } from "../app/models/SupplierPortal"
import { digitalManagerApi } from "../services/api"

export function useMacAddresses() {
  const [macAddressList, setMacAddressList] = useState<MacAddressGet[]>([])
  const [isLoadingMacAddress, setIsLoadingMacAddress] = useState<boolean>(true)
  const [reloadMacAddress, setReloadMacAddress] = useState<boolean>(true)

  useEffect(() => {
    if (reloadMacAddress) {
      digitalManagerApi
        .get<MacAddressGet[]>(`/api/v1/litePanelPro/macAddresses`)
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            setMacAddressList(res.data)
          }
        })
        .finally(() => {
          setIsLoadingMacAddress(false)
          setReloadMacAddress(false)
        })
    }
  }, [reloadMacAddress])

  const reloadMacAddresses = useCallback(() => setReloadMacAddress(true), [])

  return {
    macAddressList,
    reloadMacAddresses,
    isLoadingMacAddress,
  }
}
