import { Box } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { Link } from "react-router-dom"
import { useDeviceCategories } from "../../../../hooks/useDeviceCategories"
import { DimaFeatureShortNameEnum } from "../../../models/Authorization"
import { DeviceCategoryGet } from "../../../models/DeviceCategory"
import { DiMaAddButton } from "../../partials/DiMaAddButton"
import { EntityTab } from "../../partials/EntityTab"

export function Categories() {
  const { deviceCategoriesList, isLoadingDeviceCategories } = useDeviceCategories()

  const cols: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      renderCell: (params) => (
        <Link to={`/devices/categories/${params.row.id}`}>{params.row.name}</Link>
      ),
      flex: 2,
    },
  ]

  const rows = deviceCategoriesList.map((deviceCategory: DeviceCategoryGet) => ({
    id: deviceCategory.id,
    name: deviceCategory.name,
  }))

  return (
    <>
      <Box className="DiMaBox-one-element-right">
        <DiMaAddButton
          url="/devices/categories/create"
          feature={DimaFeatureShortNameEnum.DM}
        />
      </Box>
      <EntityTab
        rows={rows}
        cols={cols}
        isLoading={isLoadingDeviceCategories}
        initialState={{
          sorting: { sortModel: [{ field: "name", sort: "asc" }] },
        }}
      />
    </>
  )
}
