import { Box, Checkbox, Chip, Stack, Typography } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { useCallback, useState } from "react"
import { Link } from "react-router-dom"
import { useNotifications } from "../../../../hooks/useNotifications"
import { DimaFeatureShortNameEnum } from "../../../models/Authorization"
import { NotificationGet } from "../../../models/Notification"
import {
  dateStringComparator,
  getNiceFormattedDate,
} from "../../../utils/getFormattedDate"
import { getNotificationStatusProps } from "../../../utils/getNotificationStatusProps"
import { DiMaAddButton } from "../../partials/DiMaAddButton"
import { EntityTab } from "../../partials/EntityTab"

export function Notifications() {
  const [getExpired, setGetExpired] = useState<boolean>(false)
  const { notificationsList, isLoadingNotifications } = useNotifications(getExpired)

  const cols: GridColDef[] = [
    {
      field: "title",
      headerName: "Title",
      renderCell: (params) => (
        <Link to={`/notifications/${params.row.id}`}>{params.row.title}</Link>
      ),
      flex: 2,
    },
    {
      field: "applicationName",
      headerName: "App",
      flex: 1,
    },
    {
      field: "validityStartDate",
      headerName: "Validity Start Date",
      valueGetter: (params) => getNiceFormattedDate(params.row.validityStartDate),
      sortComparator: dateStringComparator,
      flex: 2,
    },
    {
      field: "validityEndDate",
      headerName: "Validity End Date",
      valueGetter: (params) => getNiceFormattedDate(params.row.validityEndDate),
      sortComparator: dateStringComparator,
      flex: 2,
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => (
        <Chip
          sx={{ width: "100px" }}
          {...getNotificationStatusProps(params.row.status)}
        />
      ),
      flex: 1,
    },
  ]

  const rows = notificationsList.map((notification: NotificationGet) => ({
    id: notification.id,
    applicationName: notification.application.name,
    title: notification.title,
    validityStartDate: notification.validityStartDate,
    validityEndDate: notification.validityEndDate,
    status: notification.status,
  }))

  const onChange = useCallback(() => {
    setGetExpired((exp) => !exp)
  }, [])

  return (
    <>
      <Box className="DiMaBox-two-elements">
        <Stack direction="row">
          <Checkbox edge="start" checked={getExpired} onChange={onChange} />
          <Typography variant="body1">Include expired notifications</Typography>
        </Stack>
        <DiMaAddButton
          url="/notifications/create"
          feature={DimaFeatureShortNameEnum.NM}
        />
      </Box>
      <EntityTab
        rows={rows}
        cols={cols}
        isLoading={isLoadingNotifications}
        initialState={{
          sorting: { sortModel: [{ field: "validityStartDate", sort: "asc" }] },
        }}
      />
    </>
  )
}
