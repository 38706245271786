import { HelpOutline } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Typography,
} from "@mui/material"
import { useFormik } from "formik"
import { StatusCodes } from "http-status-codes"
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { mixed, object, string } from "yup"
import { useApplicationProperties } from "../../../../../../hooks/useApplicationProperties"
import { useUsers } from "../../../../../../hooks/useUsers"
import { digitalManagerApi } from "../../../../../../services/api"
import { BackToURL } from "../../../../../components/BackToURL"
import HtmlTooltip from "../../../../../components/HtmlTooltip"
import {
  ApplicationPropertyGet,
  ApplicationPropertyPossibleValue,
} from "../../../../../models/ApplicationProperty"
import {
  ApplicationRoleApproverGet,
  ApplicationRoleApproverProperty,
  ApplicationRoleApproverTypeEnum,
} from "../../../../../models/ApplicationRoleApprover"

import { UserGet } from "../../../../../models/User"

interface PropertyPossibleValues {
  [key: string]: ApplicationPropertyPossibleValue[]
}

const validationSchema = object({
  userId: string().required("User field is required"),
  type: mixed<ApplicationRoleApproverTypeEnum>()
    .oneOf(Object.values(ApplicationRoleApproverTypeEnum))
    .required("Type field is required"),
  propertyName: string().when("isGenericApprover", {
    is: false,
    then: string().required("Property name field is required"),
  }),
  propertyPossibleValue: object().when("isGenericApprover", {
    is: false,
    then: object({
      value: string().required("Property value field is required"),
    }),
  }),
})

const handleAutocompleteGetOptionLabel = (
  propertyValue: ApplicationPropertyPossibleValue
) => propertyValue.label
const handleAutocompleteRenderInput = (params: AutocompleteRenderInputParams) => (
  <TextField {...params} />
)

export function ApplicationRoleApproverCreate() {
  const { applicationId, roleId, userId } = useParams()
  const { usersList, isLoadingUsers } = useUsers(false)
  const { applicationProperties, isLoadingApplicationProperties } =
    useApplicationProperties(applicationId, true, true)
  const [userPropertiesList, setUserPropertiesList] = useState<
    ApplicationRoleApproverProperty[]
  >([])
  const navigate = useNavigate()
  const returnURL = `/applications/${applicationId}/roles/${roleId}#approvers`

  const propertyValues: PropertyPossibleValues = useMemo(() => {
    const propertyValuesDict: PropertyPossibleValues = {}
    if (applicationProperties?.length) {
      applicationProperties.forEach((property: ApplicationPropertyGet) => {
        propertyValuesDict[property.name] = property.possibleValues
      })
    }
    return propertyValuesDict
  }, [applicationProperties])

  const formik = useFormik({
    validationSchema,
    initialValues: {
      userId: userId || "",
      propertyName: "",
      propertyPossibleValue: { value: "", label: "" },
      type: "",
      isAutomatic: false,
      isGenericApprover: false,
      sendEmail: true,
    },
    onSubmit: (values, { setSubmitting }) => {
      const body =
        values.propertyName === ""
          ? { type: values.type, sendEmail: values.sendEmail }
          : {
              propertyName: values.propertyName,
              propertyValue: values.propertyPossibleValue.value,
              type: values.type,
              isAutomatic: values.isAutomatic,
              sendEmail: values.sendEmail,
            }

      digitalManagerApi
        .post(
          `/api/v1/applications/${applicationId}/roles/${roleId}/approvers/${values.userId}`,
          body
        )
        .then((res) => {
          if (res.status === StatusCodes.CREATED) {
            toast.success("Approver added to role")
            navigate(returnURL)
          }
        })
        .catch(() => {
          toast.error("Cannot add approver to role")
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
  })

  const selectedUserId = formik.values.userId

  useEffect(() => {
    setUserPropertiesList([])
    if (selectedUserId) {
      digitalManagerApi
        .get<ApplicationRoleApproverGet>(
          `/api/v1/applications/${applicationId}/roles/${roleId}/approvers/${selectedUserId}`
        )
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            const rows = res.data.properties.map(
              (property: ApplicationRoleApproverProperty) => ({
                name: property.name,
                value: property.value,
                label: property.label,
                approverType: property.approverType,
                isAutomatic: property.isAutomatic,
                sendEmail: property.sendEmail,
              })
            )
            setUserPropertiesList(rows)
          }
        })
    }
  }, [applicationId, roleId, selectedUserId])

  useEffect(() => {
    if (
      formik.values.propertyName === "" ||
      formik.values.propertyPossibleValue.value === "" ||
      (formik.values.type !== ApplicationRoleApproverTypeEnum.Approver &&
        formik.values.type !== ApplicationRoleApproverTypeEnum.BackupApprover)
    ) {
      formik.setFieldValue("isAutomatic", false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.propertyName,
    formik.values.propertyPossibleValue,
    formik.values.type,
  ])

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      formik.setFieldValue(event.target.name, event.target.value)
    },
    [formik]
  )

  const handleChangeSelectPropertyName = useCallback(
    (event: SelectChangeEvent) => {
      formik.setValues({
        ...formik.values,
        propertyName: event.target.value,
        propertyPossibleValue: { value: "", label: "" },
      })
    },
    [formik]
  )

  const handleChangeFormikValues = useCallback(
    (
      _e: SyntheticEvent<Element, Event>,
      appPropertyValue: ApplicationPropertyPossibleValue | null
    ) => {
      if (appPropertyValue) {
        formik.setValues({
          ...formik.values,
          propertyPossibleValue: {
            value: appPropertyValue.value,
            label: appPropertyValue.label,
          },
        })
      }
    },
    [formik]
  )

  const handleChangeUserFormikValues = useCallback(
    (
      _e: SyntheticEvent<Element, Event>,
      value: {
        id: string
        label: string | undefined
      } | null
    ) => {
      if (value) {
        formik.setFieldValue("userId", value.id)
      }
      formik.setValues({
        ...formik.values,
        userId: value ? value.id : "",
      })
    },
    [formik]
  )

  const handleChangeSwitchIsGenericApproverFormikValues = useCallback(() => {
    formik.setValues({
      ...formik.values,
      propertyName: !formik.values.isGenericApprover ? "" : formik.values.propertyName,
      propertyPossibleValue: !formik.values.isGenericApprover
        ? { value: "", label: "" }
        : formik.values.propertyPossibleValue,
      isGenericApprover: !formik.values.isGenericApprover,
    })
  }, [formik])

  const handleAutocompleteGetOptionDisabled = useCallback(
    (option: ApplicationPropertyPossibleValue) => {
      const optionAlreadySelected = userPropertiesList.find(
        (applicationUserPropertiesLists: ApplicationRoleApproverProperty) =>
          formik.values.propertyName === applicationUserPropertiesLists.name &&
          option.value === applicationUserPropertiesLists.value
      )
      return !!optionAlreadySelected
    },
    [userPropertiesList, formik.values.propertyName]
  )

  const submitForm = useCallback(() => {
    formik.submitForm()
  }, [formik])

  const setIsAutomaticValues = useCallback(() => {
    formik.setValues({
      ...formik.values,
      isAutomatic: !formik.values.isAutomatic,
    })
  }, [formik])

  const setSendEmailValues = useCallback(() => {
    formik.setValues({
      ...formik.values,
      sendEmail: !formik.values.sendEmail,
    })
  }, [formik])

  const isSelectedUserGenericApprover =
    userPropertiesList.filter((e) => e.name === null && e.value === null).length > 0

  const renderInput = useCallback((params) => <TextField {...params} />, [])

  const isOptionEqualId = useCallback((option, label) => option.id === label.id, [])

  const isOptionEqualValue = useCallback(
    (option, label) => option.value === label.value,
    []
  )

  const getOptionLabel = useCallback((option) => option.label || "", [])

  return (
    <Box>
      <BackToURL url={returnURL} label="Back to application role approvers" />
      <Paper>
        <Card>
          <CardHeader
            title="Add approver to role"
            action={
              <LoadingButton
                variant="contained"
                type="submit"
                loading={formik.isSubmitting}
                onClick={submitForm}
                disabled={!formik.dirty || !formik.isValid}
              >
                Save
              </LoadingButton>
            }
          />
          <CardContent
            sx={{
              padding: 0,
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
          >
            <Divider />
            <List
              sx={{
                width: "100%",
                bgcolor: "Background.paper",
              }}
            >
              <ListItem key={0}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">User</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        loading={isLoadingUsers}
                        id="userId"
                        options={usersList.map((user: UserGet) => ({
                          label: user.username,
                          id: user.id,
                        }))}
                        size="small"
                        fullWidth
                        renderInput={renderInput}
                        isOptionEqualToValue={isOptionEqualId}
                        onChange={handleChangeUserFormikValues}
                        value={
                          userId
                            ? {
                                id: formik.values.userId,
                                label: usersList.find((u) => u.id === userId)?.username,
                              }
                            : undefined
                        }
                        getOptionLabel={getOptionLabel}
                        disabled={!!userId}
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={1}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography
                        variant="h6"
                        alignItems="center"
                        flexWrap="wrap"
                        display="flex"
                      >
                        Approver type {"\u00a0"}
                        <HtmlTooltip
                          custommaxwidth={400}
                          title={
                            <>
                              <b>{"Approver: "}</b>
                              {
                                "can approve, reject and forward role requests. They are notified when a new role request is opened."
                              }
                              <br />
                              <b>{"BackupApprover: "}</b>
                              {"can approve, reject and forward role requests."}
                              <br />
                              <b>{"Consulted: "}</b>
                              {"can approve and reject role requests forwarded to them."}
                              <br />
                              <b>{"Informed: "}</b>
                              {"can only see role requests."}
                              <br />
                            </>
                          }
                        >
                          <HelpOutline fontSize="small" />
                        </HtmlTooltip>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Select
                        id="type"
                        name="type"
                        value={formik.values.type}
                        onChange={handleChange}
                        size="small"
                        displayEmpty
                        fullWidth
                        disabled={!selectedUserId || isSelectedUserGenericApprover}
                      >
                        {Object.values(ApplicationRoleApproverTypeEnum).map(
                          (type: ApplicationRoleApproverTypeEnum) => (
                            <MenuItem value={type} key={type}>
                              {type}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={2}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Is Automatic</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Switch
                        checked={formik.values.isAutomatic}
                        onChange={setIsAutomaticValues}
                        value={formik.values.isAutomatic}
                        disabled={
                          formik.values.propertyName === "" ||
                          formik.values.propertyPossibleValue.value === "" ||
                          (formik.values.type !==
                            ApplicationRoleApproverTypeEnum.Approver &&
                            formik.values.type !==
                              ApplicationRoleApproverTypeEnum.BackupApprover)
                        }
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={3}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Send Email</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Switch
                        checked={formik.values.sendEmail}
                        onChange={setSendEmailValues}
                        value={formik.values.sendEmail}
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={4}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Is generic approver</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Switch
                        checked={formik.values.isGenericApprover}
                        onChange={handleChangeSwitchIsGenericApproverFormikValues}
                        value={formik.values.isGenericApprover}
                        disabled={!selectedUserId || userPropertiesList.length > 0}
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              {formik.values.isGenericApprover === false && (
                <>
                  <ListItem key={5}>
                    <ListItemText>
                      <Grid
                        container
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Grid item xs={2}>
                          <Typography variant="h6">Property Name</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Select
                            id="propertyName"
                            name="propertyName"
                            value={formik.values.propertyName}
                            onChange={handleChangeSelectPropertyName}
                            disabled={
                              !selectedUserId ||
                              isLoadingApplicationProperties ||
                              isSelectedUserGenericApprover
                            }
                            size="small"
                            displayEmpty
                            fullWidth
                          >
                            {!isLoadingApplicationProperties &&
                              applicationProperties.map(
                                (property: ApplicationPropertyGet) => (
                                  <MenuItem value={property.name} key={property.name}>
                                    {property.name}
                                  </MenuItem>
                                )
                              )}
                          </Select>
                        </Grid>
                      </Grid>
                    </ListItemText>
                  </ListItem>
                  <ListItem key={6}>
                    <ListItemText>
                      <Grid
                        container
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Grid item xs={2}>
                          <Typography variant="h6">Property Value</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Autocomplete
                            id="propertyValues"
                            options={propertyValues[formik.values.propertyName] ?? []}
                            getOptionLabel={handleAutocompleteGetOptionLabel}
                            getOptionDisabled={handleAutocompleteGetOptionDisabled}
                            isOptionEqualToValue={isOptionEqualValue}
                            fullWidth
                            disableClearable
                            size="small"
                            renderInput={handleAutocompleteRenderInput}
                            disabled={
                              !selectedUserId ||
                              !formik.values.propertyName ||
                              isSelectedUserGenericApprover
                            }
                            onChange={handleChangeFormikValues}
                            value={formik.values.propertyPossibleValue}
                          />
                        </Grid>
                      </Grid>
                    </ListItemText>
                  </ListItem>
                </>
              )}
            </List>
          </CardContent>
        </Card>
      </Paper>
    </Box>
  )
}
