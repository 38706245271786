import { GridComparatorFn } from "@mui/x-data-grid"
import moment from "moment"
import { LocalDateTime, UtcDateTime } from "../models/CustomType"

const dateTimeFormat = "YYYY-MM-DDTHH:mm"

export const getNiceFormattedDate = (date: UtcDateTime): LocalDateTime =>
  moment.utc(date).local().format("lll")

export const getNowDateTime = (): LocalDateTime => moment().format(dateTimeFormat)

export const getUtcDateTime = (date: LocalDateTime): UtcDateTime =>
  moment(date).utc().format(dateTimeFormat)

export const getLocalDateTime = (date: UtcDateTime): LocalDateTime =>
  moment.utc(date).local().format(dateTimeFormat)

export const getFutureDateTime = (period: number): LocalDateTime =>
  moment().add(period, "days").format("lll")

export const compareDates = (d1: LocalDateTime, d2: LocalDateTime): number =>
  moment(d1).unix() - moment(d2).unix()

export const dateStringComparator: GridComparatorFn<string> = (v1, v2) =>
  compareDates(v1, v2)
