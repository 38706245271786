import { Key } from "@mui/icons-material"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material"
import { useFormik } from "formik"
import { StatusCodes } from "http-status-codes"
import { useCallback, useContext, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import { object, string } from "yup"
import abbLogo from "../../../assets/logos/abb-logo.png"
import { digitalManagerApi } from "../../../services/api"
import { DiMaProgress } from "../../components/DiMaProgress"
import { UserContext } from "../../context/UserContext"

const validationSchema = object({
  mail: string().email("Invalid email").required("Email is required"),
  macAddress: string()
    .required("Mac Address is required")
    .matches(
      /^([0-9a-fA-F]{2}[:.-]){5}[0-9a-fA-F]{2}$/,
      "Invalid MAC Address format. Please use the following format: 00:00:00:00:00:00"
    ),
})

export function LitePanelProResetPassword() {
  const [searchParams] = useSearchParams()
  const requestId = searchParams.get("requestId")
    ? searchParams.get("requestId")
    : undefined
  const serialNumber = searchParams.get("serialNumber")
    ? searchParams.get("serialNumber")
    : undefined
  const [isResetRequestCompleted, setIsResetRequestCompleted] = useState<boolean>(false)
  const { user, isLoadingUser } = useContext(UserContext)

  const formik = useFormik({
    validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      mail: user?.username,
      macAddress: "",
    },
    onSubmit: (values, { setSubmitting }) => {
      digitalManagerApi
        .get(
          `/api/v1/litePanelPro/resetPassword?requestId=${requestId}&serialNumber=${serialNumber}&macAddress=${values.macAddress}&customerMail=${values.mail}`
        )
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            setIsResetRequestCompleted(true)
          }
        })
        .catch(() => {
          toast.error("Cannot send reset password request")
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
  })

  const submitForm = useCallback(() => {
    formik.submitForm()
  }, [formik])

  if (isLoadingUser || !user || !requestId || !serialNumber) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <DiMaProgress />
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "10%",
      }}
    >
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        draggable
        theme="colored"
      />
      <img src={abbLogo} />

      <List
        sx={{
          width: "100%",
          bgcolor: "Background.paper",
        }}
      >
        <ListItem key={0}>
          <ListItemText>
            <Typography
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                height: "3vh",
              }}
            >
              If you have lost your administrator password of the Lite Panel Pro, please
              fill out the form below to initiate a password reset request.
            </Typography>
            <Typography
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                height: "3vh",
              }}
            >
              After submitting the form, you will receive an email within the next three
              working days containing an attached file to upload to your Lite Panel Pro.
            </Typography>
          </ListItemText>
        </ListItem>

        <ListItem>
          <ListItemText>
            <Grid
              container
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Grid item xs={8}>
                <TextField
                  disabled
                  sx={{ marginTop: "2%" }}
                  rows={4}
                  fullWidth
                  id="mail"
                  name="mail"
                  type="text"
                  value={formik.values.mail}
                  size="small"
                ></TextField>
                {formik.dirty && formik.errors.mail && (
                  <div style={{ color: "red" }}>
                    {formik.errors.mail.charAt(0).toUpperCase() +
                      formik.errors.mail.slice(1)}
                  </div>
                )}
              </Grid>
              <Grid item xs={8}>
                <TextField
                  sx={{ marginTop: "2%" }}
                  rows={4}
                  placeholder="Insert the LAN MAC Address (ETH1) of the Lite Panel Pro"
                  fullWidth
                  id="macAddress"
                  name="macAddress"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.macAddress}
                  size="small"
                ></TextField>
                {formik.dirty && formik.errors.macAddress && (
                  <div style={{ color: "red" }}>
                    {formik.errors.macAddress.charAt(0).toUpperCase() +
                      formik.errors.macAddress.slice(1)}
                  </div>
                )}
              </Grid>
            </Grid>
          </ListItemText>
        </ListItem>
      </List>
      {!isResetRequestCompleted ? (
        <LoadingButton
          disabled={!formik.isValid}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "1%",
          }}
          onClick={submitForm}
          variant="contained"
          loading={formik.isSubmitting}
          startIcon={<Key />}
        >
          RAISE RESET PASSWORD REQUEST
        </LoadingButton>
      ) : (
        <CheckCircleIcon fontSize="large" color="success" />
      )}
    </Box>
  )
}
