import { TabContext, TabList } from "@mui/lab"
import { Box } from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom"
import { digitalManagerApi } from "../../../../../services/api"
import { BackToURL } from "../../../../components/BackToURL"
import {
  DiMaDetailsHeader,
  DiMaDetailsHeaderProps,
} from "../../../../components/DiMaDetailsHeader"
import { DiMaProgress } from "../../../../components/DiMaProgress"
import { DiMaTabPanel } from "../../../../components/DiMaTabPanel"
import { LinkTab } from "../../../../components/LinkTab"
import { FileVersionGet } from "../../../../models/FileVersion"
import { FileVersionDetails } from "./details_tab/FileVersionDetails"

export function FileVersionContainer() {
  const { fileId, versionId } = useParams()
  const [activeTab, setActiveTab] = useState<string>("#details")
  const [version, setVersion] = useState<FileVersionGet>()
  const { hash } = useLocation()
  const navigate = useNavigate()
  const returnURL = `/files/${fileId}#versions`

  const AVATAR_LENGTH = 2

  useEffect(() => {
    if (fileId && versionId) {
      digitalManagerApi
        .get<FileVersionGet>(`/api/v1/files/${fileId}/versions/${versionId}`)
        .then((res) => {
          setVersion(res.data)
        })
        .catch(() => {
          navigate(returnURL)
        })
    }
  }, [fileId, navigate, returnURL, versionId])

  useEffect(() => {
    if (hash) {
      setActiveTab(hash)
    }
  }, [hash])

  const handleChange = useCallback((_: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue)
  }, [])

  if (!fileId || !versionId) {
    return <Navigate to={returnURL} />
  }

  if (!version) {
    return <DiMaProgress />
  }

  const detailsHeaderProps: DiMaDetailsHeaderProps = {
    avatar: version.file.name.substring(0, AVATAR_LENGTH),
    title: `${version.file.name}${version.suffix ? version.suffix : ""}.${version.file.extension}`,
    label: "File",
    subtitle: `${version.file.name}.${version.file.extension}`,
    hideCopyToClipboard: true,
  }

  return (
    <>
      <BackToURL url={returnURL} label="Back to versions" />
      <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <DiMaDetailsHeader {...detailsHeaderProps} />
        <TabContext value={activeTab}>
          <Box className="DiMaBox-tabs">
            <TabList onChange={handleChange}>
              <LinkTab label="Details" value="#details" />
            </TabList>
          </Box>
          <DiMaTabPanel label="#details" activeTab={activeTab}>
            <FileVersionDetails version={version} fileId={fileId} />
          </DiMaTabPanel>
        </TabContext>
      </Box>
    </>
  )
}
