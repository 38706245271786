import DeleteIcon from "@mui/icons-material/Delete"
import { Box } from "@mui/material"
import { GridActionsCellItem, GridColumns, GridRowParams } from "@mui/x-data-grid"
import { StatusCodes } from "http-status-codes"
import { useCallback } from "react"
import { toast } from "react-toastify"
import { digitalManagerApi } from "../../../../../../../services/api"
import { ApplicationPropertyDetail } from "../../../../../../models/ApplicationProperty"
import { ApplicationRolePermissionGet } from "../../../../../../models/ApplicationRolePermission"
import {
  AccessLevel,
  DimaFeatureShortNameEnum,
  hasUserAccessToApplicationFeature,
} from "../../../../../../models/Authorization"
import { UserInfo } from "../../../../../../models/User"
import { DiMaAddButton } from "../../../../../partials/DiMaAddButton"
import { EntityTab } from "../../../../../partials/EntityTab"

export function ApplicationRolePermissionProperties(props: {
  readonly applicationRolePermission: ApplicationRolePermissionGet
  readonly reloadApplicationRolePermission: () => void
  readonly user: UserInfo
}) {
  const { applicationRolePermission, reloadApplicationRolePermission, user } = props

  const onClick = useCallback(
    (params: GridRowParams) => () => {
      digitalManagerApi
        .delete(
          `/api/v1/applications/${applicationRolePermission.application.id}/properties/${params.row.name}/values/${params.row.value}`,
          {
            params: {
              roleId: applicationRolePermission.role.id,
              permissionId: applicationRolePermission.permission.id,
              noDevice: applicationRolePermission.noDevice,
            },
          }
        )
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            toast.success("Property deleted")
          }
        })
        .catch(() => {
          toast.error("Cannot delete the property")
        })
        .finally(() => {
          reloadApplicationRolePermission()
        })
    },
    [
      applicationRolePermission.application.id,
      applicationRolePermission.noDevice,
      applicationRolePermission.permission.id,
      applicationRolePermission.role.id,
      reloadApplicationRolePermission,
    ]
  )

  const columns: GridColumns<ApplicationPropertyDetail> = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    { field: "label", headerName: "Value", flex: 2 },
    {
      field: "actions",
      type: "actions",
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          key="delete"
          icon={<DeleteIcon color="error" />}
          onClick={onClick(params)}
          label="Delete"
          showInMenu
          disabled={
            !hasUserAccessToApplicationFeature(
              user.authorizations.applications,
              applicationRolePermission.application.id,
              DimaFeatureShortNameEnum.AM,
              AccessLevel.Write
            )
          }
        />,
      ],
    },
  ]

  const applicationRolePermissionsRows = applicationRolePermission.properties.map(
    (property: ApplicationPropertyDetail, index: number) => ({
      id: index,
      name: property.name,
      value: property.value,
      label: property.label,
    })
  )

  return (
    <>
      <Box className="DiMaBox-one-element-right">
        <DiMaAddButton
          url={`/applications/${applicationRolePermission.application.id}/roles/${applicationRolePermission.role.id}/permissions/${applicationRolePermission.permission.id}/properties/create?noDevice=${applicationRolePermission.noDevice}`}
          feature={DimaFeatureShortNameEnum.AM}
        />
      </Box>
      <EntityTab
        rows={applicationRolePermissionsRows}
        cols={columns}
        isLoading={false}
        initialState={{
          sorting: { sortModel: [{ field: "name", sort: "asc" }] },
        }}
      />
    </>
  )
}
