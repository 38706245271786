import { TabContext, TabList } from "@mui/lab"
import { Box } from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom"
import { digitalManagerApi } from "../../../../services/api"
import { BackToURL } from "../../../components/BackToURL"
import {
  DiMaDetailsHeader,
  DiMaDetailsHeaderProps,
} from "../../../components/DiMaDetailsHeader"
import { DiMaProgress } from "../../../components/DiMaProgress"
import { DiMaTabPanel } from "../../../components/DiMaTabPanel"
import { LinkTab } from "../../../components/LinkTab"
import { NotificationTopicGet } from "../../../models/NotificationTopic"
import { TopicDetails } from "./details_tab/TopicDetails"

const returnURL = "/notifications#topics"

export function TopicContainer() {
  const { notificationTopicId } = useParams()
  const [activeTab, setActiveTab] = useState<string>("#details")
  const [notificationTopic, setNotificationTopic] = useState<NotificationTopicGet>()
  const { hash } = useLocation()
  const navigate = useNavigate()

  const AVATAR_LENGTH = 2

  useEffect(() => {
    if (notificationTopicId) {
      digitalManagerApi
        .get<NotificationTopicGet>(`/api/v1/notificationTopics/${notificationTopicId}`)
        .then((res) => {
          setNotificationTopic(res.data)
        })
        .catch(() => {
          navigate(returnURL)
        })
    }
  }, [navigate, notificationTopicId])

  useEffect(() => {
    if (hash) {
      setActiveTab(hash)
    }
  }, [hash])

  const handleChange = useCallback((_: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue)
  }, [])

  if (!notificationTopicId) {
    return <Navigate to={returnURL} />
  }

  if (!notificationTopic) {
    return <DiMaProgress />
  }

  const detailsHeaderProps: DiMaDetailsHeaderProps = {
    avatar: notificationTopic.name.substring(0, AVATAR_LENGTH),
    title: notificationTopic.name,
    label: "Notification topic ID",
    subtitle: notificationTopic.id,
  }

  return (
    <>
      <BackToURL url={returnURL} label="Back to notification topics" />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <DiMaDetailsHeader {...detailsHeaderProps} />
        <TabContext value={activeTab}>
          <Box className="DiMaBox-tabs">
            <TabList onChange={handleChange}>
              <LinkTab label="Details" value="#details" />
            </TabList>
          </Box>
          <DiMaTabPanel label="#details" activeTab={activeTab}>
            <TopicDetails
              notificationTopic={notificationTopic}
              setNotificationTopic={setNotificationTopic}
            />
          </DiMaTabPanel>
        </TabContext>
      </Box>
    </>
  )
}
