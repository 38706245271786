import { TabContext, TabList } from "@mui/lab"
import { Box } from "@mui/material"
import { StatusCodes } from "http-status-codes"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom"
import { digitalManagerApi } from "../../../../services/api"
import { BackToURL } from "../../../components/BackToURL"
import {
  DiMaDetailsHeader,
  DiMaDetailsHeaderProps,
} from "../../../components/DiMaDetailsHeader"
import { DiMaProgress } from "../../../components/DiMaProgress"
import { DiMaTabPanel } from "../../../components/DiMaTabPanel"
import { LinkTab } from "../../../components/LinkTab"
import { UserContext } from "../../../context/UserContext"
import { DeviceCategoryGet } from "../../../models/DeviceCategory"
import { CategoryDetails } from "./details_tab/CategoryDetails"
import { CategoryDevices } from "./devices_tab/CategoryDevices"

const returnURL = "/devices#categories"

export function CategoryContainer() {
  const { categoryId } = useParams()
  const { user, isLoadingUser } = useContext(UserContext)
  const [activeTab, setActiveTab] = useState<string>("#details")
  const [deviceCategory, setDeviceCategory] = useState<DeviceCategoryGet>()
  const [reloadEntity, setReloadEntity] = useState<boolean>(true)
  const { hash } = useLocation()
  const navigate = useNavigate()

  const reloadCategoryContainer = useCallback(() => setReloadEntity(true), [])
  const AVATAR_LENGTH = 2

  useEffect(() => {
    if (categoryId && reloadEntity === true) {
      digitalManagerApi
        .get<DeviceCategoryGet>(`/api/v1/deviceCategories/${categoryId}`)
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            setDeviceCategory(res.data)
            setReloadEntity(false)
          }
        })
        .catch(() => {
          navigate(returnURL)
        })
    }
  }, [categoryId, navigate, reloadEntity])

  useEffect(() => {
    if (hash) {
      setActiveTab(hash)
    }
  }, [hash])

  const handleChange = useCallback((_: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue)
  }, [])

  if (!categoryId) {
    return <Navigate to={returnURL} />
  }

  if (!deviceCategory || isLoadingUser || !user) {
    return <DiMaProgress />
  }

  const detailsHeaderProps: DiMaDetailsHeaderProps = {
    avatar: deviceCategory.name.substring(0, AVATAR_LENGTH),
    title: deviceCategory.name,
    label: "Device Category",
    subtitle: deviceCategory.name,
    hideCopyToClipboard: true,
  }

  return (
    <>
      <BackToURL url={returnURL} label="Back to device categories" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <DiMaDetailsHeader {...detailsHeaderProps} />
        <TabContext value={activeTab}>
          <Box className="DiMaBox-tabs">
            <TabList onChange={handleChange}>
              <LinkTab label="Details" value="#details" />
              <LinkTab label="Devices" value="#devices" />
            </TabList>
          </Box>
          <DiMaTabPanel label="#details" activeTab={activeTab}>
            <CategoryDetails
              deviceCategory={deviceCategory}
              setDeviceCategory={setDeviceCategory}
              user={user}
            />
          </DiMaTabPanel>
          <DiMaTabPanel label="#devices" activeTab={activeTab}>
            <CategoryDevices
              deviceCategory={deviceCategory}
              reloadCategoryContainer={reloadCategoryContainer}
            />
          </DiMaTabPanel>
        </TabContext>
      </Box>
    </>
  )
}
