import { TabContext, TabList } from "@mui/lab"
import { Box } from "@mui/material"
import { StatusCodes } from "http-status-codes"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom"
import { digitalManagerApi } from "../../../../../../services/api"
import { BackToURL } from "../../../../../components/BackToURL"
import {
  DiMaDetailsHeader,
  DiMaDetailsHeaderProps,
} from "../../../../../components/DiMaDetailsHeader"
import { DiMaProgress } from "../../../../../components/DiMaProgress"
import { DiMaTabPanel } from "../../../../../components/DiMaTabPanel"
import { LinkTab } from "../../../../../components/LinkTab"
import { UserContext } from "../../../../../context/UserContext"
import { ApplicationRoleApproverGet } from "../../../../../models/ApplicationRoleApprover"
import { ApplicationRoleApproverDetails } from "./details_tab/ApplicationRoleApproverDetails"
import { ApplicationRoleApproverProperties } from "./properties_tab/ApplicationRoleApproverProperties"

export function ApplicationRoleApproverContainer() {
  const { applicationId, roleId, userId } = useParams()
  const { user, isLoadingUser } = useContext(UserContext)
  const { hash } = useLocation()
  const [approver, setApprover] = useState<ApplicationRoleApproverGet>()
  const [activeTab, setActiveTab] = useState<string>("#details")
  const navigate = useNavigate()
  const returnURL = `/applications/${applicationId}/roles/${roleId}#approvers`

  useEffect(() => {
    if (applicationId && roleId && userId) {
      digitalManagerApi
        .get<ApplicationRoleApproverGet>(
          `/api/v1/applications/${applicationId}/roles/${roleId}/approvers/${userId}`
        )
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            setApprover(res.data)
          } else if (res.status === StatusCodes.NO_CONTENT) {
            navigate(returnURL)
          }
        })
        .catch(() => {
          navigate(returnURL)
        })
    }
  }, [applicationId, navigate, returnURL, roleId, userId])

  useEffect(() => {
    if (hash) {
      setActiveTab(hash)
    }
  }, [hash])

  const handleChange = useCallback((_: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue)
  }, [])

  if (!applicationId || !roleId || !userId) {
    return <Navigate to={returnURL} />
  }

  if (!approver || isLoadingUser || !user) {
    return <DiMaProgress />
  }

  const detailsHeaderProps: DiMaDetailsHeaderProps = {
    avatar: approver.application.shortName,
    title: `${approver.user.first} ${approver.user.last}`,
    label: "Role",
    subtitle: approver.role.name,
    hideCopyToClipboard: true,
  }

  return (
    <>
      <BackToURL url={returnURL} label="Back to application role approvers" />
      <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <DiMaDetailsHeader {...detailsHeaderProps} />
        <TabContext value={activeTab}>
          <Box className="DiMaBox-tabs">
            <TabList onChange={handleChange}>
              <LinkTab label="Details" value="#details" />
              {approver.properties.length > 0 && approver.properties[0].name !== null && (
                <LinkTab label="Properties" value="#properties" />
              )}
            </TabList>
          </Box>
          <DiMaTabPanel label="#details" activeTab={activeTab}>
            <ApplicationRoleApproverDetails
              approverDetails={approver}
              user={user}
              setApprover={setApprover}
            />
          </DiMaTabPanel>
          {approver.properties.length > 0 && approver.properties[0].name !== null && (
            <DiMaTabPanel label="#properties" activeTab={activeTab}>
              <ApplicationRoleApproverProperties approver={approver} />
            </DiMaTabPanel>
          )}
        </TabContext>
      </Box>
    </>
  )
}
