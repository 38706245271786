import { TabContext, TabList } from "@mui/lab"
import Box from "@mui/material/Box"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom"
import { digitalManagerApi } from "../../../../services/api"
import { BackToURL } from "../../../components/BackToURL"
import { DiMaProgress } from "../../../components/DiMaProgress"
import { DiMaTabPanel } from "../../../components/DiMaTabPanel"
import { LinkTab } from "../../../components/LinkTab"
import { UserContext } from "../../../context/UserContext"
import { ManufacturedDeviceInfo } from "../../../models/SupplierPortal"
import { ManufacturedDeviceDetails } from "./manufacturedDevice_details_tab/ManufacturedDeviceDetails"

const returnURL = "/supplierPortal#manufactured-devices"

export function ManufacturedDeviceContainer() {
  const { deviceId } = useParams()
  const { user, isLoadingUser } = useContext(UserContext)
  const [activeTab, setActiveTab] = useState<string>("#details")
  const [device, setDevice] = useState<ManufacturedDeviceInfo>()
  const { hash } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (deviceId) {
      digitalManagerApi
        .get<ManufacturedDeviceInfo>(
          `/api/v1/litePanelPro/manufacturedDevices/${deviceId}`
        )
        .then((res) => {
          setDevice(res.data)
        })
        .catch(() => {
          navigate(returnURL)
        })
    }
  }, [deviceId, navigate])

  useEffect(() => {
    if (hash) {
      setActiveTab(hash)
    }
  }, [hash])

  const handleChange = useCallback((_: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue)
  }, [])

  if (!deviceId) {
    return <Navigate to={returnURL} />
  }

  if (!device || isLoadingUser || !user) {
    return <DiMaProgress />
  }

  return (
    <>
      <BackToURL url={returnURL} label="Back to devices" />

      <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <TabContext value={activeTab}>
          <Box className="DiMaBox-tabs">
            <TabList onChange={handleChange}>
              <LinkTab label="Details" value="#details" />
            </TabList>
          </Box>
          <DiMaTabPanel label="#details" activeTab={activeTab}>
            <ManufacturedDeviceDetails device={device} user={user} />
          </DiMaTabPanel>
        </TabContext>
      </Box>
    </>
  )
}
