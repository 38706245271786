import { TextField, Typography } from "@mui/material"
import Checkbox from "@mui/material/Checkbox"
import { useFormik } from "formik"
import { StatusCodes } from "http-status-codes"
import { useCallback, useContext } from "react"
import { toast } from "react-toastify"
import { boolean, object, string } from "yup"
import { digitalManagerApi } from "../../../../../services/api"
import {
  DiMaDetailsContent,
  DiMaDetailsContentProps,
} from "../../../../components/DiMaDetailsContent"
import { DiMaProgress } from "../../../../components/DiMaProgress"
import { UserContext } from "../../../../context/UserContext"
import {
  AccessLevel,
  DimaFeatureShortNameEnum,
  hasUserAccessToFeature,
} from "../../../../models/Authorization"
import { DeviceFamilyGet, DeviceFamilyPut } from "../../../../models/DeviceFamily"

const validationSchema = object({
  name: string().required("Name is required"),
  isRequestable: boolean().required("Is Requestable is required"),
})

const returnURL = `/devices#families`

export function FamilyDetails(props: {
  readonly deviceFamily: DeviceFamilyGet
  readonly setDeviceFamily: (updatedDeviceFamily: DeviceFamilyGet) => void
}) {
  const { user, isLoadingUser } = useContext(UserContext)
  const { deviceFamily, setDeviceFamily } = props

  const deleteDeviceFamily = useCallback(
    () => digitalManagerApi.delete(`/api/v1/deviceFamilies/${deviceFamily.id}`),
    [deviceFamily.id]
  )

  const formik = useFormik<DeviceFamilyPut>({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      name: deviceFamily.name,
      isRequestable: deviceFamily.isRequestable,
    },
    onSubmit: (values, { setSubmitting }) =>
      digitalManagerApi
        .put<DeviceFamilyGet>(`/api/v1/deviceFamilies/${deviceFamily.id}`, {
          name: values.name,
          isRequestable: values.isRequestable,
        })
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            toast.success("Device Family updated")
            setDeviceFamily(res.data)
          }
        })
        .catch(() => {
          toast.error("Cannot update device family")
          formik.resetForm()
          return Promise.reject(new Error("Cannot update device family"))
        })
        .finally(() => {
          setSubmitting(false)
        }),
  })

  if (isLoadingUser || !user) {
    return <DiMaProgress />
  }

  const detailsContentProps: DiMaDetailsContentProps<DeviceFamilyPut> = {
    formik,
    canWrite: hasUserAccessToFeature(
      user.authorizations,
      DimaFeatureShortNameEnum.DM,
      AccessLevel.Write
    ),
    label: "Device Family",
    deleteAction: deleteDeviceFamily,
    onDeleteReturnUrl: returnURL,
    listItems: [
      {
        label: "Name",
        displayItem: <Typography>{deviceFamily.name}</Typography>,
        editItem: (
          <TextField
            fullWidth
            id="name"
            name="name"
            type="text"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            size="small"
          />
        ),
      },
      {
        label: "Is Requestable",
        displayItem: (
          <Typography>{deviceFamily.isRequestable?.toString() || "false"}</Typography>
        ),
        editItem: (
          <Checkbox
            id="isRequestable"
            name="isRequestable"
            checked={formik.values.isRequestable}
            onChange={formik.handleChange}
          />
        ),
      },
    ],
  }

  return <DiMaDetailsContent {...detailsContentProps} />
}
