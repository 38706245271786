import { Box } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { Link, useParams } from "react-router-dom"
import { useDeviceRolePermissions } from "../../../../../../hooks/useDeviceRolePermissions"
import { DimaFeatureShortNameEnum } from "../../../../../models/Authorization"
import { DeviceRolePermissionGet } from "../../../../../models/DeviceRolePermission"
import { DiMaAddButton } from "../../../../partials/DiMaAddButton"
import { EntityTab } from "../../../../partials/EntityTab"
import { getBooleanFlagIcon } from "../../../../../utils/getBooleanFlagIcon"

export function DeviceRolePermissions() {
  const { deviceId, roleId } = useParams()

  const { deviceRolePermissions, isLoadingDeviceRolePermissions } =
    useDeviceRolePermissions(deviceId, roleId)

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      renderCell: (params) => (
        <Link to={`/devices/${deviceId}/roles/${roleId}/permissions/${params.row.id}`}>
          {params.row.name}
        </Link>
      ),
      flex: 2,
    },
    {
      field: "application",
      headerName: "Application",
      flex: 1,
    },
    {
      field: "hasApplicationRoles",
      headerName: "Has App Roles",
      type: "boolean",
      renderCell: (params) => getBooleanFlagIcon(params.row.hasApplicationRoles),
      flex: 1,
    },
  ]

  const rows = deviceRolePermissions.map((permission: DeviceRolePermissionGet) => ({
    id: permission.permission.id,
    application: permission.application.name,
    name: permission.permission.name,
    hasApplicationRoles: permission.applicationRoles.length !== 0,
  }))

  return (
    <>
      <Box className="DiMaBox-one-element-right">
        <DiMaAddButton
          url={`/devices/${deviceId}/roles/${roleId}/permissions/create`}
          feature={DimaFeatureShortNameEnum.AM}
        />
      </Box>
      <EntityTab rows={rows} cols={columns} isLoading={isLoadingDeviceRolePermissions} />
    </>
  )
}
