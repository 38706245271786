import { Typography } from "@mui/material"
import {
  DiMaDetailsContent,
  DiMaDetailsContentProps,
} from "../../../../components/DiMaDetailsContent"
import { RoleRequestGet } from "../../../../models/RoleRequest"
import { getNiceFormattedDate } from "../../../../utils/getFormattedDate"

export function RoleRequestHistory(props: { readonly roleRequest: RoleRequestGet }) {
  const { roleRequest } = props

  const detailsContentProps: DiMaDetailsContentProps = {
    label: "History",
    isEditable: false,
    isDeletable: false,
    listItems: [
      {
        label: "Completion Date",
        displayItem: roleRequest.completionDate ? (
          <Typography>{getNiceFormattedDate(roleRequest.completionDate)}</Typography>
        ) : (
          <Typography>Not completed yet</Typography>
        ),
      },
      {
        label: "Creation Date",
        displayItem: (
          <Typography>{getNiceFormattedDate(roleRequest.creationDate)}</Typography>
        ),
      },
    ],
  }

  return <DiMaDetailsContent {...detailsContentProps} />
}
