import { Box, Checkbox, Stack, Typography } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { Link } from "react-router-dom"
import { useFiles } from "../../../../hooks/useFiles"
import { DimaFeatureShortNameEnum } from "../../../models/Authorization"
import { FileGet } from "../../../models/File"
import {
  dateStringComparator,
  getNiceFormattedDate,
} from "../../../utils/getFormattedDate"
import { DiMaAddButton } from "../../partials/DiMaAddButton"
import { EntityTab } from "../../partials/EntityTab"
import { useCallback, useState } from "react"

export function Files() {
  const [getPublic, setGetPublic] = useState<boolean>(false)
  const { files, isLoadingFiles } = useFiles(getPublic)
  

  const cols: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      renderCell: (params) => (
        <Link to={`/files/${params.row.id}`}>
          {`${params.row.name}.${params.row.extension}`}
        </Link>
      ),
      flex: 2,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
    },
    {
      field: "owner",
      headerName: "Owner",
      flex: 2,
    },
    {
      field: "lastVersionUploadDate",
      headerName: "Last version upload date",
      renderCell: (params) => getNiceFormattedDate(params.row.lastVersionUploadDate),
      sortComparator: dateStringComparator,
      flex: 2,
    },
  ]

  const rows = files.map((file: FileGet) => ({
    id: file.id,
    name: file.name,
    extension: file.extension,
    owner: file.owner.username,
    lastVersionUploadDate: file.lastVersion.uploadDate,
    status: file.isPublic ? "Public" : "Private",
  }))

  const onChange = useCallback(() => {
    setGetPublic((getPub) => !getPub)
  }, [])

  return (
    <>
      <Box className="DiMaBox-two-elements">
      <Stack direction="row">
          <Checkbox edge="start" checked={getPublic} onChange={onChange} />
          <Typography variant="body1">Include public files</Typography>
        </Stack>
        <DiMaAddButton url="/files/create" feature={DimaFeatureShortNameEnum.FM} />
      </Box>
      <EntityTab
        rows={rows}
        cols={cols}
        isLoading={isLoadingFiles}
        initialState={{
          sorting: { sortModel: [{ field: "name", sort: "asc" }] },
        }}
      />
    </>
  )
}
