import { TextField, Typography } from "@mui/material"
import { useFormik } from "formik"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { number, object } from "yup"
import { digitalManagerApi } from "../../../../../services/api"
import {
  DiMaDetailsContent,
  DiMaDetailsContentProps,
} from "../../../../components/DiMaDetailsContent"
import { ApplicationRoleApproverTypeEnum } from "../../../../models/ApplicationRoleApprover"
import { UtcDateTime } from "../../../../models/CustomType"
import {
  RoleRequestGet,
  RoleRequestPut,
  RoleRequestStatusEnum,
} from "../../../../models/RoleRequest"
import { getFutureDateTime } from "../../../../utils/getFormattedDate"

const validationSchema = object({
  period: number().required("Period is required"),
})

export function RoleRequestDetails(props: {
  readonly roleRequest: RoleRequestGet
  readonly setRoleRequest: (updateRoleRequest: RoleRequestGet) => void
}) {
  const { roleRequest, setRoleRequest } = props
  const [expirationDate, setExpirationDate] = useState<UtcDateTime>()

  const formik = useFormik<RoleRequestPut>({
    validationSchema,
    initialValues: {
      period: roleRequest.period,
    },
    onSubmit: (values, { setSubmitting }) =>
      digitalManagerApi
        .put(`/api/v1/roleRequests/${roleRequest.id}`, {
          period: values.period,
        })
        .then((res) => {
          toast.success("Role request updated")
          setRoleRequest(res.data)
        })
        .catch(() => {
          toast.error("Cannot update role request")
          return Promise.reject(new Error("Cannot update role request"))
        })
        .finally(() => {
          setSubmitting(false)
        }),
  })

  const period = formik.values.period

  useEffect(() => {
    if (period) {
      setExpirationDate(getFutureDateTime(period))
    }
  }, [period])

  const detailsContentProps: DiMaDetailsContentProps<RoleRequestPut> = {
    formik,
    label: "Request",
    isDeletable: false,
    canWrite:
      roleRequest.status === RoleRequestStatusEnum.Open &&
      roleRequest.approvals.filter(
        (a) =>
          a.userApproverType === ApplicationRoleApproverTypeEnum.Approver ||
          a.userApproverType === ApplicationRoleApproverTypeEnum.BackupApprover
      ).length > 0,
    listItems: [
      {
        label: "Application name",
        displayItem: <Typography>{roleRequest.application.name}</Typography>,
      },
      {
        label: "Role name",
        displayItem: <Typography>{roleRequest.role.name}</Typography>,
      },
      {
        label: "Referent email",
        displayItem: (
          <Typography sx={{ textTransform: "none" }}>
            {roleRequest.referentEmail}
          </Typography>
        ),
      },
      {
        label: "Reason",
        displayItem: <Typography>{roleRequest.reason}</Typography>,
      },
      {
        label: "Period",
        displayItem: <Typography>{roleRequest.period} days</Typography>,
        editItem: (
          <TextField
            fullWidth
            id="period"
            name="period"
            label="Days"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            value={formik.values.period}
            onChange={formik.handleChange}
            error={formik.touched.period && Boolean(formik.errors.period)}
            helperText={formik.touched.period && formik.errors.period}
            size="small"
          />
        ),
      },
      {
        label: "Expiration Date",
        displayItem: <Typography>{expirationDate}</Typography>,
      },
    ],
  }

  return <DiMaDetailsContent {...detailsContentProps} />
}
