import { StatusCodes } from "http-status-codes"
import { useEffect, useState } from "react"
import { DeviceCategoryGet } from "../app/models/DeviceCategory"
import { digitalManagerApi } from "../services/api"

export function useDeviceCategories() {
  const [deviceCategoriesList, setDeviceCategoriesList] = useState<DeviceCategoryGet[]>(
    []
  )
  const [isLoadingDeviceCategories, setIsLoadingDeviceCategories] =
    useState<boolean>(true)

  useEffect(() => {
    setIsLoadingDeviceCategories(true)
    setDeviceCategoriesList([])
    digitalManagerApi
      .get<DeviceCategoryGet[]>(`/api/v1/deviceCategories`)
      .then((res) => {
        if (res.status === StatusCodes.OK) {
          setDeviceCategoriesList(res.data)
        }
      })
      .finally(() => {
        setIsLoadingDeviceCategories(false)
      })
  }, [])

  return {
    deviceCategoriesList,
    isLoadingDeviceCategories,
  }
}
