import { TabContext, TabList } from "@mui/lab"
import Box from "@mui/material/Box"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom"
import { digitalManagerApi } from "../../../../services/api"
import { BackToURL } from "../../../components/BackToURL"
import {
  DiMaDetailsHeader,
  DiMaDetailsHeaderProps,
} from "../../../components/DiMaDetailsHeader"
import { DiMaProgress } from "../../../components/DiMaProgress"
import { DiMaTabPanel } from "../../../components/DiMaTabPanel"
import { LinkTab } from "../../../components/LinkTab"
import { UserContext } from "../../../context/UserContext"
import {
  AccessLevel,
  DimaFeatureShortNameEnum,
  hasUserAccessToFeature,
} from "../../../models/Authorization"
import { UserGet, UserInfo } from "../../../models/User"
import { UserApproverRoles } from "./approver_roles_tab/UserApproverRoles"
import { UserApplicationsPermissions } from "./apps_permissions_tab/UserApplicationsPermissions"
import { UserApplicationsRoles } from "./apps_roles_tab/UserApplicationsRoles"
import { UserBackofficeAccess } from "./backoffice_access_tab/UserBackofficeAccess"
import { UserDetails } from "./details_tab/UserDetails"
import { UserApplications } from "./applications_tab/UserApplications"

const returnURL = "/users"

const userHasRoleManagerReadAccess = (user: UserInfo | undefined) =>
  user?.authorizations.applications.find((app) => app.featuresAccesses["RM"]) != null

const userHasApplicationManagementReadAccess = (user: UserInfo | undefined) =>
  user?.authorizations.applications.find((app) => app.featuresAccesses["AM"]) != null

export function UserContainer() {
  const { user, isLoadingUser } = useContext(UserContext)

  const { userId } = useParams()
  const { hash } = useLocation()
  const navigate = useNavigate()

  const [activeTab, setActiveTab] = useState<string>("#details")
  const [selectedUser, setSelectedUser] = useState<UserGet>()

  useEffect(() => {
    if (userId) {
      digitalManagerApi
        .get<UserGet>(`/api/v1/users/${userId}`)
        .then((res) => {
          setSelectedUser(res.data)
        })
        .catch(() => {
          navigate(returnURL)
        })
    }
  }, [navigate, userId])

  useEffect(() => {
    if (hash) {
      setActiveTab(hash)
    }
  }, [hash])

  const handleChange = useCallback((_: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue)
  }, [])

  if (!userId) {
    return <Navigate to={returnURL} />
  }

  if (!selectedUser || !user || isLoadingUser) {
    return <DiMaProgress />
  }

  const userHasBackofficeReadAccess = hasUserAccessToFeature(
    user.authorizations,
    DimaFeatureShortNameEnum.BA,
    AccessLevel.Read
  )

  const detailsHeaderProps: DiMaDetailsHeaderProps = {
    avatar:
      selectedUser.first && selectedUser.last
        ? selectedUser.first.substring(0, 1) + selectedUser.last.substring(0, 1)
        : "US",
    title:
      selectedUser.first && selectedUser.last
        ? `${selectedUser.first} ${selectedUser.last}`
        : "No name available",
    label: "User ID",
    subtitle: selectedUser.id,
  }

  return (
    <>
      <BackToURL url={returnURL} label="Back to users" />

      {selectedUser && (
        <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
          <DiMaDetailsHeader {...detailsHeaderProps} />
          <TabContext value={activeTab}>
            <Box className="DiMaBox-tabs">
              <TabList onChange={handleChange}>
                <LinkTab key="details" label="Details" value="#details" />
                {userHasRoleManagerReadAccess(user) && (
                  <LinkTab label="Apps roles" value="#apps-roles" />
                )}
                {userHasRoleManagerReadAccess(user) && (
                  <LinkTab label="Apps permissions" value="#apps-permissions" />
                )}
                {userHasApplicationManagementReadAccess(user) && (
                  <LinkTab label="Approver roles" value="#approver-roles" />
                )}
                {userHasRoleManagerReadAccess(user) && (
                  <LinkTab label="Applications" value="#applications" />
                )}
                {userHasBackofficeReadAccess && (
                  <LinkTab label="Backoffice access" value="#backoffice-access" />
                )}
              </TabList>
            </Box>
            <DiMaTabPanel label="#details" activeTab={activeTab} key="details">
              <UserDetails
                user={selectedUser}
                loggedUser={user}
                setSelectedUser={setSelectedUser}
              />
            </DiMaTabPanel>
            <DiMaTabPanel label="#apps-roles" activeTab={activeTab}>
              <UserApplicationsRoles />
            </DiMaTabPanel>
            <DiMaTabPanel label="#apps-permissions" activeTab={activeTab}>
              <UserApplicationsPermissions />
            </DiMaTabPanel>
            <DiMaTabPanel label="#approver-roles" activeTab={activeTab}>
              <UserApproverRoles />
            </DiMaTabPanel>
            <DiMaTabPanel label="#applications" activeTab={activeTab}>
              <UserApplications />
            </DiMaTabPanel>
            <DiMaTabPanel label="#backoffice-access" activeTab={activeTab}>
              <UserBackofficeAccess />
            </DiMaTabPanel>
          </TabContext>
        </Box>
      )}
    </>
  )
}
