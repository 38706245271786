import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight"
import CancelIcon from "@mui/icons-material/Cancel"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CloseIcon from "@mui/icons-material/Close"
import FeedbackIcon from "@mui/icons-material/Feedback"
import ReportProblemIcon from "@mui/icons-material/ReportProblem"
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore"
import { LoadingButton } from "@mui/lab"
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { StatusCodes } from "http-status-codes"
import { useCallback, useState } from "react"
import { toast } from "react-toastify"
import { digitalManagerApi } from "../../../../../services/api"
import {
  ApplicationRoleApproverGet,
  ApplicationRoleApproverTypeEnum,
} from "../../../../models/ApplicationRoleApprover"
import { Guid } from "../../../../models/CustomType"
import { RoleRequestGet } from "../../../../models/RoleRequest"
import {
  RoleRequestApproval,
  RoleRequestApprovalActionEnum,
  RoleRequestApprovalStatusEnum,
} from "../../../../models/RoleRequestApproval"
import { getRoleRequestApprovalStatusProps } from "../../../../utils/getRoleRequestApprovalStatusProps"
import { DiMaApprovalTooltip } from "../../../partials/DiMaApprovalTooltip"

interface ConfirmDialogProps {
  open: boolean
  roleRequestId?: Guid
  rraId?: Guid
  roleRequestAction?: RoleRequestApprovalActionEnum
  propertyName?: string
  propertyValue?: string
}

interface CommentDialogProps {
  open: boolean
  rraStatus: RoleRequestApprovalStatusEnum
  rraComment: string
}

interface ForwardMessage {
  message: string
  label: string
}

const ulStyle = {
  margin: "2px",
  paddingRight: "15px",
  maxHeight: "100px",
  overflow: "auto",
}

const FORWARD_MESSAGE_DEFAULT_LABEL = "Insert message here"
const FORWARD_MESSAGE_MAX_CHARS = 150
const SHADE = 500

const fromActionVerbToActionName = (action: RoleRequestApprovalActionEnum) => {
  switch (action) {
    case RoleRequestApprovalActionEnum.Approve:
      return "approval"
    case RoleRequestApprovalActionEnum.Reject:
      return "rejection"
    case RoleRequestApprovalActionEnum.Forward:
      return "forwarding"
    default:
      return ""
  }
}

const fromStatusVerbToApproverName = (status: RoleRequestApprovalStatusEnum | null) => {
  switch (status) {
    case RoleRequestApprovalStatusEnum.Approved:
      return "approver"
    case RoleRequestApprovalStatusEnum.Rejected:
      return "rejecter"
    case RoleRequestApprovalStatusEnum.Forwarded:
      return "forwarder"
    default:
      return ""
  }
}

export function RoleRequestApprovals(props: {
  readonly roleRequest: RoleRequestGet
  readonly reloadRoleRequest: () => void
  readonly isLoadingApprovals: boolean
}) {
  const { roleRequest, reloadRoleRequest, isLoadingApprovals } = props
  const applicationId = props.roleRequest.application.id
  const roleId = props.roleRequest.role.id
  const roleRequestId = props.roleRequest.id

  const [isApproving, setIsApproving] = useState<boolean>(false)
  const [forwardBackedupArray, setForwardBackedupArray] = useState<Array<Guid>>([])

  const [confirmDialog, setConfirmDialog] = useState<ConfirmDialogProps>({
    open: false,
  })

  const [commentDialog, setCommentDialog] = useState<CommentDialogProps>({
    open: false,
    rraStatus: RoleRequestApprovalStatusEnum.Open,
    rraComment: "",
  })

  const [isLoadingForwarded, setIsLoadingForwarded] = useState<boolean>(false)
  const [forwardListError, setForwardListError] = useState<boolean>(false)
  const [forwardArray, setForwardArray] = useState<string[] | null>([])

  const [forwardMessage, setForwardMessage] = useState<ForwardMessage>({
    message: "",
    label: FORWARD_MESSAGE_DEFAULT_LABEL,
  })

  const renderRraStatusChip = useCallback(
    (rra: RoleRequestApproval) =>
      rra.status === RoleRequestApprovalStatusEnum.Approved ||
      rra.status === RoleRequestApprovalStatusEnum.Rejected ||
      (rra.status === RoleRequestApprovalStatusEnum.Forwarded &&
        rra.userApproverType !== ApplicationRoleApproverTypeEnum.Consulted) ||
      rra.userApproverType === null ||
      rra.userApproverType === ApplicationRoleApproverTypeEnum.Informed ||
      (rra.userApproverType === ApplicationRoleApproverTypeEnum.Consulted &&
        rra.status !== RoleRequestApprovalStatusEnum.Forwarded),
    []
  )

  const retrieveForwarded = useCallback(
    (propertyName: string, propertyValue: string) => {
      setIsLoadingForwarded(true)

      const url =
        propertyName && propertyValue
          ? `/api/v1/applications/${applicationId}/roles/${roleId}/approvers?approverType=${ApplicationRoleApproverTypeEnum.Consulted}&propertyName=${propertyName}&propertyValue=${propertyValue}`
          : `/api/v1/applications/${applicationId}/roles/${roleId}/approvers?approverType=${ApplicationRoleApproverTypeEnum.Consulted}`

      digitalManagerApi
        .get<ApplicationRoleApproverGet[]>(url)
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            const tempForwardList: string[] = []
            for (const approver of res.data) {
              tempForwardList.push(approver.user.username)
            }
            setForwardArray(tempForwardList)
            if (tempForwardList.length === 0) {
              setForwardListError(true)
            } else {
              setForwardListError(false)
            }
          }
        })
        .catch(() => {
          setForwardArray(null)
          setForwardListError(true)
        })
        .finally(() => {
          setIsLoadingForwarded(false)
        })
    },
    [applicationId, roleId]
  )

  const openDialogOnClick = useCallback(
    (rra: RoleRequestApproval, rraAction: RoleRequestApprovalActionEnum) => {
      setConfirmDialog({
        roleRequestId,
        open: true,
        rraId: rra.id,
        roleRequestAction: rraAction,
        propertyName: RoleRequestApprovalActionEnum.Forward
          ? rra.property.name
          : undefined,
        propertyValue: RoleRequestApprovalActionEnum.Forward
          ? rra.property.label
          : undefined,
      })
    },
    [roleRequestId]
  )

  const resetForwardMessage = useCallback(() => {
    setForwardMessage({
      message: "",
      label: FORWARD_MESSAGE_DEFAULT_LABEL,
    })
  }, [])

  const setConfirmDialogOpenFalse = useCallback(() => {
    setConfirmDialog({ ...confirmDialog, open: false })
  }, [confirmDialog])

  const setCommentDialogOpenFalse = useCallback(() => {
    setCommentDialog({ ...commentDialog, open: false })
  }, [commentDialog])

  const formatMessageReset = useCallback(() => {
    if (forwardMessage.message === "") {
      resetForwardMessage()
    }
  }, [forwardMessage.message, resetForwardMessage])

  const setCommentDialogOpenTrueCompletion = useCallback(
    (rra: RoleRequestApproval) => () =>
      setCommentDialog({
        open: true,
        rraStatus: rra.status,
        rraComment: rra.completionComment ?? rra.forwardingComment,
      }),
    [setCommentDialog]
  )
  const setForwardBackedupArrayId = useCallback(
    (rra: RoleRequestApproval) => () => {
      setForwardBackedupArray([...forwardBackedupArray, rra.id])
    },
    [forwardBackedupArray]
  )

  const openDialogOnClickApprove = useCallback(
    (rra: RoleRequestApproval) => () => {
      openDialogOnClick(rra, RoleRequestApprovalActionEnum.Approve)
    },
    [openDialogOnClick]
  )

  const openDialogOnClickReject = useCallback(
    (rra: RoleRequestApproval) => () => {
      openDialogOnClick(rra, RoleRequestApprovalActionEnum.Reject)
    },
    [openDialogOnClick]
  )

  const setCommentDialogOpenTrueForwarding = useCallback(
    (rra: RoleRequestApproval) => () =>
      setCommentDialog({
        open: true,
        rraStatus: rra.status,
        rraComment: rra.forwardingComment ?? "",
      }),
    []
  )

  const setForwardBackedupArrayUpdate = useCallback(
    (rra: RoleRequestApproval) => () => {
      const updatedArr = [...forwardBackedupArray]
      updatedArr.splice(forwardBackedupArray.indexOf(rra.id), 1)
      setForwardBackedupArray(updatedArr)
    },
    [forwardBackedupArray]
  )

  const resetForwardMessageApprove = useCallback(
    (rra: RoleRequestApproval) => () => {
      resetForwardMessage()
      openDialogOnClick(rra, RoleRequestApprovalActionEnum.Approve)
    },
    [openDialogOnClick, resetForwardMessage]
  )

  const resetForwardMessageReject = useCallback(
    (rra: RoleRequestApproval) => () => {
      resetForwardMessage()
      openDialogOnClick(rra, RoleRequestApprovalActionEnum.Reject)
    },
    [openDialogOnClick, resetForwardMessage]
  )

  const retrieveForwardedForward = useCallback(
    (rra: RoleRequestApproval) => () => {
      retrieveForwarded(rra.property.name, rra.property.value)
      resetForwardMessage()
      openDialogOnClick(rra, RoleRequestApprovalActionEnum.Forward)
    },
    [openDialogOnClick, resetForwardMessage, retrieveForwarded]
  )

  const onClick = useCallback(() => {
    setIsApproving(true)

    digitalManagerApi
      .put(
        `/api/v1/roleRequests/${confirmDialog.roleRequestId}/approvals/${confirmDialog.rraId}`,
        { comment: forwardMessage.message },
        {
          params: {
            action: confirmDialog.roleRequestAction,
          },
        }
      )
      .then(() => {
        toast.success("Action completed")
        reloadRoleRequest()
      })
      .catch(() => {
        toast.error("Cannot complete the action")
      })
      .finally(() => {
        setConfirmDialog({ ...confirmDialog, open: false })
        setIsApproving(false)
      })
  }, [confirmDialog, forwardMessage.message, reloadRoleRequest])

  const onInput = useCallback((e) => {
    const textFieldValue = (e.target as HTMLInputElement).value
    setForwardMessage({
      message: textFieldValue,
      label: `Remaining characters: ${
        FORWARD_MESSAGE_MAX_CHARS - textFieldValue.length
      }/${FORWARD_MESSAGE_MAX_CHARS}`,
    })
  }, [])

  return (
    <Paper>
      <Card>
        <CardHeader title="Approvals" />
        <CardContent
          sx={{
            padding: 0,
            paddingLeft: "16px",
            paddingRight: "16px",
          }}
        >
          <Divider />
          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
            }}
          >
            {roleRequest.approvals.map((rra: RoleRequestApproval) => (
              <ListItem key={rra.id} divider>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={5}>
                      <Stack direction="row" spacing={1}>
                        <Typography variant="h6">
                          {rra.property.name ? rra.property.name + ":" : "Generic"}
                        </Typography>
                        <Typography variant="body1">
                          {rra.property.label ? rra.property.label : ""}
                          {!rra.property.label && rra.property.name ? "Generic" : ""}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
                      {renderRraStatusChip(rra) && (
                        <>
                          {!forwardBackedupArray.includes(rra.id) && (
                            <>
                              <DiMaApprovalTooltip
                                roleRequestApproval={rra}
                                applicationId={roleRequest.application.id}
                                roleId={roleRequest.role.id}
                              >
                                <Chip
                                  {...getRoleRequestApprovalStatusProps(rra.status)}
                                  sx={{ width: "95px" }}
                                />
                              </DiMaApprovalTooltip>
                              {(rra.completionComment || rra.forwardingComment) && (
                                <IconButton
                                  onClick={setCommentDialogOpenTrueCompletion(rra)}
                                >
                                  <FeedbackIcon />
                                </IconButton>
                              )}
                              {rra.status === RoleRequestApprovalStatusEnum.Forwarded &&
                                (rra.userApproverType ===
                                  ApplicationRoleApproverTypeEnum.Approver ||
                                  rra.userApproverType ===
                                    ApplicationRoleApproverTypeEnum.BackupApprover) && (
                                  <IconButton
                                    aria-label="TemporaryRestoreForwardButton"
                                    onClick={setForwardBackedupArrayId(rra)}
                                  >
                                    <SettingsBackupRestoreIcon />
                                  </IconButton>
                                )}
                            </>
                          )}
                          {forwardBackedupArray.includes(rra.id) && (
                            <>
                              <Stack direction="row" spacing={1}>
                                <LoadingButton
                                  startIcon={<CheckCircleIcon />}
                                  onClick={openDialogOnClickApprove(rra)}
                                  variant="contained"
                                  color="success"
                                  loading={false}
                                  disabled={isApproving || isLoadingApprovals}
                                >
                                  Approve
                                </LoadingButton>
                                <LoadingButton
                                  startIcon={<CancelIcon />}
                                  onClick={openDialogOnClickReject(rra)}
                                  variant="contained"
                                  color="error"
                                  loading={false}
                                  disabled={isApproving || isLoadingApprovals}
                                >
                                  Reject
                                </LoadingButton>
                              </Stack>
                              {rra.forwardingComment && (
                                <IconButton
                                  onClick={setCommentDialogOpenTrueForwarding(rra)}
                                >
                                  <FeedbackIcon />
                                </IconButton>
                              )}
                              <IconButton
                                aria-label="TemporaryRestoreForwardButton"
                                onClick={setForwardBackedupArrayUpdate(rra)}
                              >
                                <SettingsBackupRestoreIcon />
                              </IconButton>
                            </>
                          )}
                        </>
                      )}
                      {!renderRraStatusChip(rra) && (
                        <Stack direction="row" spacing={1}>
                          <LoadingButton
                            startIcon={<CheckCircleIcon />}
                            onClick={resetForwardMessageApprove(rra)}
                            variant="contained"
                            color="success"
                            loading={false}
                            disabled={isApproving || isLoadingApprovals}
                          >
                            Approve
                          </LoadingButton>
                          <LoadingButton
                            startIcon={<CancelIcon />}
                            onClick={resetForwardMessageReject(rra)}
                            variant="contained"
                            color="error"
                            loading={false}
                            disabled={isApproving || isLoadingApprovals}
                          >
                            Reject
                          </LoadingButton>
                          {!(
                            rra.userApproverType ===
                              ApplicationRoleApproverTypeEnum.Consulted &&
                            rra.status === RoleRequestApprovalStatusEnum.Forwarded
                          ) && (
                            <LoadingButton
                              startIcon={<ArrowCircleRightIcon />}
                              onClick={retrieveForwardedForward(rra)}
                              variant="contained"
                              color="info"
                              loading={false}
                              disabled={isApproving || isLoadingApprovals}
                            >
                              Forward
                            </LoadingButton>
                          )}
                        </Stack>
                      )}
                      {rra.userApproverType ===
                        ApplicationRoleApproverTypeEnum.Consulted &&
                        rra.status === RoleRequestApprovalStatusEnum.Forwarded && (
                          <>
                            {rra.forwardingComment && (
                              <IconButton
                                onClick={setCommentDialogOpenTrueForwarding(rra)}
                              >
                                <FeedbackIcon />
                              </IconButton>
                            )}
                          </>
                        )}
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
            ))}
          </List>
          <Dialog
            id="confirm-dialog"
            maxWidth="xs"
            open={confirmDialog.open}
            onClose={setConfirmDialogOpenFalse}
          >
            <DialogTitle>
              {!(
                forwardListError &&
                confirmDialog.roleRequestAction === RoleRequestApprovalActionEnum.Forward
              ) &&
                !isLoadingForwarded && (
                  <>
                    Are you sure to
                    {" " +
                      confirmDialog.roleRequestAction
                        ?.toString()
                        .toLocaleLowerCase()}{" "}
                    the role request?
                  </>
                )}
            </DialogTitle>
            <DialogContent>
              <DialogContentText component={"span"}>
                {!(
                  forwardListError &&
                  confirmDialog.roleRequestAction ===
                    RoleRequestApprovalActionEnum.Forward
                ) &&
                  !isLoadingForwarded && (
                    <>
                      This operation cannot be undone.
                      <br />
                      <br />
                    </>
                  )}
                {confirmDialog.roleRequestAction ===
                  RoleRequestApprovalActionEnum.Forward && (
                  <>
                    {isLoadingForwarded && (
                      <Grid container direction="row" justifyContent="center">
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                          <CircularProgress />
                        </Grid>
                      </Grid>
                    )}
                    {!isLoadingForwarded && !forwardArray && (
                      <>
                        <br />
                        <Grid container direction="row" justifyContent="center">
                          <Grid item xs={12} style={{ textAlign: "center" }}>
                            <ReportProblemIcon color="error" fontSize="large" />
                          </Grid>
                          <Grid item>
                            <Typography color="error">
                              Error: cannot retrieve consulted users.
                            </Typography>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    {!isLoadingForwarded && forwardArray && forwardArray.length === 0 && (
                      <>
                        <br />
                        <Grid container direction="row" justifyContent="center">
                          <Grid item xs={12} style={{ textAlign: "center" }}>
                            <ReportProblemIcon color="error" fontSize="large" />
                          </Grid>
                          <Grid item>
                            <Typography color="error" align="center">
                              Cannot forward the request
                              {confirmDialog.propertyName &&
                                confirmDialog.propertyValue && (
                                  <>
                                    {" "}
                                    with property <i>{confirmDialog.propertyName}</i> and
                                    value <i>{confirmDialog.propertyValue}</i>
                                  </>
                                )}{" "}
                              because there are no consulted users that can approve it.
                            </Typography>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    {!isLoadingForwarded && forwardArray && forwardArray.length !== 0 && (
                      <>
                        The request will be forwarded to:
                        <ul style={ulStyle}>
                          {forwardArray.map((name: string) => (
                            <li key={name}>{name}</li>
                          ))}
                        </ul>
                        <br />
                      </>
                    )}
                  </>
                )}
                {!(
                  confirmDialog.roleRequestAction ===
                    RoleRequestApprovalActionEnum.Forward &&
                  !(!isLoadingForwarded && forwardArray && forwardArray.length !== 0)
                ) && (
                  <>
                    Add a message to the{" "}
                    {confirmDialog.roleRequestAction &&
                      fromActionVerbToActionName(confirmDialog.roleRequestAction)}{" "}
                    (optional):
                    <TextField
                      fullWidth
                      multiline
                      sx={{ mt: "10px" }}
                      label={forwardMessage.label}
                      variant="filled"
                      inputProps={{ maxLength: FORWARD_MESSAGE_MAX_CHARS }}
                      onInput={onInput}
                      onBlur={formatMessageReset}
                    />
                  </>
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={setConfirmDialogOpenFalse} disabled={isApproving}>
                Cancel
              </Button>
              {!(
                forwardListError &&
                confirmDialog.roleRequestAction === RoleRequestApprovalActionEnum.Forward
              ) &&
                !isLoadingForwarded && (
                  <LoadingButton
                    onClick={onClick}
                    autoFocus
                    variant="contained"
                    color="info"
                    disabled={isApproving}
                    loading={isApproving}
                  >
                    Confirm
                  </LoadingButton>
                )}
            </DialogActions>
          </Dialog>

          <Dialog
            id="comment-dialog"
            maxWidth="xs"
            open={commentDialog.open}
            onClose={setCommentDialogOpenFalse}
          >
            <DialogTitle sx={{ paddingRight: 8 }}>
              Message from the {fromStatusVerbToApproverName(commentDialog.rraStatus)}:
              <IconButton
                aria-label="close"
                onClick={setCommentDialogOpenFalse}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[SHADE],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>{" "}
            <DialogContent>
              <DialogContentText component={"span"} sx={{ overflowWrap: "break-word" }}>
                {commentDialog.rraComment}
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </CardContent>
      </Card>
    </Paper>
  )
}
