import { TabContext, TabList } from "@mui/lab"
import { Box, Grid } from "@mui/material"
import { useCallback, useContext, useEffect, useState } from "react"
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom"
import { UserContext } from "../../../context/UserContext"
import { FactoryGet } from "../../../models/Factory"
import { digitalManagerApi } from "../../../../services/api"
import { DiMaProgress } from "../../../components/DiMaProgress"
import { DiMaDetailsHeader, DiMaDetailsHeaderProps } from "../../../components/DiMaDetailsHeader"
import { BackToURL } from "../../../components/BackToURL"
import { LinkTab } from "../../../components/LinkTab"
import { DiMaTabPanel } from "../../../components/DiMaTabPanel"
import { FactoryDetails } from "./details_tab/FactoryDetails"

export function FactoryContainer() {
  const { user, isLoadingUser } = useContext(UserContext)

  const { factoryId } = useParams()
  const [activeTab, setActiveTab] = useState<string>("#details")
  const [factory, setFactory] = useState<FactoryGet>()
  const navigate = useNavigate()
  const { hash } = useLocation()

  const returnURL = `/test-reports#factories`
  const AVATAR_LENGTH = 2

  const handleChange = useCallback((_: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue)
  }, [])

  useEffect(() => {
    if (factoryId) {
      digitalManagerApi
        .get<FactoryGet>(`/api/v1/factories/${factoryId}`)
        .then((res) => {
          setFactory(res.data)
        })
        .catch((err) => {
          if (err.response.status) {
            navigate(returnURL)
          }
        })
    }
  }, [factoryId, navigate, returnURL])

  useEffect(() => {
    if (hash) {
      setActiveTab(hash)
    }
  }, [hash])

  if (!factoryId) {
    return <Navigate to="/test-reports#factories" />
  }

  if (!factory || isLoadingUser || !user) {
    return <DiMaProgress />
  }

  const detailsHeaderProps: DiMaDetailsHeaderProps = {
    avatar: factory.name.substring(0, AVATAR_LENGTH),
    title: factory.name,
    label: "Factory ID",
    subtitle: factory.id,
  }

  return (
    <>
      <BackToURL url={returnURL} label="Back to factories" />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <Grid container>
          <Grid item xs={10}>
            <DiMaDetailsHeader {...detailsHeaderProps} />
          </Grid>
        </Grid>
        <TabContext value={activeTab}>
          <Box className="DiMaBox-tabs">
            <TabList onChange={handleChange}>
              <LinkTab label="Details" value="#details" />
            </TabList>
          </Box>
          <DiMaTabPanel label="#details" activeTab={activeTab}>
            <FactoryDetails
              factory={factory}
              user={user}
              setFactory={setFactory}
            />
          </DiMaTabPanel>
        </TabContext>
      </Box>
    </>
  )
}
