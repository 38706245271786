import { TabContext, TabList } from "@mui/lab"
import { Box, Grid } from "@mui/material"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom"
import { digitalManagerApi } from "../../../../../services/api"
import { BackToURL } from "../../../../components/BackToURL"
import {
  DiMaDetailsHeader,
  DiMaDetailsHeaderProps,
} from "../../../../components/DiMaDetailsHeader"
import { DiMaProgress } from "../../../../components/DiMaProgress"
import { DiMaTabPanel } from "../../../../components/DiMaTabPanel"
import { LinkTab } from "../../../../components/LinkTab"
import { UserContext } from "../../../../context/UserContext"
import { ApplicationPermissionUserGet } from "../../../../models/ApplicationPermissionUser"
import { UserApplicationPermissionDetails } from "./details_tab/UserApplicationPermissionDetails"
import { UserApplicationPermissionProperties } from "./properties_tab/UserApplicationPermissionProperties"

export function UserApplicationPermissionContainer() {
  const { userId, applicationId, permissionId } = useParams()
  const { user, isLoadingUser } = useContext(UserContext)

  const [activeTab, setActiveTab] = useState<string>("#details")
  const [userApplicationPermission, setUserApplicationPermission] =
    useState<ApplicationPermissionUserGet>()
  const [reloadEntity, setReloadEntity] = useState<boolean>(true)
  const { hash } = useLocation()
  const navigate = useNavigate()
  const returnURL = `/users/${userId}#apps-permissions`

  const AVATAR_LENGTH = 2

  useEffect(() => {
    if (userId && applicationId && permissionId && reloadEntity === true) {
      digitalManagerApi
        .get<ApplicationPermissionUserGet>(
          `/api/v1/users/${userId}/applications/${applicationId}/permissions/${permissionId}`
        )
        .then((res) => {
          setUserApplicationPermission(res.data)
          setReloadEntity(false)
        })
        .catch(() => {
          navigate(returnURL)
        })
    }
  }, [applicationId, navigate, permissionId, reloadEntity, returnURL, userId])

  const reloadUserApplicationPermission = useCallback(() => setReloadEntity(true), [])

  useEffect(() => {
    if (hash) {
      setActiveTab(hash)
    }
  }, [hash])

  const handleChange = useCallback((_: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue)
  }, [])

  if (!userId || !applicationId || !permissionId) {
    return <Navigate to={returnURL} />
  }

  if (!userApplicationPermission || !user || isLoadingUser) {
    return <DiMaProgress />
  }

  const detailsHeaderProps: DiMaDetailsHeaderProps = {
    avatar: userApplicationPermission.permission.name.substring(0, AVATAR_LENGTH),
    title: userApplicationPermission.permission.name,
    label: "Permission ID",
    subtitle: userApplicationPermission.permission.id,
  }

  return (
    <>
      <BackToURL url={returnURL} label="Back to user apps permissions" />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <Grid container>
          <Grid item xs={10}>
            <DiMaDetailsHeader {...detailsHeaderProps} />
          </Grid>
        </Grid>
        <TabContext value={activeTab}>
          <Box className="DiMaBox-tabs">
            <TabList onChange={handleChange}>
              <LinkTab label="Details" value="#details" />
              <LinkTab label="Properties" value="#properties" />
            </TabList>
          </Box>
          <DiMaTabPanel label="#details" activeTab={activeTab}>
            <UserApplicationPermissionDetails
              userApplicationPermission={userApplicationPermission}
              setUserApplicationPermission={setUserApplicationPermission}
            />
          </DiMaTabPanel>
          <DiMaTabPanel label="#properties" activeTab={activeTab}>
            <UserApplicationPermissionProperties
              userApplicationPermission={userApplicationPermission}
              reloadUserApplicationPermission={reloadUserApplicationPermission}
              user={user}
            />
          </DiMaTabPanel>
        </TabContext>
      </Box>
    </>
  )
}
