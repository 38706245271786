import { MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material"
import { useFormik } from "formik"
import { StatusCodes } from "http-status-codes"
import { useCallback } from "react"
import { toast } from "react-toastify"
import { mixed, object } from "yup"
import { digitalManagerApi } from "../../../../../../../services/api"
import {
  DiMaDetailsContent,
  DiMaDetailsContentProps,
} from "../../../../../../components/DiMaDetailsContent"
import {
  ApplicationRoleApproverGet,
  ApplicationRoleApproverPut,
  ApplicationRoleApproverTypeEnum,
} from "../../../../../../models/ApplicationRoleApprover"
import {
  AccessLevel,
  DimaFeatureShortNameEnum,
  hasUserAccessToApplicationFeature,
} from "../../../../../../models/Authorization"
import { UserInfo } from "../../../../../../models/User"

const validationSchema = object({
  type: mixed<ApplicationRoleApproverTypeEnum>()
    .oneOf(Object.values(ApplicationRoleApproverTypeEnum))
    .required("Approver type field is required"),
})

export function ApplicationRoleApproverDetails(props: {
  readonly approverDetails: ApplicationRoleApproverGet
  readonly user: UserInfo
  readonly setApprover: (updatedApprover: ApplicationRoleApproverGet) => void
}) {
  const { approverDetails, user, setApprover } = props
  const returnURL = `/applications/${approverDetails.application.id}/roles/${approverDetails.role.id}#approvers`
  const isGeneric = !(
    approverDetails.properties.length > 0 && approverDetails.properties[0].name !== null
  )

  const deleteApprover = useCallback(
    () =>
      digitalManagerApi.delete(
        `api/v1/applications/${approverDetails.application.id}/roles/${approverDetails.role.id}/approvers/${approverDetails.user.id}`
      ),
    [approverDetails.application.id, approverDetails.role.id, approverDetails.user.id]
  )

  const formik = useFormik<ApplicationRoleApproverPut>({
    validationSchema,
    initialValues: {
      type: approverDetails.properties[0].approverType,
      isAutomatic: false
    },
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) =>
      digitalManagerApi
        .put<ApplicationRoleApproverGet>(
          `api/v1/applications/${approverDetails.application.id}/roles/${approverDetails.role.id}/approvers/${approverDetails.user.id}`,
          {
            type: values.type,
            isAutomatic: false
          }
        )
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            toast.success("Approver type updated")
            setApprover(res.data)
          }
        })
        .catch(() => {
          toast.error("Cannot update approver type")
          formik.resetForm()
          return Promise.reject(new Error("Cannot update approver type"))
        })
        .finally(() => {
          setSubmitting(false)
        }),
  })

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      formik.setFieldValue(event.target.name, event.target.value)
    },
    [formik]
  )

  const detailsContentProps: DiMaDetailsContentProps<ApplicationRoleApproverPut> = {
    formik,
    label: "Approver",
    canWrite: hasUserAccessToApplicationFeature(
      user.authorizations.applications,
      approverDetails.application.id,
      DimaFeatureShortNameEnum.AM,
      AccessLevel.Write
    ),
    deleteAction: deleteApprover,
    onDeleteReturnUrl: returnURL,
    isEditable: isGeneric,
    isDeletable: isGeneric,
    listItems: [
      {
        label: "Application",
        displayItem: <Typography>{approverDetails.application.shortName}</Typography>,
      },
      {
        label: "Email",
        displayItem: <Typography>{approverDetails.user.username}</Typography>,
      },
      {
        label: "Is generic",
        displayItem: <Typography>{isGeneric.toString()}</Typography>,
      },
    ],
  }

  if (isGeneric) {
    detailsContentProps.listItems.push({
      label: "Approver type",
      displayItem: <Typography>{approverDetails.properties[0].approverType}</Typography>,
      editItem: (
        <Stack direction="column">
          <Select
            id="type"
            name="type"
            value={formik.values.type}
            onChange={handleChange}
            size="small"
            displayEmpty
            fullWidth
          >
            {Object.values(ApplicationRoleApproverTypeEnum).map(
              (type: ApplicationRoleApproverTypeEnum) => (
                <MenuItem value={type} key={type}>
                  {type}
                </MenuItem>
              )
            )}
          </Select>
        </Stack>
      ),
    })
  }

  return <DiMaDetailsContent {...detailsContentProps} />
}
