import { LockOpen } from "@mui/icons-material"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material"
import { useFormik } from "formik"
import { StatusCodes } from "http-status-codes"
import { useCallback, useContext } from "react"
import { Navigate } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import { object, string } from "yup"
import abbLogo from "../../../assets/logos/abb-logo.png"
import { useAccessRequestCheck } from "../../../hooks/useAccessRequestCheck"
import { digitalManagerApi } from "../../../services/api"
import { DiMaProgress } from "../../components/DiMaProgress"
import { UserContext } from "../../context/UserContext"

const REASON_MIN_LENGTH = 30
const REASON_MAX_LENGTH = 300

const validationSchema = object({
  reason: string()
    .required("Reason field is required")
    .min(REASON_MIN_LENGTH)
    .max(REASON_MAX_LENGTH),
})

export function NotAuthorized() {
  const { user, isLoadingUser } = useContext(UserContext)

  const { existAccessRequest, setAccessRequest, isLoadingResponse } =
    useAccessRequestCheck()

  const formik = useFormik({
    validationSchema,
    validateOnMount: true,
    initialValues: {
      reason: "",
    },
    onSubmit: (values, { setSubmitting }) => {
      digitalManagerApi
        .post(`/api/v1/backOfficeAccess/requests`, { reason: values.reason })
        .then((res) => {
          if (res.status === StatusCodes.CREATED) {
            setAccessRequest()
          }
        })
        .catch(() => {
          toast.error("Cannot send backoffice access request")
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
  })

  const submitForm = useCallback(() => {
    formik.submitForm()
  }, [formik])

  if (isLoadingResponse || isLoadingUser || !user) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "50%",
        }}
      >
        <DiMaProgress />
      </Box>
    )
  }

  if (user.hasAccessToDima) {
    return <Navigate to="/" />
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "10%",
      }}
    >
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        draggable
        theme="colored"
      />
      <img src={abbLogo} />
      {!existAccessRequest ? (
        <List
          sx={{
            width: "100%",
            bgcolor: "Background.paper",
          }}
        >
          <ListItem key={0}>
            <ListItemText>
              <Typography
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "3vh",
                }}
              >
                You do not have access to Digital ELSP Manager Backoffice, please fill the
                form and request the access.
              </Typography>
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemText>
              <Grid
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Grid item xs={8}>
                  <TextField
                    placeholder="Describe why you need access to the Digital Manager backoffice, specifying which apps and features you need."
                    multiline
                    rows={4}
                    fullWidth
                    id="reason"
                    name="reason"
                    type="text"
                    value={formik.values.reason}
                    onChange={formik.handleChange}
                    size="small"
                  ></TextField>
                  {formik.dirty && formik.errors.reason && (
                    <div style={{ color: "red" }}>
                      {formik.errors.reason.charAt(0).toUpperCase() +
                        formik.errors.reason.slice(1)}
                    </div>
                  )}
                </Grid>
              </Grid>
            </ListItemText>
          </ListItem>

          <></>
        </List>
      ) : (
        <Typography
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: "10vh",
          }}
        >
          Your request has been received and its being processed.
        </Typography>
      )}

      {!existAccessRequest ? (
        <LoadingButton
          disabled={!formik.isValid}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "3%",
          }}
          onClick={submitForm}
          variant="contained"
          loading={formik.isSubmitting}
          startIcon={<LockOpen />}
        >
          Request Access
        </LoadingButton>
      ) : (
        <CheckCircleIcon fontSize="large" color="success" />
      )}
    </Box>
  )
}
