import { StatusCodes } from "http-status-codes"
import { useEffect, useState } from "react"
import { ApplicationPropertyGet } from "../app/models/ApplicationProperty"
import { Guid } from "../app/models/CustomType"
import { digitalManagerApi } from "../services/api"

export function useApplicationProperties(
  applicationId?: Guid,
  onlyRequestable = false,
  getPossibleValues = false
) {
  const [applicationProperties, setApplicationProperties] = useState<
    ApplicationPropertyGet[]
  >([])
  const [isLoadingApplicationProperties, setIsLoadingApplicationProperties] =
    useState<boolean>(true)

  useEffect(() => {
    if (applicationId) {
      digitalManagerApi
        .get<ApplicationPropertyGet[]>(
          `/api/v1/applications/${applicationId}/properties`,
          {
            params: {
              onlyRequestable,
              getPossibleValues,
            },
          }
        )
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            setApplicationProperties(res.data)
          }
        })
        .finally(() => {
          setIsLoadingApplicationProperties(false)
        })
    } else {
      setIsLoadingApplicationProperties(false)
    }
  }, [applicationId, getPossibleValues, onlyRequestable])

  return {
    applicationProperties,
    isLoadingApplicationProperties,
  }
}
