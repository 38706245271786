import { LoadingButton } from "@mui/lab"
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from "@mui/material"
import { useFormik } from "formik"
import { StatusCodes } from "http-status-codes"
import { useCallback } from "react"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { boolean, object, string } from "yup"
import { useUsers } from "../../../../../hooks/useUsers"
import { digitalManagerApi } from "../../../../../services/api"
import { BackToURL } from "../../../../components/BackToURL"
import { FileSharingPost } from "../../../../models/File"
import { UserGet } from "../../../../models/User"

const validationSchema = object({
  userId: string().required("User is required"),
  canWrite: boolean().required("Can Write is required"),
  canShare: boolean().required("Can Share is required"),
})

const returnURL = `/files`

export function FileSharing() {
  const { fileId } = useParams()
  const { usersList, isLoadingUsers } = useUsers(false)

  const formik = useFormik<FileSharingPost>({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      userId: "",
      canWrite: false,
      canShare: false,
    },
    onSubmit: (values, { setSubmitting }) =>
      digitalManagerApi
        .post(`/api/v1/files/${fileId}/sharing`, {
          userId: values.userId,
          canWrite: values.canWrite,
          canShare: values.canShare,
        })
        .then((res) => {
          if (res.status === StatusCodes.CREATED) {
            toast.success("File shared")
          }
        })
        .catch(() => {
          toast.error("Cannot share the file")
          formik.resetForm()
          return Promise.reject(new Error("Cannot share the file"))
        })
        .finally(() => {
          setSubmitting(false)
        }),
  })

  const submitForm = useCallback(() => {
    formik.submitForm()
  }, [formik])

  const setFields = useCallback(
    (_e, value) => {
      if (value) {
        formik.setFieldValue("userId", value.id)
      }
    },
    [formik]
  )

  const isOptionEqualId = useCallback((option, label) => option.id === label.id, [])

  const getOptionLabel = useCallback((option) => option.label || "", [])

  const renderInput = useCallback((params) => <TextField {...params} />, [])

  return (
    <Box>
      <BackToURL url={returnURL} label="Back to files" />
      <Paper>
        <Card>
          <CardHeader
            title="Share the file"
            action={
              <LoadingButton
                variant="contained"
                type="submit"
                loading={formik.isSubmitting}
                onClick={submitForm}
                disabled={!formik.dirty || !formik.isValid}
              >
                Save
              </LoadingButton>
            }
          />
          <CardContent
            sx={{
              padding: 0,
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
          >
            <Divider />
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
              }}
            >
              <ListItem key={0}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">User</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        loading={isLoadingUsers}
                        id="userId"
                        options={usersList.map((user: UserGet) => ({
                          label: user.username,
                          id: user.id,
                        }))}
                        size="small"
                        fullWidth
                        renderInput={renderInput}
                        isOptionEqualToValue={isOptionEqualId}
                        onChange={setFields}
                        value={undefined}
                        getOptionLabel={getOptionLabel}
                        disabled={false}
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={1}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Can Write</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Checkbox
                        id="canWrite"
                        name="canWrite"
                        checked={formik.values.canWrite}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={2}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Can Share</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Checkbox
                        id="canShare"
                        name="canShare"
                        checked={formik.values.canShare}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Paper>
    </Box>
  )
}
