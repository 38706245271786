import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive"
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff"
import UpdateIcon from "@mui/icons-material/Update"
import { ChipProps } from "@mui/material"

export function getNotificationStatusProps(
  status: "active" | "expired" | "planned"
): ChipProps {
  let props: ChipProps = {
    label: status,
    variant: "outlined",
  }

  switch (status.toLowerCase()) {
    case "active":
      props = {
        ...props,
        icon: <NotificationsActiveIcon />,
        color: "success",
      }
      break
    case "planned":
      props = { ...props, icon: <UpdateIcon />, color: "info" }
      break
    case "expired":
      props = {
        ...props,
        icon: <NotificationsOffIcon />,
        color: "error",
      }
      break
    default:
      props = { ...props, color: undefined }
  }

  return props
}
