import { TabContext, TabList } from "@mui/lab"
import { Box } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { DiMaTabPanel } from "../../components/DiMaTabPanel"
import { LinkTab } from "../../components/LinkTab"
import { TestReportSearch } from "./search_tab/TestReportSearch"
import { Factories } from "./factories_tab/Factories"

export function TestReportsContainer() {
  const [activeTab, setActiveTab] = useState<string>("#search")
  const { hash } = useLocation()

  useEffect(() => {
    if (hash) {
      setActiveTab(hash)
    }
  }, [hash])

  const handleChange = useCallback((_: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue)
  }, [])

  return (
    <TabContext value={activeTab}>
      <Box className="DiMaBox-tabs">
        <TabList onChange={handleChange}>
          <LinkTab label="Search" value="#search" />
          <LinkTab label="Factories" value="#factories" />
        </TabList>
      </Box>
      <DiMaTabPanel label="#search" activeTab={activeTab}>
        <TestReportSearch />
      </DiMaTabPanel>
      <DiMaTabPanel label="#factories" activeTab={activeTab}>
        <Factories />
      </DiMaTabPanel>
    </TabContext>
  )
}
