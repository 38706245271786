import { InteractionStatus } from "@azure/msal-browser"
import { useMsal } from "@azure/msal-react"
import { StatusCodes } from "http-status-codes"
import { createContext, ReactChild, ReactFragment, useEffect, useState } from "react"
import { digitalManagerApi } from "../../services/api"
import { hasUserAccessToDima, UserAuthorizations } from "../models/Authorization"
import { UserInfo, UserPropertiesGet } from "../models/User"

export interface UserContextType {
  isLoadingUser: boolean
  user?: UserInfo
}

export const UserContext = createContext<UserContextType>({
  isLoadingUser: true,
})

export const UserContextProvider = (props: { children: ReactChild | ReactFragment }) => {
  const { accounts, inProgress } = useMsal()
  const account = accounts?.length ? accounts[0] : undefined
  const isLoadingUser = inProgress !== InteractionStatus.None
  const [currentUser, setCurrentUser] = useState<UserPropertiesGet>()
  const [userContextState, setUserContextState] = useState<UserContextType>({
    isLoadingUser,
  })

  useEffect(() => {
    if (inProgress === InteractionStatus.None && account) {
      digitalManagerApi
        .get<UserPropertiesGet>(`/api/v1/users/currentUser/properties`, {
          params: { username: account.username },
        })
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            setCurrentUser(res.data)
          }
        })
    }
  }, [account, inProgress])

  useEffect(() => {
    if (inProgress === InteractionStatus.None && account && currentUser) {
      digitalManagerApi
        .get<UserAuthorizations>(`/api/v1/admin/currentUser/authorizations`)
        .then((res) => {
          if (res.status === StatusCodes.OK && res.data) {
            setUserContextState((currentState) => ({
              ...currentState,
              user: {
                ...account,
                ...currentUser,
                hasAccessToDima: hasUserAccessToDima(res.data),
                authorizations: {
                  applications: res.data.applications,
                  features: res.data.features,
                },
              },
            }))
          }
        })
        .catch((err) => {
          if (err.response.status === StatusCodes.UNAUTHORIZED) {
            setUserContextState((currentState) => ({
              ...currentState,
              user: {
                ...account,
                ...currentUser,
                hasAccessToDima: false,
                authorizations: {
                  applications: [],
                  features: [],
                },
              },
            }))
          }
        })
        .finally(() => {
          setUserContextState((currentState) => ({
            ...currentState,
            isLoadingUser: false,
          }))
        })
    }
  }, [account, currentUser, inProgress])

  return (
    <UserContext.Provider value={userContextState}>{props.children}</UserContext.Provider>
  )
}
