export enum ApplicationPropertyType {
  String = "String",
  Int = "Int",
  Json = "Json",
  Bool = "Bool",
  DeviceFamily = "DeviceFamily",
  SlaveId = "SlaveId",
}

export interface ApplicationPropertyGet {
  name: string
  type: ApplicationPropertyType
  isRequestable: boolean
  possibleValues: ApplicationPropertyPossibleValue[]
}

export interface ApplicationPropertyPost {
  name: string
  type: ApplicationPropertyType
  isRequestable: boolean
}

export interface ApplicationPropertyPut {
  type: ApplicationPropertyType
  isRequestable: boolean
}

export interface ApplicationPropertyDetail {
  id?: string
  name: string
  value: string
  label?: string
}

export interface ApplicationPropertyPossibleValue {
  value: string
  label: string
}
