import { TabContext, TabList } from "@mui/lab"
import { Box, Grid } from "@mui/material"
import { useCallback, useContext, useEffect, useState } from "react"
import { Navigate, useLocation, useParams } from "react-router-dom"
import { digitalManagerApi } from "../../../../../services/api"
import { BackToURL } from "../../../../components/BackToURL"
import {
  DiMaDetailsHeader,
  DiMaDetailsHeaderProps,
} from "../../../../components/DiMaDetailsHeader"
import { DiMaProgress } from "../../../../components/DiMaProgress"
import { DiMaTabPanel } from "../../../../components/DiMaTabPanel"
import { LinkTab } from "../../../../components/LinkTab"
import { UserContext } from "../../../../context/UserContext"
import { ApplicationPermissionGet } from "../../../../models/ApplicationPermission"
import { ApplicationPermissionDetails } from "./details_tab/ApplicationPermissionDetails"

export function ApplicationPermissionContainer() {
  const { applicationId, permissionId } = useParams()
  const { user, isLoadingUser } = useContext(UserContext)
  const [activeTab, setActiveTab] = useState<string>("#details")
  const [permission, setPermission] = useState<ApplicationPermissionGet>()
  const { hash } = useLocation()
  const returnURL = `/applications/${applicationId}#permissions`

  const AVATAR_LENGTH = 2

  useEffect(() => {
    if (applicationId && permissionId) {
      digitalManagerApi
        .get<ApplicationPermissionGet>(
          `/api/v1/applications/${applicationId}/permissions/${permissionId}`
        )
        .then((res) => {
          setPermission(res.data)
        })
    }
  }, [applicationId, permissionId])

  useEffect(() => {
    if (hash) {
      setActiveTab(hash)
    }
  }, [hash])

  const handleChange = useCallback((_: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue)
  }, [])

  if (!applicationId || !permissionId) {
    return <Navigate to="/applications" />
  }

  if (!permission || isLoadingUser || !user) {
    return <DiMaProgress />
  }

  const detailsHeaderProps: DiMaDetailsHeaderProps = {
    avatar: permission.name.substring(0, AVATAR_LENGTH),
    title: permission.name,
    label: "Permission ID",
    subtitle: permission.id,
  }

  return (
    <>
      <BackToURL url={returnURL} label="Back to permissions" />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <Grid container>
          <Grid item xs={10}>
            <DiMaDetailsHeader {...detailsHeaderProps} />
          </Grid>
        </Grid>
        <TabContext value={activeTab}>
          <Box className="DiMaBox-tabs">
            <TabList onChange={handleChange}>
              <LinkTab label="Details" value="#details" />
            </TabList>
          </Box>
          <DiMaTabPanel label="#details" activeTab={activeTab}>
            <ApplicationPermissionDetails
              permission={permission}
              applicationId={applicationId}
              user={user}
              setPermission={setPermission}
            />
          </DiMaTabPanel>
        </TabContext>
      </Box>
    </>
  )
}
