import { LoadingButton } from "@mui/lab"
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material"
import { useFormik } from "formik"
import { StatusCodes } from "http-status-codes"
import { SyntheticEvent, useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { boolean, object, string } from "yup"
import { useApplicationPermissions } from "../../../../../hooks/useApplicationPermissions"
import { useApplications } from "../../../../../hooks/useApplications"
import { digitalManagerApi } from "../../../../../services/api"
import { BackToURL } from "../../../../components/BackToURL"
import { ApplicationGet } from "../../../../models/Application"
import { ApplicationPermissionGet } from "../../../../models/ApplicationPermission"
import { ApplicationPermissionUserGet } from "../../../../models/ApplicationPermissionUser"
import { AccessLevel, DimaFeatureShortNameEnum } from "../../../../models/Authorization"
import { Guid } from "../../../../models/CustomType"
import { getNowDateTime, getUtcDateTime } from "../../../../utils/getFormattedDate"

const validationSchema = object({
  applicationId: string().required("Application is required"),
  permissionId: string().required("Permission is required"),
  validityStartDate: string().required("Validity Start Date is required"),
  validityEndDate: string().required("Validity End Date is required"),
  isDisabled: boolean().required("Is Disabled is required"),
})

const handleAutocompleteRenderInput = (params: AutocompleteRenderInputParams) => (
  <TextField {...params} />
)

const handleAutocompleteGetOptionLabel = (
  applicationPermissions: ApplicationPermissionGet
) => applicationPermissions.name

export function UserApplicationsPermissionCreate() {
  const { userId } = useParams()
  const navigate = useNavigate()
  const returnURL = `/users/${userId}#apps-permissions`
  const { applicationsList, isLoadingApplications } = useApplications(
    DimaFeatureShortNameEnum.RM,
    AccessLevel.Write
  )
  const [userPermissionsIds, setUserPermissionsIds] = useState<Guid[]>([])

  const formik = useFormik({
    validationSchema,
    initialValues: {
      applicationId: "",
      permissionId: "",
      validityStartDate: getNowDateTime(),
      validityEndDate: "",
      isDisabled: false,
    },
    onSubmit: (values, { setSubmitting }) => {
      const utcValidityStartDate = getUtcDateTime(values.validityStartDate)
      const utcValidityEndDate = getUtcDateTime(values.validityEndDate)

      digitalManagerApi
        .post(
          `/api/v1/users/${userId}/applications/${values.applicationId}/permissions/${values.permissionId}`,
          {
            validityStartDate: utcValidityStartDate,
            validityEndDate: utcValidityEndDate,
            isDisabled: values.isDisabled,
          }
        )
        .then((res) => {
          if (res.status === StatusCodes.CREATED) {
            toast.success("Permission added to user")
            navigate(returnURL)
          }
        })
        .catch(() => {
          toast.error("Cannot add permission to user")
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
  })

  const handleChangeFormikValues = useCallback(
    (
      _e: SyntheticEvent<Element, Event>,
      selectedApplicationPermission: ApplicationPermissionGet | null
    ) => {
      if (selectedApplicationPermission) {
        formik.setValues({
          ...formik.values,
          permissionId: selectedApplicationPermission.id,
        })
      }
    },
    [formik]
  )

  const applicationId = formik.values.applicationId

  useEffect(() => {
    if (applicationId) {
      digitalManagerApi
        .get(`/api/v1/users/${userId}/permissions`, {
          params: { applicationId },
        })
        .then((res) => {
          if (res.status === StatusCodes.OK && res.data && res.data.length) {
            setUserPermissionsIds(
              res.data.map(
                (userPermission: ApplicationPermissionUserGet) =>
                  userPermission.permission.id
              )
            )
          }
        })
    }
  }, [applicationId, userId])

  const handleAutocompleteGetOptionDisabled = useCallback(
    (option: ApplicationPermissionGet) => {
      const optionAlreadySelected = userPermissionsIds.find(
        (userPermissionId: Guid) => option.id === userPermissionId
      )
      return !!optionAlreadySelected
    },
    [userPermissionsIds]
  )

  const { applicationPermissions, isLoadingApplicationPermissions } =
    useApplicationPermissions(formik.values.applicationId)

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      formik.setFieldValue(event.target.name, event.target.value)
    },
    [formik]
  )

  const submitForm = useCallback(() => {
    formik.submitForm()
  }, [formik])

  return (
    <Box>
      <BackToURL url={returnURL} label="Back to user apps permissions" />
      <Paper>
        <Card>
          <CardHeader
            title="Add permission to user"
            action={
              <LoadingButton
                variant="contained"
                type="submit"
                loading={formik.isSubmitting}
                onClick={submitForm}
                disabled={!formik.dirty || !formik.isValid}
              >
                Save
              </LoadingButton>
            }
          />
          <CardContent
            sx={{
              padding: 0,
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
          >
            <Divider />
            <List
              sx={{
                width: "100%",
                bgcolor: "Background.paper",
              }}
            >
              <ListItem key={0}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Application</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Select
                        id="applicationId"
                        name="applicationId"
                        value={formik.values.applicationId}
                        onChange={handleChange}
                        disabled={isLoadingApplications}
                        size="small"
                        displayEmpty
                        fullWidth
                      >
                        {!isLoadingApplications &&
                          applicationsList.map((application: ApplicationGet) => (
                            <MenuItem value={application.id} key={application.id}>
                              {application.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={1}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Permission</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        id="permissionId"
                        options={applicationPermissions}
                        renderInput={handleAutocompleteRenderInput}
                        getOptionLabel={handleAutocompleteGetOptionLabel}
                        getOptionDisabled={handleAutocompleteGetOptionDisabled}
                        onChange={handleChangeFormikValues}
                        disabled={isLoadingApplicationPermissions}
                        size="small"
                        disableClearable
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={2}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Validity Start Date</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="validityStartDate"
                        name="validityStartDate"
                        type="datetime-local"
                        inputProps={{
                          min: getNowDateTime(),
                          max: "9999-12-31T23:59:59",
                        }}
                        value={formik.values.validityStartDate}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.validityStartDate &&
                          Boolean(formik.errors.validityStartDate)
                        }
                        helperText={
                          formik.touched.validityStartDate &&
                          formik.errors.validityStartDate
                        }
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={3}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Validity End Date</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="validityEndDate"
                        name="validityEndDate"
                        type="datetime-local"
                        inputProps={{
                          min: formik.values.validityStartDate,
                          max: "9999-12-31T23:59:59",
                        }}
                        value={formik.values.validityEndDate}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.validityEndDate &&
                          Boolean(formik.errors.validityEndDate)
                        }
                        helperText={
                          formik.touched.validityEndDate && formik.errors.validityEndDate
                        }
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={4}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Is Disabled</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Checkbox
                        id="isDisabled"
                        name="isDisabled"
                        checked={formik.values.isDisabled}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Paper>
    </Box>
  )
}
