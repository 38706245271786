import CloseOutlinedIcon from "@mui/icons-material/Close"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { GridColumns } from "@mui/x-data-grid"
import { useFormik } from "formik"
import { StatusCodes } from "http-status-codes"
import { Dispatch, SetStateAction, useCallback, useState } from "react"
import { toast } from "react-toastify"
import { object, string } from "yup"
import { useMacAddresses } from "../../../../hooks/useMacAddresses"
import { digitalManagerApi } from "../../../../services/api"
import { MacAddressGet } from "../../../models/SupplierPortal"
import { getBooleanFlagIcon } from "../../../utils/getBooleanFlagIcon"
import { getNiceFormattedDate } from "../../../utils/getFormattedDate"
import { EntityTab } from "../../partials/EntityTab"

function parseMacAddress(macAddress: string) {
  return macAddress.split(":").join("")
}

function isValidRange(highMacAddress: string, lowMacAddress: string) {
  const parsedhighMacAddress = "0x" + parseMacAddress(highMacAddress)
  const parsedLowMacAddress = "0x" + parseMacAddress(lowMacAddress)

  return parseInt(parsedhighMacAddress, 16) > parseInt(parsedLowMacAddress, 16)
}

const MAC_REGEX = /^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$/
const SHADE = 500

function CreateMacAddressDialog(props: {
  readonly elaborateMacAddress: boolean
  readonly reloadMacAddresses: () => void
  readonly setElaborateMacAddress: Dispatch<SetStateAction<boolean>>
}) {
  const { elaborateMacAddress, reloadMacAddresses, setElaborateMacAddress } = props
  const validationSchema = object().shape({
    lowMacAddress: string()
      .required("Please enter a mac address")
      .matches(MAC_REGEX, "Please enter a valid mac address"),

    highMacAddress: string()
      .required("Please enter a mac address")
      .matches(MAC_REGEX, "Please enter a valid mac address"),
  })
  const formik = useFormik({
    validationSchema,
    validateOnMount: true,
    initialValues: {
      highMacAddress: "",
      lowMacAddress: "",
    },
    onSubmit: (values, { setSubmitting }) => {
      digitalManagerApi
        .post(
          `/api/v1/litePanelPro/macAddresses?macAddressLow=${parseMacAddress(
            values.lowMacAddress
          )}&macAddressHigh=${parseMacAddress(values.highMacAddress)}`
        )
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            setElaborateMacAddress(false)
            toast.success("Mac adresses created")
          }
        })
        .catch(() => {
          toast.error("Cannot create mac adresses")
        })
        .finally(() => {
          setSubmitting(false)
          reloadMacAddresses()
        })
    },
  })

  const setElaborateMacAddressFalse = useCallback(() => {
    setElaborateMacAddress(false)
    formik.resetForm()
  }, [formik, setElaborateMacAddress])

  const submitForm = useCallback(() => {
    formik.submitForm()
  }, [formik])

  return (
    <Dialog open={elaborateMacAddress}>
      <DialogTitle>
        <Typography sx={{ marginRight: "10%" }}>
          {"Please write the interval of macadresses that you would create"}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={setElaborateMacAddressFalse}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[SHADE],
          }}
        >
          <CloseOutlinedIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ padding: "10px" }}>
          <Stack direction="row" spacing={2} sx={{ marginBottom: "10px" }}>
            <TextField
              fullWidth
              id="lowMacAddress"
              name="lowMacAddress"
              label="Low Mac Address"
              placeholder="1a:2b:3c:4d:5e:6a"
              value={formik.values.lowMacAddress}
              onChange={formik.handleChange}
              error={formik.touched.lowMacAddress && Boolean(formik.errors.lowMacAddress)}
              helperText={formik.touched.lowMacAddress && formik.errors.lowMacAddress}
              size="small"
            />
            <TextField
              fullWidth
              id="highMacAddress"
              name="highMacAddress"
              label="High Mac Address"
              placeholder="1a:2b:3c:4d:5e:6f"
              value={formik.values.highMacAddress}
              onChange={formik.handleChange}
              error={
                formik.touched.highMacAddress && Boolean(formik.errors.highMacAddress)
              }
              helperText={formik.touched.highMacAddress && formik.errors.highMacAddress}
              size="small"
            />
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          onClick={submitForm}
          autoFocus
          variant="text"
          color="success"
          loading={formik.isSubmitting}
          disabled={
            !formik.isValid ||
            !isValidRange(formik.values.highMacAddress, formik.values.lowMacAddress)
          }
        >
          Create
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export function MacAddress() {
  const [elaborateMacAddress, setElaborateMacAddress] = useState<boolean>(false)
  const { macAddressList, reloadMacAddresses, isLoadingMacAddress } = useMacAddresses()

  const columns: GridColumns<MacAddressGet> = [
    {
      field: "value",
      headerName: "Mac address",
      renderCell: (params) => <Typography>{params.row.value}</Typography>,
      flex: 1,
    },
    {
      field: "isReserved",
      headerName: "is Reserved",
      renderCell: (params) => (
        <Typography>{getBooleanFlagIcon(params.row.isReserved)}</Typography>
      ),
      flex: 1,
    },
    {
      field: "isUsed",
      headerName: "is Used",
      renderCell: (params) => (
        <Typography>{getBooleanFlagIcon(params.row.isUsed)}</Typography>
      ),
      flex: 1,
    },
    {
      field: "creationDate",
      headerName: "Creation date",
      renderCell: (params) => (
        <Typography>{getNiceFormattedDate(params.row.creationDate)}</Typography>
      ),
      flex: 1,
    },
  ]

  const rows = macAddressList.map((macAddress: MacAddressGet) => ({
    id: macAddress.id,
    isUsed: macAddress.isUsed,
    isReserved: macAddress.isReserved,
    creationDate: macAddress.creationDate,
    value: macAddress.value,
  }))

  const setElaborateMacAddressTrue = useCallback(() => {
    setElaborateMacAddress(true)
  }, [setElaborateMacAddress])

  return (
    <>
      <CreateMacAddressDialog
        elaborateMacAddress={elaborateMacAddress}
        reloadMacAddresses={reloadMacAddresses}
        setElaborateMacAddress={setElaborateMacAddress}
      />
      <Box className={"DiMaBox-one-element-right"}>
        <Button color="primary" variant="contained" onClick={setElaborateMacAddressTrue}>
          Create mac addresses
        </Button>
      </Box>
      <EntityTab rows={rows} cols={columns} isLoading={isLoadingMacAddress} />
    </>
  )
}
