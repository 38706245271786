import { TabContext, TabList } from "@mui/lab"
import { Box, Grid } from "@mui/material"
import { StatusCodes } from "http-status-codes"
import { useCallback, useContext, useEffect, useState } from "react"
import { Navigate, useNavigate, useParams } from "react-router-dom"
import { digitalManagerApi } from "../../../../../../services/api"
import { BackToURL } from "../../../../../components/BackToURL"
import {
  DiMaDetailsHeader,
  DiMaDetailsHeaderProps,
} from "../../../../../components/DiMaDetailsHeader"
import { DiMaProgress } from "../../../../../components/DiMaProgress"
import { DiMaTabPanel } from "../../../../../components/DiMaTabPanel"
import { LinkTab } from "../../../../../components/LinkTab"
import { UserContext } from "../../../../../context/UserContext"
import { DeviceRolePermissionGet } from "../../../../../models/DeviceRolePermission"
import { DeviceRolePermissionApplicationRoles } from "./applicationRoles_tab/DeviceRolePermissionApplicationRoles"
import { DeviceRolePermissionDetails } from "./details_tab/DeviceRolePermissionDetails"

export function DeviceRolePermissionContainer() {
  const { deviceId, roleId, permissionId } = useParams()
  const { user, isLoadingUser } = useContext(UserContext)
  const [deviceRolePermission, setDeviceRolePermission] =
    useState<DeviceRolePermissionGet>()
  const [reloadEntity, setReloadEntity] = useState<boolean>(true)
  const [activeTab, setActiveTab] = useState<string>(`#details`)
  const returnURL = `/devices/${deviceId}/roles/${roleId}#permissions`
  const navigate = useNavigate()

  const AVATAR_LENGTH = 2

  const handleChange = useCallback((_: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue)
  }, [])

  const reloadDeviceRolePermission = useCallback(() => setReloadEntity(true), [])

  useEffect(() => {
    if (deviceId && roleId && permissionId && reloadEntity === true) {
      digitalManagerApi
        .get<DeviceRolePermissionGet>(
          `/api/v1/devices/${deviceId}/roles/${roleId}/permissions/${permissionId}`
        )
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            setDeviceRolePermission(res.data)
            setReloadEntity(false)
          }
        })
        .catch((err) => {
          if (err.response.status) {
            navigate(returnURL)
          }
        })
    }
  }, [deviceId, navigate, permissionId, reloadEntity, returnURL, roleId])

  if (!deviceId || !roleId || !permissionId) {
    return <Navigate to="/devices" />
  }

  if (!deviceRolePermission || isLoadingUser || !user) {
    return <DiMaProgress />
  }

  const detailsHeaderProps: DiMaDetailsHeaderProps = {
    avatar: deviceRolePermission.permission.name.substring(0, AVATAR_LENGTH),
    title: deviceRolePermission.permission.name,
    label: "Permission ID",
    subtitle: deviceRolePermission.permission.id,
  }

  return (
    <>
      <BackToURL url={returnURL} label="Back to permissions" />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <Grid container>
          <Grid item xs={10}>
            <DiMaDetailsHeader {...detailsHeaderProps} />
          </Grid>
        </Grid>
        <TabContext value={activeTab}>
          <Box className="DiMaBox-tabs">
            <TabList onChange={handleChange}>
              <LinkTab label="Details" value={`#details`} />
              <LinkTab label="App roles" value={`#app-roles`} />
            </TabList>
          </Box>
          <DiMaTabPanel label={`#details`} activeTab={activeTab}>
            <DeviceRolePermissionDetails
              deviceRolePermission={deviceRolePermission}
              user={user}
            />
          </DiMaTabPanel>
          <DiMaTabPanel label={`#app-roles`} activeTab={activeTab}>
            <DeviceRolePermissionApplicationRoles
              deviceRolePermission={deviceRolePermission}
              reloadDeviceRolePermission={reloadDeviceRolePermission}
              user={user}
            />
          </DiMaTabPanel>
        </TabContext>
      </Box>
    </>
  )
}
