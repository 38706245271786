import { StatusCodes } from "http-status-codes"
import { useEffect, useState } from "react"
import { RoleRequestGet } from "../app/models/RoleRequest"
import { digitalManagerApi } from "../services/api"

export function useRoleRequests(getCompleted = false) {
  const [roleRequestsList, setRoleRequestsList] = useState<RoleRequestGet[]>([])
  const [isLoadingRoleRequests, setIsLoadingRoleRequests] = useState<boolean>(true)

  useEffect(() => {
    setIsLoadingRoleRequests(true)
    setRoleRequestsList([])
    digitalManagerApi
      .get<RoleRequestGet[]>(`/api/v1/roleRequests`, {
        params: { completed: getCompleted },
      })
      .then((res) => {
        if (res.status === StatusCodes.OK) {
          setRoleRequestsList(res.data)
        }
      })
      .finally(() => {
        setIsLoadingRoleRequests(false)
      })
  }, [getCompleted])

  return {
    roleRequestsList,
    isLoadingRoleRequests,
  }
}
