import { TabPanel } from "@mui/lab"
import React from "react"

export function DiMaTabPanel(props: {
  readonly label: string
  readonly activeTab: string
  readonly children: React.ReactNode
}) {
  const { label, activeTab, children } = props
  return (
    <TabPanel
      value={label}
      sx={{
        display: activeTab === label ? "flex" : "none",
        flexDirection: "column",
        flexGrow: 1,
        padding: "16px 0 0 0",
      }}
    >
      {children}
    </TabPanel>
  )
}
