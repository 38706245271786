import ReportProblemIcon from "@mui/icons-material/ReportProblem"
import { CircularProgress, Grid, Typography } from "@mui/material"
import { StatusCodes } from "http-status-codes"
import { useEffect, useState } from "react"
import { digitalManagerApi } from "../../../services/api"
import HtmlTooltip from "../../components/HtmlTooltip"
import {
  ApplicationRoleApproverGet,
  ApplicationRoleApproverTypeEnum,
} from "../../models/ApplicationRoleApprover"
import { Guid } from "../../models/CustomType"
import {
  RoleRequestApproval,
  RoleRequestApprovalStatusEnum,
} from "../../models/RoleRequestApproval"
import { getNiceFormattedDate } from "../../utils/getFormattedDate"

interface DiMaApprovalTooltipProps {
  readonly children: React.ReactElement
  readonly roleRequestApproval: RoleRequestApproval
  readonly applicationId: Guid
  readonly roleId: Guid
}

const maxWidth = 300

const ulStyle = {
  margin: "0",
  paddingRight: "15px",
  maxHeight: "70px",
  overflow: "auto",
}

export function DiMaApprovalTooltip(props: DiMaApprovalTooltipProps) {
  const {
    property,
    status,
    userApproverType,
    completionDate,
    completedBy,
    forwardingDate,
    forwardedBy,
  } = props.roleRequestApproval

  const { applicationId, roleId } = props

  const [approversList, setApproversList] = useState<string[] | null>([])
  const [isLoadingForwarded, setIsLoadingForwarded] = useState<boolean>(true)

  const url =
    property.name && property.value
      ? `/api/v1/applications/${applicationId}/roles/${roleId}/approvers?approverType=${ApplicationRoleApproverTypeEnum.Consulted}&propertyName=${property.name}&propertyValue=${property.value}`
      : `/api/v1/applications/${applicationId}/roles/${roleId}/approvers?approverType=${ApplicationRoleApproverTypeEnum.Consulted}`

  useEffect(() => {
    if (status === RoleRequestApprovalStatusEnum.Forwarded) {
      digitalManagerApi
        .get<ApplicationRoleApproverGet[]>(url)
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            const tempApproverList: string[] = []
            for (const approver of res.data) {
              tempApproverList.push(approver.user.username)
            }
            setApproversList(tempApproverList)
          }
        })
        .catch(() => {
          setApproversList(null)
        })
        .finally(() => {
          setIsLoadingForwarded(false)
        })
    }
  }, [status, url])

  return (
    <HtmlTooltip
      custommaxwidth={maxWidth}
      title={
        <>
          {status === RoleRequestApprovalStatusEnum.Approved && (
            <>
              <b>{"Approved by: "}</b> {completedBy?.username} <br />
              <b>{"Date approved: "}</b>
              {completionDate && getNiceFormattedDate(completionDate)}
            </>
          )}

          {status === RoleRequestApprovalStatusEnum.Rejected && (
            <>
              <b>{"Rejected by: "}</b> {completedBy?.username} <br />
              <b>{"Date rejected: "}</b>
              {completionDate && getNiceFormattedDate(completionDate)}
            </>
          )}
          {status === RoleRequestApprovalStatusEnum.Forwarded && (
            <>
              <b>{"Forwarded by: "}</b>
              {forwardedBy ? forwardedBy.username : ""}
              <br />
              <b>{"Forwarding date: "}</b>{" "}
              {forwardingDate ? getNiceFormattedDate(forwardingDate) : ""}
              <br />
              <b>{"Pending on: "}</b>
              {isLoadingForwarded && <CircularProgress size={10} />}
              {!isLoadingForwarded && approversList && (
                <ul style={ulStyle}>
                  {approversList.map((name: string) => (
                    <li key={name}>{name}</li>
                  ))}
                </ul>
              )}
              {!isLoadingForwarded && !approversList && (
                <>
                  <br />
                  <Grid container direction="row" justifyContent="center">
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                      <ReportProblemIcon color="error" fontSize="large" />
                    </Grid>
                    <Grid item>
                      <Typography color="error" fontSize="small">
                        Error: cannot retrieve consulted users.
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          )}

          {status === RoleRequestApprovalStatusEnum.Open && (
            <>
              You cannot handle this request because you have{" "}
              <b>
                {userApproverType === null
                  ? "no access"
                  : `${String(userApproverType).toLowerCase()} role`}
              </b>
              {property.name && property.label && (
                <>
                  {" "}
                  on property <i>{property.name}</i> with value <i>{property.label}</i>
                </>
              )}
              .
            </>
          )}
        </>
      }
    >
      {props.children}
    </HtmlTooltip>
  )
}
