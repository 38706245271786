import AddIcon from "@mui/icons-material/Add"
import { Button, Tooltip } from "@mui/material"
import { useContext } from "react"
import { Link } from "react-router-dom"
import { UserContext } from "../../context/UserContext"
import {
  AccessLevel,
  DimaFeature,
  hasUserAccessToApplicationFeature,
  hasUserAccessToFeature,
} from "../../models/Authorization"
import { Guid } from "../../models/CustomType"

export function DiMaAddButton(props: {
  readonly url: string
  readonly feature: string
  readonly applicationId?: Guid
  readonly requireCreateLevel?: boolean
}) {
  const { user } = useContext(UserContext)

  const { url, feature, applicationId, requireCreateLevel = false } = props

  function getAccessLevel(createLevel: boolean) {
    if (createLevel) {
      return AccessLevel.Create
    }
    return AccessLevel.Write
  }

  const hasAccess = applicationId
    ? user &&
      hasUserAccessToApplicationFeature(
        user.authorizations.applications,
        applicationId,
        feature,
        getAccessLevel(requireCreateLevel)
      )
    : user &&
      hasUserAccessToFeature(
        user.authorizations,
        feature,
        getAccessLevel(requireCreateLevel)
      )

  function getTitle(access: unknown) {
    if (access) {
      return ""
    }
    return `You do not have ${requireCreateLevel ? "create" : "write"} access to ${
      DimaFeature[feature]
    } feature${applicationId ? " for this application" : ""}`
  }

  return (
    <Tooltip followCursor title={getTitle(hasAccess)}>
      {hasAccess ? (
        <Link to={url}>
          <Button variant="contained" startIcon={<AddIcon />}>
            Add
          </Button>
        </Link>
      ) : (
        <span>
          <Button variant="contained" startIcon={<AddIcon />} disabled>
            Add
          </Button>
        </span>
      )}
    </Tooltip>
  )
}
