import CloseIcon from "@mui/icons-material/Close"
import EditIcon from "@mui/icons-material/Edit"
import VisibilityIcon from "@mui/icons-material/Visibility"
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { AxiosResponse } from "axios"
import { useFormik } from "formik"
import { StatusCodes } from "http-status-codes"
import { useCallback, useContext, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { array, mixed, object, string } from "yup"
import { useApplicationRoles } from "../../../../../hooks/useApplicationRoles"
import { useNotificationTopics } from "../../../../../hooks/useNotificationTopics"
import { digitalManagerApi } from "../../../../../services/api"
import DiMaCheckboxList from "../../../../components/DiMaCheckboxList"
import {
  DiMaDetailsContent,
  DiMaDetailsContentProps,
} from "../../../../components/DiMaDetailsContent"
import { DiMaProgress } from "../../../../components/DiMaProgress"
import {
  DiMaRichTextEditor,
  DiMaRichTextEditorValue,
} from "../../../../components/DiMaRichTextEditor"
import { UserContext } from "../../../../context/UserContext"
import { ApplicationRoleGet } from "../../../../models/ApplicationRole"
import {
  AccessLevel,
  DimaFeatureShortNameEnum,
  hasUserAccessToApplicationFeature,
} from "../../../../models/Authorization"
import { Guid } from "../../../../models/CustomType"
import {
  NotificationGet,
  NotificationPut,
  NotificationTarget,
} from "../../../../models/Notification"
import { NotificationTargetType } from "../../../../models/NotificationTarget"
import { NotificationTopicGet } from "../../../../models/NotificationTopic"
import {
  getLocalDateTime,
  getNiceFormattedDate,
  getUtcDateTime,
} from "../../../../utils/getFormattedDate"
import { getNotificationStatusProps } from "../../../../utils/getNotificationStatusProps"

const validationSchema = object({
  title: string().required("Title is required"),
  body: string().required("Body is required"),
  url: string().url(),
  applicationVersion: string(),
  operatingSystem: string(),
  validityStartDate: string().required("Validity Start Date is required"),
  validityEndDate: string().required("Validity End Date is required"),
  topicId: string().required("Topic is required"),
  targetType: mixed<NotificationTargetType>()
    .oneOf(Object.values(NotificationTargetType))
    .required("Notification Target is required"),
  targetRoles: array()
    .of(string())
    .when("targetType", {
      is: NotificationTargetType.Role,
      then: array().of(string()).min(1),
      otherwise: array().of(string()),
    }),
})

const returnURL = `/notifications/`

const notificationUpdateErrorMessage = "Cannot update notification"

const getNotificationTargetType = (
  notification: NotificationGet
): NotificationTargetType => {
  if (notification.isAnonymous) {
    return NotificationTargetType.Anonymous
  }

  let targetType = NotificationTargetType.Role

  notification.targets.forEach((target: NotificationTarget) => {
    if (target.type === NotificationTargetType.All) {
      targetType = NotificationTargetType.All
    }
  })

  return targetType
}

const notificationTargetsContainRole = (
  roleId: string,
  targets: NotificationTarget[]
): boolean => {
  let contains = false
  targets.forEach((target) => {
    if (target.type === NotificationTargetType.Role && target.value === roleId) {
      contains = true
    }
  })

  return contains
}

export function NotificationDetails(props: {
  readonly notification: NotificationGet
  readonly setNotification: (updatedNotification: NotificationGet) => void
}) {
  const { user, isLoadingUser } = useContext(UserContext)

  const { notification, setNotification } = props
  const [isShowBodyOpen, setIsShowBodyOpen] = useState<boolean>(false)
  const [isEditBodyOpen, setIsEditBodyOpen] = useState<boolean>(false)
  const [editorValue, setEditorValue] = useState<string>(notification.bodyPlate)

  const openShowBody = useCallback(() => {
    setIsShowBodyOpen(true)
  }, [])

  const closeShowBody = useCallback(() => {
    setIsShowBodyOpen(false)
  }, [])

  const openEditBody = useCallback(() => {
    setIsEditBodyOpen(true)
  }, [])

  const closeEditBody = useCallback(() => {
    setIsEditBodyOpen(false)
  }, [])

  const { notificationTopicsList, isLoadingNotificationTopics } = useNotificationTopics(
    false,
    notification.application.id
  )
  const { applicationRoles, isLoadingApplicationRoles } = useApplicationRoles(
    notification.application.id
  )
  const [checkedApplicationRolesIds, setCheckedApplicationRolesIds] = useState<Guid[]>([])
  const [targetRoles, setTargetRoles] = useState<Guid[]>([])

  const deleteNotification = useCallback(
    () => digitalManagerApi.delete(`/api/v1/notifications/${notification.id}`),
    [notification.id]
  )

  const formik = useFormik<NotificationPut>({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      title: notification.title,
      body: notification.body,
      bodyHtml: notification.bodyHtml,
      bodyPlate: notification.bodyPlate,
      url: notification.url,
      applicationVersion: notification.applicationVersion,
      operatingSystem: notification.operatingSystem,
      validityStartDate: getLocalDateTime(notification.validityStartDate),
      validityEndDate: getLocalDateTime(notification.validityEndDate),
      topicId: notification.topic.id,
      targetType: getNotificationTargetType(notification),
      isAnonymous: notification.isAnonymous,
      targetRoles,
    },
    onSubmit: async (values) => {
      const utcValidityStartDate = getUtcDateTime(values.validityStartDate)
      const utcValidityEndDate = getUtcDateTime(values.validityEndDate)

      if (values.targetType === NotificationTargetType.Anonymous) {
        values.targetType = NotificationTargetType.All
        values.isAnonymous = true
      } else {
        values.isAnonymous = false
      }

      return digitalManagerApi
        .put<NotificationGet>(`/api/v1/notifications/${notification.id}`, {
          topicId: values.topicId,
          title: values.title,
          body: values.body,
          bodyHtml: values.bodyHtml,
          bodyPlate: values.bodyPlate,
          url: values.url ? values.url : null,
          applicationVersion: values.applicationVersion ? values.applicationVersion : null,
          operatingSystem: values.operatingSystem ? values.operatingSystem : null,
          validityStartDate: utcValidityStartDate,
          validityEndDate: utcValidityEndDate,
          targetType: values.targetType,
          isAnonymous: values.isAnonymous,
        })
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            const promises: Promise<AxiosResponse<NotificationTarget>>[] = []
            // remove existing targets
            notification.targets.forEach((target) => {
              promises.push(
                digitalManagerApi.delete(
                  `/api/v1/notifications/${notification.id}/targets/${target.id}`
                )
              )
            })

            // create targets
            const notificationId = notification.id

            if (values.targetType === NotificationTargetType.All) {
              const target = {
                type: NotificationTargetType.All,
              }
              promises.push(
                digitalManagerApi
                  .post<NotificationTarget>(
                    `/api/v1/notifications/${notificationId}/targets`,
                    target
                  )
                  .then((response) => response)
              )
            } else {
              values.targetRoles.forEach((roleId) => {
                promises.push(
                  digitalManagerApi
                    .post<NotificationTarget>(
                      `/api/v1/notifications/${notificationId}/targets`,
                      {
                        type: NotificationTargetType.Role,
                        value: roleId,
                      }
                    )
                    .then((response) => response)
                )
              })
            }

            return Promise.all(promises)
              .then((responses) => {
                const targets: NotificationTarget[] = []
                responses.forEach((r) => {
                  if (r.status === StatusCodes.CREATED) {
                    targets.push(r.data)
                  }
                })
                toast.success("Notification updated")
                setNotification({ ...res.data, targets })
              })
              .catch(() => {
                toast.error(notificationUpdateErrorMessage)
              })
          } else {
            toast.error(notificationUpdateErrorMessage)
            return Promise.reject(new Error(notificationUpdateErrorMessage))
          }
        })
        .catch(() => {
          toast.error(notificationUpdateErrorMessage)
          return Promise.reject(new Error(notificationUpdateErrorMessage))
        })
    },
  })

  const editBodyOnGetValue = useCallback(
    (val: DiMaRichTextEditorValue) => {
      setIsEditBodyOpen(false)
      formik.setFieldValue("body", val.stringValue)
      formik.setFieldValue("bodyHtml", val.htmlValue)
      formik.setFieldValue("bodyPlate", val.plateValue)
      setEditorValue(val.plateValue)
    },
    [formik]
  )

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      formik.setFieldValue(event.target.name, event.target.value)
    },
    [formik]
  )

  const handleChangeTargetType = useCallback(
    (event: SelectChangeEvent) => {
      const tt = (event.target as HTMLInputElement).value

      if (
        tt === NotificationTargetType.All ||
        tt === NotificationTargetType.Role ||
        tt === NotificationTargetType.Anonymous
      ) {
        formik.setFieldValue("targetType", tt)
      }
    },
    [formik]
  )

  useEffect(() => {
    formik.setFieldValue("targetRoles", checkedApplicationRolesIds)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedApplicationRolesIds])

  useEffect(() => {
    if (applicationRoles) {
      const checkedRoles = applicationRoles.filter((role: ApplicationRoleGet) =>
        notificationTargetsContainRole(role.id, notification.targets)
      )
      const checkedRolesIds = checkedRoles
        .map((checkedRole) => checkedRole.id)
        .sort((a, b) => a.localeCompare(b))
      setTargetRoles(checkedRolesIds)
      setCheckedApplicationRolesIds(checkedRolesIds)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationRoles, notification.targets])

  if (isLoadingUser || !user) {
    return <DiMaProgress />
  }

  const detailsContentProps: DiMaDetailsContentProps<NotificationPut> = {
    formik,
    canWrite: hasUserAccessToApplicationFeature(
      user.authorizations.applications,
      notification.application.id,
      DimaFeatureShortNameEnum.NM,
      AccessLevel.Write
    ),
    label: "Notification",
    deleteAction: deleteNotification,
    onDeleteReturnUrl: returnURL,
    listItems: [
      {
        label: "Status",
        displayItem: (
          <Chip
            sx={{ width: "100px" }}
            {...getNotificationStatusProps(notification.status)}
          />
        ),
      },
      {
        label: "Title",
        displayItem: <Typography>{notification.title}</Typography>,
        editItem: (
          <TextField
            fullWidth
            id="title"
            name="title"
            type="text"
            value={formik.values.title}
            onChange={formik.handleChange}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && formik.errors.title}
            size="small"
          />
        ),
      },
      {
        label: "Body",
        displayItem: (
          <Typography>
            <Button
              variant="outlined"
              startIcon={<VisibilityIcon />}
              onClick={openShowBody}
            >
              Show body
            </Button>
            <Modal
              open={isShowBodyOpen}
              onClose={closeShowBody}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "80%",
                  height: "80%",
                  bgcolor: "background.paper",
                  border: "2px solid #000",
                  boxShadow: 24,
                  overflow: "hidden",
                  pl: "47px",
                  pr: "32px",
                  pt: "20px",
                  pb: "50px",
                }}
              >
                <Box display="flex" flexWrap="wrap" justifyContent="space-between">
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    sx={{ mt: 2, mb: 2, ml: 0 }}
                  >
                    Notification Body
                  </Typography>
                  <Button
                    variant="contained"
                    startIcon={<CloseIcon />}
                    sx={{ mt: 2, mb: 2, mr: 0 }}
                    onClick={closeShowBody}
                  >
                    Close
                  </Button>
                </Box>
                <Divider />
                <Box
                  dangerouslySetInnerHTML={{ __html: notification.bodyHtml }}
                  sx={{
                    mt: "20px",
                    pr: "15px",
                    display: "flex",
                    flexDirection: "column",
                    height: "80%",
                    overflow: "hidden",
                    overflowY: "scroll",
                    textAlign: "justify",
                  }}
                ></Box>
              </Box>
            </Modal>
          </Typography>
        ),
        editItem: (
          <>
            <Button variant="outlined" startIcon={<EditIcon />} onClick={openEditBody}>
              Edit body
            </Button>
            <Modal
              open={isEditBodyOpen}
              onClose={closeEditBody}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "80%",
                  height: "80%",
                  bgcolor: "background.paper",
                  border: "2px solid #000",
                  boxShadow: 24,
                  p: 4,
                }}
              >
                <DiMaRichTextEditor
                  initialValue={editorValue}
                  onGetValue={editBodyOnGetValue}
                />
              </Box>
            </Modal>
          </>
        ),
      },
      {
        label: "Url",
        displayItem: <Typography>{notification.url}</Typography>,
        editItem: (
          <TextField
            fullWidth
            id="url"
            name="url"
            type="text"
            value={formik.values.url}
            onChange={formik.handleChange}
            error={formik.touched.url && Boolean(formik.errors.url)}
            helperText={formik.touched.url && formik.errors.url}
            size="small"
          />
        ),
      },
      {
        label: "Application Versions",
        displayItem: <Typography>{notification.applicationVersion}</Typography>,
        editItem: (
          <TextField
            fullWidth
            id="applicationVersion"
            name="applicationVersion"
            type="text"
            value={formik.values.applicationVersion}
            onChange={formik.handleChange}
            error={formik.touched.applicationVersion && Boolean(formik.errors.applicationVersion)}
            helperText={formik.touched.applicationVersion && formik.errors.applicationVersion}
            size="small"
          />
        ),
      },
      {
        label: "Operating Systems",
        displayItem: <Typography>{notification.operatingSystem}</Typography>,
        editItem: (
          <TextField
            fullWidth
            id="operatingSystem"
            name="operatingSystem"
            type="text"
            value={formik.values.operatingSystem}
            onChange={formik.handleChange}
            error={formik.touched.operatingSystem && Boolean(formik.errors.operatingSystem)}
            helperText={formik.touched.operatingSystem && formik.errors.operatingSystem}
            size="small"
          />
        ),
      },
      {
        label: "Validity Start Date",
        displayItem: (
          <Typography>{getNiceFormattedDate(notification.validityStartDate)}</Typography>
        ),
        editItem: (
          <TextField
            fullWidth
            id="validityStartDate"
            name="validityStartDate"
            type="datetime-local"
            inputProps={{
              max: "9999-12-31T23:59:59",
            }}
            value={formik.values.validityStartDate}
            onChange={formik.handleChange}
            error={
              formik.touched.validityStartDate && Boolean(formik.errors.validityStartDate)
            }
            helperText={
              formik.touched.validityStartDate && formik.errors.validityStartDate
            }
            size="small"
          />
        ),
      },
      {
        label: "Validity End Date",
        displayItem: (
          <Typography>{getNiceFormattedDate(notification.validityEndDate)}</Typography>
        ),
        editItem: (
          <TextField
            fullWidth
            id="validityEndDate"
            name="validityEndDate"
            type="datetime-local"
            inputProps={{
              min: formik.values.validityStartDate,
              max: "9999-12-31T23:59:59",
            }}
            value={formik.values.validityEndDate}
            onChange={formik.handleChange}
            error={
              formik.touched.validityEndDate && Boolean(formik.errors.validityEndDate)
            }
            helperText={formik.touched.validityEndDate && formik.errors.validityEndDate}
            size="small"
          />
        ),
      },
      {
        label: "Topic",
        displayItem: <Typography>{notification.topic.name}</Typography>,
        editItem: (
          <Select
            id="topicId"
            name="topicId"
            value={formik.values.topicId}
            onChange={handleChange}
            disabled={isLoadingNotificationTopics}
            size="small"
            displayEmpty
            fullWidth
          >
            {!isLoadingNotificationTopics &&
              notificationTopicsList.map((notificationTopic: NotificationTopicGet) => (
                <MenuItem value={notificationTopic.id} key={notificationTopic.id}>
                  {notificationTopic.name}
                </MenuItem>
              ))}
          </Select>
        ),
      },
      {
        label: "Who will receive",
        displayItem: !isLoadingApplicationRoles ? (
          <Typography>
            {notification.targets.map((target, i) => {
              if (target.type === NotificationTargetType.All) {
                if (notification.isAnonymous) {
                  return "Only NOT logged users"
                } else {
                  return "All logged users"
                }
              } else if (target.type === NotificationTargetType.Anonymous) {
                return "Anonymous"
              } else {
                const role = applicationRoles.find((r) => r.id === target.value)

                if (role?.name) {
                  if (i > 0) {
                    return `, ${role.name}`
                  } else {
                    return role.name
                  }
                } else {
                  return "Unknown role"
                }
              }
            })}
          </Typography>
        ) : (
          <CircularProgress size={20} />
        ),
        editItem: <></>,
      },
      {
        editItem: (
          <Stack direction="column">
            <Select
              value={formik.values.targetType}
              onChange={handleChangeTargetType}
              size="small"
              displayEmpty
            >
              <MenuItem value={NotificationTargetType.All}>All logged users</MenuItem>
              <MenuItem
                value={NotificationTargetType.Role}
                disabled={isLoadingApplicationRoles || applicationRoles.length === 0}
              >
                Users with specific roles
              </MenuItem>
              <MenuItem value={NotificationTargetType.Anonymous}>
                Only NOT logged users
              </MenuItem>
            </Select>
            {formik.values.targetType === NotificationTargetType.Role && (
              <DiMaCheckboxList
                items={applicationRoles.map((role: ApplicationRoleGet) => ({
                  id: role.id,
                  title: role.name,
                  description: role.description,
                }))}
                checkedItemsIds={checkedApplicationRolesIds}
                setCheckedItemsIds={setCheckedApplicationRolesIds}
                options={{ sortCheckedItemsIds: true }}
              />
            )}
          </Stack>
        ),
      },
    ],
  }

  return <DiMaDetailsContent {...detailsContentProps} />
}
