import { TabContext, TabList } from "@mui/lab"
import Box from "@mui/material/Box"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom"
import { digitalManagerApi } from "../../../../services/api"
import { BackToURL } from "../../../components/BackToURL"
import { DiMaProgress } from "../../../components/DiMaProgress"
import { DiMaTabPanel } from "../../../components/DiMaTabPanel"
import { LinkTab } from "../../../components/LinkTab"
import { UserContext } from "../../../context/UserContext"
import { ObjectIds } from "../../../models/SupplierPortal"
import { ObjectIdsDetails } from "./objectIds_details_tab/ObjectIdsDetails"

const returnURL = "/supplierPortal#object-id"

export function ObjectIdContainer() {
  const { objectIdsRequestId } = useParams()
  const { user, isLoadingUser } = useContext(UserContext)
  const [activeTab, setActiveTab] = useState<string>("#details")
  const [oids, setOids] = useState<ObjectIds[]>()
  const { hash } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (objectIdsRequestId) {
      digitalManagerApi
        .get<ObjectIds[]>(
          `/api/v1/litePanelPro/objectIdsRequests/${objectIdsRequestId}/objectIds`
        )
        .then((res) => {
          setOids(res.data)
        })
        .catch(() => {
          navigate(returnURL)
        })
    }
  }, [objectIdsRequestId, navigate])

  useEffect(() => {
    if (hash) {
      setActiveTab(hash)
    }
  }, [hash])

  const handleChange = useCallback((_: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue)
  }, [])

  if (!objectIdsRequestId) {
    return <Navigate to={returnURL} />
  }

  if (!oids || isLoadingUser || !user) {
    return <DiMaProgress />
  }

  return (
    <>
      <BackToURL url={returnURL} label="Back to objectId requests" />
      <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <TabContext value={activeTab}>
          <Box className="DiMaBox-tabs">
            <TabList onChange={handleChange}>
              <LinkTab label="Details" value="#details" />
            </TabList>
          </Box>
          <DiMaTabPanel label="#details" activeTab={activeTab}>
            <ObjectIdsDetails objectIds={oids} />
          </DiMaTabPanel>
        </TabContext>
      </Box>
    </>
  )
}
