import { StatusCodes } from "http-status-codes"
import { useEffect, useState } from "react"
import { ApplicationUserGet } from "../app/models/ApplicationUser"
import { digitalManagerApi } from "../services/api"
import { Guid } from "../app/models/CustomType"

export function useApplicationUsers(applicationId?: Guid) {
  const [applicationUsers, setApplicationUsers] = useState<ApplicationUserGet[]>([])
  const [isLoadingApplicationUsers, setIsLoadingApplicationUsers] =
    useState<boolean>(true)

  useEffect(() => {
    setIsLoadingApplicationUsers(true)
    setApplicationUsers([])
    if (applicationId) {
      digitalManagerApi
        .get<ApplicationUserGet[]>(`/api/v1/applications/${applicationId}/users`)
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            setApplicationUsers(res.data)
          }
        })
        .finally(() => {
          setIsLoadingApplicationUsers(false)
        })
    } else {
      setIsLoadingApplicationUsers(false)
    }
  }, [applicationId])

  return {
    applicationUsers,
    isLoadingApplicationUsers,
  }
}
