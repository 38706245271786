import { TabContext, TabList } from "@mui/lab"
import { Box, Grid } from "@mui/material"
import { StatusCodes } from "http-status-codes"
import { useCallback, useContext, useEffect, useState } from "react"
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom"
import { digitalManagerApi } from "../../../../../services/api"
import { BackToURL } from "../../../../components/BackToURL"
import {
  DiMaDetailsHeader,
  DiMaDetailsHeaderProps,
} from "../../../../components/DiMaDetailsHeader"
import { DiMaProgress } from "../../../../components/DiMaProgress"
import { DiMaTabPanel } from "../../../../components/DiMaTabPanel"
import { LinkTab } from "../../../../components/LinkTab"
import { UserContext } from "../../../../context/UserContext"
import { ApplicationPropertyGet } from "../../../../models/ApplicationProperty"
import { ApplicationPropertyDetails } from "./details_tab/ApplicationPropertyDetails"

export function ApplicationPropertyContainer() {
  const { applicationId, propertyName } = useParams()
  const { user, isLoadingUser } = useContext(UserContext)
  const { hash } = useLocation()
  const [property, setProperty] = useState<ApplicationPropertyGet>()
  const [activeTab, setActiveTab] = useState<string>("#details")
  const navigate = useNavigate()
  const returnURL = `/applications/${applicationId}#properties`

  useEffect(() => {
    if (applicationId && propertyName) {
      digitalManagerApi
        .get<ApplicationPropertyGet>(
          `/api/v1/applications/${applicationId}/properties/${propertyName}`
        )
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            setProperty(res.data)
          }
        })
        .catch(() => {
          navigate(returnURL)
        })
    }
  }, [applicationId, navigate, propertyName, returnURL])

  useEffect(() => {
    if (hash) {
      setActiveTab(hash)
    }
  }, [hash])

  const handleChange = useCallback((_: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue)
  }, [])

  if (!applicationId) {
    return <Navigate to="/applications" />
  }
  if (!propertyName) {
    return <Navigate to={returnURL} />
  }
  if (!property || isLoadingUser || !user) {
    return <DiMaProgress />
  }

  const detailsHeaderProps: DiMaDetailsHeaderProps = {
    avatar: "AP",
    title: property.name,
    label: "Property Type",
    subtitle: property.type,
    hideCopyToClipboard: true,
  }

  return (
    <>
      <BackToURL url={returnURL} label="Back to properties" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <Grid container>
          <Grid item xs={10}>
            <DiMaDetailsHeader {...detailsHeaderProps} />
          </Grid>
        </Grid>
        <TabContext value={activeTab}>
          <Box className="DiMaBox-tabs">
            <TabList onChange={handleChange}>
              <LinkTab label="Details" value="#details" />
            </TabList>
          </Box>
          <DiMaTabPanel label="#details" activeTab={activeTab}>
            <ApplicationPropertyDetails
              property={property}
              applicationId={applicationId}
              user={user}
              setProperty={setProperty}
            />
          </DiMaTabPanel>
        </TabContext>
      </Box>
    </>
  )
}
