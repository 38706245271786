import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { Avatar, Box, Chip, IconButton, Stack, Typography } from "@mui/material"
import { useCallback } from "react"
import { copyToClipboard } from "../utils/copyToClipboard"

export interface DiMaDetailsHeaderProps {
  readonly avatar: string
  readonly title: string
  readonly label: string
  readonly subtitle: string
  readonly hideCopyToClipboard?: boolean
}

export function DiMaDetailsHeader(props: DiMaDetailsHeaderProps) {
  const { avatar, title, label, subtitle, hideCopyToClipboard = false } = props

  const copyToClipboardSubtitle = useCallback(() => {
    copyToClipboard(subtitle)
  }, [subtitle])

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        alignItems: "center",
        paddingBottom: "10px",
      }}
    >
      <Box>
        <Avatar sx={{ width: 64, height: 64, textTransform: "uppercase" }}>
          {avatar}
        </Avatar>
      </Box>
      <Box>
        <Typography variant="h4">{title}</Typography>
        <Stack
          direction="row"
          spacing={2}
          sx={{
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle1">{label}:</Typography>
          <Typography variant="subtitle1">
            <Stack direction="row" sx={{ alignItems: "center" }}>
              <Chip label={subtitle} variant="outlined" />
              {!hideCopyToClipboard && (
                <IconButton onClick={copyToClipboardSubtitle}>
                  <ContentCopyIcon />
                </IconButton>
              )}
            </Stack>
          </Typography>
        </Stack>
      </Box>
    </Stack>
  )
}
