import { Checkbox, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material"
import { useFormik } from "formik"
import { StatusCodes } from "http-status-codes"
import { useCallback } from "react"
import { Navigate } from "react-router-dom"
import { toast } from "react-toastify"
import { boolean, mixed, object } from "yup"
import { digitalManagerApi } from "../../../../../../services/api"
import {
  DiMaDetailsContent,
  DiMaDetailsContentProps,
} from "../../../../../components/DiMaDetailsContent"
import {
  ApplicationPropertyGet,
  ApplicationPropertyPut,
  ApplicationPropertyType,
} from "../../../../../models/ApplicationProperty"
import {
  AccessLevel,
  DimaFeatureShortNameEnum,
  hasUserAccessToApplicationFeature,
} from "../../../../../models/Authorization"
import { UserInfo } from "../../../../../models/User"

const validationSchema = object({
  type: mixed<ApplicationPropertyType>()
    .oneOf(Object.values(ApplicationPropertyType))
    .required("Type field is required"),
  isRequestable: boolean().required("Is Requestable field is required"),
})

export function ApplicationPropertyDetails(props: {
  readonly property: ApplicationPropertyGet
  readonly applicationId: string
  readonly user: UserInfo
  readonly setProperty: (updatedProperty: ApplicationPropertyGet) => void
}) {
  const { property, applicationId, user, setProperty } = props
  const returnURL = `/applications/${applicationId}#properties`

  const deleteProperty = useCallback(
    () =>
      digitalManagerApi.delete(
        `/api/v1/applications/${applicationId}/properties/${property.name}`
      ),
    [applicationId, property.name]
  )

  const formik = useFormik<ApplicationPropertyPut>({
    validationSchema,
    initialValues: {
      type: property.type,
      isRequestable: property.isRequestable,
    },
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) =>
      digitalManagerApi
        .put<ApplicationPropertyGet>(
          `/api/v1/applications/${applicationId}/properties/${property.name}`,
          {
            type: values.type,
            isRequestable: values.isRequestable,
          }
        )
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            toast.success("Property updated")
            setProperty(res.data)
          }
        })
        .catch(() => {
          toast.error("Cannot update property")
          formik.resetForm()
          return Promise.reject(new Error("Cannot update property"))
        })
        .finally(() => {
          setSubmitting(false)
        }),
  })

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      formik.setFieldValue("type", event.target.value)
    },
    [formik]
  )

  if (!applicationId) {
    return <Navigate to={returnURL} />
  }

  const detailsContentProps: DiMaDetailsContentProps<ApplicationPropertyPut> = {
    formik,
    label: "Property",
    canWrite: hasUserAccessToApplicationFeature(
      user.authorizations.applications,
      applicationId,
      DimaFeatureShortNameEnum.AM,
      AccessLevel.Write
    ),
    deleteAction: deleteProperty,
    onDeleteReturnUrl: returnURL,
    listItems: [
      {
        label: "Name",
        displayItem: <Typography>{property.name}</Typography>,
      },
      {
        label: "Type",
        displayItem: <Typography>{property.type}</Typography>,
        editItem: (
          <Select
            id="type"
            name="type"
            value={formik.values.type}
            onChange={handleChange}
            size="small"
            displayEmpty
            fullWidth
          >
            {Object.values(ApplicationPropertyType).map(
              (type: ApplicationPropertyType) => (
                <MenuItem value={type} key={type}>
                  {type}
                </MenuItem>
              )
            )}
          </Select>
        ),
      },
      {
        label: "Is Requestable",
        displayItem: <Typography>{property.isRequestable.toString()}</Typography>,
        editItem: (
          <Checkbox
            id="isRequestable"
            name="isRequestable"
            checked={formik.values.isRequestable}
            onChange={formik.handleChange}
          />
        ),
      },
    ],
  }

  return <DiMaDetailsContent {...detailsContentProps} />
}
