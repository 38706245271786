import { StatusCodes } from "http-status-codes"
import { useCallback, useEffect, useState } from "react"
import { OidsRequestGet } from "../app/models/SupplierPortal"
import { digitalManagerApi } from "../services/api"

export function useOidsRequests() {
  const [oidRequestList, setOidRequestList] = useState<OidsRequestGet[]>([])
  const [isLoadingOidRequests, setIsLoadingOidRequests] = useState<boolean>(true)
  const [reloadEntities, setReloadEntities] = useState<boolean>(true)

  useEffect(() => {
    if (reloadEntities) {
      digitalManagerApi
        .get<OidsRequestGet[]>(`/api/v1/litePanelPro/objectIdsRequests`)
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            setOidRequestList(res.data)
          }
        })
        .finally(() => {
          setIsLoadingOidRequests(false)
          setReloadEntities(false)
        })
    }
  }, [reloadEntities])

  const reloadOidRequests = useCallback(() => setReloadEntities(true), [])

  return {
    oidRequestList,
    reloadOidRequests,
    isLoadingOidRequests: isLoadingOidRequests || reloadEntities,
  }
}
