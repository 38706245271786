import {
  AzureCloudInstance,
  BrowserCacheLocation,
  Configuration,
  PublicClientApplication,
} from "@azure/msal-browser"
import { MsalProvider } from "@azure/msal-react"
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material"
import { Navigate, Route, Routes } from "react-router-dom"
import "./App.scss"
import { AppContextProvider } from "./app/context/AppContext"
import { UserContextProvider } from "./app/context/UserContext"
import { AccessLevel, DimaFeatureShortNameEnum } from "./app/models/Authorization"
import { Layout } from "./app/pages/Layout"
import { ActiveDirectoryContainer } from "./app/pages/active_directory_page/ActiveDirectoryContainer"
import { ApplicationsContainer } from "./app/pages/applications_page/ApplicationsContainer"
import { ApplicationContainer } from "./app/pages/applications_page/applications_tab/ApplicationContainer"
import { ApplicationPermissionContainer } from "./app/pages/applications_page/applications_tab/permissions_tab/ApplicationPermissionContainer"
import { ApplicationPermissionCreate } from "./app/pages/applications_page/applications_tab/permissions_tab/ApplicationPermissionCreate"
import { ApplicationPropertyContainer } from "./app/pages/applications_page/applications_tab/properties_tab/ApplicationPropertyContainer"
import { ApplicationPropertyCreate } from "./app/pages/applications_page/applications_tab/properties_tab/ApplicationPropertyCreate"
import { ApplicationRoleContainer } from "./app/pages/applications_page/applications_tab/roles_tab/ApplicationRoleContainer"
import { ApplicationRoleCreate } from "./app/pages/applications_page/applications_tab/roles_tab/ApplicationRoleCreate"
import { ApplicationRoleApproverContainer } from "./app/pages/applications_page/applications_tab/roles_tab/approvers_tab/ApplicationRoleApproverContainer"
import { ApplicationRoleApproverCreate } from "./app/pages/applications_page/applications_tab/roles_tab/approvers_tab/ApplicationRoleApproverCreate"
import { ApplicationRoleApproverPropertyContainer } from "./app/pages/applications_page/applications_tab/roles_tab/approvers_tab/properties_tab/ApplicationRoleApproverPropertyContainer"
import { ApplicationRolePermissionContainer } from "./app/pages/applications_page/applications_tab/roles_tab/permissions_tab/ApplicationRolePermissionContainer"
import { ApplicationRolePermissionCreate } from "./app/pages/applications_page/applications_tab/roles_tab/permissions_tab/ApplicationRolePermissionCreate"
import { ApplicationRolePermissionPropertyCreate } from "./app/pages/applications_page/applications_tab/roles_tab/permissions_tab/properties_tab/ApplicationRolePermissionPropertyCreate"
import { Logout } from "./app/pages/auth/Logout"
import { DevicesContainer } from "./app/pages/devices_page/DevicesContainer"
import { CategoryContainer } from "./app/pages/devices_page/categories_tab/CategoryContainer"
import { CategoryCreate } from "./app/pages/devices_page/categories_tab/CategoryCreate"
import { DeviceContainer } from "./app/pages/devices_page/devices_tab/DeviceContainer"
import { DeviceCreate } from "./app/pages/devices_page/devices_tab/DeviceCreate"
import { DeviceRoleContainer } from "./app/pages/devices_page/devices_tab/roles_tab/DeviceRoleContainer"
import { DeviceRoleCreate } from "./app/pages/devices_page/devices_tab/roles_tab/DeviceRoleCreate"
import { DeviceRolePermissionContainer } from "./app/pages/devices_page/devices_tab/roles_tab/permissions_tab/DeviceRolePermissionContainer"
import { DeviceRolePermissionCreate } from "./app/pages/devices_page/devices_tab/roles_tab/permissions_tab/DeviceRolePermissionCreate"
import { DeviceRolePermissionApplicationRoleCreate } from "./app/pages/devices_page/devices_tab/roles_tab/permissions_tab/applicationRoles_tab/DeviceRolePermissionApplicationRoleCreate"
import { FamilyContainer } from "./app/pages/devices_page/families_tab/FamilyContainer"
import { FamilyCreate } from "./app/pages/devices_page/families_tab/FamilyCreate"
import { FilesContainer } from "./app/pages/files_page/FilesContainer"
import { FileContainer } from "./app/pages/files_page/files_tab/FileContainer"
import { FileCreate } from "./app/pages/files_page/files_tab/FileCreate"
import { FileVersionContainer } from "./app/pages/files_page/files_tab/versions_tab/FileVersionContainer"
import { FileVersionCreate } from "./app/pages/files_page/files_tab/versions_tab/FileVersionCreate"
import { LitePanelProResetPassword } from "./app/pages/litePanelPro_reset_password_page/LitePanelProResetPassword"
import { NotificationsContainer } from "./app/pages/notifications_page/NotificationsContainer"
import { NotificationContainer } from "./app/pages/notifications_page/notifications_tab/NotificationContainer"
import { NotificationCreate } from "./app/pages/notifications_page/notifications_tab/NotificationCreate"
import { TopicContainer } from "./app/pages/notifications_page/topics_tab/TopicContainer"
import { TopicCreate } from "./app/pages/notifications_page/topics_tab/TopicCreate"
import { RoleRequestsContainer } from "./app/pages/role_requests_page/RoleRequestsContainer"
import { RoleRequestContainer } from "./app/pages/role_requests_page/role_requests_tab/RoleRequestContainer"
import { SettingsContainer } from "./app/pages/settings_page/SettingsContainer"
import { SupplierContainer } from "./app/pages/supplier_portal_page/SupplierContainer"
import { ManufacturedDeviceContainer } from "./app/pages/supplier_portal_page/supplier_tab/ManufacturedDeviceContainer"
import { ObjectIdContainer } from "./app/pages/supplier_portal_page/supplier_tab/ObjectIdContainer"
import { TestReportsContainer } from "./app/pages/test_reports_page/TestReportsContainer"
import { FactoryContainer } from "./app/pages/test_reports_page/factories_tab/FactoryContainer"
import { FactoryCreate } from "./app/pages/test_reports_page/factories_tab/FactoryCreate"
import { UsersContainer } from "./app/pages/users_page/UsersContainer"
import { UserContainer } from "./app/pages/users_page/users_tab/UserContainer"
import { UserCreate } from "./app/pages/users_page/users_tab/UserCreate"
import { UserApplicationPermissionContainer } from "./app/pages/users_page/users_tab/apps_permissions_tab/UserApplicationPermissionContainer"
import { UserApplicationsPermissionCreate } from "./app/pages/users_page/users_tab/apps_permissions_tab/UserApplicationsPermissionCreate"
import { UserApplicationPermissionPropertyCreate } from "./app/pages/users_page/users_tab/apps_permissions_tab/properties_tab/UserApplicationPermissionPropertyCreate"
import { UserApplicationRoleContainer } from "./app/pages/users_page/users_tab/apps_roles_tab/UserApplicationRoleContainer"
import { UserApplicationsRoleCreate } from "./app/pages/users_page/users_tab/apps_roles_tab/UserApplicationsRoleCreate"
import { UserApplicationRolePropertyCreate } from "./app/pages/users_page/users_tab/apps_roles_tab/properties_tab/UserApplicationRolePropertyCreate"
import { RequireAuth } from "./app/routing/RequireAuth"
import { RequireFeatureAccessLevel } from "./app/routing/RequireFeatureAccessLevel"

// MSAL configuration
const configuration: Configuration = {
  auth: {
    clientId: "55dba566-9ec4-46d3-aff8-08ab0e6a75ee",
    azureCloudOptions: {
      azureCloudInstance: AzureCloudInstance.AzurePublic,
      tenant: "372ee9e0-9ce0-4033-a64a-c07073a91ecd",
    },
    redirectUri: "/",
    postLogoutRedirectUri: "/",
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },
}

export const msalInstance = new PublicClientApplication(configuration)

const fontFamilyRg = "ABBvoice_W_Rg, Arial"
const fontFamilyMd = "ABBvoice_W_Md, Arial"
const lineHeight = "150%"

const theme = createTheme({
  palette: {
    error: {
      main: "#F03040",
    },
    warning: {
      main: "#FF7300",
    },
    info: {
      main: "#3366FF",
    },
    success: {
      main: "#0CA919",
    },
  },
  typography: {
    fontFamily: fontFamilyRg,
    h1: {
      fontFamily: fontFamilyMd,
      fontSize: "2.5rem",
      lineHeight,
    },
    h2: {
      fontFamily: fontFamilyMd,
      fontSize: "2rem",
      lineHeight,
    },
    h3: {
      fontFamily: fontFamilyMd,
      fontSize: "1.5rem",
      lineHeight,
    },
    h4: {
      fontFamily: fontFamilyMd,
      fontSize: "1.3rem",
      lineHeight,
    },
    h5: {
      fontFamily: fontFamilyMd,
      fontSize: "1.1rem",
      lineHeight,
    },
    h6: {
      fontFamily: fontFamilyMd,
      fontSize: "1rem",
      lineHeight,
    },
    body1: {
      fontFamily: fontFamilyRg,
      fontSize: "1rem",
      lineHeight,
    },
    body2: {
      fontFamily: fontFamilyRg,
      fontSize: "0.8rem",
      lineHeight,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          color: "#262626",
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: "0 24px 24px 24px",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          height: "1.5rem",
        },
      },
    },
  },
})

function App() {
  return (
    <MsalProvider instance={msalInstance}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route
            path="/"
            element={
              <RequireAuth>
                <AppContextProvider>
                  <UserContextProvider>
                    <Layout />
                  </UserContextProvider>
                </AppContextProvider>
              </RequireAuth>
            }
          >
            <Route
              path="applications"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.AM}
                  accessLevel={AccessLevel.Read}
                >
                  <ApplicationsContainer />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="applications/:applicationId"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.AM}
                  accessLevel={AccessLevel.Read}
                >
                  <ApplicationContainer />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="applications/:applicationId/roles/create"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.AM}
                  accessLevel={AccessLevel.Write}
                >
                  <ApplicationRoleCreate />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="applications/:applicationId/roles/:roleId"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.AM}
                  accessLevel={AccessLevel.Read}
                >
                  <ApplicationRoleContainer />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="applications/:applicationId/roles/:roleId/permissions/create"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.AM}
                  accessLevel={AccessLevel.Write}
                >
                  <ApplicationRolePermissionCreate />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="applications/:applicationId/roles/:roleId/permissions/:permissionId"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.AM}
                  accessLevel={AccessLevel.Read}
                >
                  <ApplicationRolePermissionContainer />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="applications/:applicationId/roles/:roleId/permissions/:permissionId/properties/create"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.AM}
                  accessLevel={AccessLevel.Read}
                >
                  <ApplicationRolePermissionPropertyCreate />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="applications/:applicationId/permissions/create"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.AM}
                  accessLevel={AccessLevel.Write}
                >
                  <ApplicationPermissionCreate />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="applications/:applicationId/permissions/:permissionId"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.AM}
                  accessLevel={AccessLevel.Read}
                >
                  <ApplicationPermissionContainer />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="applications/:applicationId/properties/create"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.AM}
                  accessLevel={AccessLevel.Read}
                >
                  <ApplicationPropertyCreate />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="applications/:applicationId/properties/:propertyName"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.AM}
                  accessLevel={AccessLevel.Read}
                >
                  <ApplicationPropertyContainer />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="applications/:applicationId/roles/:roleId/approvers/create"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.AM}
                  accessLevel={AccessLevel.Write}
                >
                  <ApplicationRoleApproverCreate />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="applications/:applicationId/roles/:roleId/approvers/:userId"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.AM}
                  accessLevel={AccessLevel.Read}
                >
                  <ApplicationRoleApproverContainer />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="applications/:applicationId/roles/:roleId/approvers/:userId/create"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.AM}
                  accessLevel={AccessLevel.Write}
                >
                  <ApplicationRoleApproverCreate />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="applications/:applicationId/roles/:roleId/approvers/:userId/properties/:propertyName/values/:propertyValue"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.AM}
                  accessLevel={AccessLevel.Read}
                >
                  <ApplicationRoleApproverPropertyContainer />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="notifications"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.NM}
                  accessLevel={AccessLevel.Read}
                >
                  <NotificationsContainer />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="notifications/create"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.NM}
                  accessLevel={AccessLevel.Write}
                >
                  <NotificationCreate />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="notifications/:notificationId"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.NM}
                  accessLevel={AccessLevel.Read}
                >
                  <NotificationContainer />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="notifications/topics/create"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.NM}
                  accessLevel={AccessLevel.Write}
                >
                  <TopicCreate />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="notifications/topics/:notificationTopicId"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.NM}
                  accessLevel={AccessLevel.Read}
                >
                  <TopicContainer />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="role-requests"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.RR}
                  accessLevel={AccessLevel.Read}
                >
                  <RoleRequestsContainer />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="role-requests/:roleRequestId"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.RR}
                  accessLevel={AccessLevel.Read}
                >
                  <RoleRequestContainer />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="devices"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.DM}
                  accessLevel={AccessLevel.Read}
                >
                  <DevicesContainer />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="devices/create"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.DM}
                  accessLevel={AccessLevel.Write}
                >
                  <DeviceCreate />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="devices/:deviceId"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.DM}
                  accessLevel={AccessLevel.Read}
                >
                  <DeviceContainer />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="devices/:deviceId/roles/create"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.DM}
                  accessLevel={AccessLevel.Write}
                >
                  <DeviceRoleCreate />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="devices/:deviceId/roles/:roleId"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.DM}
                  accessLevel={AccessLevel.Read}
                >
                  <DeviceRoleContainer />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="devices/:deviceId/roles/:roleId/permissions/create"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.DM}
                  accessLevel={AccessLevel.Write}
                >
                  <DeviceRolePermissionCreate />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="devices/:deviceId/roles/:roleId/permissions/:permissionId"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.DM}
                  accessLevel={AccessLevel.Read}
                >
                  <DeviceRolePermissionContainer />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="devices/:deviceId/roles/:roleId/permissions/:permissionId/applicationRoles/create"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.DM}
                  accessLevel={AccessLevel.Write}
                >
                  <DeviceRolePermissionApplicationRoleCreate />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="devices/families/create"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.DM}
                  accessLevel={AccessLevel.Write}
                >
                  <FamilyCreate />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="devices/families/:familyId"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.DM}
                  accessLevel={AccessLevel.Read}
                >
                  <FamilyContainer />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="devices/categories/create"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.DM}
                  accessLevel={AccessLevel.Write}
                >
                  <CategoryCreate />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="devices/categories/:categoryId"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.DM}
                  accessLevel={AccessLevel.Read}
                >
                  <CategoryContainer />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="files"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.FM}
                  accessLevel={AccessLevel.Read}
                >
                  <FilesContainer />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="files/create"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.FM}
                  accessLevel={AccessLevel.Write}
                >
                  <FileCreate />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="files/:fileId"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.FM}
                  accessLevel={AccessLevel.Read}
                >
                  <FileContainer />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="files/:fileId/versions/create"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.FM}
                  accessLevel={AccessLevel.Write}
                >
                  <FileVersionCreate />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="files/:fileId/versions/:versionId"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.FM}
                  accessLevel={AccessLevel.Read}
                >
                  <FileVersionContainer />
                </RequireFeatureAccessLevel>
              }
            />
            {/*
            <Route
              path="files/groups/create"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.FM}
                  accessLevel={AccessLevel.Write}
                >
                  <GroupCreate />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="files/groups/:groupId"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.FM}
                  accessLevel={AccessLevel.Read}
                >
                  <GroupContainer />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="files/groups/:groupId/users/create"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.FM}
                  accessLevel={AccessLevel.Write}
                >
                  <GroupUserCreate />
                </RequireFeatureAccessLevel>
              }
            />
            */}
            <Route
              path="test-reports"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.TR}
                  accessLevel={AccessLevel.Read}
                >
                  <TestReportsContainer />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="test-reports/factories/create"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.TR}
                  accessLevel={AccessLevel.Write}
                >
                  <FactoryCreate />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="test-reports/factories/:factoryId"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.TR}
                  accessLevel={AccessLevel.Read}
                >
                  <FactoryContainer />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="users"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.RM}
                  accessLevel={AccessLevel.Read}
                >
                  <UsersContainer />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="users/create"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.RM}
                  accessLevel={AccessLevel.Write}
                >
                  <UserCreate />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="users/:userId"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.RM}
                  accessLevel={AccessLevel.Read}
                >
                  <UserContainer />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="users/:userId/apps-roles/create"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.RM}
                  accessLevel={AccessLevel.Write}
                >
                  <UserApplicationsRoleCreate />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="users/:userId/applications/:applicationId/roles/:roleId"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.RM}
                  accessLevel={AccessLevel.Read}
                >
                  <UserApplicationRoleContainer />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="users/:userId/applications/:applicationId/roles/:roleId/properties/create"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.AM}
                  accessLevel={AccessLevel.Read}
                >
                  <UserApplicationRolePropertyCreate />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="users/:userId/apps-permissions/create"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.RM}
                  accessLevel={AccessLevel.Write}
                >
                  <UserApplicationsPermissionCreate />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="users/:userId/applications/:applicationId/permissions/:permissionId"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.RM}
                  accessLevel={AccessLevel.Write}
                >
                  <UserApplicationPermissionContainer />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="users/:userId/applications/:applicationId/permissions/:permissionId/properties/create"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.AM}
                  accessLevel={AccessLevel.Read}
                >
                  <UserApplicationPermissionPropertyCreate />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="ActiveDirectory"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.AD}
                  accessLevel={AccessLevel.Read}
                >
                  <ActiveDirectoryContainer />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="settings"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.RR}
                  accessLevel={AccessLevel.Read}
                >
                  <SettingsContainer />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="supplierPortal"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.SP}
                  accessLevel={AccessLevel.Write}
                >
                  <SupplierContainer />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="supplierPortal/manufacturedDevices/:deviceId"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.SP}
                  accessLevel={AccessLevel.Write}
                >
                  <ManufacturedDeviceContainer />
                </RequireFeatureAccessLevel>
              }
            />
            <Route
              path="supplierPortal/objectIdsRequests/:objectIdsRequestId/objectIds"
              element={
                <RequireFeatureAccessLevel
                  feature={DimaFeatureShortNameEnum.SP}
                  accessLevel={AccessLevel.Write}
                >
                  <ObjectIdContainer />
                </RequireFeatureAccessLevel>
              }
            />
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="logout" element={<Logout />} />
          <Route
            path="litePanelPro/resetPassword"
            element={
              <RequireAuth>
                <UserContextProvider>
                  <LitePanelProResetPassword />
                </UserContextProvider>
              </RequireAuth>
            }
          />
        </Routes>
      </ThemeProvider>
    </MsalProvider>
  )
}

export default App
