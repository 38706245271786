import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { useCallback, useState } from "react"
import { Link, useParams, useSearchParams } from "react-router-dom"
import { useApplicationRolePermissions } from "../../../../../../hooks/useApplicationRolePermissions"
import { ApplicationRolePermissionGet } from "../../../../../models/ApplicationRolePermission"
import { DimaFeatureShortNameEnum } from "../../../../../models/Authorization"
import { getBooleanFlagIcon } from "../../../../../utils/getBooleanFlagIcon"
import { DiMaAddButton } from "../../../../partials/DiMaAddButton"
import { EntityTab } from "../../../../partials/EntityTab"

export function ApplicationRolePermissions() {
  const { applicationId, roleId } = useParams()
  const [searchParams] = useSearchParams()
  const [noDevicePermissions, setNoDevicePermissions] = useState<boolean>(
    searchParams.get("noDevice") ? searchParams.get("noDevice") === "true" : false
  )
  const { applicationRolePermissions, isLoadingApplicationRolePermissions } =
    useApplicationRolePermissions(applicationId, roleId, noDevicePermissions)

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      renderCell: (params) => (
        <Link
          to={`/applications/${applicationId}/roles/${roleId}/permissions/${params.row.id}?noDevice=${noDevicePermissions}`}
        >
          {params.row.name}
        </Link>
      ),
      flex: 2,
    },
    {
      field: "hasProperties",
      headerName: "Has properties",
      type: "boolean",
      renderCell: (params) => getBooleanFlagIcon(params.row.hasProperties),
      flex: 2,
    },
  ]

  const rows = applicationRolePermissions.map(
    (permission: ApplicationRolePermissionGet) => ({
      id: permission.permission.id,
      name: permission.permission.name,
      hasProperties: permission.properties.length !== 0,
    })
  )

  const onChange = useCallback((event: SelectChangeEvent<boolean>) => {
    event.target.value === "true"
      ? setNoDevicePermissions(true)
      : setNoDevicePermissions(false)
  }, [])

  return (
    <>
      <Box className="DiMaBox-two-elements">
        <Stack direction="row" spacing={2}>
          <Typography
            variant="body1"
            sx={{ width: "95%", display: "flex", alignItems: "center" }}
          >
            No Device
          </Typography>
          <Select
            value={noDevicePermissions}
            onChange={onChange}
            disabled={isLoadingApplicationRolePermissions}
            size="small"
            displayEmpty
            fullWidth
          >
            <MenuItem value={"false"}>False</MenuItem>
            <MenuItem value={"true"}>True</MenuItem>
          </Select>
        </Stack>
        <DiMaAddButton
          url={`/applications/${applicationId}/roles/${roleId}/permissions/create?noDevice=${noDevicePermissions}`}
          feature={DimaFeatureShortNameEnum.AM}
        />
      </Box>
      <EntityTab
        rows={rows}
        cols={columns}
        isLoading={isLoadingApplicationRolePermissions}
      />
    </>
  )
}
