import { StatusCodes } from "http-status-codes"
import { useEffect, useState } from "react"
import { FileGet } from "../app/models/File"
import { digitalManagerApi } from "../services/api"

export function useFiles(getPublic = false) {
  const [files, setFiles] = useState<FileGet[]>([])
  const [isLoadingFiles, setIsLoadingFiles] = useState<boolean>(true)

  useEffect(() => {
    setIsLoadingFiles(true)
    setFiles([])
    digitalManagerApi
      .get<FileGet[]>(`/api/v1/files`, {
        params: { getPublic },
      })
      .then((res) => {
        if (res.status === StatusCodes.OK) {
          setFiles(res.data)
        }
      })
      .finally(() => {
        setIsLoadingFiles(false)
      })
  }, [getPublic])

  return {
    files,
    isLoadingFiles,
  }
}
