import { useCallback, useContext, useEffect, useState } from "react"
import { UserContext } from "../app/context/UserContext"
import {
  AccessLevel,
  DimaFeatureShortNameEnum,
  hasUserAccessToFeature,
} from "../app/models/Authorization"
import { UserAccessRequest } from "../app/models/User"
import { digitalManagerApi } from "../services/api"

export function useAccessRequests() {
  const [accessRequestList, setAccessRequestList] = useState<UserAccessRequest[]>([])
  const [loadingEntities, setLoadingEntities] = useState<boolean>(true)
  const [reloadEntities, setReloadEntities] = useState<boolean>(true)
  const [accessRequestsBadge, setAccessRequestsBadge] = useState<number | undefined>(
    undefined
  )
  const { user, isLoadingUser } = useContext(UserContext)

  useEffect(() => {
    if (
      user &&
      hasUserAccessToFeature(
        user.authorizations,
        DimaFeatureShortNameEnum.BA,
        AccessLevel.Read
      ) &&
      reloadEntities === true
    ) {
      digitalManagerApi
        .get<UserAccessRequest[]>("/api/v1/backOfficeAccess/requests")
        .then((res) => {
          setAccessRequestList(res.data)
          setAccessRequestsBadge(res.data.length)
        })
        .finally(() => {
          setLoadingEntities(false)
          setReloadEntities(false)
        })
    } else {
      setLoadingEntities(false)
    }
  }, [reloadEntities, user])

  const reloadAccessRequests = useCallback(() => setReloadEntities(true), [])

  return {
    accessRequestList,
    accessRequestsBadge,
    reloadAccessRequests,
    isLoadingAccessRequest: loadingEntities || isLoadingUser,
  }
}
