import { Box } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { Link } from "react-router-dom"
import { useApplications } from "../../../../hooks/useApplications"
import { ApplicationGet } from "../../../models/Application"
import { DimaFeatureShortNameEnum } from "../../../models/Authorization"
import { EntityTab } from "../../partials/EntityTab"

export function Applications() {
  const { applicationsList, isLoadingApplications } = useApplications(
    DimaFeatureShortNameEnum.AM
  )

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      renderCell: (params) => (
        <Link to={`/applications/${params.row.id}`}>{params.row.name}</Link>
      ),
      flex: 1,
    },
    { field: "shortName", headerName: "Short", flex: 1 },
  ]

  const rows = applicationsList.map((app: ApplicationGet) => ({
    id: app.id,
    name: app.name,
    shortName: app.shortName,
  }))

  return (
    <>
      <Box className="DiMaBox-one-element-right"></Box>
      <EntityTab
        rows={rows}
        cols={columns}
        isLoading={isLoadingApplications}
        initialState={{
          sorting: { sortModel: [{ field: "name", sort: "asc" }] },
        }}
      />
    </>
  )
}
