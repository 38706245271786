import { Box } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { Link, useParams } from "react-router-dom"
import { useApplicationProperties } from "../../../../../hooks/useApplicationProperties"
import { ApplicationPropertyGet } from "../../../../models/ApplicationProperty"
import { DimaFeatureShortNameEnum } from "../../../../models/Authorization"
import { getBooleanFlagIcon } from "../../../../utils/getBooleanFlagIcon"
import { DiMaAddButton } from "../../../partials/DiMaAddButton"
import { EntityTab } from "../../../partials/EntityTab"

export function ApplicationProperties() {
  const { applicationId } = useParams()
  const { applicationProperties, isLoadingApplicationProperties } =
    useApplicationProperties(applicationId)

  const cols: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      renderCell: (params) => (
        <Link to={`/applications/${applicationId}/properties/${params.row.name}`}>
          {params.row.name}
        </Link>
      ),
      flex: 1,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
    },
    {
      field: "isRequestable",
      headerName: "Is Requestable",
      renderCell: (params) => getBooleanFlagIcon(params.row.isRequestable),
      flex: 1,
    },
  ]

  const rows = applicationProperties.map(
    (appProperty: ApplicationPropertyGet, index: number) => ({
      id: index,
      name: appProperty.name,
      type: appProperty.type,
      isRequestable: appProperty.isRequestable,
    })
  )

  return (
    <>
      <Box className="DiMaBox-one-element-right">
        <DiMaAddButton
          url={`/applications/${applicationId}/properties/create`}
          feature={DimaFeatureShortNameEnum.AM}
        />
      </Box>
      <EntityTab
        rows={rows}
        cols={cols}
        isLoading={isLoadingApplicationProperties}
        initialState={{
          sorting: { sortModel: [{ field: "name", sort: "asc" }] },
        }}
      />
    </>
  )
}
