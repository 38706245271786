import { StatusCodes } from "http-status-codes"
import { useContext, useEffect, useState } from "react"
import { UserContext } from "../app/context/UserContext"
import { DimaFeatureShortNameEnum } from "../app/models/Authorization"
import { RoleRequestGet } from "../app/models/RoleRequest"
import { digitalManagerApi } from "../services/api"

export function useRoleRequestsBadge() {
  const [roleRequestsBadge, setRoleRequestsBadge] = useState<number | undefined>(
    undefined
  )
  const [isLoadingRoleRequestsBadge, setIsLoadingRoleRequestsBadge] =
    useState<boolean>(true)
  const { user, isLoadingUser } = useContext(UserContext)

  useEffect(() => {
    if (
      !isLoadingUser &&
      user &&
      user.authorizations.applications.find(
        (app) => app.featuresAccesses[DimaFeatureShortNameEnum.RR] >= 1
      ) != null
    ) {
      setIsLoadingRoleRequestsBadge(true)
      digitalManagerApi
        .get<RoleRequestGet[]>(`/api/v1/roleRequests`, {
          params: { completed: false },
        })
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            const roleRequestsNeedAction = res.data.filter(
              (rr) =>
                rr.approvals.filter(
                  (a) => a.userApproverType !== null && a.completionDate === null
                ).length > 0
            ).length
            setRoleRequestsBadge(roleRequestsNeedAction)
          }
        })
        .finally(() => {
          setIsLoadingRoleRequestsBadge(false)
        })
    } else {
      setRoleRequestsBadge(0)
      setIsLoadingRoleRequestsBadge(false)
    }
  }, [isLoadingUser, user])

  return {
    roleRequestsBadge,
    isLoadingRoleRequestsBadge: isLoadingRoleRequestsBadge || isLoadingUser,
  }
}
