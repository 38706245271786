import { Box, Checkbox, Chip, Stack, Typography } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { useCallback, useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useRoleRequests } from "../../../../hooks/useRoleRequests"
import { useRoleRequestsBadge } from "../../../../hooks/useRoleRequestsBadge"
import { AppContext } from "../../../context/AppContext"
import { RoleRequestGet } from "../../../models/RoleRequest"
import {
  dateStringComparator,
  getNiceFormattedDate,
} from "../../../utils/getFormattedDate"
import { getRoleRequestStatusProps } from "../../../utils/getRoleRequestStatusProps"
import { EntityTab } from "../../partials/EntityTab"

export function RoleRequests() {
  const { badges, setBadges } = useContext(AppContext)

  const [getCompleted, setGetCompleted] = useState<boolean>(false)
  const { roleRequestsList, isLoadingRoleRequests } = useRoleRequests(getCompleted)
  const { roleRequestsBadge } = useRoleRequestsBadge()

  useEffect(() => {
    if (roleRequestsBadge !== undefined && badges.roleRequests !== roleRequestsBadge) {
      setBadges({
        ...badges,
        roleRequests: roleRequestsBadge,
      })
    }
  }, [badges, setBadges, roleRequestsBadge])

  const columns: GridColDef[] = [
    {
      field: "creationDate",
      headerName: "Creation date",
      renderCell: (params) => (
        <Link to={`/role-requests/${params.row.id}`}>
          {getNiceFormattedDate(params.row.creationDate)}
        </Link>
      ),
      sortComparator: dateStringComparator,
      flex: 2,
    },
    {
      field: "username",
      headerName: "Requestor",
      flex: 2,
    },
    {
      field: "roleName",
      headerName: "Role",
      valueGetter: (params) =>
        `${params.row.applicationShortName} - ${params.row.roleName}`,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => (
        <Chip sx={{ width: "100px" }} {...getRoleRequestStatusProps(params.row.status)} />
      ),
      flex: 1,
    },
  ]

  const rows = roleRequestsList.map((roleRequest: RoleRequestGet) => ({
    id: roleRequest.id,
    username: roleRequest.user.username,
    roleName: roleRequest.role.name,
    applicationShortName: roleRequest.application.shortName,
    status: roleRequest.status,
    creationDate: roleRequest.creationDate,
  }))

  const onChange = useCallback(() => {
    setGetCompleted((completed) => !completed)
  }, [setGetCompleted])

  return (
    <>
      <Box className="DiMaBox-one-element-left">
        <Stack direction="row">
          <Checkbox edge="start" checked={getCompleted} onChange={onChange} />
          <Typography variant="body1">Include completed requests</Typography>
        </Stack>
      </Box>
      <EntityTab
        rows={rows}
        cols={columns}
        isLoading={isLoadingRoleRequests}
        initialState={{
          sorting: { sortModel: [{ field: "creationDate", sort: "asc" }] },
        }}
      />
    </>
  )
}
