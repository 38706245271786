import CancelIcon from "@mui/icons-material/Cancel"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import SaveIcon from "@mui/icons-material/Save"
import LoadingButton from "@mui/lab/LoadingButton"
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@mui/material"
import { AxiosResponse } from "axios"
import { FormikProps } from "formik"
import { ReactNode, useCallback, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { DiMaProgress } from "./DiMaProgress"

export interface DiMaDetailsContentItem {
  label?: string
  displayItem?: ReactNode
  editItem?: ReactNode
}

export interface DiMaDetailsContentProps<T = unknown> {
  readonly label: string
  readonly formik?: FormikProps<T>
  readonly isEditable?: boolean
  readonly isDeletable?: boolean
  readonly canWrite?: boolean
  readonly deleteAction?: () => Promise<AxiosResponse>
  readonly onDeleteReturnUrl?: string
  readonly listItems: DiMaDetailsContentItem[]
}

export function DiMaDetailsContent<T>(props: DiMaDetailsContentProps<T>) {
  const {
    label,
    formik,
    isEditable = true,
    isDeletable = true,
    canWrite = true,
    deleteAction,
    onDeleteReturnUrl,
    listItems,
  } = props
  const [open, setOpen] = useState<boolean>(false)
  const [editing, setEditing] = useState<boolean>(false)
  const [deleting, setDeleting] = useState<boolean>(false)
  const navigate = useNavigate()
  const headerTitle = `${label} details`

  const setEditingTrue = useCallback(() => {
    setEditing(true)
  }, [])

  const setEditingFalseWithFormReset = useCallback(() => {
    setEditing(false)
    if (formik) {
      formik.resetForm()
    }
  }, [formik])

  const setEditingFalseAfterSubmitForm = useCallback(() => {
    if (formik) {
      formik.submitForm().then(() => {
        setEditing(false)
      })
    }
  }, [formik])

  const setOpenTrue = useCallback(() => {
    setOpen(true)
  }, [])

  const setOpenFalse = useCallback(() => {
    setOpen(false)
  }, [])

  const setDeletingTrue = useCallback(() => {
    setDeleting(true)
    if (deleteAction && onDeleteReturnUrl) {
      deleteAction()
        .then(() => {
          toast.success(`${label} deleted`)
          navigate(onDeleteReturnUrl)
        })
        .catch(() => {
          toast.error(`Cannot delete the ${label}`)
          setOpen(false)
          setDeleting(false)
        })
    }
  }, [deleteAction, label, navigate, onDeleteReturnUrl])

  function getCardHeader(edit: boolean) {
    if (!edit) {
      return (
        <CardHeader
          title={headerTitle}
          action={
            <Button
              disabled={!canWrite}
              variant="outlined"
              startIcon={<EditIcon />}
              onClick={setEditingTrue}
            >
              Edit
            </Button>
          }
        />
      )
    }
    return (
      <CardHeader
        title="Editing"
        action={
          <Stack direction="row" spacing={1}>
            {isDeletable && onDeleteReturnUrl && deleteAction && (
              <>
                <Button
                  variant="outlined"
                  color="error"
                  startIcon={<DeleteIcon />}
                  sx={{
                    marginRight: "15px",
                  }}
                  onClick={setOpenTrue}
                  disabled={formik?.isSubmitting}
                >
                  Delete {label}
                </Button>
                <Dialog open={open} onClose={setOpenFalse}>
                  <DialogTitle>Are you sure to delete the {label}?</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      The {label} will be deleted. This operation cannot be undone.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={setOpenFalse} disabled={deleting}>
                      Cancel
                    </Button>
                    <LoadingButton
                      startIcon={<DeleteIcon />}
                      onClick={setDeletingTrue}
                      autoFocus
                      variant="contained"
                      color="error"
                      loading={deleting}
                    >
                      Delete
                    </LoadingButton>
                  </DialogActions>
                </Dialog>
              </>
            )}
            <Button
              variant="outlined"
              startIcon={<CancelIcon />}
              disabled={formik?.isSubmitting}
              onClick={setEditingFalseWithFormReset}
            >
              Cancel
            </Button>
            {formik && isEditable && (
              <LoadingButton
                disabled={!formik.dirty || !formik.isValid}
                variant="contained"
                startIcon={<SaveIcon />}
                type="submit"
                loading={formik.isSubmitting}
                onClick={setEditingFalseAfterSubmitForm}
              >
                Save
              </LoadingButton>
            )}
          </Stack>
        }
      />
    )
  }
  return (
    <Paper>
      <Card>
        {isEditable || isDeletable ? (
          getCardHeader(editing)
        ) : (
          <CardHeader title={headerTitle} />
        )}
        <Divider />
        <CardContent
          sx={{
            padding: 0,
            paddingLeft: "16px",
            paddingRight: "16px",
          }}
        >
          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
            }}
          >
            {formik?.isSubmitting ? (
              <DiMaProgress />
            ) : (
              listItems.map((item, index) => (
                <ListItem key={index} divider>
                  <ListItemText>
                    {item.label ? (
                      <Grid
                        container
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Grid item xs={2}>
                          <Typography variant="h6">{item.label}</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
                          {editing && item.editItem ? item.editItem : item.displayItem}
                        </Grid>
                      </Grid>
                    ) : (
                      (!editing && item.displayItem) || (editing && item.editItem)
                    )}
                  </ListItemText>
                </ListItem>
              ))
            )}
          </List>
        </CardContent>
      </Card>
    </Paper>
  )
}
