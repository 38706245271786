import SearchIcon from "@mui/icons-material/Search"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from "@mui/material"
import { useFormik } from "formik"
import { StatusCodes } from "http-status-codes"
import { ChangeEvent, useCallback, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { object, string } from "yup"
import { digitalManagerApi } from "../../../../services/api"
import { BackToURL } from "../../../components/BackToURL"
import { UserExistsGet, UserGet } from "../../../models/User"

const userValidationSchema = object({
  first: string().required("First name is required"),
  last: string().required("Last name is required"),
})

const searchValidationSchema = object({
  username: string().email().required("Email is required"),
})

export function UserCreate() {
  const navigate = useNavigate()
  const returnURL = `/users`
  const [disableInput, setDisableInput] = useState<boolean>(true)
  const [userFound, setUserFound] = useState<boolean>(false)
  const [missingInformation, setMissingInformation] = useState<boolean>(false)

  const formik = useFormik({
    validationSchema: userValidationSchema,
    initialValues: {
      first: "",
      last: "",
    },
    onSubmit: (values, { setSubmitting }) => {
      digitalManagerApi
        .post<UserGet>(`/api/v1/users`, {
          first: values.first,
          last: values.last,
          username: Searchformik.values.username,
        })
        .then((res) => {
          if (res.status === StatusCodes.CREATED && res.data.id) {
            toast.success("User added")
            navigate(`/users/${res.data.id}`)
          }
        })
        .catch((err) => {
          if (
            err.response.status === StatusCodes.BAD_REQUEST &&
            err.response.data &&
            err.response.data.errorMessage &&
            err.response.data.errorMessage.indexOf("already exists")
          ) {
            toast.warning("An user with this email already exists")
          } else {
            toast.error("Cannot add the user")
          }
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
  })

  const Searchformik = useFormik({
    validationSchema: searchValidationSchema,
    initialValues: {
      username: "",
    },
    validateOnMount: true,
    onSubmit: (values, { setSubmitting }) => {
      digitalManagerApi
        .get<UserExistsGet>(`/api/v1/users/userByUsername`, {
          params: { username: values.username },
        })
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            if (!res.data.alreadyExists) {
              toast.success("User found on Active Directory")
              formik.setValues({
                first: res.data.first ? res.data.first : "",
                last: res.data.last ? res.data.last : "",
              })

              if (!res.data.first || !res.data.last) {
                setDisableInput(false)
                setMissingInformation(true)
              } else {
                setUserFound(true)
              }
            } else {
              toast.warning("User already exist")
              navigate(`/users/${res.data.id}`)
            }
          }
        })
        .catch((err) => {
          if (err.response.status === StatusCodes.NOT_FOUND) {
            toast.error("User not found on Active Directory")
          } else {
            toast.warning("Unknown error")
          }
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
  })

  const submitForm = useCallback(() => {
    formik.submitForm()
  }, [formik])

  const submitFormSearch = useCallback(
    (e) => {
      if (e.code === "Enter" && Searchformik.isValid) {
        Searchformik.submitForm()
      }
    },
    [Searchformik]
  )

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.value) {
        Searchformik.setFieldValue("username", event.target.value)
        if (formik.values !== formik.initialValues) {
          formik.setValues(formik.initialValues)
          setDisableInput(true)
        }
        if (missingInformation) {
          setMissingInformation(false)
        }
        if (userFound) {
          setUserFound(false)
        }
      }
    },
    [Searchformik, formik, missingInformation, userFound]
  )

  const submitFormSearchOnClick = useCallback(() => {
    Searchformik.submitForm()
  }, [Searchformik])

  return (
    <Box>
      <BackToURL url={returnURL} label="Back to users" />

      <Paper>
        <Card>
          <CardHeader
            title="Add new user"
            action={
              <LoadingButton
                variant="contained"
                loading={formik.isSubmitting}
                onClick={submitForm}
                disabled={!formik.dirty || !formik.isValid}
              >
                Save
              </LoadingButton>
            }
          />
          <CardContent
            sx={{
              padding: 0,
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
          >
            <Divider />
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
              }}
            >
              <ListItem key={0}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Email address</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Paper
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <TextField
                          fullWidth
                          placeholder="Search email address on Active Directory"
                          onKeyDown={submitFormSearch}
                          onChange={onChange}
                        />
                        <LoadingButton
                          onClick={submitFormSearchOnClick}
                          disabled={!Searchformik.isValid}
                          variant="contained"
                          loading={Searchformik.isSubmitting}
                        >
                          <SearchIcon />
                        </LoadingButton>
                      </Paper>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>

              {(userFound || missingInformation) && (
                <ListItem key={1}>
                  <ListItemText>
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Grid item xs={2}>
                        <Typography variant="h6">First name</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        {missingInformation ? (
                          <TextField
                            disabled={disableInput}
                            fullWidth
                            id="first"
                            name="first"
                            type="text"
                            value={formik.values.first}
                            onChange={formik.handleChange}
                            error={formik.touched.first && Boolean(formik.errors.first)}
                            helperText={formik.touched.first && formik.errors.first}
                            size="small"
                          />
                        ) : (
                          <Typography>{formik.values.first}</Typography>
                        )}
                      </Grid>
                    </Grid>
                  </ListItemText>
                </ListItem>
              )}
              {(userFound || missingInformation) && (
                <ListItem key={2}>
                  <ListItemText>
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Grid item xs={2}>
                        <Typography variant="h6">Last name</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        {missingInformation ? (
                          <TextField
                            fullWidth
                            disabled={disableInput}
                            id="last"
                            name="last"
                            type="text"
                            value={formik.values.last}
                            onChange={formik.handleChange}
                            error={formik.touched.last && Boolean(formik.errors.last)}
                            helperText={formik.touched.last && formik.errors.last}
                            size="small"
                          />
                        ) : (
                          <Typography>{formik.values.last}</Typography>
                        )}
                      </Grid>
                    </Grid>
                  </ListItemText>
                </ListItem>
              )}

              {(missingInformation || userFound) && (
                <ListItem key={3}>
                  <ListItemText>
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Grid item xs={8}>
                        {missingInformation ? (
                          <Typography sx={{ color: "orange" }}>
                            User found on Active Directory, complete the missing
                            information
                          </Typography>
                        ) : (
                          <Typography sx={{ color: "green" }}>
                            User found on Active Directory, click save to add the user.
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </ListItemText>
                </ListItem>
              )}
            </List>
          </CardContent>
        </Card>
      </Paper>
    </Box>
  )
}
