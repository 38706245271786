import { StatusCodes } from "http-status-codes"
import { useEffect, useState } from "react"
import { DeviceRolePermissionGet } from "../app/models/DeviceRolePermission"
import { digitalManagerApi } from "../services/api"

export function useDeviceRolePermissions(deviceId?: string, roleId?: string) {
  const [deviceRolePermissions, setDeviceRolePermissions] = useState<
    DeviceRolePermissionGet[]
  >([])
  const [isLoadingDeviceRolePermissions, setIsLoadingDeviceRolePermissions] =
    useState<boolean>(true)

  useEffect(() => {
    setIsLoadingDeviceRolePermissions(true)
    setDeviceRolePermissions([])
    if (deviceId && roleId) {
      digitalManagerApi
        .get<DeviceRolePermissionGet[]>(
          `/api/v1/devices/${deviceId}/roles/${roleId}/permissions`
        )
        .then((res) => {
          if (res.status === StatusCodes.OK && res.data.length) {
            setDeviceRolePermissions(res.data)
          }
        })
        .finally(() => {
          setIsLoadingDeviceRolePermissions(false)
        })
    } else {
      setIsLoadingDeviceRolePermissions(false)
    }
  }, [deviceId, roleId])

  return {
    deviceRolePermissions,
    isLoadingDeviceRolePermissions,
  }
}
