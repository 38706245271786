import { TabContext, TabList } from "@mui/lab"
import { Box } from "@mui/material"
import { StatusCodes } from "http-status-codes"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom"
import { digitalManagerApi } from "../../../../../../../services/api"
import { BackToURL } from "../../../../../../components/BackToURL"
import {
  DiMaDetailsHeader,
  DiMaDetailsHeaderProps,
} from "../../../../../../components/DiMaDetailsHeader"
import { DiMaProgress } from "../../../../../../components/DiMaProgress"
import { DiMaTabPanel } from "../../../../../../components/DiMaTabPanel"
import { LinkTab } from "../../../../../../components/LinkTab"
import { UserContext } from "../../../../../../context/UserContext"
import { ApplicationRoleApproverGet } from "../../../../../../models/ApplicationRoleApprover"
import { ApplicationRoleApproverPropertyDetails } from "./details_tab/ApplicationRoleApproverPropertyDetails"

export function ApplicationRoleApproverPropertyContainer() {
  const { applicationId, roleId, userId, propertyName, propertyValue } = useParams()
  const { user, isLoadingUser } = useContext(UserContext)
  const { hash } = useLocation()
  const [activeTab, setActiveTab] = useState<string>("#details")
  const [approver, setApprover] = useState<ApplicationRoleApproverGet>()

  const navigate = useNavigate()

  const returnURL = `/applications/${applicationId}/roles/${roleId}/approvers/${userId}`

  useEffect(() => {
    if (applicationId && roleId && userId && propertyName && propertyValue) {
      digitalManagerApi
        .get(
          `/api/v1/applications/${applicationId}/roles/${roleId}/approvers/${userId}/properties/${propertyName}/values/${propertyValue}`
        )
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            setApprover(res.data)
          }
        })
        .catch(() => {
          navigate(returnURL)
        })
    }
  }, [applicationId, navigate, propertyName, propertyValue, returnURL, roleId, userId])

  useEffect(() => {
    if (hash) {
      setActiveTab(hash)
    }
  }, [hash])

  const handleChange = useCallback((_: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue)
  }, [])

  if (!applicationId || !roleId || !userId) {
    return <Navigate to={returnURL} />
  }

  if (!approver || isLoadingUser || !user) {
    return <DiMaProgress />
  }

  const detailsHeaderProps: DiMaDetailsHeaderProps = {
    avatar: approver.application.shortName,
    title: `${approver.user.first} ${approver.user.last}`,
    label: "Role",
    subtitle: approver.role.name,
    hideCopyToClipboard: true,
  }

  return (
    <>
      <BackToURL url={returnURL} label="Back to application role approvers" />

      <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <DiMaDetailsHeader {...detailsHeaderProps} />
        <TabContext value={activeTab}>
          <Box className="DiMaBox-tabs">
            <TabList onChange={handleChange}>
              <LinkTab label="Details" value="#details" />
            </TabList>
          </Box>
          <DiMaTabPanel label="#details" activeTab={activeTab}>
            <ApplicationRoleApproverPropertyDetails
              approver={approver}
              user={user}
              setApprover={setApprover}
            />
          </DiMaTabPanel>
        </TabContext>
      </Box>
    </>
  )
}
