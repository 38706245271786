import { StatusCodes } from "http-status-codes"
import { useEffect, useState } from "react"
import { ApplicationRoleApproverGet } from "../app/models/ApplicationRoleApprover"
import { Guid } from "../app/models/CustomType"
import { digitalManagerApi } from "../services/api"

export function useApprovers(applicationId?: Guid, roleId?: Guid) {
  const [approversList, setApproversList] = useState<ApplicationRoleApproverGet[]>([])
  const [isLoadingApprovers, setIsLoadingApprovers] = useState<boolean>(true)

  useEffect(() => {
    if (applicationId && roleId) {
      digitalManagerApi
        .get<ApplicationRoleApproverGet[]>(
          `/api/v1/applications/${applicationId}/roles/${roleId}/approvers`
        )
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            setApproversList(res.data)
          }
        })
        .finally(() => {
          setIsLoadingApprovers(false)
        })
    } else {
      setIsLoadingApprovers(false)
    }
  }, [applicationId, roleId])

  return {
    approversList,
    isLoadingApprovers,
  }
}
