import DeleteIcon from "@mui/icons-material/Delete"
import { Box } from "@mui/material"
import { GridActionsCellItem, GridColumns, GridRowParams } from "@mui/x-data-grid"
import { StatusCodes } from "http-status-codes"
import { useCallback } from "react"
import { toast } from "react-toastify"
import { digitalManagerApi } from "../../../../../../../services/api"
import {
  AccessLevel,
  DimaFeatureShortNameEnum,
  hasUserAccessToFeature,
} from "../../../../../../models/Authorization"
import {
  DeviceRolePermissionGet,
} from "../../../../../../models/DeviceRolePermission"
import { UserInfo } from "../../../../../../models/User"
import { DiMaAddButton } from "../../../../../partials/DiMaAddButton"
import { EntityTab } from "../../../../../partials/EntityTab"
import { ApplicationRoleDetail } from "../../../../../../models/ApplicationRole"

export function DeviceRolePermissionApplicationRoles(props: {
  readonly deviceRolePermission: DeviceRolePermissionGet
  readonly reloadDeviceRolePermission: () => void
  readonly user: UserInfo
}) {
  const { deviceRolePermission, reloadDeviceRolePermission, user } = props

  const onClick = useCallback(
    (params: GridRowParams) => () => {
      digitalManagerApi
        .delete(
          `/api/v1/devices/${deviceRolePermission.device.id}/roles/${deviceRolePermission.role.id}/permissions/${deviceRolePermission.permission.id}/applicationRoles/${params.row.id}`
        )
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            toast.success("Application roles deleted")
          }
        })
        .catch(() => {
          toast.error("Cannot delete the application role")
        })
        .finally(() => {
          reloadDeviceRolePermission()
        })
    },
    [
      deviceRolePermission.device.id,
      deviceRolePermission.permission.id,
      deviceRolePermission.role.id,
      reloadDeviceRolePermission,
    ]
  )

  const columns: GridColumns<ApplicationRoleDetail> = [
    { field: "name", headerName: "Role", flex: 1 },
    {
      field: "actions",
      type: "actions",
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          key="delete"
          icon={<DeleteIcon color="error" />}
          onClick={onClick(params)}
          label="Delete"
          showInMenu
          disabled={
            !hasUserAccessToFeature(
              user.authorizations,
              DimaFeatureShortNameEnum.DM,
              AccessLevel.Write
            )
          }
        />,
      ],
    },
  ]

  const deviceRolePermissionApplicationRolesRows =
    deviceRolePermission.applicationRoles.map(
      (applicationRole: ApplicationRoleDetail) => ({
        id: applicationRole.id,
        name: applicationRole.name,
      })
    )

  return (
    <>
      <Box className="DiMaBox-one-element-right">
        <DiMaAddButton
          url={`/devices/${deviceRolePermission.device.id}/roles/${deviceRolePermission.role.id}/permissions/${deviceRolePermission.permission.id}/applicationRoles/create`}
          feature={DimaFeatureShortNameEnum.AM}
        />
      </Box>
      <EntityTab
        rows={deviceRolePermissionApplicationRolesRows}
        cols={columns}
        isLoading={false}
        initialState={{
          sorting: { sortModel: [{ field: "name", sort: "asc" }] },
        }}
      />
    </>
  )
}
