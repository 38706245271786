import { StatusCodes } from "http-status-codes"
import { useEffect, useState } from "react"
import { FileTypeGet } from "../app/models/FileType"
import { digitalManagerApi } from "../services/api"

export function useFileTypes() {
  const [fileTypes, setFileTypes] = useState<FileTypeGet[]>([])
  const [isLoadingFileTypes, setIsLoadingFileTypes] = useState<boolean>(true)

  useEffect(() => {
    setIsLoadingFileTypes(true)
    setFileTypes([])
    digitalManagerApi
      .get<FileTypeGet[]>(`/api/v1/fileTypes`)
      .then((res) => {
        if (res.status === StatusCodes.OK) {
          setFileTypes(res.data)
        }
      })
      .finally(() => {
        setIsLoadingFileTypes(false)
      })
  }, [])

  return {
    fileTypes,
    isLoadingFileTypes,
  }
}
