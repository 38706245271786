import { Logout } from "@mui/icons-material"
import AppsIcon from "@mui/icons-material/Apps"
import CableIcon from "@mui/icons-material/Cable"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import FolderCopyIcon from "@mui/icons-material/FolderCopy"
import HomeIcon from "@mui/icons-material/Home"
import HowToRegIcon from "@mui/icons-material/HowToReg"
import LocalShippingIcon from "@mui/icons-material/LocalShipping"
import NotificationsIcon from "@mui/icons-material/Notifications"
import PeopleAltIcon from "@mui/icons-material/PeopleAlt"
import PersonIcon from "@mui/icons-material/Person"
import PersonSearchIcon from "@mui/icons-material/PersonSearch"
import SettingsIcon from "@mui/icons-material/Settings"
import SummarizeIcon from "@mui/icons-material/Summarize"
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Container,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material"
import { ReactNode, useCallback, useContext, useEffect, useState } from "react"
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import abbLogo from "../../assets/logos/abb-logo.png"
import { useAccessRequests } from "../../hooks/useAccessRequests"
import { useRoleRequestsBadge } from "../../hooks/useRoleRequestsBadge"
import { DiMaProgress } from "../components/DiMaProgress"
import { AppContext } from "../context/AppContext"
import { UserContext } from "../context/UserContext"
import {
  AccessLevel,
  DimaFeatureShortNameEnum,
  hasUserAccessToFeature,
} from "../models/Authorization"
import { UserInfo } from "../models/User"
import { copyToClipboard } from "../utils/copyToClipboard"
import { NotAuthorized } from "./not_authorized_page/NotAuthorized"

const DRAWER_WIDTH = 240
const AVATAR_LENGTH = 2

const getFirstUsernameLetters = (username: string) => {
  if (username.indexOf(".") !== -1) {
    const pcs = username.split(".")
    return pcs[0][0] + pcs[1][0]
  } else {
    return username.substring(0, AVATAR_LENGTH)
  }
}

interface MenuEntry {
  path: string
  text: string
  icon: ReactNode
  feature: string
  accessLevel: AccessLevel
  badge?: number
}

const redLineCSS = "4px solid #ff000f"
const greyLineCSS = "4px solid #bababa"

const settingsPath = "/settings"

const userHasRoleManagerReadAccess = (user: UserInfo | undefined) =>
  user?.authorizations.applications.find((app) => app.featuresAccesses["RM"]) != null

export function Layout() {
  const { badges, setBadges } = useContext(AppContext)
  const { user, isLoadingUser } = useContext(UserContext)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openUserMenu = Boolean(anchorEl)
  const location = useLocation()
  const navigate = useNavigate()
  const { isLoadingAccessRequest, accessRequestsBadge } = useAccessRequests()
  const { isLoadingRoleRequestsBadge, roleRequestsBadge } = useRoleRequestsBadge()

  const navigateToCurrentUserTab = useCallback(() => {
    if (user) {
      navigate(`/users/${user?.id}`)
    }
  }, [navigate, user])

  useEffect(() => {
    if (accessRequestsBadge !== undefined && roleRequestsBadge !== undefined) {
      setBadges({
        roleRequests: roleRequestsBadge,
        accessRequests: accessRequestsBadge,
      })
    }
  }, [setBadges, roleRequestsBadge, accessRequestsBadge])

  const menuItems: MenuEntry[] = [
    {
      path: "/activeDirectory",
      text: "AD Explorer",
      icon: <PersonSearchIcon />,
      feature: DimaFeatureShortNameEnum.AD,
      accessLevel: AccessLevel.Read,
    },
    {
      path: "/applications",
      text: "Applications",
      icon: <AppsIcon />,
      feature: DimaFeatureShortNameEnum.AM,
      accessLevel: AccessLevel.Read,
    },
    {
      path: "/notifications",
      text: "Notifications",
      icon: <NotificationsIcon />,
      feature: DimaFeatureShortNameEnum.NM,
      accessLevel: AccessLevel.Read,
    },
    {
      path: "/role-requests",
      text: "Role requests",
      icon: <HowToRegIcon />,
      feature: DimaFeatureShortNameEnum.RR,
      accessLevel: AccessLevel.Read,
      badge: badges.roleRequests,
    },
    {
      path: "/devices",
      text: "Devices",
      icon: <CableIcon />,
      feature: DimaFeatureShortNameEnum.DM,
      accessLevel: AccessLevel.Read,
    },
    {
      path: "/files",
      text: "Files",
      icon: <FolderCopyIcon />,
      feature: DimaFeatureShortNameEnum.FM,
      accessLevel: AccessLevel.Read,
    },
    {
      path: "/test-reports",
      text: "Test reports",
      icon: <SummarizeIcon />,
      feature: DimaFeatureShortNameEnum.TR,
      accessLevel: AccessLevel.Read,
    },
    {
      path: "/users",
      text: "Users",
      icon: <PeopleAltIcon />,
      feature: DimaFeatureShortNameEnum.RM,
      accessLevel: AccessLevel.Read,
      badge: badges.accessRequests,
    },
    {
      path: "/supplierPortal",
      text: "Supplier Portal",
      icon: <LocalShippingIcon />,
      feature: DimaFeatureShortNameEnum.SP,
      accessLevel: AccessLevel.Write,
    },
  ]

  const isDevEnv = window.location.host !== "dima.abb.com"

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const copyToClipboardJwt = useCallback(() => {
    const jwt = window.localStorage.getItem("lastAccessToken")
    if (jwt) {
      copyToClipboard(jwt)
    }
  }, [])

  if (!user || isLoadingUser || isLoadingAccessRequest || isLoadingRoleRequestsBadge) {
    return (
      <Container sx={{ display: "flex", height: "100vh" }}>
        <DiMaProgress />
      </Container>
    )
  }

  if (!user.hasAccessToDima || !user.authorizations) {
    return <NotAuthorized />
  }

  const userHasSettingsAccess = hasUserAccessToFeature(
    user.authorizations,
    DimaFeatureShortNameEnum.RR,
    AccessLevel.Read
  )

  const drawer = (
    <>
      <Toolbar />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box>
          <List sx={{ padding: 0 }}>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to="/"
                sx={{
                  borderLeft: location.pathname === "/" ? redLineCSS : greyLineCSS,
                }}
              >
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Home" />
              </ListItemButton>
            </ListItem>
            <Divider
              sx={{
                borderLeft: greyLineCSS,
              }}
            />
            {menuItems.map(
              (item) =>
                hasUserAccessToFeature(
                  user.authorizations,
                  item.feature,
                  item.accessLevel
                ) && (
                  <ListItem disablePadding key={item.path}>
                    <ListItemButton
                      component={Link}
                      to={item.path}
                      sx={{
                        borderLeft: location.pathname.startsWith(item.path)
                          ? redLineCSS
                          : greyLineCSS,
                      }}
                    >
                      <ListItemIcon>
                        <Badge badgeContent={item.badge} color="error">
                          {item.icon}
                        </Badge>
                      </ListItemIcon>

                      <ListItemText>
                        <Typography>{item.text}</Typography>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                )
            )}
          </List>
        </Box>
        {userHasSettingsAccess && (
          <Box>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to={settingsPath}
                sx={{
                  borderLeft: location.pathname.startsWith(settingsPath)
                    ? redLineCSS
                    : greyLineCSS,
                }}
              >
                <ListItemIcon>
                  <Badge color="error">
                    <SettingsIcon />
                  </Badge>
                </ListItemIcon>
                <ListItemText>
                  <Typography color={"black"}>{"Settings"}</Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          </Box>
        )}
      </Box>
    </>
  )

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          boxShadow: "4px 4px 6px -4px rgb(0 0 0 / 80%)",
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Link to="/">
            <img src={abbLogo} />
          </Link>
          <Typography variant="h6" noWrap component="div">
            Digital ELSP Manager Backoffice
          </Typography>
          {user.username && (
            <>
              <Tooltip title="User profile" sx={{ textAlign: "right" }}>
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={openUserMenu ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openUserMenu ? "true" : undefined}
                >
                  <Avatar sx={{ width: 43, height: 43, textTransform: "uppercase" }}>
                    {getFirstUsernameLetters(user.username)}
                  </Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={openUserMenu}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                {isDevEnv && (
                  <div>
                    <List>
                      <ListItem>
                        <ListItemButton
                          component="button"
                          onClick={copyToClipboardJwt}
                          sx={{
                            minHeight: 30,
                            px: 2.5,
                          }}
                        >
                          <ListItemIcon>
                            <ContentCopyIcon fontSize="small" />
                          </ListItemIcon>
                          Get JWT Token
                        </ListItemButton>
                      </ListItem>
                      {userHasRoleManagerReadAccess(user) && (
                        <ListItem>
                          <ListItemButton
                            component="button"
                            onClick={navigateToCurrentUserTab}
                            sx={{
                              minHeight: 30,
                              px: 2.5,
                            }}
                          >
                            <ListItemIcon>
                              <PersonIcon fontSize="small" />
                            </ListItemIcon>
                            MyProfile
                          </ListItemButton>
                        </ListItem>
                      )}
                    </List>
                    <Divider />
                  </div>
                )}
                <MenuItem>
                  <ListItemButton
                    component="a"
                    href="/logout"
                    sx={{
                      minHeight: 48,
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </ListItemButton>
                </MenuItem>
              </Menu>
            </>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: DRAWER_WIDTH,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: DRAWER_WIDTH,
            boxSizing: "border-box",
          },
          boxShadow: "4px 4px 4px -4px rgb(0 0 0 / 80%)",
        }}
      >
        {drawer}
      </Drawer>
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          p: 3,
          height: "100vh",
        }}
      >
        <Toolbar />
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
          }}
        >
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnHover={false}
            pauseOnFocusLoss={false}
            draggable
            theme="colored"
          />
          <Outlet />
        </Container>
      </Box>
    </Box>
  )
}
