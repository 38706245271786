import { StatusCodes } from "http-status-codes"
import { useEffect, useState } from "react"
import { UserDetail } from "../app/models/User"
import { digitalManagerApi } from "../services/api"

export function useUsers(onlyWithBackofficeAccess: boolean) {
  const [usersList, setUsersList] = useState<UserDetail[]>([])
  const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(true)

  useEffect(() => {
    digitalManagerApi
      .get<UserDetail[]>(`/api/v1/users`, {
        params: { onlyWithBackofficeAccess },
      })
      .then((res) => {
        if (res.status === StatusCodes.OK) {
          setUsersList(res.data)
        }
      })
      .finally(() => {
        setIsLoadingUsers(false)
      })
  }, [onlyWithBackofficeAccess])

  return {
    usersList,
    isLoadingUsers,
  }
}
