import { LoadingButton } from "@mui/lab"
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from "@mui/material"
import { useFormik } from "formik"
import { StatusCodes } from "http-status-codes"
import { SyntheticEvent, useCallback, useEffect, useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"
import { boolean, object, string } from "yup"
import { useApplicationPermissions } from "../../../../../../hooks/useApplicationPermissions"
import { digitalManagerApi } from "../../../../../../services/api"
import { BackToURL } from "../../../../../components/BackToURL"
import { ApplicationPermissionGet } from "../../../../../models/ApplicationPermission"
import { ApplicationRolePermissionGet } from "../../../../../models/ApplicationRolePermission"
import { Guid } from "../../../../../models/CustomType"

const validationSchema = object({
  permissionId: string().required("Permission is required"),
  noDevice: boolean().required("No Device is required"),
})

const handleAutocompleteRenderInput = (params: AutocompleteRenderInputParams) => (
  <TextField {...params} />
)

const handleAutocompleteGetOptionLabel = (
  applicationPermissions: ApplicationPermissionGet
) => applicationPermissions.name

export function ApplicationRolePermissionCreate() {
  const { applicationId, roleId } = useParams()
  const [searchParams] = useSearchParams()
  const noDeviceUrl = searchParams.get("noDevice")
    ? searchParams.get("noDevice") === "true"
    : false
  const { applicationPermissions, isLoadingApplicationPermissions } =
    useApplicationPermissions(applicationId)
  const [applicationRolePermissionsIds, setApplicationRolePermissionsIds] = useState<
    Guid[]
  >([])
  const navigate = useNavigate()
  const returnURL = `/applications/${applicationId}/roles/${roleId}?noDevice=${noDeviceUrl}#permissions`

  const formik = useFormik({
    validationSchema,
    initialValues: {
      permissionId: "",
      noDevice: noDeviceUrl,
    },
    onSubmit: (values, { setSubmitting }) => {
      digitalManagerApi
        .post(
          `/api/v1/applications/${applicationId}/roles/${roleId}/permissions/${values.permissionId}`,
          {
            noDevice: values.noDevice,
          }
        )
        .then((res) => {
          if (res.status === StatusCodes.CREATED) {
            toast.success("Permission added to role")
            navigate(returnURL)
          }
        })
        .catch(() => {
          toast.error("Cannot add permission to role")
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
  })

  const handleChangeFormikValues = useCallback(
    (
      _e: SyntheticEvent<Element, Event>,
      selectedApplicationPermission: ApplicationPermissionGet | null
    ) => {
      if (selectedApplicationPermission) {
        formik.setValues({
          ...formik.values,
          permissionId: selectedApplicationPermission.id,
        })
      }
    },
    [formik]
  )
  const noDeviceValue = formik.values.noDevice

  useEffect(() => {
    if (applicationId && roleId) {
      digitalManagerApi
        .get<ApplicationRolePermissionGet[]>(
          `/api/v1/applications/${applicationId}/roles/${roleId}/permissions?noDevice=${noDeviceValue}`
        )
        .then((res) => {
          if (res.status === StatusCodes.OK && res.data && res.data.length) {
            setApplicationRolePermissionsIds(
              res.data.map(
                (applicationRolePermission: ApplicationRolePermissionGet) =>
                  applicationRolePermission.permission.id
              )
            )
          }
        })
    }
  }, [applicationId, noDeviceValue, roleId])

  const handleAutocompleteGetOptionDisabled = useCallback(
    (option: ApplicationPermissionGet) => {
      const optionAlreadySelected = applicationRolePermissionsIds.find(
        (applicationRolePermissionId: Guid) => option.id === applicationRolePermissionId
      )
      return !!optionAlreadySelected
    },
    [applicationRolePermissionsIds]
  )

  const submitForm = useCallback(() => {
    formik.submitForm()
  }, [formik])

  const onChange = useCallback(() => {
    formik.setFieldValue("noDevice", !formik.values.noDevice)
    formik.setFieldValue("permissionId", "")
  }, [formik])

  return (
    <Box>
      <BackToURL url={returnURL} label="Back to role's permissions" />
      <Paper>
        <Card>
          <CardHeader
            title="Add permission to role"
            action={
              <LoadingButton
                variant="contained"
                type="submit"
                loading={formik.isSubmitting}
                onClick={submitForm}
                disabled={!formik.dirty || !formik.isValid}
              >
                Save
              </LoadingButton>
            }
          />
          <CardContent
            sx={{
              padding: 0,
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
          >
            <Divider />
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
              }}
            >
              <ListItem key={0}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Permission</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        id="permissionId"
                        options={applicationPermissions}
                        renderInput={handleAutocompleteRenderInput}
                        getOptionLabel={handleAutocompleteGetOptionLabel}
                        getOptionDisabled={handleAutocompleteGetOptionDisabled}
                        onChange={handleChangeFormikValues}
                        disabled={isLoadingApplicationPermissions}
                        size="small"
                        disableClearable
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={1}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">No Device</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Checkbox
                        id="noDevice"
                        name="noDevice"
                        checked={formik.values.noDevice}
                        onChange={onChange}
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Paper>
    </Box>
  )
}
