import { Checkbox, Chip, TextField, Typography } from "@mui/material"
import { useFormik } from "formik"
import { useCallback, useContext } from "react"
import { Navigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { boolean, object, string } from "yup"
import { digitalManagerApi } from "../../../../../../services/api"
import {
  DiMaDetailsContent,
  DiMaDetailsContentProps,
} from "../../../../../components/DiMaDetailsContent"
import { DiMaProgress } from "../../../../../components/DiMaProgress"
import { UserContext } from "../../../../../context/UserContext"
import {
  ApplicationRoleUserGet,
  ApplicationRoleUserPut,
} from "../../../../../models/ApplicationRoleUser"
import {
  AccessLevel,
  DimaFeatureShortNameEnum,
  hasUserAccessToApplicationFeature,
} from "../../../../../models/Authorization"
import {
  getLocalDateTime,
  getNiceFormattedDate,
  getNowDateTime,
  getUtcDateTime,
} from "../../../../../utils/getFormattedDate"
import { getUserApplicationStatusProps } from "../../../../../utils/getUserApplicationStatusProps"

const validationSchema = object({
  validityStartDate: string().required("Validity Start Date is required"),
  validityEndDate: string().required("Validity End Date is required"),
  isDisabled: boolean().required("Is Disabled is required"),
})

export function UserApplicationRoleDetails(props: {
  readonly userApplicationRole: ApplicationRoleUserGet
  readonly setUserApplicationRole: (
    updatedUserApplicationRole: ApplicationRoleUserGet
  ) => void
}) {
  const { userApplicationRole, setUserApplicationRole } = props
  const { userId, applicationId, roleId } = useParams()
  const { user, isLoadingUser } = useContext(UserContext)
  const returnURL = `/users/${userId}#apps-roles`

  const deleteRole = useCallback(
    () =>
      digitalManagerApi.delete(
        `/api/v1/users/${userId}/applications/${applicationId}/roles/${roleId}`
      ),
    [applicationId, roleId, userId]
  )

  const formik = useFormik<ApplicationRoleUserPut>({
    validationSchema,
    initialValues: {
      validityStartDate: getLocalDateTime(userApplicationRole.validityStartDate),
      validityEndDate: getLocalDateTime(userApplicationRole.validityEndDate),
      isDisabled: userApplicationRole.isDisabled || false,
    },
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      const utcValidityStartDate = getUtcDateTime(values.validityStartDate)
      const utcValidityEndDate = getUtcDateTime(values.validityEndDate)

      return digitalManagerApi
        .put<ApplicationRoleUserGet>(
          `/api/v1/users/${userId}/applications/${applicationId}/roles/${roleId}`,
          {
            validityStartDate: utcValidityStartDate,
            validityEndDate: utcValidityEndDate,
            isDisabled: values.isDisabled,
          }
        )
        .then((res) => {
          toast.success("User application role updated")
          setUserApplicationRole(res.data)
        })
        .catch(() => {
          toast.error("Cannot update user application role")
          formik.resetForm()
          return Promise.reject(new Error("Cannot update user application role"))
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
  })

  if (!userApplicationRole || !applicationId) {
    return <Navigate to={returnURL} />
  }

  if (isLoadingUser || !user) {
    return <DiMaProgress />
  }

  const detailsContentProps: DiMaDetailsContentProps<ApplicationRoleUserPut> = {
    formik,
    canWrite: hasUserAccessToApplicationFeature(
      user.authorizations.applications,
      applicationId,
      DimaFeatureShortNameEnum.RM,
      AccessLevel.Write
    ),
    label: "User Application Role",
    deleteAction: deleteRole,
    onDeleteReturnUrl: returnURL,
    listItems: [
      {
        label: "Status",
        displayItem: (
          <Chip {...getUserApplicationStatusProps(userApplicationRole.status)} />
        ),
      },
      {
        label: "Validity Start Date",
        displayItem: (
          <Typography>
            {getNiceFormattedDate(userApplicationRole.validityStartDate)}
          </Typography>
        ),
        editItem: (
          <TextField
            fullWidth
            id="validityStartDate"
            name="validityStartDate"
            type="datetime-local"
            inputProps={{
              min: getNowDateTime(),
              max: "9999-12-31T23:59:59",
            }}
            value={formik.values.validityStartDate}
            onChange={formik.handleChange}
            error={
              formik.touched.validityStartDate && Boolean(formik.errors.validityStartDate)
            }
            helperText={
              formik.touched.validityStartDate && formik.errors.validityStartDate
            }
            size="small"
          />
        ),
      },
      {
        label: "Validity End Date",
        displayItem: (
          <Typography>
            {getNiceFormattedDate(userApplicationRole.validityEndDate)}
          </Typography>
        ),
        editItem: (
          <TextField
            fullWidth
            id="validityEndDate"
            name="validityEndDate"
            type="datetime-local"
            inputProps={{
              min: formik.values.validityStartDate,
              max: "9999-12-31T23:59:59",
            }}
            value={formik.values.validityEndDate}
            onChange={formik.handleChange}
            error={
              formik.touched.validityEndDate && Boolean(formik.errors.validityEndDate)
            }
            helperText={formik.touched.validityEndDate && formik.errors.validityEndDate}
            size="small"
          />
        ),
      },
      {
        label: "Is Disabled",
        displayItem: (
          <Typography>{userApplicationRole.isDisabled?.toString() || "false"}</Typography>
        ),
        editItem: (
          <Checkbox
            id="isDisabled"
            name="isDisabled"
            checked={formik.values.isDisabled}
            onChange={formik.handleChange}
          />
        ),
      },
    ],
  }

  return <DiMaDetailsContent {...detailsContentProps} />
}
