import CancelIcon from "@mui/icons-material/Cancel"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn"
import WatchLaterIcon from "@mui/icons-material/WatchLater"
import { ChipProps } from "@mui/material"

export function getUserApplicationStatusProps(
  status: "active" | "expired" | "planned" | "disabled"
): ChipProps {
  let props: ChipProps = {
    label: status,
    variant: "outlined",
  }

  switch (status.toLowerCase()) {
    case "active":
      props = {
        ...props,
        icon: <CheckCircleIcon />,
        color: "success",
      }
      break
    case "planned":
      props = { ...props, icon: <WatchLaterIcon />, color: "info" }
      break
    case "expired":
      props = {
        ...props,
        icon: <CancelIcon />,
        color: "error",
      }
      break
    case "disabled":
      props = {
        ...props,
        icon: <DoNotDisturbOnIcon />,
        color: "warning",
      }
      break
    default:
      props = { ...props, color: undefined }
  }

  return props
}
