import { TextField, Typography } from "@mui/material"
import { useFormik } from "formik"
import { toast } from "react-toastify"
import { object, string } from "yup"
import { digitalManagerApi } from "../../../../../services/api"
import {
  DiMaDetailsContent,
  DiMaDetailsContentProps,
} from "../../../../components/DiMaDetailsContent"
import { ApplicationGet, ApplicationPut } from "../../../../models/Application"
import {
  AccessLevel,
  DimaFeatureShortNameEnum,
  hasUserAccessToApplicationFeature,
} from "../../../../models/Authorization"
import { UserInfo } from "../../../../models/User"

const validationSchema = object({
  description: string().required("Description is required"),
})

export function ApplicationDetails(props: {
  readonly app: ApplicationGet
  readonly user: UserInfo
  readonly setApplication: (updatedApplication: ApplicationGet) => void
}) {
  const { app, user, setApplication } = props

  const formik = useFormik<ApplicationPut>({
    validationSchema,
    initialValues: {
      description: app.description,
    },
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) =>
      digitalManagerApi
        .put<ApplicationGet>(`/api/v1/applications/${app.id}`, {
          id: app.id,
          name: app.name,
          shortName: app.shortName,
          description: values.description,
        })
        .then((res) => {
          toast.success("Application updated")
          setApplication(res.data)
        })
        .catch(() => {
          toast.error("Cannot update application")
          formik.resetForm()
          return Promise.reject(new Error("Cannot update application"))
        })
        .finally(() => {
          setSubmitting(false)
        }),
  })

  const detailsContentProps: DiMaDetailsContentProps<ApplicationPut> = {
    formik,
    label: "Application",
    canWrite: hasUserAccessToApplicationFeature(
      user.authorizations.applications,
      app.id,
      DimaFeatureShortNameEnum.AM,
      AccessLevel.Write
    ),
    isDeletable: false,
    listItems: [
      {
        label: "Name",
        displayItem: <Typography>{app.name}</Typography>,
      },
      {
        label: "Short",
        displayItem: <Typography>{app.shortName}</Typography>,
      },
      {
        label: "Description",
        displayItem: <Typography>{app.description}</Typography>,
        editItem: (
          <TextField
            fullWidth
            id="description"
            name="description"
            type="text"
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={formik.touched.description && formik.errors.description}
            size="small"
          />
        ),
      },
    ],
  }

  return <DiMaDetailsContent {...detailsContentProps} />
}
