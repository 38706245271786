import { Box } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { Link } from "react-router-dom"
import { useDeviceFamilies } from "../../../../hooks/useDeviceFamilies"
import { DimaFeatureShortNameEnum } from "../../../models/Authorization"
import { DeviceFamilyGet } from "../../../models/DeviceFamily"
import { DiMaAddButton } from "../../partials/DiMaAddButton"
import { EntityTab } from "../../partials/EntityTab"

export function Families() {
  const { deviceFamiliesList, isLoadingDeviceFamilies } = useDeviceFamilies()

  const cols: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      renderCell: (params) => (
        <Link to={`/devices/families/${params.row.id}`}>{params.row.name}</Link>
      ),
      flex: 2,
    },
  ]

  const rows = deviceFamiliesList.map((deviceFamily: DeviceFamilyGet) => ({
    id: deviceFamily.id,
    name: deviceFamily.name,
  }))

  return (
    <>
      <Box className="DiMaBox-one-element-right">
        <DiMaAddButton
          url="/devices/families/create"
          feature={DimaFeatureShortNameEnum.DM}
        />
      </Box>
      <EntityTab
        rows={rows}
        cols={cols}
        isLoading={isLoadingDeviceFamilies}
        initialState={{
          sorting: { sortModel: [{ field: "name", sort: "asc" }] },
        }}
      />
    </>
  )
}
