import { LoadingButton } from "@mui/lab"
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material"
import { useFormik } from "formik"
import { StatusCodes } from "http-status-codes"
import { SyntheticEvent, useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { object, string } from "yup"
import { digitalManagerApi } from "../../../../../../services/api"
import { BackToURL } from "../../../../../components/BackToURL"
import { Guid } from "../../../../../models/CustomType"
import { DeviceRolePermissionGet } from "../../../../../models/DeviceRolePermission"
import { useApplications } from "../../../../../../hooks/useApplications"
import { AccessLevel, DimaFeatureShortNameEnum } from "../../../../../models/Authorization"
import { ApplicationGet } from "../../../../../models/Application"
import { useApplicationPermissions } from "../../../../../../hooks/useApplicationPermissions"
import { ApplicationPermissionGet } from "../../../../../models/ApplicationPermission"

const validationSchema = object({
  permissionId: string().required("Permission is required"),
})

const handleAutocompleteRenderInput = (params: AutocompleteRenderInputParams) => (
  <TextField {...params} />
)

const handleAutocompleteGetOptionLabel = (applicationPermission: ApplicationPermissionGet) =>
  applicationPermission.name

export function DeviceRolePermissionCreate() {
  const { deviceId, roleId } = useParams()
  const [deviceRolePermissionsIds, setDeviceRolePermissionsIds] = useState<Guid[]>([])
  const [selectedApplicationId, setSelectedApplicationId] = useState<Guid>("")
  const { applicationsList, isLoadingApplications } = useApplications(
    DimaFeatureShortNameEnum.AM,
    AccessLevel.Write
  )
  const navigate = useNavigate()
  const returnURL = `/devices/${deviceId}/roles/${roleId}#permissions`

  const formik = useFormik({
    validationSchema,
    initialValues: {
      permissionId: "",
    },
    onSubmit: (values, { setSubmitting }) => {
      digitalManagerApi
        .post(
          `/api/v1/devices/${deviceId}/roles/${roleId}/permissions/${values.permissionId}`
        )
        .then((res) => {
          if (res.status === StatusCodes.CREATED) {
            toast.success("Permission added to role")
            navigate(returnURL)
          }
        })
        .catch(() => {
          toast.error("Cannot add permission to role")
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
  })

  const handleChangeFormikValues = useCallback(
    (
      _e: SyntheticEvent<Element, Event>,
      selectedApplicationPermission: ApplicationPermissionGet | null
    ) => {
      if (selectedApplicationPermission) {
        formik.setValues({
          ...formik.values,
          permissionId: selectedApplicationPermission.id,
        })
      }
    },
    [formik]
  )

  useEffect(() => {
    if (deviceId && roleId) {
      digitalManagerApi
        .get<DeviceRolePermissionGet[]>(
          `/api/v1/devices/${deviceId}/roles/${roleId}/permissions`
        )
        .then((res) => {
          if (res.status === StatusCodes.OK && res.data && res.data.length) {
            setDeviceRolePermissionsIds(
              res.data.map(
                (deviceRolePermission: DeviceRolePermissionGet) =>
                  deviceRolePermission.permission.id
              )
            )
          }
        })
    }
  }, [deviceId, roleId])

  const handleApplicationIdChange = useCallback(
    (event: SelectChangeEvent) => {
      setSelectedApplicationId(event.target.value)
    },
    [setSelectedApplicationId]
  )

  const handleAutocompleteGetOptionDisabled = useCallback(
    (option: ApplicationPermissionGet) => {
      const optionAlreadySelected = deviceRolePermissionsIds.find(
        (deviceRolePermissionId: Guid) => option.id === deviceRolePermissionId
      )
      return !!optionAlreadySelected
    },
    [deviceRolePermissionsIds]
  )

  const { applicationPermissions, isLoadingApplicationPermissions } = useApplicationPermissions(
    selectedApplicationId
  )

  const submitForm = useCallback(() => {
    formik.submitForm()
  }, [formik])

  return (
    <Box>
      <BackToURL url={returnURL} label="Back to role's permissions" />
      <Paper>
        <Card>
          <CardHeader
            title="Add permission to role"
            action={
              <LoadingButton
                variant="contained"
                type="submit"
                loading={formik.isSubmitting}
                onClick={submitForm}
                disabled={!formik.dirty || !formik.isValid}
              >
                Save
              </LoadingButton>
            }
          />
          <CardContent
            sx={{
              padding: 0,
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
          >
            <Divider />
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
              }}
            >
              <ListItem key={0}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Application</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Select
                        id="applicationId"
                        name="applicationId"
                        value={selectedApplicationId}
                        onChange={handleApplicationIdChange}
                        disabled={isLoadingApplications}
                        size="small"
                        displayEmpty
                        fullWidth
                      >
                        {!isLoadingApplications &&
                          applicationsList.map((application: ApplicationGet) => (
                            <MenuItem value={application.id} key={application.id}>
                              {application.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={1}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Permission</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        id="permissionId"
                        options={applicationPermissions}
                        renderInput={handleAutocompleteRenderInput}
                        getOptionLabel={handleAutocompleteGetOptionLabel}
                        getOptionDisabled={handleAutocompleteGetOptionDisabled}
                        onChange={handleChangeFormikValues}
                        disabled={!selectedApplicationId || isLoadingApplicationPermissions}
                        size="small"
                        disableClearable
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Paper>
    </Box>
  )
}
