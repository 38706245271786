import { LoadingButton } from "@mui/lab"
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from "@mui/material"
import { useFormik } from "formik"
import { StatusCodes } from "http-status-codes"
import { useCallback } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { boolean, object, string } from "yup"
import { digitalManagerApi } from "../../../../../services/api"
import { BackToURL } from "../../../../components/BackToURL"
import { ApplicationPermissionPost } from "../../../../models/ApplicationPermission"

const validationSchema = object({
  name: string().required("Name is required"),
  description: string().required("Description is required"),
  noDevice: boolean().required("No Device is required"),
})

export function ApplicationPermissionCreate() {
  const { applicationId } = useParams()
  const navigate = useNavigate()
  const returnURL = `/applications/${applicationId}#permissions`

  const formik = useFormik<ApplicationPermissionPost>({
    validationSchema,
    initialValues: {
      name: "",
      description: "",
      noDevice: false,
    },
    onSubmit: (values, { setSubmitting }) => {
      digitalManagerApi
        .post(`/api/v1/applications/${applicationId}/permissions`, {
          name: values.name,
          description: values.description,
          noDevice: values.noDevice,
        })
        .then((res) => {
          if (res.status === StatusCodes.CREATED) {
            toast.success("Permission created")
            navigate(returnURL)
          }
        })
        .catch(() => {
          toast.error("Cannot create permission")
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
  })

  const submitForm = useCallback(() => {
    formik.submitForm()
  }, [formik])

  return (
    <Box>
      <BackToURL url={returnURL} label="Back to permissions" />
      <Paper>
        <Card>
          <CardHeader
            title="Create new permission"
            action={
              <LoadingButton
                variant="contained"
                type="submit"
                loading={formik.isSubmitting}
                onClick={submitForm}
                disabled={!formik.dirty || !formik.isValid}
              >
                Save
              </LoadingButton>
            }
          />
          <CardContent
            sx={{
              padding: 0,
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
          >
            <Divider />
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
              }}
            >
              <ListItem key={0}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Name</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="name"
                        name="name"
                        type="text"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={1}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Description</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="description"
                        name="description"
                        type="text"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.description && Boolean(formik.errors.description)
                        }
                        helperText={
                          formik.touched.description && formik.errors.description
                        }
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={2}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">No Device</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Checkbox
                        id="noDevice"
                        name="noDevice"
                        checked={formik.values.noDevice}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Paper>
    </Box>
  )
}
