import { TabContext, TabList } from "@mui/lab"
import { Box } from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom"
import { digitalManagerApi } from "../../../../services/api"
import { BackToURL } from "../../../components/BackToURL"
import {
  DiMaDetailsHeader,
  DiMaDetailsHeaderProps,
} from "../../../components/DiMaDetailsHeader"
import { DiMaProgress } from "../../../components/DiMaProgress"
import { DiMaTabPanel } from "../../../components/DiMaTabPanel"
import { LinkTab } from "../../../components/LinkTab"
import { DeviceFamilyGet } from "../../../models/DeviceFamily"
import { FamilyDetails } from "./details_tab/FamilyDetails"

const returnURL = "/devices#families"

export function FamilyContainer() {
  const { familyId } = useParams()
  const [activeTab, setActiveTab] = useState<string>("#details")
  const [deviceFamily, setDeviceFamily] = useState<DeviceFamilyGet>()
  const { hash } = useLocation()
  const navigate = useNavigate()

  const AVATAR_LENGTH = 2

  useEffect(() => {
    if (familyId) {
      digitalManagerApi
        .get<DeviceFamilyGet>(`/api/v1/deviceFamilies/${familyId}`)
        .then((res) => {
          setDeviceFamily(res.data)
        })
        .catch(() => {
          navigate(returnURL)
        })
    }
  }, [familyId, navigate])

  useEffect(() => {
    if (hash) {
      setActiveTab(hash)
    }
  }, [hash])

  const handleChange = useCallback((_: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue)
  }, [])

  if (!familyId) {
    return <Navigate to={returnURL} />
  }

  if (!deviceFamily) {
    return <DiMaProgress />
  }

  const detailsHeaderProps: DiMaDetailsHeaderProps = {
    avatar: deviceFamily.name.substring(0, AVATAR_LENGTH),
    title: deviceFamily.name,
    label: "Device Family",
    subtitle: deviceFamily.name,
    hideCopyToClipboard: true,
  }

  return (
    <>
      <BackToURL url={returnURL} label="Back to device families" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <DiMaDetailsHeader {...detailsHeaderProps} />
        <TabContext value={activeTab}>
          <Box className="DiMaBox-tabs">
            <TabList onChange={handleChange}>
              <LinkTab label="Details" value="#details" />
            </TabList>
          </Box>
          <DiMaTabPanel label="#details" activeTab={activeTab}>
            <FamilyDetails
              deviceFamily={deviceFamily}
              setDeviceFamily={setDeviceFamily}
            />
          </DiMaTabPanel>
        </TabContext>
      </Box>
    </>
  )
}
