import { StatusCodes } from "http-status-codes"
import { useEffect, useState } from "react"
import { ApplicationRolePermissionGet } from "../app/models/ApplicationRolePermission"
import { digitalManagerApi } from "../services/api"

export function useApplicationRolePermissions(
  applicationId?: string,
  roleId?: string,
  noDevice = false
) {
  const [applicationRolePermissions, setApplicationRolePermissions] = useState<
    ApplicationRolePermissionGet[]
  >([])
  const [isLoadingApplicationRolePermissions, setIsLoadingApplicationRolePermissions] =
    useState<boolean>(true)

  useEffect(() => {
    setIsLoadingApplicationRolePermissions(true)
    setApplicationRolePermissions([])
    if (applicationId && roleId) {
      digitalManagerApi
        .get<ApplicationRolePermissionGet[]>(
          `/api/v1/applications/${applicationId}/roles/${roleId}/permissions?noDevice=${noDevice}`
        )
        .then((res) => {
          if (res.status === StatusCodes.OK && res.data.length) {
            setApplicationRolePermissions(res.data)
          }
        })
        .finally(() => {
          setIsLoadingApplicationRolePermissions(false)
        })
    } else {
      setIsLoadingApplicationRolePermissions(false)
    }
  }, [applicationId, roleId, noDevice])

  return {
    applicationRolePermissions,
    isLoadingApplicationRolePermissions,
  }
}
