import { TabContext, TabList } from "@mui/lab"
import { Box } from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom"
import { digitalManagerApi } from "../../../../services/api"
import { BackToURL } from "../../../components/BackToURL"
import {
  DiMaDetailsHeader,
  DiMaDetailsHeaderProps,
} from "../../../components/DiMaDetailsHeader"
import { DiMaProgress } from "../../../components/DiMaProgress"
import { DiMaTabPanel } from "../../../components/DiMaTabPanel"
import { LinkTab } from "../../../components/LinkTab"
import { NotificationGet } from "../../../models/Notification"
import { NotificationDetails } from "./details_tab/NotificationDetails"

const returnURL = "/notifications"

export function NotificationContainer() {
  const { notificationId } = useParams()
  const [activeTab, setActiveTab] = useState<string>("#details")
  const [notification, setNotification] = useState<NotificationGet>()
  const { hash } = useLocation()
  const navigate = useNavigate()

  const AVATAR_LENGTH = 2

  useEffect(() => {
    if (notificationId) {
      digitalManagerApi
        .get<NotificationGet>(`/api/v1/notifications/${notificationId}`)
        .then((res) => {
          setNotification(res.data)
        })
        .catch(() => {
          navigate(returnURL)
        })
    }
  }, [navigate, notificationId])

  useEffect(() => {
    if (hash) {
      setActiveTab(hash)
    }
  }, [hash])

  const handleChange = useCallback((_: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue)
  }, [])

  if (!notificationId) {
    return <Navigate to={returnURL} />
  }

  if (!notification) {
    return <DiMaProgress />
  }

  const detailsHeaderProps: DiMaDetailsHeaderProps = {
    avatar: notification.title.substring(0, AVATAR_LENGTH),
    title: notification.title,
    label: "Application",
    subtitle: notification.application.name,
    hideCopyToClipboard: true,
  }

  return (
    <>
      <BackToURL url={returnURL} label="Back to notifications" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <DiMaDetailsHeader {...detailsHeaderProps} />
        <TabContext value={activeTab}>
          <Box className="DiMaBox-tabs">
            <TabList onChange={handleChange}>
              <LinkTab label="Details" value="#details" />
            </TabList>
          </Box>
          <DiMaTabPanel label="#details" activeTab={activeTab}>
            <NotificationDetails
              notification={notification}
              setNotification={setNotification}
            />
          </DiMaTabPanel>
        </TabContext>
      </Box>
    </>
  )
}
