import { ReactJSXElement } from "@emotion/react/types/jsx-namespace"
import DeleteIcon from "@mui/icons-material/Delete"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { LoadingButton } from "@mui/lab"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material"
import { useFormik } from "formik"
import { StatusCodes } from "http-status-codes"
import { useCallback, useContext, useEffect, useState } from "react"
import { Navigate, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { digitalManagerApi } from "../../../../../services/api"
import { DiMaProgress } from "../../../../components/DiMaProgress"
import { UserContext } from "../../../../context/UserContext"
import {
  AccessLevel,
  ApplicationAccessLevel,
  ApplicationAccessLevelEdit,
  ApplicationFeaturesAccessLevel,
  DimaFeature,
  DimaFeatureShortNameEnum,
  FeatureAccessLevel,
  FeatureAccessLevelEdit,
  UserAuthorizations,
  canUserGrantAccessToDima,
  getAppFeatureAccessLevelByAppShortName,
  getFeatureAccessLevel,
  hasUserAccessToDima,
} from "../../../../models/Authorization"
import "./UserBackofficeAccess.scss"

const returnURL = `/users`

export function UserBackofficeAccess() {
  const { user, isLoadingUser } = useContext(UserContext)

  const { userId } = useParams<string>()
  const navigate = useNavigate()

  const [selectedUserHasAccessToDima, setSelectedUserHasAccessToDima] =
    useState<boolean>(false)
  const [isLoadingAuthorizations, setIsLoadingAuthorizations] = useState<boolean>(true)
  const [selectedUserApplicationsAccesses, setSelectedUserApplicationsAccesses] =
    useState<ApplicationAccessLevel[]>()
  const [selectedUserFeaturesAccesses, setSelectedUserFeaturesAccesses] =
    useState<FeatureAccessLevel[]>()
  const [confirmRevokeOpen, setConfirmRevokeOpen] = useState<boolean>(false)
  const [isChangingAccess, setIsChangingAccess] = useState<boolean>(false)

  const initialAppSFeaturesVal: {
    [key: string]: ApplicationFeaturesAccessLevel
  } = {}

  const [initialAppFeaturesVal] = useState(initialAppSFeaturesVal)

  const deleteUserAuthorizations = useCallback(
    () =>
      digitalManagerApi.delete(`/api/v1/admin/authorizations/${userId}/`).then(() => {
        setSelectedUserHasAccessToDima(false)
      }),
    [userId]
  )

  // retrieve selected user authorizations
  useEffect(() => {
    if (userId) {
      digitalManagerApi
        .get<UserAuthorizations>(`/api/v1/admin/authorizations/${userId}`)
        .then((res) => {
          if (res.status === StatusCodes.OK && res.data) {
            setSelectedUserHasAccessToDima(hasUserAccessToDima(res.data))
            setSelectedUserApplicationsAccesses(res.data.applications)
            setSelectedUserFeaturesAccesses(res.data.features)
          }
        })
        .catch(() => {
          navigate(returnURL)
        })
        .finally(() => {
          setIsLoadingAuthorizations(false)
        })
    }
  }, [navigate, userId])

  const changeAccessToDima = useCallback(() => {
    setSelectedUserHasAccessToDima((hasAccess) => !hasAccess)
  }, [])

  const handleAccessToDimaChange = useCallback(() => {
    if (selectedUserHasAccessToDima) {
      setConfirmRevokeOpen(true)
    } else {
      changeAccessToDima()
    }
  }, [selectedUserHasAccessToDima, setConfirmRevokeOpen, changeAccessToDima])

  const formikAppFeatures = useFormik<{
    [key: string]: ApplicationFeaturesAccessLevel
  }>({
    initialValues: initialAppFeaturesVal,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const appsAuthorizations: ApplicationAccessLevelEdit[] = []
      Object.keys(values)
        .filter((feature) => feature.indexOf("features") === -1)
        .forEach((id) => {
          if (JSON.stringify(initialAppFeaturesVal[id]) !== JSON.stringify(values[id])) {
            const ModifiedAppFeaturesVal: ApplicationFeaturesAccessLevel = {}

            Object.keys(values[id]).forEach((feature) => {
              if (values[id][feature] !== initialAppFeaturesVal[id][feature]) {
                ModifiedAppFeaturesVal[feature] = values[id][feature]
              }
            })

            appsAuthorizations.push({
              id,
              featuresAccesses: ModifiedAppFeaturesVal,
            })
          }
        })

      const featuresAuthorizations: FeatureAccessLevelEdit[] = []

      if (values.features) {
        Object.keys(values.features).forEach((id) => {
          if (initialAppFeaturesVal["features"][id] !== values["features"][id]) {
            featuresAuthorizations.push({
              id,
              accessLevel: values["features"][id],
            })
          }
        })
      }

      digitalManagerApi
        .put(`/api/v1/admin/authorizations/${userId}`, {
          applications: appsAuthorizations,
          features: featuresAuthorizations,
        })
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            setSelectedUserHasAccessToDima(hasUserAccessToDima(res.data))
            setSelectedUserApplicationsAccesses(res.data.applications)
            setSelectedUserFeaturesAccesses(res.data.features)
            resetForm()
            toast.success("Accesses to backoffice features updated")
          }
          setSubmitting(false)
        })
        .catch(() => {
          toast.error("Cannot update accesses to backoffice features")
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
    enableReinitialize: true,
  })

  const onChange = useCallback(
    (event: SelectChangeEvent<AccessLevel>) => {
      formikAppFeatures.setFieldValue(event.target.name, event.target.value)
    },
    [formikAppFeatures]
  )

  const setConfirmRevokeOpenFalse = useCallback(() => {
    setConfirmRevokeOpen(false)
  }, [])

  const onClick = useCallback(() => {
    setIsChangingAccess(true)
    deleteUserAuthorizations()
      .then(() => {
        toast.success(`User has no longer access`)
      })
      .catch(() => {
        toast.error(
          `You can't revoke access to Backoffice, because this user has access to some features you don't have access to or has an approval role.`
        )
      })
      .finally(() => {
        setConfirmRevokeOpen(false)
        setIsChangingAccess(false)
      })
  }, [deleteUserAuthorizations])

  if (!userId) {
    return <Navigate to={returnURL} />
  }

  if (isLoadingUser || isLoadingAuthorizations || !user) {
    return <DiMaProgress />
  }

  const userCanGiveAccessToDima = canUserGrantAccessToDima(user.authorizations)
  const UserBackofficeAccessLevel = getFeatureAccessLevel(
    DimaFeatureShortNameEnum.BA,
    user.authorizations.features
  )

  function getTitle(
    selectedUserHigherThanCurrent: boolean,
    currentUserHasReadAccessLevel: boolean
  ) {
    if (selectedUserHigherThanCurrent) {
      return "You cannot change access level, because this user has higher privileges than you"
    }
    if (currentUserHasReadAccessLevel) {
      return "You cannot change access level, because you have only read access level on this feature"
    }
    return ""
  }

  const appsList: Array<ReactJSXElement> = []
  if (user.authorizations && selectedUserApplicationsAccesses) {
    // create an accordion for each app
    user.authorizations.applications.forEach((app: ApplicationAccessLevel) => {
      // create a select for each app feature
      const appFeaturesList: Array<ReactJSXElement> = []

      Object.keys(app.featuresAccesses)
        .filter(
          (feature) =>
            feature.indexOf("$") === -1 &&
            getAppFeatureAccessLevelByAppShortName(
              app.shortName,
              feature,
              user.authorizations.applications
            ) >= AccessLevel.Read &&
            feature !== DimaFeatureShortNameEnum.RR
        )
        .forEach((feature) => {
          const selectedUserFeaturesAccessesLevel =
            getAppFeatureAccessLevelByAppShortName(
              app.shortName,
              feature,
              selectedUserApplicationsAccesses
            )
          const currentUserFeatureAccessLevel = app.featuresAccesses[feature]
          const selectedUserHigherThanCurrent =
            selectedUserFeaturesAccessesLevel > currentUserFeatureAccessLevel
          const currentUserHasReadAccessLevel =
            currentUserFeatureAccessLevel === AccessLevel.Read

          const selectId = `${app.id}.${feature}`

          if (!initialAppFeaturesVal[app.id]) {
            initialAppFeaturesVal[app.id] = {}
          }
          initialAppFeaturesVal[app.id][feature] = selectedUserFeaturesAccessesLevel

          if (formikAppFeatures.values[app.id]) {
            appFeaturesList.push(
              <ListItem key={selectId} divider>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={4}>
                      <Typography variant="h6">{DimaFeature[feature]}</Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
                      <Tooltip
                        followCursor
                        title={getTitle(
                          selectedUserHigherThanCurrent,
                          currentUserHasReadAccessLevel
                        )}
                      >
                        <Select
                          name={selectId}
                          value={
                            formikAppFeatures.values[app.id] &&
                            formikAppFeatures.values[app.id][feature]
                          }
                          onChange={onChange}
                          size="small"
                          displayEmpty
                          fullWidth
                          disabled={
                            selectedUserHigherThanCurrent ||
                            currentUserHasReadAccessLevel ||
                            feature === DimaFeatureShortNameEnum.RR ||
                            UserBackofficeAccessLevel <= AccessLevel.Read
                          }
                        >
                          {Object.keys(AccessLevel).map((level) => {
                            const levelNumber = parseInt(level, 10)
                            if (!isNaN(levelNumber)) {
                              const currentUserLowerThanLevel =
                                currentUserFeatureAccessLevel < levelNumber
                              return (
                                <MenuItem
                                  key={level}
                                  value={levelNumber}
                                  disabled={currentUserLowerThanLevel}
                                >
                                  {AccessLevel[levelNumber]}
                                </MenuItem>
                              )
                            }
                            return null
                          })}
                        </Select>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
            )
          }
        })

      appsList.push(
        <Accordion key={app.shortName} defaultExpanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ width: "33%", flexShrink: 0 }}>{app.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Divider />
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
              }}
            >
              {appFeaturesList}
            </List>
          </AccordionDetails>
        </Accordion>
      )
    })

    // create a select for each feature
    const featuresList: Array<ReactJSXElement> = []

    user.authorizations.features.forEach((feature: FeatureAccessLevel) => {
      const selectedUserFeaturesAccessesLevel = getFeatureAccessLevel(
        feature.shortName,
        selectedUserFeaturesAccesses
      )
      const currentUserFeatureAccessLevel = feature.accessLevel
      const selectedUserHigherThanCurrent =
        selectedUserFeaturesAccessesLevel > currentUserFeatureAccessLevel
      const currentUserHasReadAccessLevel =
        currentUserFeatureAccessLevel === AccessLevel.Read

      const selectId = `features.${feature.id}`

      if (!initialAppFeaturesVal["features"]) {
        initialAppFeaturesVal["features"] = {}
      }
      initialAppFeaturesVal["features"][feature.id] = selectedUserFeaturesAccessesLevel

      if (formikAppFeatures.values["features"]) {
        featuresList.push(
          <ListItem key={selectId} divider>
            <ListItemText>
              <Grid
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Grid item xs={4}>
                  <Typography variant="h6">{DimaFeature[feature.shortName]}</Typography>
                </Grid>
                <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
                  <Tooltip
                    followCursor
                    title={getTitle(
                      selectedUserHigherThanCurrent,
                      currentUserHasReadAccessLevel
                    )}
                  >
                    <Select
                      name={selectId}
                      value={
                        formikAppFeatures.values["features"] &&
                        formikAppFeatures.values["features"][feature.id]
                      }
                      onChange={onChange}
                      size="small"
                      displayEmpty
                      fullWidth
                      disabled={
                        selectedUserHigherThanCurrent ||
                        currentUserHasReadAccessLevel ||
                        UserBackofficeAccessLevel <= AccessLevel.Read
                      }
                    >
                      {Object.keys(AccessLevel).map((level) => {
                        const levelNumber = parseInt(level, 10)
                        if (!isNaN(levelNumber)) {
                          const currentUserLowerThanLevel =
                            currentUserFeatureAccessLevel < levelNumber
                          return (
                            <MenuItem
                              key={level}
                              value={levelNumber}
                              disabled={currentUserLowerThanLevel}
                            >
                              {AccessLevel[levelNumber]}
                            </MenuItem>
                          )
                        }
                        return null
                      })}
                    </Select>
                  </Tooltip>
                </Grid>
              </Grid>
            </ListItemText>
          </ListItem>
        )
      }
    })

    if (featuresList.length) {
      appsList.push(
        <Accordion key="other" defaultExpanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ width: "33%", flexShrink: 0 }}>Other features</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Divider />
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
              }}
            >
              {featuresList}
            </List>
          </AccordionDetails>
        </Accordion>
      )
    }
  }

  return (
    <Stack direction="column" spacing={3}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
          <Switch
            checked={selectedUserHasAccessToDima}
            onChange={handleAccessToDimaChange}
            disabled={
              !userCanGiveAccessToDima || UserBackofficeAccessLevel <= AccessLevel.Read
            }
          />
          <Typography variant="body1">
            The user {selectedUserHasAccessToDima ? "has" : "does not have"} access to
            Backoffice
          </Typography>
        </Stack>
        {selectedUserHasAccessToDima && (
          <LoadingButton
            variant="contained"
            onClick={formikAppFeatures.submitForm}
            loading={formikAppFeatures.isSubmitting}
            disabled={
              UserBackofficeAccessLevel <= AccessLevel.Read || !formikAppFeatures.dirty
            }
          >
            Save
          </LoadingButton>
        )}
      </Box>
      {selectedUserHasAccessToDima && <>{appsList}</>}
      <Dialog open={confirmRevokeOpen} onClose={setConfirmRevokeOpenFalse}>
        <DialogTitle>
          {"Are you sure you want to revoke access to Backoffice?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            If you proceed, the user will no more have access to Backoffice.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={setConfirmRevokeOpenFalse} disabled={isChangingAccess}>
            Cancel
          </Button>
          <LoadingButton
            startIcon={<DeleteIcon />}
            onClick={onClick}
            autoFocus
            variant="contained"
            color="error"
            loading={isChangingAccess}
          >
            Revoke access
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Stack>
  )
}
