import {
  FormatAlignCenter,
  FormatAlignJustify,
  FormatAlignLeft,
  FormatAlignRight,
  FormatBold,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  FormatUnderlined,
  Image,
  Link,
  Looks3,
  Looks4,
  Looks5,
  Looks6,
  LooksOne,
  LooksTwo,
} from "@mui/icons-material"
import SaveIcon from "@mui/icons-material/Save"
import { Box, Button, Stack } from "@mui/material"
import {
  AlignToolbarButton,
  BlockToolbarButton,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
  ELEMENT_OL,
  ELEMENT_PARAGRAPH,
  ELEMENT_UL,
  HeadingToolbar,
  ImageToolbarButton,
  LinkToolbarButton,
  ListToolbarButton,
  MARK_BOLD,
  MARK_ITALIC,
  MARK_UNDERLINE,
  MarkToolbarButton,
  Plate,
  PlateProvider,
  Value,
  createAlignPlugin,
  createBoldPlugin,
  createHeadingPlugin,
  createImagePlugin,
  createItalicPlugin,
  createLinkPlugin,
  createListPlugin,
  createParagraphPlugin,
  createPlateUI,
  createPlugins,
  createUnderlinePlugin,
  getPluginType,
  serializeHtml,
  usePlateEditorRef,
} from "@udecode/plate"
import { useCallback } from "react"
import { htmlDecode } from "../utils/htmlDecode"

const ToolbarButtons = (props: { onSave: (val: DiMaRichTextEditorValue) => void }) => {
  const editor = usePlateEditorRef()

  const onClick = useCallback(() => {
    if (editor?.children) {
      const value = editor.children
      const serializedHtml = serializeHtml(editor, {
        nodes: value,
        stripWhitespace: true,
      })
      const stringValue = htmlDecode(serializedHtml.replace(/<\/?[^>]+>/gi, "").trim())
      props.onSave({
        stringValue,
        plateValue: JSON.stringify(value),
        htmlValue: serializedHtml,
      })
    }
  }, [editor, props])

  if (!editor) {
    return null
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        pl: "15px",
      }}
    >
      <Stack direction="row">
        <BlockToolbarButton
          type={getPluginType(editor, ELEMENT_H1)}
          icon={<LooksOne />}
        />
        <BlockToolbarButton
          type={getPluginType(editor, ELEMENT_H2)}
          icon={<LooksTwo />}
        />
        <BlockToolbarButton type={getPluginType(editor, ELEMENT_H3)} icon={<Looks3 />} />
        <BlockToolbarButton type={getPluginType(editor, ELEMENT_H4)} icon={<Looks4 />} />
        <BlockToolbarButton type={getPluginType(editor, ELEMENT_H5)} icon={<Looks5 />} />
        <BlockToolbarButton type={getPluginType(editor, ELEMENT_H6)} icon={<Looks6 />} />
        <MarkToolbarButton
          type={getPluginType(editor, MARK_BOLD)}
          icon={<FormatBold />}
        />
        <MarkToolbarButton
          type={getPluginType(editor, MARK_ITALIC)}
          icon={<FormatItalic />}
        />
        <MarkToolbarButton
          type={getPluginType(editor, MARK_UNDERLINE)}
          icon={<FormatUnderlined />}
        />
        <AlignToolbarButton value="left" icon={<FormatAlignLeft />} />
        <AlignToolbarButton value="center" icon={<FormatAlignCenter />} />
        <AlignToolbarButton value="right" icon={<FormatAlignRight />} />
        <AlignToolbarButton value="justify" icon={<FormatAlignJustify />} />
        <ListToolbarButton
          type={getPluginType(editor, ELEMENT_UL)}
          icon={<FormatListBulleted />}
        />
        <ListToolbarButton
          type={getPluginType(editor, ELEMENT_OL)}
          icon={<FormatListNumbered />}
        />
        <LinkToolbarButton icon={<Link />} />
        <ImageToolbarButton icon={<Image />} />
      </Stack>

      <Button variant="contained" startIcon={<SaveIcon />} onClick={onClick}>
        Save
      </Button>
    </Box>
  )
}

const plugins = createPlugins(
  [
    createParagraphPlugin(),
    createHeadingPlugin(),
    createBoldPlugin(),
    createItalicPlugin(),
    createUnderlinePlugin(),
    createAlignPlugin({
      inject: {
        props: {
          validTypes: [
            ELEMENT_PARAGRAPH,
            ELEMENT_H1,
            ELEMENT_H2,
            ELEMENT_H3,
            ELEMENT_H4,
            ELEMENT_H5,
            ELEMENT_H6,
          ],
        },
      },
    }),
    createListPlugin(),
    createLinkPlugin(),
    createImagePlugin(),
  ],
  {
    components: createPlateUI(),
  }
)

const editableProps = {
  placeholder: "Insert here the text of your notification",
  autoFocus: false,
  spellCheck: false,
  style: { height: "calc(100% - 100px)", overflow: "auto", padding: "15px" },
}

export interface DiMaRichTextEditorValue {
  readonly plateValue: string
  readonly htmlValue: string
  readonly stringValue: string
}

export function DiMaRichTextEditor(props: {
  readonly initialValue: string
  readonly onGetValue: (val: DiMaRichTextEditorValue) => void
}) {
  const plateValue: Value = props.initialValue ? JSON.parse(props.initialValue) : ""

  return (
    <PlateProvider>
      <HeadingToolbar>
        <ToolbarButtons onSave={props.onGetValue} />
      </HeadingToolbar>
      <Plate editableProps={editableProps} initialValue={plateValue} plugins={plugins} />
    </PlateProvider>
  )
}
