import { Box, Paper, Stack, TableContainer } from "@mui/material"
import LinearProgress from "@mui/material/LinearProgress"
import {
  GridColDef,
  GridNoRowsOverlay,
  GridRowsProp,
  GridToolbar,
} from "@mui/x-data-grid"
import { GridInitialStateCommunity } from "@mui/x-data-grid/models/gridStateCommunity"
import { JSXElementConstructor, useState } from "react"
import DiMaDataGrid from "../../components/DiMaDataGrid"

export function EntityTab(props: {
  readonly rows: GridRowsProp
  readonly cols: GridColDef[]
  readonly isLoading: boolean
  readonly initialState?: GridInitialStateCommunity
  readonly customEmptyGridMessage?: string
  readonly CustomToolbar?: JSXElementConstructor<unknown>
}) {
  const { rows, cols, isLoading, initialState, CustomToolbar, customEmptyGridMessage } =
    props
  const PAGE_SIZE_ONE = 25
  const PAGE_SIZE_TWO = 50
  const PAGE_SIZE_THREE = 100
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE_ONE)
  const rowsPerPageOptions: number[] = [PAGE_SIZE_ONE, PAGE_SIZE_TWO, PAGE_SIZE_THREE]

  return (
    <Box
      mt={2}
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      <TableContainer
        component={Paper}
        sx={{
          display: "flex",
          flexGrow: 1,
        }}
      >
        <DiMaDataGrid
          rows={rows}
          columns={cols}
          loading={isLoading}
          disableSelectionOnClick={true}
          initialState={initialState}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
          rowsPerPageOptions={rowsPerPageOptions}
          pagination
          components={{
            LoadingOverlay: LinearProgress,
            Toolbar: CustomToolbar ?? GridToolbar,
            NoRowsOverlay: customEmptyGridMessage
              ? () => (
                  <Stack height="100%" alignItems="center" justifyContent="center">
                    {customEmptyGridMessage}
                  </Stack>
                )
              : GridNoRowsOverlay,
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
              printOptions: { disableToolbarButton: true },
              csvOptions: { disableToolbarButton: true },
            },
          }}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
        />
      </TableContainer>
    </Box>
  )
}
