import { Box } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { Link } from "react-router-dom"
import { useFactories } from "../../../../hooks/useFactories"
import { DimaFeatureShortNameEnum } from "../../../models/Authorization"
import { FactoryGet } from "../../../models/Factory"
import { DiMaAddButton } from "../../partials/DiMaAddButton"
import { EntityTab } from "../../partials/EntityTab"
import { getBooleanFlagIcon } from "../../../utils/getBooleanFlagIcon"

export function Factories() {
  const { factories, isLoadingFactories } = useFactories()

  const factoriesColumns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      renderCell: (params) => (
        <Link to={`/test-reports/factories/${params.row.id}`}>{params.row.name}</Link>
      ),
      flex: 3,
    },
    {
      field: "isFree",
      headerName: "Is Free",
      type: "boolean",
      renderCell: (params) => getBooleanFlagIcon(params.row.isFree),
      flex: 1,
    },
    {
      field: "isEnabled",
      headerName: "Is Enabled",
      type: "boolean",
      renderCell: (params) => getBooleanFlagIcon(params.row.isEnabled),
      flex: 1,
    },
  ]

  const factoriesRows = factories.map((factory: FactoryGet) => ({
    id: factory.id,
    name: factory.name,
    isFree: factory.isFree,
    isEnabled: factory.isEnabled,
  }))

  return (
    <>
      <Box className="DiMaBox-one-element-right">
        <DiMaAddButton
          url="/test-reports/factories/create"
          feature={DimaFeatureShortNameEnum.TR}
        />
      </Box>
      <EntityTab
        rows={factoriesRows}
        cols={factoriesColumns}
        isLoading={isLoadingFactories}
        initialState={{
          sorting: { sortModel: [{ field: "name", sort: "asc" }] },
        }}
      />
    </>
  )
}
