import { useCallback, useState } from "react"
import { toast } from "react-toastify"

export function useFileDownload() {
  const [downloading, setDownloading] = useState(false)

  const handleDownload = useCallback(async (downloadLink, fileName) => {
    try {
      setDownloading(true)

      if (!downloadLink) {
        toast.error("Download link is empty")
        return
      }

      const response = await fetch(downloadLink)

      if (!response.ok) {
        throw new Error("Failed to fetch resource")
      }

      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)

      const a = document.createElement("a")
      a.href = url
      a.download = fileName
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      window.URL.revokeObjectURL(url)
    } catch (error) {
      toast.error("Error downloading file")
    } finally {
      setDownloading(false)
    }
  }, [])

  return { handleDownload, downloading }
}