import { StatusCodes } from "http-status-codes"
import { useEffect, useState } from "react"
import { NotificationGet } from "../app/models/Notification"
import { digitalManagerApi } from "../services/api"

export function useNotifications(getExpired = false) {
  const [notificationsList, setNotificationsList] = useState<NotificationGet[]>([])
  const [isLoadingNotifications, setIsLoadingNotifications] = useState<boolean>(true)

  useEffect(() => {
    setIsLoadingNotifications(true)
    setNotificationsList([])
    digitalManagerApi
      .get<NotificationGet[]>(`/api/v1/notifications`, {
        params: { expired: getExpired },
      })
      .then((res) => {
        if (res.status === StatusCodes.OK) {
          setNotificationsList(res.data)
        }
      })
      .finally(() => {
        setIsLoadingNotifications(false)
      })
  }, [getExpired])

  return {
    notificationsList,
    isLoadingNotifications,
  }
}
