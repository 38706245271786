import { Typography } from "@mui/material"

import { GridColumns } from "@mui/x-data-grid"
import { ObjectIds } from "../../../../models/SupplierPortal"
import { getBooleanFlagIcon } from "../../../../utils/getBooleanFlagIcon"
import { getNiceFormattedDate } from "../../../../utils/getFormattedDate"
import { EntityTab } from "../../../partials/EntityTab"

export function ObjectIdsDetails(props: { readonly objectIds: ObjectIds[] }) {
  const { objectIds } = props

  const columns: GridColumns<ObjectIds> = [
    {
      field: "oid",
      headerName: "OID",
      renderCell: (params) => <Typography>{params.row.oid}</Typography>,
      flex: 2,
    },
    {
      field: "otp",
      headerName: "OTP",
      renderCell: (params) => <Typography>{params.row.otp}</Typography>,
      flex: 1,
    },
    {
      field: "isReserved",
      headerName: "is Reserved",
      renderCell: (params) => (
        <Typography>{getBooleanFlagIcon(params.row.isReserved)}</Typography>
      ),
      flex: 1,
    },
    {
      field: "isUsed",
      headerName: "is Used",
      renderCell: (params) => (
        <Typography>{getBooleanFlagIcon(params.row.isUsed)}</Typography>
      ),
      flex: 1,
    },
    {
      field: "reservationDate",
      headerName: "Reservation date",
      renderCell: (params) => (
        <Typography>{getNiceFormattedDate(params.row.reservationDate)}</Typography>
      ),
      flex: 1,
    },
    {
      field: "expirationDate",
      headerName: "Expiration date",
      renderCell: (params) => (
        <Typography>{getNiceFormattedDate(params.row.expireDate)}</Typography>
      ),
      flex: 1,
    },
  ]

  const rows = objectIds.map((oid: ObjectIds) => ({
    id: oid.oid,
    oid: oid.oid,
    otp: oid.otp,
    isReserved: oid.isReserved,
    isUsed: oid.isUsed,
    reservationDate: oid.reservationDate,
    expireDate: oid.expireDate,
  }))

  return (
    <EntityTab
      rows={rows}
      cols={columns}
      isLoading={false}
      initialState={{
        sorting: { sortModel: [{ field: "isUsed", sort: "desc" }] },
      }}
    />
  )
}
