import { Typography } from "@mui/material"
import { useCallback } from "react"
import { Navigate } from "react-router-dom"
import { digitalManagerApi } from "../../../../../../../services/api"
import {
  DiMaDetailsContent,
  DiMaDetailsContentProps,
} from "../../../../../../components/DiMaDetailsContent"
import { ApplicationPermissionPut } from "../../../../../../models/ApplicationPermission"
import {
  AccessLevel,
  DimaFeatureShortNameEnum,
  hasUserAccessToFeature,
} from "../../../../../../models/Authorization"
import { DeviceRolePermissionGet } from "../../../../../../models/DeviceRolePermission"
import { UserInfo } from "../../../../../../models/User"

export function DeviceRolePermissionDetails(props: {
  readonly deviceRolePermission: DeviceRolePermissionGet
  readonly user: UserInfo
}) {
  const { deviceRolePermission, user } = props
  const returnURL = `/devices/${deviceRolePermission.device.id}/roles/${deviceRolePermission.role.id}#permissions`

  const deleteRolePermission = useCallback(
    () =>
      digitalManagerApi.delete(
        `/api/v1/devices/${deviceRolePermission.device.id}/roles/${deviceRolePermission.role.id}/permissions/${deviceRolePermission.permission.id}`
      ),
    [
      deviceRolePermission.device.id,
      deviceRolePermission.permission.id,
      deviceRolePermission.role.id,
    ]
  )

  if (!deviceRolePermission) {
    return <Navigate to={returnURL} />
  }

  const detailsContentProps: DiMaDetailsContentProps<ApplicationPermissionPut> = {
    label: "Permission",
    canWrite: hasUserAccessToFeature(
      user.authorizations,
      DimaFeatureShortNameEnum.DM,
      AccessLevel.Write
    ),
    isEditable: false,
    deleteAction: deleteRolePermission,
    onDeleteReturnUrl: returnURL,
    listItems: [
      {
        label: "Device",
        displayItem: <Typography>{deviceRolePermission.device.name}</Typography>,
      },
      {
        label: "Role",
        displayItem: <Typography>{deviceRolePermission.role.name}</Typography>,
      },
      {
        label: "Application",
        displayItem: <Typography>{deviceRolePermission.application.name}</Typography>,
      },
      {
        label: "Name",
        displayItem: <Typography>{deviceRolePermission.permission.name}</Typography>,
      },
      {
        label: "Description",
        displayItem: (
          <Typography>{deviceRolePermission.permission.description}</Typography>
        ),
      },
    ],
  }

  return <DiMaDetailsContent {...detailsContentProps} />
}
