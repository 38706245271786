import { Box, Checkbox, Stack, Typography } from "@mui/material"
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid"
import { useCallback, useContext, useState } from "react"
import { Link } from "react-router-dom"
import { useUsers } from "../../../../hooks/useUsers"
import { DiMaProgress } from "../../../components/DiMaProgress"
import { UserContext } from "../../../context/UserContext"
import {
  AccessLevel,
  DimaFeatureShortNameEnum,
  hasUserAccessToFeature,
} from "../../../models/Authorization"
import { UserGet } from "../../../models/User"
import { DiMaAddButton } from "../../partials/DiMaAddButton"
import { EntityTab } from "../../partials/EntityTab"

function getFullName(params: GridValueGetterParams) {
  return `${params.row.first || ""} ${params.row.last || ""}`
}

export function Users() {
  const [onlyWithBackofficeAccess, setOnlyWithBackofficeAccess] = useState<boolean>(false)
  const { usersList, isLoadingUsers } = useUsers(onlyWithBackofficeAccess)
  const { user, isLoadingUser } = useContext(UserContext)

  const onChange = useCallback(() => {
    setOnlyWithBackofficeAccess((backOffice) => !backOffice)
  }, [])

  const columns: GridColDef[] = [
    {
      field: "username",
      headerName: "Email address",
      renderCell: (params) => (
        <Link to={`/users/${params.row.id}`}>{params.row.username}</Link>
      ),
      flex: 1,
    },
    {
      field: "fullName",
      headerName: "Full name",
      valueGetter: getFullName,
      flex: 1,
    },
  ]

  const rows = usersList.map((userDetail: UserGet) => ({
    id: userDetail.id,
    username: userDetail.username,
    first: userDetail.first,
    last: userDetail.last,
  }))

  if (isLoadingUser || !user) {
    return <DiMaProgress />
  }

  const hasAccessToBackOffice = hasUserAccessToFeature(
    user?.authorizations,
    DimaFeatureShortNameEnum.BA,
    AccessLevel.Read
  )

  return (
    <>
      <Box
        className={
          hasAccessToBackOffice ? "DiMaBox-two-elements" : "DiMaBox-one-element-right"
        }
      >
        {hasAccessToBackOffice && (
          <Stack direction="row">
            <Checkbox
              edge="start"
              checked={onlyWithBackofficeAccess}
              onChange={onChange}
            />

            <Typography variant="body1">Only with backoffice access</Typography>
          </Stack>
        )}
        <DiMaAddButton url="/users/create" feature={DimaFeatureShortNameEnum.RM} />
      </Box>
      <EntityTab
        rows={rows}
        cols={columns}
        isLoading={isLoadingUsers || isLoadingUser}
        initialState={{
          sorting: { sortModel: [{ field: "username", sort: "asc" }] },
        }}
      />
    </>
  )
}
