import { Box } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { Link } from "react-router-dom"
import { useDevices } from "../../../../hooks/useDevices"
import { DimaFeatureShortNameEnum } from "../../../models/Authorization"
import { DeviceGet } from "../../../models/Device"
import { DiMaAddButton } from "../../partials/DiMaAddButton"
import { EntityTab } from "../../partials/EntityTab"

export function Devices() {
  const { devicesList, isLoadingDevices } = useDevices()

  const cols: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      renderCell: (params) => (
        <Link to={`/devices/${params.row.id}`}>{params.row.name}</Link>
      ),
      flex: 2,
    },
    {
      field: "slaveId",
      headerName: "Slave ID",
      flex: 1,
    },
    {
      field: "familyName",
      headerName: "Family",
      flex: 1,
    },
  ]

  const rows = devicesList.map((device: DeviceGet) => ({
    id: device.id,
    name: device.name,
    slaveId: device.slaveId,
    familyName: device.family.name,
  }))

  return (
    <>
      <Box className="DiMaBox-one-element-right">
        <DiMaAddButton url="/devices/create" feature={DimaFeatureShortNameEnum.DM} />
      </Box>
      <EntityTab
        rows={rows}
        cols={cols}
        isLoading={isLoadingDevices}
        initialState={{
          sorting: { sortModel: [{ field: "slaveId", sort: "asc" }] },
        }}
      />
    </>
  )
}
