import { TabContext, TabList } from "@mui/lab"
import { Box, Grid } from "@mui/material"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom"
import { digitalManagerApi } from "../../../../../services/api"
import { BackToURL } from "../../../../components/BackToURL"
import {
  DiMaDetailsHeader,
  DiMaDetailsHeaderProps,
} from "../../../../components/DiMaDetailsHeader"
import { DiMaProgress } from "../../../../components/DiMaProgress"
import { DiMaTabPanel } from "../../../../components/DiMaTabPanel"
import { LinkTab } from "../../../../components/LinkTab"
import { UserContext } from "../../../../context/UserContext"
import { ApplicationRoleUserGet } from "../../../../models/ApplicationRoleUser"
import { UserApplicationRoleDetails } from "./details_tab/UserApplicationRoleDetails"
import { UserApplicationRoleProperties } from "./properties_tab/UserApplicationRoleProperties"

export function UserApplicationRoleContainer() {
  const { userId, applicationId, roleId } = useParams()
  const { user, isLoadingUser } = useContext(UserContext)

  const [activeTab, setActiveTab] = useState<string>("#details")
  const [userApplicationRole, setUserApplicationRole] = useState<ApplicationRoleUserGet>()
  const [reloadEntity, setReloadEntity] = useState<boolean>(true)
  const { hash } = useLocation()
  const navigate = useNavigate()
  const returnURL = `/users/${userId}#apps-roles`
  const AVATAR_LENGTH = 2

  useEffect(() => {
    if (userId && applicationId && roleId && reloadEntity === true) {
      digitalManagerApi
        .get<ApplicationRoleUserGet>(
          `/api/v1/users/${userId}/applications/${applicationId}/roles/${roleId}`
        )
        .then((res) => {
          setUserApplicationRole(res.data)
          setReloadEntity(false)
        })
        .catch(() => {
          navigate(returnURL)
        })
    }
  }, [navigate, userId, applicationId, roleId, returnURL, reloadEntity])

  const reloadUserApplicationRole = useCallback(() => setReloadEntity(true), [])

  useEffect(() => {
    if (hash) {
      setActiveTab(hash)
    }
  }, [hash])

  const handleChange = useCallback((_: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue)
  }, [])

  if (!userId || !applicationId || !roleId) {
    return <Navigate to={returnURL} />
  }

  if (!userApplicationRole || !user || isLoadingUser) {
    return <DiMaProgress />
  }

  const detailsHeaderProps: DiMaDetailsHeaderProps = {
    avatar: userApplicationRole.role.name.substring(0, AVATAR_LENGTH),
    title: userApplicationRole.role.name,
    label: "Role ID",
    subtitle: userApplicationRole.role.id,
  }

  return (
    <>
      <BackToURL url={returnURL} label="Back to user apps roles" />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <Grid container>
          <Grid item xs={10}>
            <DiMaDetailsHeader {...detailsHeaderProps} />
          </Grid>
        </Grid>
        <TabContext value={activeTab}>
          <Box className="DiMaBox-tabs">
            <TabList onChange={handleChange}>
              <LinkTab label="Details" value="#details" />
              <LinkTab label="Properties" value="#properties" />
            </TabList>
          </Box>
          <DiMaTabPanel label="#details" activeTab={activeTab}>
            <UserApplicationRoleDetails
              userApplicationRole={userApplicationRole}
              setUserApplicationRole={setUserApplicationRole}
            />
          </DiMaTabPanel>
          <DiMaTabPanel label="#properties" activeTab={activeTab}>
            <UserApplicationRoleProperties
              userApplicationRole={userApplicationRole}
              reloadUserApplicationRole={reloadUserApplicationRole}
              user={user}
            />
          </DiMaTabPanel>
        </TabContext>
      </Box>
    </>
  )
}
