import { useEffect, useState } from "react"
import { NotificationTopicGet } from "../app/models/NotificationTopic"
import { digitalManagerApi } from "../services/api"

export function useNotificationTopics(retrieveAll = false, applicationId?: string) {
  const [notificationTopicsList, setNotificationTopicsList] = useState<
    NotificationTopicGet[]
  >([])
  const [loadingEntities, setLoadingEntities] = useState<boolean>(true)

  useEffect(() => {
    setNotificationTopicsList([])
    setLoadingEntities(true)
    if ((!retrieveAll && applicationId) || retrieveAll) {
      const url =
        !retrieveAll && applicationId
          ? `/api/v1/notificationTopics/applications/${applicationId}`
          : "/api/v1/notificationTopics"

      digitalManagerApi
        .get<NotificationTopicGet[]>(url)
        .then((res) => {
          setNotificationTopicsList(res.data)
        })
        .finally(() => {
          setLoadingEntities(false)
        })
    }
  }, [applicationId, retrieveAll])

  return {
    notificationTopicsList,
    isLoadingNotificationTopics: loadingEntities,
  }
}
