import { Box } from "@mui/material"
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid"
import { Link, useParams } from "react-router-dom"
import { useApprovers } from "../../../../../../hooks/useApprovers"
import { ApplicationRoleApproverGet } from "../../../../../models/ApplicationRoleApprover"
import { DimaFeatureShortNameEnum } from "../../../../../models/Authorization"
import { getBooleanFlagIcon } from "../../../../../utils/getBooleanFlagIcon"
import { DiMaAddButton } from "../../../../partials/DiMaAddButton"
import { EntityTab } from "../../../../partials/EntityTab"

function getFullName(params: GridValueGetterParams) {
  return `${params.row.first || ""} ${params.row.last || ""}`
}

export function ApplicationRoleApprovers() {
  const { applicationId, roleId } = useParams()
  const { approversList, isLoadingApprovers } = useApprovers(applicationId, roleId)

  const columns: GridColDef[] = [
    {
      field: "username",
      headerName: "Email address",
      renderCell: (params) => (
        <Link
          to={`/applications/${applicationId}/roles/${roleId}/approvers/${params.row.userId}`}
        >
          {params.row.username}
        </Link>
      ),
      flex: 2,
    },
    {
      field: "fullName",
      headerName: "Full name",
      valueGetter: getFullName,
      flex: 2,
    },
    {
      field: "hasProperties",
      headerName: "Has properties",
      type: "boolean",
      renderCell: (params) => getBooleanFlagIcon(params.row.hasProperties),
      flex: 2,
    },
  ]

  const rows = approversList.map((approver: ApplicationRoleApproverGet) => ({
    id: approver.user.id,
    userId: approver.user.id,
    username: approver.user.username,
    first: approver.user.first,
    last: approver.user.last,
    hasProperties: approver.properties.length > 0 && approver.properties[0].name !== null,
  }))

  return (
    <>
      <Box className="DiMaBox-one-element-right">
        <DiMaAddButton
          url={`/applications/${applicationId}/roles/${roleId}/approvers/create`}
          applicationId={applicationId}
          feature={DimaFeatureShortNameEnum.AM}
        />
      </Box>
      <EntityTab
        rows={rows}
        cols={columns}
        isLoading={isLoadingApprovers}
        initialState={{
          sorting: {
            sortModel: [{ field: "username", sort: "asc" }],
          },
        }}
      />
    </>
  )
}
