import { TabContext, TabList } from "@mui/lab"
import { Box, Grid } from "@mui/material"
import { StatusCodes } from "http-status-codes"
import { useCallback, useContext, useEffect, useState } from "react"
import { Navigate, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { digitalManagerApi } from "../../../../../../services/api"
import { BackToURL } from "../../../../../components/BackToURL"
import {
  DiMaDetailsHeader,
  DiMaDetailsHeaderProps,
} from "../../../../../components/DiMaDetailsHeader"
import { DiMaProgress } from "../../../../../components/DiMaProgress"
import { DiMaTabPanel } from "../../../../../components/DiMaTabPanel"
import { LinkTab } from "../../../../../components/LinkTab"
import { UserContext } from "../../../../../context/UserContext"
import { ApplicationRolePermissionGet } from "../../../../../models/ApplicationRolePermission"
import { ApplicationRolePermissionDetails } from "./details_tab/ApplicationRolePermissionDetails"
import { ApplicationRolePermissionProperties } from "./properties_tab/ApplicationRolePermissionProperties"

export function ApplicationRolePermissionContainer() {
  const { applicationId, roleId, permissionId } = useParams()
  const { user, isLoadingUser } = useContext(UserContext)
  const [searchParams] = useSearchParams()
  const noDevice = searchParams.get("noDevice") ? searchParams.get("noDevice") : false
  const [applicationRolePermission, setApplicationRolePermission] =
    useState<ApplicationRolePermissionGet>()
  const [reloadEntity, setReloadEntity] = useState<boolean>(true)
  const [activeTab, setActiveTab] = useState<string>(`?noDevice=${noDevice}#details`)
  const returnURL = `/applications/${applicationId}/roles/${roleId}?noDevice=${noDevice}#permissions`
  const navigate = useNavigate()

  const AVATAR_LENGTH = 2

  const handleChange = useCallback((_: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue)
  }, [])

  const reloadApplicationRolePermission = useCallback(() => setReloadEntity(true), [])

  useEffect(() => {
    if (applicationId && roleId && permissionId && noDevice && reloadEntity === true) {
      digitalManagerApi
        .get<ApplicationRolePermissionGet>(
          `/api/v1/applications/${applicationId}/roles/${roleId}/permissions/${permissionId}?noDevice=${noDevice}`
        )
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            setApplicationRolePermission(res.data)
            setReloadEntity(false)
          }
        })
        .catch((err) => {
          if (err.response.status) {
            navigate(returnURL)
          }
        })
    }
  }, [applicationId, navigate, noDevice, permissionId, reloadEntity, returnURL, roleId])

  if (!applicationId || !roleId || !permissionId || !noDevice) {
    return <Navigate to="/applications" />
  }

  if (!applicationRolePermission || isLoadingUser || !user) {
    return <DiMaProgress />
  }

  const detailsHeaderProps: DiMaDetailsHeaderProps = {
    avatar: applicationRolePermission.permission.name.substring(0, AVATAR_LENGTH),
    title: applicationRolePermission.permission.name,
    label: "Permission ID",
    subtitle: applicationRolePermission.permission.id,
  }

  return (
    <>
      <BackToURL url={returnURL} label="Back to permissions" />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <Grid container>
          <Grid item xs={10}>
            <DiMaDetailsHeader {...detailsHeaderProps} />
          </Grid>
        </Grid>
        <TabContext value={activeTab}>
          <Box className="DiMaBox-tabs">
            <TabList onChange={handleChange}>
              <LinkTab
                label="Details"
                value={`?noDevice=${applicationRolePermission.noDevice}#details`}
              />
              <LinkTab
                label="Properties"
                value={`?noDevice=${applicationRolePermission.noDevice}#properties`}
              />
            </TabList>
          </Box>
          <DiMaTabPanel
            label={`?noDevice=${applicationRolePermission.noDevice}#details`}
            activeTab={activeTab}
          >
            <ApplicationRolePermissionDetails
              applicationRolePermission={applicationRolePermission}
              user={user}
            />
          </DiMaTabPanel>
          <DiMaTabPanel
            label={`?noDevice=${applicationRolePermission.noDevice}#properties`}
            activeTab={activeTab}
          >
            <ApplicationRolePermissionProperties
              applicationRolePermission={applicationRolePermission}
              reloadApplicationRolePermission={reloadApplicationRolePermission}
              user={user}
            />
          </DiMaTabPanel>
        </TabContext>
      </Box>
    </>
  )
}
