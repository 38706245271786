import { TabContext, TabList } from "@mui/lab"
import { Box } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { DiMaTabPanel } from "../../components/DiMaTabPanel"
import { LinkTab } from "../../components/LinkTab"
import { Categories } from "./categories_tab/Categories"
import { Devices } from "./devices_tab/Devices"
import { Families } from "./families_tab/Families"

export function DevicesContainer() {
  const [activeTab, setActiveTab] = useState<string>("#devices")
  const { hash } = useLocation()

  useEffect(() => {
    if (hash) {
      setActiveTab(hash)
    }
  }, [hash])

  const handleChange = useCallback((_: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue)
  }, [])

  return (
    <TabContext value={activeTab}>
      <Box className="DiMaBox-tabs">
        <TabList onChange={handleChange}>
          <LinkTab label="Devices" value="#devices" />
          <LinkTab label="Families" value="#families" />
          <LinkTab label="Categories" value="#categories" />
        </TabList>
      </Box>
      <DiMaTabPanel label="#devices" activeTab={activeTab}>
        <Devices />
      </DiMaTabPanel>
      <DiMaTabPanel label="#families" activeTab={activeTab}>
        <Families />
      </DiMaTabPanel>
      <DiMaTabPanel label="#categories" activeTab={activeTab}>
        <Categories />
      </DiMaTabPanel>
    </TabContext>
  )
}
