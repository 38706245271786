import { Guid } from "./CustomType"

export enum NotificationTargetType {
  All = "All",
  Role = "Role",
  Anonymous = "Anonymous",
}

export interface NotificationTargetGet {
  id: Guid
  type: NotificationTargetType
  value?: string
}
