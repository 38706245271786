import { TabContext, TabList } from "@mui/lab"
import { Box } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { DiMaTabPanel } from "../../components/DiMaTabPanel"
import { LinkTab } from "../../components/LinkTab"
import { Notifications } from "./notifications_tab/Notifications"
import { Topics } from "./topics_tab/Topics"

export function NotificationsContainer() {
  const [activeTab, setActiveTab] = useState<string>("#notifications")
  const { hash } = useLocation()

  useEffect(() => {
    if (hash) {
      setActiveTab(hash)
    }
  }, [hash])

  const handleChange = useCallback((_: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue)
  }, [])

  return (
    <TabContext value={activeTab}>
      <Box className="DiMaBox-tabs">
        <TabList onChange={handleChange}>
          <LinkTab label="Notifications" value="#notifications" />
          <LinkTab label="Topics" value="#topics" />
        </TabList>
      </Box>
      <DiMaTabPanel label="#notifications" activeTab={activeTab}>
        <Notifications />
      </DiMaTabPanel>
      <DiMaTabPanel label="#topics" activeTab={activeTab}>
        <Topics />
      </DiMaTabPanel>
    </TabContext>
  )
}
