import { StatusCodes } from "http-status-codes"
import { useEffect, useState } from "react"
import { FactoryGet } from "../app/models/Factory"
import { digitalManagerApi } from "../services/api"

export function useFactories() {
  const [factories, setFactories] = useState<FactoryGet[]>([])
  const [isLoadingFactories, setIsLoadingFactories] = useState<boolean>(true)

  useEffect(() => {
    setIsLoadingFactories(true)
    setFactories([])
    digitalManagerApi
      .get<FactoryGet[]>(`/api/v1/factories`)
      .then((res) => {
        if (res.status === StatusCodes.OK) {
          setFactories(res.data)
        }
      })
      .finally(() => {
        setIsLoadingFactories(false)
      })
  }, [])

  return {
    factories,
    isLoadingFactories,
  }
}
