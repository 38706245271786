import { TabContext, TabList } from "@mui/lab"
import Box from "@mui/material/Box"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom"
import { digitalManagerApi } from "../../../../services/api"
import { BackToURL } from "../../../components/BackToURL"
import {
  DiMaDetailsHeader,
  DiMaDetailsHeaderProps,
} from "../../../components/DiMaDetailsHeader"
import { DiMaProgress } from "../../../components/DiMaProgress"
import { DiMaTabPanel } from "../../../components/DiMaTabPanel"
import { LinkTab } from "../../../components/LinkTab"
import { UserContext } from "../../../context/UserContext"
import { ApplicationGet } from "../../../models/Application"
import { ApplicationDeviceGet } from "../../../models/ApplicationDevice"
import { ApplicationDetails } from "./details_tab/ApplicationDetails"
import { ApplicationDevices } from "./devices_tab/ApplicationDevices"
import { ApplicationPermissions } from "./permissions_tab/ApplicationPermissions"
import { ApplicationProperties } from "./properties_tab/ApplicationProperties"
import { ApplicationRoles } from "./roles_tab/ApplicationRoles"
import { ApplicationUsers } from "./users_tab/ApplicationUsers"

const returnURL = "/applications"

export function ApplicationContainer() {
  const { applicationId } = useParams()
  const { user, isLoadingUser } = useContext(UserContext)
  const [activeTab, setActiveTab] = useState<string>("#details")
  const [app, setApp] = useState<ApplicationGet>()
  const [applicationDevices, setApplicationDevices] = useState<ApplicationDeviceGet>()
  const { hash } = useLocation()
  const navigate = useNavigate()
  const [reloadEntity, setReloadEntity] = useState<boolean>(true)
  const reloadDevices = useCallback(() => setReloadEntity(true), [])

  useEffect(() => {
    if (applicationId) {
      digitalManagerApi
        .get<ApplicationGet>(`/api/v1/applications/${applicationId}`)
        .then((res) => {
          setApp(res.data)
        })
        .catch(() => {
          navigate(returnURL)
        })
    }
  }, [applicationId, navigate])

  useEffect(() => {
    if (applicationId && reloadEntity === true) {
      digitalManagerApi
        .get<ApplicationDeviceGet>(`/api/v1/applications/${applicationId}/devices`)
        .then((res) => {
          setApplicationDevices(res.data)
          setReloadEntity(false)
        })
        .catch(() => {
          navigate(returnURL)
        })
    }
  }, [applicationId, navigate, reloadEntity])

  useEffect(() => {
    if (hash) {
      setActiveTab(hash)
    }
  }, [hash])

  const handleChange = useCallback((_: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue)
  }, [])

  if (!applicationId) {
    return <Navigate to={returnURL} />
  }

  if (!app || !applicationDevices || isLoadingUser || !user) {
    return <DiMaProgress />
  }

  const detailsHeaderProps: DiMaDetailsHeaderProps = {
    avatar: app.shortName,
    title: app.name,
    label: "Application ID",
    subtitle: app.id,
  }

  return (
    <>
      <BackToURL url={returnURL} label="Back to applications" />

      <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <DiMaDetailsHeader {...detailsHeaderProps} />
        <TabContext value={activeTab}>
          <Box className="DiMaBox-tabs">
            <TabList onChange={handleChange}>
              <LinkTab label="Details" value="#details" />
              <LinkTab label="Roles" value="#roles" />
              <LinkTab label="Permissions" value="#permissions" />
              <LinkTab label="Properties" value="#properties" />
              <LinkTab label="Devices" value="#devices" />
              <LinkTab label="Users" value="#users" />
            </TabList>
          </Box>
          <DiMaTabPanel label="#details" activeTab={activeTab}>
            <ApplicationDetails app={app} user={user} setApplication={setApp} />
          </DiMaTabPanel>
          <DiMaTabPanel label="#roles" activeTab={activeTab}>
            <ApplicationRoles />
          </DiMaTabPanel>
          <DiMaTabPanel label="#permissions" activeTab={activeTab}>
            <ApplicationPermissions />
          </DiMaTabPanel>
          <DiMaTabPanel label="#properties" activeTab={activeTab}>
            <ApplicationProperties />
          </DiMaTabPanel>
          <DiMaTabPanel label="#devices" activeTab={activeTab}>
            <ApplicationDevices
              applicationDevices={applicationDevices}
              reloadDevices={reloadDevices}
            />
          </DiMaTabPanel>
          <DiMaTabPanel label="#users" activeTab={activeTab}>
            <ApplicationUsers />
          </DiMaTabPanel>
        </TabContext>
      </Box>
    </>
  )
}
